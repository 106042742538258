import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
};

export type AddCouponCodeToCartInput = {
  coupon_code?: InputMaybe<Scalars['String']>;
  uuid: Scalars['ID'];
};

export type AddImageInput = {
  files: Array<Scalars['String']>;
  squidstudio_category_uuid: Scalars['ID'];
};

export type AddInvoiceInput = {
  invoiceUuid: Scalars['String'];
  orderUuid: Scalars['String'];
};

export type Article = {
  __typename?: 'Article';
  article_category?: Maybe<ArticleCategory>;
  createdAt: Scalars['DateTime'];
  duration: Scalars['String'];
  enabled: Scalars['Boolean'];
  image?: Maybe<Storage>;
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  subtitle: Scalars['String'];
  text: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type ArticleCategory = {
  __typename?: 'ArticleCategory';
  articles?: Maybe<Array<Article>>;
  category: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type ArticleCategoryPagination = {
  __typename?: 'ArticleCategoryPagination';
  nodes: Array<ArticleCategory>;
  totalCount: Scalars['Int'];
};

export type ArticleCategoryPaginationSortBy = {
  category?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
};

export type ArticlePagination = {
  __typename?: 'ArticlePagination';
  nodes: Array<Article>;
  totalCount: Scalars['Int'];
};

export type ArticlePaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type AuthLoginOutput = {
  __typename?: 'AuthLoginOutput';
  accessToken: Scalars['String'];
  user: User;
};

export type BrandModel = {
  __typename?: 'BrandModel';
  car_brand?: Maybe<CarBrand>;
  createdAt: Scalars['DateTime'];
  info_description?: Maybe<Scalars['String']>;
  info_title?: Maybe<Scalars['String']>;
  model_name: Scalars['String'];
  model_years?: Maybe<Array<ModelYear>>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type BrandModelPagination = {
  __typename?: 'BrandModelPagination';
  nodes: Array<BrandModel>;
  totalCount: Scalars['Int'];
};

export type CarBackward = {
  __typename?: 'CarBackward';
  car_backward_types?: Maybe<Array<CarBackwardType>>;
  createdAt: Scalars['DateTime'];
  extra_price: Scalars['Float'];
  info_description?: Maybe<Scalars['String']>;
  info_title?: Maybe<Scalars['String']>;
  installation_difficulty: Scalars['Float'];
  installer_extra_price: Scalars['Float'];
  pictogram?: Maybe<Storage>;
  preview_image?: Maybe<Storage>;
  preview_text?: Maybe<Scalars['String']>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  version_lateral?: Maybe<VersionLateral>;
};

export type CarBackwardOutput = {
  __typename?: 'CarBackwardOutput';
  carBackward: CarBackward;
};

export type CarBackwardPagination = {
  __typename?: 'CarBackwardPagination';
  nodes: Array<CarBackward>;
  totalCount: Scalars['Int'];
};

export type CarBackwardType = {
  __typename?: 'CarBackwardType';
  car_backward?: Maybe<CarBackward>;
  createdAt: Scalars['DateTime'];
  has_rear_camera: Scalars['Boolean'];
  info_description?: Maybe<Scalars['String']>;
  info_title?: Maybe<Scalars['String']>;
  pictogram?: Maybe<Storage>;
  preview_image?: Maybe<Storage>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type CarBackwardTypePagination = {
  __typename?: 'CarBackwardTypePagination';
  nodes: Array<CarBackwardType>;
  totalCount: Scalars['Int'];
};

export type CarBrand = {
  __typename?: 'CarBrand';
  brand_models?: Maybe<Array<BrandModel>>;
  brand_name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  info_description?: Maybe<Scalars['String']>;
  info_title?: Maybe<Scalars['String']>;
  pictogram?: Maybe<Storage>;
  pictogram_dark?: Maybe<Storage>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type CarBrandPagination = {
  __typename?: 'CarBrandPagination';
  nodes: Array<CarBrand>;
  totalCount: Scalars['Int'];
};

export type CarVersion = {
  __typename?: 'CarVersion';
  createdAt: Scalars['DateTime'];
  info_description?: Maybe<Scalars['String']>;
  info_title?: Maybe<Scalars['String']>;
  model_year?: Maybe<ModelYear>;
  pictogram?: Maybe<Storage>;
  preview_text?: Maybe<Scalars['String']>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  version_laterals?: Maybe<Array<VersionLateral>>;
};

export type CarVersionPagination = {
  __typename?: 'CarVersionPagination';
  nodes: Array<CarVersion>;
  totalCount: Scalars['Int'];
};

export type Cart = {
  __typename?: 'Cart';
  billing_address?: Maybe<OrderAddress>;
  browser_uuid: Scalars['String'];
  cart_products?: Maybe<Array<CartProduct>>;
  createdAt: Scalars['DateTime'];
  delivery_address?: Maybe<OrderAddress>;
  delivery_mode: Delivery_Modes;
  discount_amount: Scalars['Float'];
  discount_code?: Maybe<DiscountCode>;
  order_code: Scalars['String'];
  shipping_cost: Scalars['Float'];
  sub_total: Scalars['Float'];
  total_all_vat: Scalars['Float'];
  total_without_vat: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  vat: Scalars['Float'];
};

export type CartProduct = {
  __typename?: 'CartProduct';
  brand_model?: Maybe<BrandModel>;
  car_backward_type?: Maybe<CarBackwardType>;
  car_backwards?: Maybe<CarBackward>;
  car_brand?: Maybe<CarBrand>;
  car_version?: Maybe<CarVersion>;
  createdAt: Scalars['DateTime'];
  custom_design: Scalars['Boolean'];
  design_is_finished: Scalars['Boolean'];
  extra_stickers?: Maybe<Array<ExtraSticker>>;
  graphic_service: Scalars['Boolean'];
  image?: Maybe<Storage>;
  image_pdf?: Maybe<Storage>;
  image_svg?: Maybe<Storage>;
  is_rear_camera_selected: Scalars['Boolean'];
  model_year?: Maybe<ModelYear>;
  note: Scalars['String'];
  product?: Maybe<Product>;
  professional_installer: Scalars['Boolean'];
  quantity: Scalars['Int'];
  section?: Maybe<Section>;
  side_glazing?: Maybe<SideGlazing>;
  size?: Maybe<Size>;
  squid_studio_data: SquidStudioData;
  support?: Maybe<Support>;
  synthetic_design: Scalars['Boolean'];
  total: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  vehicle_information: VehicleInformation;
  verification: Scalars['Boolean'];
  version_lateral?: Maybe<VersionLateral>;
};

export type ChangeOrderStatusInput = {
  status: Ordering_Status;
  uuid: Scalars['ID'];
};

export type ChangeOrderStatusOutput = {
  __typename?: 'ChangeOrderStatusOutput';
  order: Order;
};

export type Configs = {
  __typename?: 'Configs';
  country: Array<DeliveryCountry>;
  createdAt: Scalars['DateTime'];
  facebook_social_link: Scalars['String'];
  graphic_service_cost: Scalars['Float'];
  instagram_social_link: Scalars['String'];
  installer_price: Scalars['Float'];
  shipping_express_cost_percentage?: Maybe<Scalars['Float']>;
  shipping_express_days_number?: Maybe<Scalars['Float']>;
  shipping_standard_cost: Array<DeliveryStandardCost>;
  shipping_standard_days_number?: Maybe<Scalars['Float']>;
  symetrique_design_cost: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  vat: Scalars['Float'];
  verification_cost: Scalars['Float'];
  youtube_social_link: Scalars['String'];
};

export type ConfirmResetPasswordInput = {
  new_password: Scalars['String'];
  token: Scalars['String'];
};

export type CreateArticleCategoryInput = {
  category: Scalars['String'];
};

export type CreateArticleCategoryOutput = {
  __typename?: 'CreateArticleCategoryOutput';
  category: ArticleCategory;
};

export type CreateArticleInput = {
  category_uuid?: InputMaybe<Scalars['String']>;
  duration: Scalars['String'];
  enabled?: Scalars['Boolean'];
  image_uuid?: InputMaybe<Scalars['ID']>;
  meta_description?: InputMaybe<Scalars['String']>;
  meta_title?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  subtitle: Scalars['String'];
  text: Scalars['String'];
  title: Scalars['String'];
};

export type CreateArticleOutput = {
  __typename?: 'CreateArticleOutput';
  article: Article;
};

export type CreateBrandModelInput = {
  car_brand_uuid: Scalars['String'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  model_name: Scalars['String'];
};

export type CreateBrandModelOutput = {
  __typename?: 'CreateBrandModelOutput';
  brandModel: BrandModel;
};

export type CreateCarBackwardInput = {
  extra_price: Scalars['Float'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  installation_difficulty?: InputMaybe<Scalars['Float']>;
  installer_extra_price?: InputMaybe<Scalars['Float']>;
  pictogram_uuid: Scalars['ID'];
  preview_image_uuid: Scalars['ID'];
  preview_text?: InputMaybe<Scalars['String']>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
  version_lateral_uuid: Scalars['String'];
};

export type CreateCarBackwardOutput = {
  __typename?: 'CreateCarBackwardOutput';
  carBackward: CarBackward;
};

export type CreateCarBackwardTypeInput = {
  car_backward_uuid: Scalars['String'];
  has_rear_camera?: Scalars['Boolean'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  pictogram_uuid: Scalars['ID'];
  preview_image_uuid: Scalars['ID'];
  sub_title: Scalars['String'];
  title: Scalars['String'];
};

export type CreateCarBackwardTypeOutput = {
  __typename?: 'CreateCarBackwardTypeOutput';
  carBackwardType: CarBackwardType;
};

export type CreateCarBrandInput = {
  brand_name: Scalars['String'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  pictogram_dark_uuid: Scalars['ID'];
  pictogram_uuid: Scalars['ID'];
};

export type CreateCarBrandOutput = {
  __typename?: 'CreateCarBrandOutput';
  carBrand: CarBrand;
};

export type CreateCarVersionInput = {
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  model_year_uuid: Scalars['String'];
  pictogram_uuid: Scalars['ID'];
  preview_text?: InputMaybe<Scalars['String']>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
};

export type CreateCarVersionOutput = {
  __typename?: 'CreateCarVersionOutput';
  carVersion: CarVersion;
};

export type CreateCartProductInput = {
  brand_model_uuid?: InputMaybe<Scalars['ID']>;
  browser_uuid: Scalars['String'];
  car_backward_type_uuid?: InputMaybe<Scalars['ID']>;
  car_backwards_uuid?: InputMaybe<Scalars['ID']>;
  car_brand_uuid?: InputMaybe<Scalars['ID']>;
  car_version_uuid?: InputMaybe<Scalars['ID']>;
  extra_sticker_uuids?: InputMaybe<Array<Scalars['ID']>>;
  graphic_service?: Scalars['Boolean'];
  image_uuid?: InputMaybe<Scalars['ID']>;
  is_rear_camera_selected?: Scalars['Boolean'];
  model_year_uuid?: InputMaybe<Scalars['ID']>;
  product_uuid: Scalars['ID'];
  professional_installer?: Scalars['Boolean'];
  quantity: Scalars['Int'];
  section_uuid?: InputMaybe<Scalars['ID']>;
  side_glazing_uuid?: InputMaybe<Scalars['ID']>;
  size_uuid?: InputMaybe<Scalars['ID']>;
  support_uuid?: InputMaybe<Scalars['ID']>;
  synthetic_design: Scalars['Boolean'];
  use_image_cart_product_uuid?: InputMaybe<Scalars['ID']>;
  vehicle_information?: InputMaybe<CreateVehicleInformation>;
  verification?: Scalars['Boolean'];
  version_lateral_uuid?: InputMaybe<Scalars['ID']>;
};

export type CreateCartProductOutput = {
  __typename?: 'CreateCartProductOutput';
  cart: Cart;
  cart_product_uuid?: Maybe<Scalars['ID']>;
};

export type CreateDiscountCodeInput = {
  code: Scalars['String'];
  is_percentage?: Scalars['Boolean'];
  max_use_times?: InputMaybe<Scalars['Float']>;
  use_before?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['Float'];
};

export type CreateDiscountCodeOutput = {
  __typename?: 'CreateDiscountCodeOutput';
  discount_code: DiscountCode;
};

export type CreateExtraStickerInput = {
  design_choice_possible?: Scalars['Boolean'];
  extra_price?: Scalars['Float'];
  extra_price_sections?: Array<ExtraStickerSectionPriceInput>;
  help?: Scalars['String'];
  help_title?: Scalars['String'];
  installation_difficulty?: Scalars['Float'];
  installer_extra_price?: Scalars['Float'];
  overview?: Scalars['String'];
  pictogram_uuid?: InputMaybe<Scalars['ID']>;
  subtitle?: Scalars['String'];
  title: Scalars['String'];
  video_url: Scalars['String'];
};

export type CreateExtraStickersInput = {
  extra_stickers: Array<CreateExtraStickerInput>;
  product_uuid: Scalars['ID'];
};

export type CreateExtraStickersOutput = {
  __typename?: 'CreateExtraStickersOutput';
  extra_stickers: Array<ExtraSticker>;
  product_uuid: Scalars['ID'];
};

export type CreateFaqInput = {
  category: FaqCategoriesTypes;
  response: Scalars['String'];
  title: Scalars['String'];
};

export type CreateFaqOutput = {
  __typename?: 'CreateFaqOutput';
  faq: Faq;
};

export type CreateModelYearInput = {
  brand_model_uuid: Scalars['String'];
  end_year: Scalars['String'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  start_year: Scalars['String'];
};

export type CreateModelYearOutput = {
  __typename?: 'CreateModelYearOutput';
  modelYear: ModelYear;
};

export type CreateOrderInput = {
  billing_address: OrderAddressInput;
  browser_uuid: Scalars['String'];
  delivery_address: OrderAddressInput;
  delivery_mode: Delivery_Modes;
  has_quote?: Scalars['Boolean'];
  payment_method: Payment_Methods;
  stripe_card_token?: InputMaybe<Scalars['String']>;
};

export type CreateOrderOutput = {
  __typename?: 'CreateOrderOutput';
  order_uuid: Scalars['ID'];
  payment_link?: Maybe<Scalars['String']>;
  status: Ordering_Status;
};

export type CreatePaymentIntentOutput = {
  __typename?: 'CreatePaymentIntentOutput';
  client_secret: Scalars['String'];
  customer_id: Scalars['String'];
};

export type CreateProductImageInput = {
  extra_sticker_uuid?: InputMaybe<Scalars['String']>;
  image_uuid: Scalars['ID'];
  product_uuid: Scalars['ID'];
  section_uuid?: InputMaybe<Scalars['String']>;
  size_uuid?: InputMaybe<Scalars['String']>;
  support_uuid?: InputMaybe<Scalars['String']>;
};

export type CreateProductImageOutput = {
  __typename?: 'CreateProductImageOutput';
  product_image: ProductImage;
};

export type CreateProductInput = {
  description: Scalars['String'];
  enabled?: Scalars['Boolean'];
  graphic_service?: Scalars['Boolean'];
  image_uuid?: InputMaybe<Scalars['ID']>;
  index?: Scalars['Float'];
  is_perfect_squid?: Scalars['Boolean'];
  meta_description?: InputMaybe<Scalars['String']>;
  meta_title?: InputMaybe<Scalars['String']>;
  professional_installer?: Scalars['Boolean'];
  slug?: InputMaybe<Scalars['String']>;
  start_price?: Scalars['Float'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  type: ProductTypes;
  verification?: Scalars['Boolean'];
};

export type CreateProductOutput = {
  __typename?: 'CreateProductOutput';
  product: Product;
};

export type CreateReviewInput = {
  note: Scalars['Float'];
  orderUuid: Scalars['String'];
  text: Scalars['String'];
};

export type CreateReviewOutput = {
  __typename?: 'CreateReviewOutput';
  review: Review;
};

export type CreateSectionInput = {
  can_have_background?: Scalars['Boolean'];
  canvas_height?: Scalars['Int'];
  canvas_radius?: Scalars['Int'];
  canvas_width?: Scalars['Int'];
  extra_price?: Scalars['Float'];
  extra_price_size?: Array<ExtraSectionSizePriceInput>;
  help?: Scalars['String'];
  help_title?: Scalars['String'];
  installation_difficulty?: Scalars['Float'];
  overview?: Scalars['String'];
  pictogram_uuid?: InputMaybe<Scalars['ID']>;
  subtitle?: Scalars['String'];
  title: Scalars['String'];
  video_url: Scalars['String'];
};

export type CreateSectionsInput = {
  product_uuid: Scalars['ID'];
  sections: Array<CreateSectionInput>;
};

export type CreateSectionsOutput = {
  __typename?: 'CreateSectionsOutput';
  product_uuid: Scalars['ID'];
  sections: Array<Section>;
};

export type CreateSideGlazingInput = {
  extra_price: Scalars['Float'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  installer_extra_price?: InputMaybe<Scalars['Float']>;
  pictogram_uuid: Scalars['ID'];
  preview_image_uuid: Scalars['ID'];
  preview_text?: InputMaybe<Scalars['String']>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
  version_lateral_uuid: Scalars['String'];
};

export type CreateSideGlazingOutput = {
  __typename?: 'CreateSideGlazingOutput';
  sideGlazing: SideGlazing;
};

export type CreateSizeInput = {
  canvas_height?: Scalars['Int'];
  canvas_radius?: Scalars['Int'];
  canvas_width?: Scalars['Int'];
  design_choice_possible?: Scalars['Boolean'];
  help?: Scalars['String'];
  help_title?: Scalars['String'];
  image_uuid?: InputMaybe<Scalars['ID']>;
  index?: Scalars['Float'];
  installation_difficulty?: Scalars['Float'];
  installer_price?: Scalars['Float'];
  need_vehicle_info?: Scalars['Boolean'];
  overview?: Scalars['String'];
  pictogram_uuid?: InputMaybe<Scalars['ID']>;
  price?: Scalars['Float'];
  subtitle?: Scalars['String'];
  title: Scalars['String'];
  video_url?: Scalars['String'];
};

export type CreateSizesInput = {
  product_uuid: Scalars['ID'];
  sizes: Array<CreateSizeInput>;
};

export type CreateSizesOutput = {
  __typename?: 'CreateSizesOutput';
  product_uuid: Scalars['ID'];
  sizes: Array<Size>;
};

export type CreateSquidstudioCategoryInput = {
  icon_uuid: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type CreateSquidstudioShapeCategoryInput = {
  icon_uuid: Scalars['String'];
  title: Scalars['String'];
};

export type CreateSquidstudioShapeInput = {
  background: Scalars['String'];
  category_uuid: Scalars['String'];
  clip_path: Scalars['String'];
  desc: Scalars['String'];
  height: Scalars['String'];
  img_uuid: Scalars['String'];
  width: Scalars['String'];
};

export type CreateSquidstudioTemplateInput = {
  data: Scalars['String'];
  desc: Scalars['String'];
  img_uuid: Scalars['String'];
  sizes?: InputMaybe<Array<SquidstudioTemplateSize>>;
};

export type CreateStorageInput = {
  alternative_title?: Scalars['String'];
  base_64: Scalars['String'];
  caption?: Scalars['String'];
  compress?: Scalars['Boolean'];
  description?: Scalars['String'];
  file_name: Scalars['String'];
};

export type CreateStorageOutput = {
  __typename?: 'CreateStorageOutput';
  storage: Storage;
};

export type CreateSupportInput = {
  can_have_background?: Scalars['Boolean'];
  installation_difficulty?: Scalars['Float'];
  overview?: Scalars['String'];
  pictogram_uuid?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  video_url: Scalars['String'];
};

export type CreateSupportsInput = {
  product_uuid: Scalars['ID'];
  supports: Array<CreateSupportInput>;
};

export type CreateSupportsOutput = {
  __typename?: 'CreateSupportsOutput';
  product_uuid: Scalars['ID'];
  supports: Array<Support>;
};

export type CreateTemplateInput = {
  file_uuid?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type CreateTemplatesInput = {
  product_uuid: Scalars['ID'];
  templates: Array<CreateTemplateInput>;
};

export type CreateTemplatesOutput = {
  __typename?: 'CreateTemplatesOutput';
  product_uuid: Scalars['ID'];
  templates: Array<Template>;
};

export type CreateUserInput = {
  activated?: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Roles;
};

export type CreateUserOutput = {
  __typename?: 'CreateUserOutput';
  user: User;
};

export type CreateVehicleInformation = {
  brand?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type CreateVersionLateralInput = {
  car_version_uuid: Scalars['String'];
  extra_price: Scalars['Float'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  installation_difficulty?: InputMaybe<Scalars['Float']>;
  installer_extra_price?: InputMaybe<Scalars['Float']>;
  pictogram_uuid: Scalars['ID'];
  preview_image_uuid: Scalars['ID'];
  preview_text?: InputMaybe<Scalars['String']>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
};

export type CreateVersionLateralOutput = {
  __typename?: 'CreateVersionLateralOutput';
  versionLateral: VersionLateral;
};

export type DeleteAllNotificationOutput = {
  __typename?: 'DeleteAllNotificationOutput';
  message: Scalars['String'];
};

export type DeleteArticleCategoryOutput = {
  __typename?: 'DeleteArticleCategoryOutput';
  uuid: Scalars['ID'];
};

export type DeleteArticleOutput = {
  __typename?: 'DeleteArticleOutput';
  uuid: Scalars['ID'];
};

export type DeleteBrandModelOutput = {
  __typename?: 'DeleteBrandModelOutput';
  uuid: Scalars['ID'];
};

export type DeleteCArVersionOutput = {
  __typename?: 'DeleteCArVersionOutput';
  uuid: Scalars['ID'];
};

export type DeleteCarBackwardOutput = {
  __typename?: 'DeleteCarBackwardOutput';
  uuid: Scalars['ID'];
};

export type DeleteCarBackwardTypeOutput = {
  __typename?: 'DeleteCarBackwardTypeOutput';
  uuid: Scalars['ID'];
};

export type DeleteCarBrandOutput = {
  __typename?: 'DeleteCarBrandOutput';
  uuid: Scalars['ID'];
};

export type DeleteDiscountCodeOutput = {
  __typename?: 'DeleteDiscountCodeOutput';
  uuid: Scalars['ID'];
};

export type DeleteEstimateNotificationOutput = {
  __typename?: 'DeleteEstimateNotificationOutput';
  estimateUuid: Scalars['String'];
};

export type DeleteExtraStickerOutput = {
  __typename?: 'DeleteExtraStickerOutput';
  uuid: Scalars['ID'];
};

export type DeleteFaqOutput = {
  __typename?: 'DeleteFaqOutput';
  uuid: Scalars['ID'];
};

export type DeleteModelYear = {
  __typename?: 'DeleteModelYear';
  uuid: Scalars['ID'];
};

export type DeleteOrderNotificationOutput = {
  __typename?: 'DeleteOrderNotificationOutput';
  orderUuid: Scalars['String'];
};

export type DeleteOrderOutput = {
  __typename?: 'DeleteOrderOutput';
  uuid: Scalars['ID'];
};

export type DeleteProductImageOutput = {
  __typename?: 'DeleteProductImageOutput';
  uuid: Scalars['ID'];
};

export type DeleteProductOutput = {
  __typename?: 'DeleteProductOutput';
  uuid: Scalars['ID'];
};

export type DeleteSectionOutput = {
  __typename?: 'DeleteSectionOutput';
  uuid: Scalars['ID'];
};

export type DeleteSideGlazingOutput = {
  __typename?: 'DeleteSideGlazingOutput';
  uuid: Scalars['ID'];
};

export type DeleteSizeOutput = {
  __typename?: 'DeleteSizeOutput';
  uuid: Scalars['ID'];
};

export type DeleteSupportOutput = {
  __typename?: 'DeleteSupportOutput';
  uuid: Scalars['ID'];
};

export type DeleteTemplateOutput = {
  __typename?: 'DeleteTemplateOutput';
  uuid: Scalars['ID'];
};

export type DeleteVersionLateralOutput = {
  __typename?: 'DeleteVersionLateralOutput';
  uuid: Scalars['ID'];
};

export type DeliveryCountry = {
  __typename?: 'DeliveryCountry';
  country_code: Scalars['String'];
  country_name: Scalars['String'];
  country_type: Country_Type;
  delivery_cost: Scalars['Float'];
  shipping_days_number?: Maybe<Scalars['Float']>;
  vat: Scalars['Float'];
};

export type DeliveryCountryInput = {
  country_code?: Scalars['String'];
  country_name: Scalars['String'];
  country_type?: Scalars['String'];
  delivery_cost?: Scalars['Float'];
  shipping_days_number?: InputMaybe<Scalars['Float']>;
  vat?: Scalars['Float'];
};

export type DeliveryStandardCost = {
  __typename?: 'DeliveryStandardCost';
  cost: Scalars['Float'];
  end_amount: Scalars['Float'];
  start_amount: Scalars['Float'];
};

export type DeliveryStandardCostInput = {
  cost?: Scalars['Float'];
  end_amount?: Scalars['Float'];
  start_amount?: Scalars['Float'];
};

export type DiscountCode = {
  __typename?: 'DiscountCode';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  is_percentage: Scalars['Boolean'];
  max_use_times: Scalars['Int'];
  saved_money: Scalars['Float'];
  total_buy_amount: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  use_before?: Maybe<Scalars['DateTime']>;
  used_times: Scalars['Int'];
  uuid: Scalars['ID'];
  value: Scalars['Float'];
};

export type DiscountCodeUpdateInput = {
  code: Scalars['String'];
  is_percentage?: Scalars['Boolean'];
  max_use_times?: InputMaybe<Scalars['Float']>;
  use_before?: InputMaybe<Scalars['DateTime']>;
  value: Scalars['Float'];
};

export type DiscountCodesPagination = {
  __typename?: 'DiscountCodesPagination';
  nodes: Array<DiscountCode>;
  totalCount: Scalars['Int'];
};

export type DiscountCodesPaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
};

export type Estimate = {
  __typename?: 'Estimate';
  company_name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description_of_project: Scalars['String'];
  file?: Maybe<Storage>;
  mail: Scalars['String'];
  marquetry: Scalars['String'];
  name: Scalars['String'];
  phonenumber: Scalars['String'];
  status: Estimates_Status;
  subject: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  vehicle: Scalars['String'];
  year_of_your_vehicle: Scalars['String'];
  your_mark: Scalars['String'];
  your_model: Scalars['String'];
};

export type EstimatePagination = {
  __typename?: 'EstimatePagination';
  nodes: Array<Estimate>;
  totalCount: Scalars['Int'];
};

export type EstimatePaginationSortBy = {
  company_name?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
};

export type EstimatesNotification = {
  __typename?: 'EstimatesNotification';
  createdAt: Scalars['DateTime'];
  estimate?: Maybe<Estimate>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type ExtraSectionSizePrice = {
  __typename?: 'ExtraSectionSizePrice';
  extra_price: Scalars['Float'];
  size_uuid: Scalars['ID'];
};

export type ExtraSectionSizePriceInput = {
  extra_price?: Scalars['Float'];
  size_uuid: Scalars['ID'];
};

export type ExtraSticker = {
  __typename?: 'ExtraSticker';
  createdAt: Scalars['DateTime'];
  depends_on_sizes?: Maybe<Array<Size>>;
  design_choice_possible: Scalars['Boolean'];
  extra_price: Scalars['Float'];
  extra_price_sections: Array<ExtraStickerSectionPrice>;
  help: Scalars['String'];
  help_title: Scalars['String'];
  installation_difficulty: Scalars['Float'];
  installer_extra_price: Scalars['Float'];
  overview: Scalars['String'];
  pictogram?: Maybe<Storage>;
  subtitle: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  video_url: Scalars['String'];
};

export type ExtraStickerSectionPrice = {
  __typename?: 'ExtraStickerSectionPrice';
  extra_price: Scalars['Float'];
  section_uuid: Scalars['ID'];
};

export type ExtraStickerSectionPriceInput = {
  extra_price?: Scalars['Float'];
  section_uuid: Scalars['ID'];
};

export type ExtraStickerUpdateDependenciesInput = {
  depends_on_sizes: Array<Scalars['ID']>;
};

export type ExtraStickerUpdateInput = {
  design_choice_possible?: InputMaybe<Scalars['Boolean']>;
  extra_price?: InputMaybe<Scalars['Float']>;
  extra_price_sections?: InputMaybe<Array<ExtraStickerSectionPriceInput>>;
  help?: InputMaybe<Scalars['String']>;
  help_title?: InputMaybe<Scalars['String']>;
  installation_difficulty?: Scalars['Float'];
  installer_extra_price?: InputMaybe<Scalars['Float']>;
  overview?: Scalars['String'];
  pictogram_uuid?: InputMaybe<Scalars['ID']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  video_url?: Scalars['String'];
};

export type Faq = {
  __typename?: 'Faq';
  author?: Maybe<User>;
  category: FaqCategoriesTypes;
  createdAt: Scalars['DateTime'];
  response: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export enum FaqCategoriesTypes {
  AboutAdsquid = 'aboutAdsquid',
  GeneralQuestion = 'generalQuestion',
  ServiceAdsquid = 'serviceAdsquid',
}

export type FaqPage = {
  __typename?: 'FaqPage';
  createdAt: Scalars['DateTime'];
  question: Scalars['String'];
  response: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type FaqPageList = {
  __typename?: 'FaqPageList';
  createdAt: Scalars['DateTime'];
  faqPages?: Maybe<Array<FaqPage>>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type FaqPageListPagination = {
  __typename?: 'FaqPageListPagination';
  nodes: Array<FaqPageList>;
  totalCount: Scalars['Int'];
};

export type FaqPageListPaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type FaqPagePagination = {
  __typename?: 'FaqPagePagination';
  nodes: Array<FaqPage>;
  totalCount: Scalars['Int'];
};

export type FaqPagePaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  question?: InputMaybe<SortDirection>;
  response?: InputMaybe<SortDirection>;
};

export type FaqUpdateInput = {
  category: FaqCategoriesTypes;
  response: Scalars['String'];
  title: Scalars['String'];
};

export type FaqsPagination = {
  __typename?: 'FaqsPagination';
  nodes: Array<Faq>;
  totalCount: Scalars['Int'];
};

export type FaqsPaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type FinishSquidStudioDataInput = {
  image_pdf_uuid?: InputMaybe<Scalars['ID']>;
  image_png_uuid?: InputMaybe<Scalars['ID']>;
  image_svg_uuid?: InputMaybe<Scalars['ID']>;
};

export type Font = {
  __typename?: 'Font';
  createdAt: Scalars['DateTime'];
  response: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type FontPagination = {
  __typename?: 'FontPagination';
  nodes: Array<Font>;
  totalCount: Scalars['Int'];
};

export type FontPaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  response?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type Logo = {
  __typename?: 'Logo';
  createdAt: Scalars['DateTime'];
  link: Scalars['String'];
  logo?: Maybe<Logo>;
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type LogoPagination = {
  __typename?: 'LogoPagination';
  nodes: Array<Logo>;
  totalCount: Scalars['Int'];
};

export type LogoPaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type MessageOutput = {
  __typename?: 'MessageOutput';
  message: Scalars['String'];
};

export type ModelYear = {
  __typename?: 'ModelYear';
  brand_model?: Maybe<BrandModel>;
  car_versions?: Maybe<Array<CarVersion>>;
  createdAt: Scalars['DateTime'];
  end_year: Scalars['String'];
  info_description?: Maybe<Scalars['String']>;
  info_title?: Maybe<Scalars['String']>;
  start_year: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type ModelYearPagination = {
  __typename?: 'ModelYearPagination';
  nodes: Array<ModelYear>;
  totalCount: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  ConfigsUpdate: RegisterConfigsOutput;
  addFileSquidstudioCategory: SquidstudioCategoryOutput;
  addInvoice: Order;
  addSquidStudioData: SquidStudioData;
  articleCategoryCreate: CreateArticleCategoryOutput;
  articleCategoryDelete: DeleteArticleCategoryOutput;
  articleCategoryUpdate: UpdateArticleCategoryOutput;
  articleCreate: CreateArticleOutput;
  articleDelete: DeleteArticleOutput;
  articleUpdate: UpdateArticleOutput;
  authLogin: AuthLoginOutput;
  authLoginAdmin: AuthLoginOutput;
  brandModelCreate: CreateBrandModelOutput;
  brandModelDelete: DeleteBrandModelOutput;
  brandModelUpdate: UpdateBrandModelOutput;
  carBackwardCreate: CreateCarBackwardOutput;
  carBackwardDelete: DeleteCarBackwardOutput;
  carBackwardTypeCreate: CreateCarBackwardTypeOutput;
  carBackwardTypeDelete: DeleteCarBackwardTypeOutput;
  carBackwardTypeUpdate: UpdateCarBackTypeOutput;
  carBackwardUpdate: UpdateCarBackwardOutput;
  carBrandCreate: CreateCarBrandOutput;
  carBrandDelete: DeleteCarBrandOutput;
  carBrandUpdate: UpdateCarBrandOutput;
  carVersionCreate: CreateCarVersionOutput;
  carVersionRemove: DeleteCArVersionOutput;
  carVersionUpdate: UpdateCarVersionOutput;
  cartAddDiscountCode: CreateCartProductOutput;
  cartProductCreate: CreateCartProductOutput;
  cartProductCreateUnAuthentified: CreateCartProductOutput;
  cartProductUpdate: CreateCartProductOutput;
  cartRemoveDiscountCode: CreateCartProductOutput;
  cartUpdate: CreateCartProductOutput;
  confirmResetPassword: ResetPasswordOutput;
  countryUpdate: RegisterConfigsOutput;
  createReview: CreateReviewOutput;
  deleteAllNotifications: DeleteAllNotificationOutput;
  deleteCountry: RegisterConfigsOutput;
  deleteEstimatesNotifications: DeleteEstimateNotificationOutput;
  deleteFaqPage: Scalars['String'];
  deleteInvoice: Scalars['String'];
  deleteOrdersNotifications: DeleteOrderNotificationOutput;
  discountCodeCreate: CreateDiscountCodeOutput;
  discountCodeDelete: DeleteDiscountCodeOutput;
  discountCodeUpdate: UpdateDiscountCodeOutput;
  estimateCreate: RegisterEstimateOutput;
  estimateDelete: MessageOutput;
  estimateUpdate: RegisterEstimateOutput;
  extra_stickerCreate: CreateExtraStickersOutput;
  extra_stickerDelete: DeleteExtraStickerOutput;
  extra_stickerUpdate: UpdateExtraStickerOutput;
  extra_stickerUpdateDependencies: UpdateExtraStickerDependenciesOutput;
  faqCreate: CreateFaqOutput;
  faqDelete: DeleteFaqOutput;
  faqPageCreate: RegisterFaqPageOutput;
  faqPageDelete: MessageOutput;
  faqPageListCreate: RegisterFaqPageListOutput;
  faqPageListDelete: MessageOutput;
  faqPageListUpdate: RegisterFaqPageListOutput;
  faqPageRegister: RegisterPageOutput;
  faqPageUpdate: RegisterFaqPageOutput;
  faqTitleMutation: RegisterPageOutput;
  faqUpdate: UpdateFaqOutput;
  finishSquidStudioData: SquidStudioData;
  fontCreate: RegisterFontOutput;
  fontDelete: MessageOutput;
  fontUpdate: RegisterFontOutput;
  logoCreate: RegisterLogoOutput;
  logoDelete: MessageOutput;
  logoUpdate: RegisterLogoOutput;
  makeOrder: CreateOrderOutput;
  modelYearCreate: CreateModelYearOutput;
  modelYearDelete: DeleteModelYear;
  modelYearUpdate: UpdateModelyearOutput;
  orderDelete: DeleteOrderOutput;
  pagesCreate: RegisterPageOutput;
  pagesDelete: MessageOutput;
  pagesUpdate: RegisterPageOutput;
  productCreate: CreateProductOutput;
  productDelete: DeleteProductOutput;
  productImageCreate: CreateProductImageOutput;
  productImageDelete: DeleteProductImageOutput;
  productUpdate: UpdateProductOutput;
  questionPageCreate: RegisterQuestionPageOutput;
  questionPageDelete: MessageOutput;
  questionPageListCreate: RegisterQuestionPageListOutput;
  questionPageListDelete: MessageOutput;
  questionPageListUpdate: RegisterQuestionPageListOutput;
  questionPageUpdate: RegisterQuestionPageOutput;
  register: AuthLoginOutput;
  removeStorage: StorageDeleteOutput;
  resetPasswordInit: ResetPasswordOutput;
  sectionCreate: CreateSectionsOutput;
  sectionDelete: DeleteSectionOutput;
  sectionUpdate: UpdateSectionOutput;
  sectionUpdateDependencies: UpdateSectionDependenciesOutput;
  sideGlazingCreate: CreateSideGlazingOutput;
  sideGlazingDelete: DeleteSideGlazingOutput;
  sideGlazingUpdate: UpdateSideGlazingOutput;
  sizeCreate: CreateSizesOutput;
  sizeDelete: DeleteSizeOutput;
  sizeUpdate: UpdateSizeOutput;
  sizeUpdateDependencies: UpdateSizeDependenciesOutput;
  squidstudioCategoryCreate: SquidstudioCategoryOutput;
  squidstudioCategoryDelete: MessageOutput;
  squidstudioCategoryUpdate: SquidstudioCategoryOutput;
  squidstudioShapeCategoryCreate: SquidstudioShapeCategoryOutput;
  squidstudioShapeCategoryDelete: MessageOutput;
  squidstudioShapeCategoryUpdate: SquidstudioShapeCategoryOutput;
  squidstudioShapeCreate: SquidstudioShapeOutput;
  squidstudioShapeDelete: MessageOutput;
  squidstudioShapeUpdate: SquidstudioShapeOutput;
  squidstudioTemplateCreate: SquidstudioTemplateOutput;
  squidstudioTemplateDelete: MessageOutput;
  squidstudioTemplateUpdate: SquidstudioTemplateOutput;
  supportCreate: CreateSupportsOutput;
  supportDelete: DeleteSupportOutput;
  supportUpdate: UpdateSupportOutput;
  templateCreate: CreateTemplatesOutput;
  templateDelete: DeleteTemplateOutput;
  updateFaqPage: RegisterPageOutput;
  updateFile: UpdateStorageOutput;
  updateMyPassword: MessageOutput;
  updateOrderQuote: UpdateOrderQuoteOutput;
  updateOrderStatus: ChangeOrderStatusOutput;
  updateProfile: CreateUserOutput;
  updateReview: ReviewUpdateOutput;
  updateTemplate: UpdateTemplateOutput;
  uploadFile: CreateStorageOutput;
  userCreate: CreateUserOutput;
  userDelete: MessageOutput;
  userUpdate: CreateUserOutput;
  validateAccount: AuthLoginOutput;
  verifyPaypal: VerifyPaypalOutput;
  versionLateralCreate: CreateVersionLateralOutput;
  versionLateralDelete: DeleteVersionLateralOutput;
  versionLateralUpdate: UpdateVersionLateralOutput;
  withoutAuthenticationMakeOrder: CreateOrderOutput;
};

export type MutationConfigsUpdateArgs = {
  input: RegisterConfigsInput;
};

export type MutationAddFileSquidstudioCategoryArgs = {
  input: AddImageInput;
};

export type MutationAddInvoiceArgs = {
  input: AddInvoiceInput;
};

export type MutationAddSquidStudioDataArgs = {
  data: Scalars['JSON'];
  uuid: Scalars['ID'];
};

export type MutationArticleCategoryCreateArgs = {
  input: CreateArticleCategoryInput;
};

export type MutationArticleCategoryDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationArticleCategoryUpdateArgs = {
  input: UpdateArticleCategoryInput;
  uuid: Scalars['ID'];
};

export type MutationArticleCreateArgs = {
  input: CreateArticleInput;
};

export type MutationArticleDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationArticleUpdateArgs = {
  input: UpdateArticleInput;
  uuid: Scalars['ID'];
};

export type MutationAuthLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MutationAuthLoginAdminArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type MutationBrandModelCreateArgs = {
  input: CreateBrandModelInput;
};

export type MutationBrandModelDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationBrandModelUpdateArgs = {
  input: UpdateBrandModelInput;
  uuid: Scalars['ID'];
};

export type MutationCarBackwardCreateArgs = {
  input: CreateCarBackwardInput;
};

export type MutationCarBackwardDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationCarBackwardTypeCreateArgs = {
  input: CreateCarBackwardTypeInput;
};

export type MutationCarBackwardTypeDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationCarBackwardTypeUpdateArgs = {
  input: UpdateCarBackwardTypeInput;
  uuid: Scalars['String'];
};

export type MutationCarBackwardUpdateArgs = {
  input: UpdateCarBackwardInput;
  uuid: Scalars['String'];
};

export type MutationCarBrandCreateArgs = {
  input: CreateCarBrandInput;
};

export type MutationCarBrandDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationCarBrandUpdateArgs = {
  input: UpdateCarBrandInput;
  uuid: Scalars['ID'];
};

export type MutationCarVersionCreateArgs = {
  input: CreateCarVersionInput;
};

export type MutationCarVersionRemoveArgs = {
  uuid: Scalars['String'];
};

export type MutationCarVersionUpdateArgs = {
  input: UpdateCarVersionInput;
  uuid: Scalars['String'];
};

export type MutationCartAddDiscountCodeArgs = {
  input: AddCouponCodeToCartInput;
};

export type MutationCartProductCreateArgs = {
  input: CreateCartProductInput;
};

export type MutationCartProductCreateUnAuthentifiedArgs = {
  input: CreateCartProductInput;
};

export type MutationCartProductUpdateArgs = {
  input: UpdateCartProductInput;
};

export type MutationCartRemoveDiscountCodeArgs = {
  input: RemoveCouponCodeToCartInput;
};

export type MutationCartUpdateArgs = {
  input: UpdateCartInput;
};

export type MutationConfirmResetPasswordArgs = {
  input: ConfirmResetPasswordInput;
};

export type MutationCountryUpdateArgs = {
  country_code: Scalars['String'];
  input: DeliveryCountryInput;
};

export type MutationCreateReviewArgs = {
  input: CreateReviewInput;
};

export type MutationDeleteCountryArgs = {
  country_code: Scalars['String'];
};

export type MutationDeleteEstimatesNotificationsArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteFaqPageArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteInvoiceArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteOrdersNotificationsArgs = {
  uuid: Scalars['String'];
};

export type MutationDiscountCodeCreateArgs = {
  input: CreateDiscountCodeInput;
};

export type MutationDiscountCodeDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationDiscountCodeUpdateArgs = {
  input: DiscountCodeUpdateInput;
  uuid: Scalars['ID'];
};

export type MutationEstimateCreateArgs = {
  input: RegisterEstimateInput;
};

export type MutationEstimateDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationEstimateUpdateArgs = {
  input: UpdateEstimateInput;
  uuid: Scalars['ID'];
};

export type MutationExtra_StickerCreateArgs = {
  input: CreateExtraStickersInput;
};

export type MutationExtra_StickerDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationExtra_StickerUpdateArgs = {
  input: ExtraStickerUpdateInput;
  uuid: Scalars['ID'];
};

export type MutationExtra_StickerUpdateDependenciesArgs = {
  input: ExtraStickerUpdateDependenciesInput;
  uuid: Scalars['ID'];
};

export type MutationFaqCreateArgs = {
  input: CreateFaqInput;
};

export type MutationFaqDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationFaqPageCreateArgs = {
  input: RegisterFaqPageInput;
};

export type MutationFaqPageDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationFaqPageListCreateArgs = {
  input: RegisterFaqPageListInput;
};

export type MutationFaqPageListDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationFaqPageListUpdateArgs = {
  input: UpdateFaqPageListInput;
  uuid: Scalars['ID'];
};

export type MutationFaqPageRegisterArgs = {
  input: RegisterFaqPage;
};

export type MutationFaqPageUpdateArgs = {
  input: UpdateFaqPageInput;
  uuid: Scalars['ID'];
};

export type MutationFaqTitleMutationArgs = {
  input: MutationFaqTitle;
};

export type MutationFaqUpdateArgs = {
  input: FaqUpdateInput;
  uuid: Scalars['ID'];
};

export type MutationFinishSquidStudioDataArgs = {
  input: FinishSquidStudioDataInput;
  uuid: Scalars['ID'];
};

export type MutationFontCreateArgs = {
  input: RegisterFontInput;
};

export type MutationFontDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationFontUpdateArgs = {
  input: RegisterFontInput;
  uuid: Scalars['ID'];
};

export type MutationLogoCreateArgs = {
  input: RegisterLogoInput;
};

export type MutationLogoDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationLogoUpdateArgs = {
  input: RegisterLogoInput;
  uuid: Scalars['ID'];
};

export type MutationMakeOrderArgs = {
  input: CreateOrderInput;
};

export type MutationModelYearCreateArgs = {
  input: CreateModelYearInput;
};

export type MutationModelYearDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationModelYearUpdateArgs = {
  input: UpdateModelYearInput;
  uuid: Scalars['String'];
};

export type MutationOrderDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationPagesCreateArgs = {
  input: RegisterPageInput;
};

export type MutationPagesDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationPagesUpdateArgs = {
  input: UpdatePageInput;
  uuid: Scalars['ID'];
};

export type MutationProductCreateArgs = {
  input: CreateProductInput;
};

export type MutationProductDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationProductImageCreateArgs = {
  input: CreateProductImageInput;
};

export type MutationProductImageDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationProductUpdateArgs = {
  input: ProductUpdateInput;
  uuid: Scalars['ID'];
};

export type MutationQuestionPageCreateArgs = {
  input: RegisterQuestionPageInput;
};

export type MutationQuestionPageDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationQuestionPageListCreateArgs = {
  input: RegisterQuestionPageListInput;
};

export type MutationQuestionPageListDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationQuestionPageListUpdateArgs = {
  input: UpdateQuestionPageListInput;
  uuid: Scalars['ID'];
};

export type MutationQuestionPageUpdateArgs = {
  input: UpdateQuestionPageInput;
  uuid: Scalars['ID'];
};

export type MutationRegisterArgs = {
  input: RegisterUserInput;
};

export type MutationRemoveStorageArgs = {
  uuid: Scalars['String'];
};

export type MutationResetPasswordInitArgs = {
  input: ResetPasswordInput;
};

export type MutationSectionCreateArgs = {
  input: CreateSectionsInput;
};

export type MutationSectionDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationSectionUpdateArgs = {
  input: SectionUpdateInput;
  uuid: Scalars['ID'];
};

export type MutationSectionUpdateDependenciesArgs = {
  input: SectionUpdateDependenciesInput;
  uuid: Scalars['ID'];
};

export type MutationSideGlazingCreateArgs = {
  input: CreateSideGlazingInput;
};

export type MutationSideGlazingDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationSideGlazingUpdateArgs = {
  input: UpdateSideGlazingInput;
  uuid: Scalars['String'];
};

export type MutationSizeCreateArgs = {
  input: CreateSizesInput;
};

export type MutationSizeDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationSizeUpdateArgs = {
  input: SizeUpdateInput;
  uuid: Scalars['ID'];
};

export type MutationSizeUpdateDependenciesArgs = {
  input: SizeUpdateDependenciesInput;
  uuid: Scalars['ID'];
};

export type MutationSquidstudioCategoryCreateArgs = {
  input: CreateSquidstudioCategoryInput;
};

export type MutationSquidstudioCategoryDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationSquidstudioCategoryUpdateArgs = {
  input: UpdateSquidstudioCategoryInput;
  uuid: Scalars['ID'];
};

export type MutationSquidstudioShapeCategoryCreateArgs = {
  input: CreateSquidstudioShapeCategoryInput;
};

export type MutationSquidstudioShapeCategoryDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationSquidstudioShapeCategoryUpdateArgs = {
  input: UpdateSquidstudioShapeCategoryInput;
  uuid: Scalars['ID'];
};

export type MutationSquidstudioShapeCreateArgs = {
  input: CreateSquidstudioShapeInput;
};

export type MutationSquidstudioShapeDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationSquidstudioShapeUpdateArgs = {
  input: UpdateSquidstudioShapeInput;
  uuid: Scalars['ID'];
};

export type MutationSquidstudioTemplateCreateArgs = {
  input: CreateSquidstudioTemplateInput;
};

export type MutationSquidstudioTemplateDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationSquidstudioTemplateUpdateArgs = {
  input: UpdateSquidstudioTemplateInput;
  uuid: Scalars['ID'];
};

export type MutationSupportCreateArgs = {
  input: CreateSupportsInput;
};

export type MutationSupportDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationSupportUpdateArgs = {
  input: SupportUpdateInput;
  uuid: Scalars['ID'];
};

export type MutationTemplateCreateArgs = {
  input: CreateTemplatesInput;
};

export type MutationTemplateDeleteArgs = {
  uuid: Scalars['ID'];
};

export type MutationUpdateFaqPageArgs = {
  input: UpdateFaqPage;
};

export type MutationUpdateFileArgs = {
  updateStorageInput: UpdateStorageInput;
  uuid: Scalars['ID'];
};

export type MutationUpdateMyPasswordArgs = {
  input: UpdateMyPasswordDto;
};

export type MutationUpdateOrderQuoteArgs = {
  input: UpdateOrderQuoteInput;
};

export type MutationUpdateOrderStatusArgs = {
  input: ChangeOrderStatusInput;
};

export type MutationUpdateProfileArgs = {
  input: UpdateProfileDto;
};

export type MutationUpdateReviewArgs = {
  input: UpdateReviewInput;
  uuid: Scalars['String'];
};

export type MutationUpdateTemplateArgs = {
  input: TemplateUpdateInput;
  uuid: Scalars['ID'];
};

export type MutationUploadFileArgs = {
  createStorageInput: CreateStorageInput;
};

export type MutationUserCreateArgs = {
  input: CreateUserInput;
};

export type MutationUserDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationUserUpdateArgs = {
  input: UserDtoUpdate;
  uuid: Scalars['ID'];
};

export type MutationValidateAccountArgs = {
  input: ValidateUserInput;
};

export type MutationVerifyPaypalArgs = {
  token: Scalars['ID'];
};

export type MutationVersionLateralCreateArgs = {
  input: CreateVersionLateralInput;
};

export type MutationVersionLateralDeleteArgs = {
  uuid: Scalars['String'];
};

export type MutationVersionLateralUpdateArgs = {
  input: UpdateVersionLateralInput;
  uuid: Scalars['String'];
};

export type MutationWithoutAuthenticationMakeOrderArgs = {
  input: CreateOrderInput;
};

export type MutationFaqTitle = {
  faq_title?: Scalars['String'];
  meta_description?: Scalars['String'];
  meta_title?: Scalars['String'];
  page_uuid: Scalars['ID'];
  question_page?: Scalars['String'];
};

export type Node = {
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type Order = {
  __typename?: 'Order';
  already_add_review: Scalars['Boolean'];
  billing_address?: Maybe<OrderAddress>;
  createdAt: Scalars['DateTime'];
  delivery_address?: Maybe<OrderAddress>;
  delivery_mode: Delivery_Modes;
  discount_amount: Scalars['Float'];
  discount_code?: Maybe<DiscountCode>;
  end_date_of_delivery?: Maybe<Scalars['DateTime']>;
  has_quote: Scalars['Boolean'];
  invoice?: Maybe<Storage>;
  order_code: Scalars['String'];
  order_products?: Maybe<Array<OrderProduct>>;
  ordering_status: Ordering_Status;
  payment_method: Payment_Methods;
  shipping_cost: Scalars['Float'];
  start_date_of_delivery?: Maybe<Scalars['DateTime']>;
  stripe_payment_data?: Maybe<StripePaymentData>;
  sub_total: Scalars['Float'];
  total_all_vat: Scalars['Float'];
  total_without_vat: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  uuid: Scalars['ID'];
  vat: Scalars['Float'];
};

export type OrderAddress = {
  __typename?: 'OrderAddress';
  address: Scalars['String'];
  city: Scalars['String'];
  company_name: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  phonenumber: Scalars['String'];
  postal_code: Scalars['String'];
  tva_number: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type OrderAddressInput = {
  address?: Scalars['String'];
  city?: Scalars['String'];
  company_name?: Scalars['String'];
  country?: Scalars['String'];
  email?: Scalars['String'];
  firstname?: Scalars['String'];
  lastname?: Scalars['String'];
  phonenumber?: Scalars['String'];
  postal_code?: Scalars['String'];
  tva_number?: Scalars['String'];
};

export type OrderByDayObject = {
  __typename?: 'OrderByDayObject';
  quantity: Scalars['Int'];
  quantityPercent: Scalars['Int'];
};

export type OrderByDayOutput = {
  __typename?: 'OrderByDayOutput';
  stats: OrderByDayObject;
};

export type OrderProduct = {
  __typename?: 'OrderProduct';
  brand_model?: Maybe<BrandModel>;
  car_backward?: Maybe<CarBackward>;
  car_backward_type?: Maybe<CarBackwardType>;
  car_brand?: Maybe<CarBrand>;
  car_version?: Maybe<CarVersion>;
  createdAt: Scalars['DateTime'];
  custom_design: Scalars['Boolean'];
  design_is_finished: Scalars['Boolean'];
  extra_stickers?: Maybe<Array<ExtraSticker>>;
  graphic_service: Scalars['Boolean'];
  image?: Maybe<Storage>;
  image_pdf?: Maybe<Storage>;
  image_svg?: Maybe<Storage>;
  is_rear_camera_selected: Scalars['Boolean'];
  model_year?: Maybe<ModelYear>;
  note: Scalars['String'];
  product?: Maybe<Product>;
  professional_installer: Scalars['Boolean'];
  quantity: Scalars['Int'];
  section?: Maybe<Section>;
  side_glazing?: Maybe<SideGlazing>;
  size?: Maybe<Size>;
  squid_studio_data: SquidStudioData;
  support?: Maybe<Support>;
  synthetic_design: Scalars['Boolean'];
  total: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  vehicle_information: VehicleInformation;
  verification: Scalars['Boolean'];
  version_lateral?: Maybe<VersionLateral>;
};

export type OrdersNotification = {
  __typename?: 'OrdersNotification';
  createdAt: Scalars['DateTime'];
  order?: Maybe<Order>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type OrdersPagination = {
  __typename?: 'OrdersPagination';
  nodes: Array<Order>;
  totalCount: Scalars['Int'];
};

export type OrdersPaginationSortBy = {
  amount?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
};

export type Page = {
  __typename?: 'Page';
  createdAt: Scalars['DateTime'];
  faqPageLists?: Maybe<Array<FaqPageList>>;
  meta_description: Scalars['String'];
  meta_title: Scalars['String'];
  name: Scalars['String'];
  questionPageLists?: Maybe<Array<QuestionPageList>>;
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type PagePagination = {
  __typename?: 'PagePagination';
  nodes: Array<Page>;
  totalCount: Scalars['Int'];
};

export type PagePaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  slug?: InputMaybe<SortDirection>;
};

export type Product = {
  __typename?: 'Product';
  canvas_height?: Maybe<Scalars['Float']>;
  canvas_radius?: Maybe<Scalars['Float']>;
  canvas_width?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  extra_stickers?: Maybe<Array<ExtraSticker>>;
  graphic_service: Scalars['Boolean'];
  image?: Maybe<Storage>;
  index: Scalars['Float'];
  is_perfect_squid: Scalars['Boolean'];
  meta_description?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  product_images?: Maybe<Array<ProductImage>>;
  professional_installer: Scalars['Boolean'];
  sections?: Maybe<Array<Section>>;
  sizes?: Maybe<Array<Size>>;
  slug?: Maybe<Scalars['String']>;
  start_price: Scalars['Float'];
  subtitle: Scalars['String'];
  supports?: Maybe<Array<Support>>;
  templates?: Maybe<Array<Template>>;
  title: Scalars['String'];
  type: ProductTypes;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  verification: Scalars['Boolean'];
  video_url?: Maybe<Scalars['String']>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  createdAt: Scalars['DateTime'];
  extra_sticker_uuid?: Maybe<Scalars['ID']>;
  file?: Maybe<Storage>;
  section_uuid?: Maybe<Scalars['ID']>;
  size_uuid?: Maybe<Scalars['ID']>;
  support_uuid?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export enum ProductTypes {
  Removable = 'removable',
  Sticker = 'sticker',
}

export type ProductUpdateInput = {
  canvas_height?: InputMaybe<Scalars['Float']>;
  canvas_radius?: InputMaybe<Scalars['Float']>;
  canvas_width?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  graphic_service?: InputMaybe<Scalars['Boolean']>;
  image_uuid?: InputMaybe<Scalars['ID']>;
  index?: InputMaybe<Scalars['Float']>;
  meta_description?: InputMaybe<Scalars['String']>;
  meta_title?: InputMaybe<Scalars['String']>;
  professional_installer?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  start_price?: InputMaybe<Scalars['Float']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type: ProductTypes;
  verification?: InputMaybe<Scalars['Boolean']>;
  video_url?: InputMaybe<Scalars['String']>;
  video_uuid?: InputMaybe<Scalars['ID']>;
};

export type ProductsPagination = {
  __typename?: 'ProductsPagination';
  nodes: Array<Product>;
  totalCount: Scalars['Int'];
};

export type ProductsPaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type Query = {
  __typename?: 'Query';
  articleCategoryGet: ArticleCategory;
  articleGet: Article;
  articleGetBySlug: Article;
  articlesCategoriesList: ArticleCategoryPagination;
  articlesList: ArticlePagination;
  articlesListAdmin: ArticlePagination;
  brandModelDetail: BrandModel;
  brandModelList: BrandModelPagination;
  carBackwardDetail: CarBackwardOutput;
  carBackwardList: CarBackwardPagination;
  carBackwardTypeDetail: CarBackwardType;
  carBackwardTypeList: CarBackwardTypePagination;
  carBrandDetail: CarBrand;
  carBrandList: CarBrandPagination;
  carVersionDetail: CarVersion;
  carVersionList: CarVersionPagination;
  cartGet: Cart;
  cartGetUnAuthentified: Cart;
  configsGet: Configs;
  createSetupIntent: CreatePaymentIntentOutput;
  discountCodesList: DiscountCodesPagination;
  estimateGet: Estimate;
  estimateList: EstimatePagination;
  estimatesNotificationsGet: Array<EstimatesNotification>;
  faqPageGet: FaqPage;
  faqPageList: FaqPagePagination;
  faqPageListGet: FaqPageList;
  faqPageListList: FaqPageListPagination;
  faqsList: FaqsPagination;
  findAllSquidstudioShape: Array<SquidstudioShape>;
  fontGet: Font;
  fontList: FontPagination;
  getProductsMonthStats: GetProductsMonthStatsOutput;
  getProductsYearsStats: GetProductsYearStatsOutput;
  getProfile: CreateUserOutput;
  getSquidStudioData: SquidStudioData;
  getStatsByDay: OrderByDayOutput;
  listAllVersionLateral: VersionLateralPagination;
  listSideGlazings: SideGlazingPagination;
  logoGet: Logo;
  logoList: LogoPagination;
  modelYearDetail: ModelYear;
  modelYearsList: ModelYearPagination;
  orderGet: Order;
  ordersGetStats: StatsOrderOutput;
  ordersList: OrdersPagination;
  ordersNotificationsGet: Array<OrdersNotification>;
  pagesGet: Page;
  pagesList: PagePagination;
  pagesListWithoutAuth: PagePagination;
  ping: Scalars['String'];
  productGet: Product;
  productGetBySlug: Product;
  productsList: ProductsPagination;
  productsListAdmin: ProductsPagination;
  questionPageGet: QuestionPage;
  questionPageList: QuestionPagePagination;
  questionPageListGet: QuestionPageList;
  questionPageListList: QuestionPageListPagination;
  review: Review;
  reviewList: ReviewPagination;
  reviewListByOrder: ReviewPagination;
  sideGlazingDetail: SideGlazing;
  squidstudioCategoriesList: SquidstudioCategoryPagination;
  squidstudioCategoriesListUnAuthentified: SquidstudioCategoryPagination;
  squidstudioCategoryGet: SquidstudioCategory;
  squidstudioCategoryGetUnAuthentified: SquidstudioCategory;
  squidstudioShapeCategoriesList: SquidstudioShapeCategoryPagination;
  squidstudioShapeCategoriesListUnAuthentified: SquidstudioShapeCategoryPagination;
  squidstudioShapeCategoryGet: SquidstudioShapeCategory;
  squidstudioShapeCategoryGetUnAuthentified: SquidstudioShapeCategory;
  squidstudioShapeGet: SquidstudioShape;
  squidstudioTemplatesList: SquidstudioTemplatePagination;
  storageGet: Storage;
  storagesList: StoragesPagination;
  userGet: User;
  usersList: UsersPagination;
  versionLateralDetail: VersionLateral;
  withoutAuthenticationCartGet: Cart;
};

export type QueryArticleCategoryGetArgs = {
  uuid: Scalars['String'];
};

export type QueryArticleGetArgs = {
  uuid: Scalars['String'];
};

export type QueryArticleGetBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryArticlesCategoriesListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<ArticleCategoryPaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryArticlesListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<ArticlePaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryArticlesListAdminArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<ArticlePaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryBrandModelDetailArgs = {
  uuid: Scalars['String'];
};

export type QueryBrandModelListArgs = {
  car_brand_uuid?: InputMaybe<Scalars['String']>;
};

export type QueryCarBackwardDetailArgs = {
  uuid: Scalars['String'];
};

export type QueryCarBackwardListArgs = {
  version_lateral_uuid?: InputMaybe<Scalars['String']>;
};

export type QueryCarBackwardTypeDetailArgs = {
  uuid: Scalars['String'];
};

export type QueryCarBackwardTypeListArgs = {
  car_backward_uuid?: InputMaybe<Scalars['String']>;
};

export type QueryCarBrandDetailArgs = {
  uuid: Scalars['String'];
};

export type QueryCarVersionDetailArgs = {
  uuid: Scalars['String'];
};

export type QueryCarVersionListArgs = {
  model_year_uuid?: InputMaybe<Scalars['String']>;
};

export type QueryCartGetArgs = {
  browser_uuid: Scalars['String'];
};

export type QueryCartGetUnAuthentifiedArgs = {
  browser_uuid: Scalars['String'];
};

export type QueryCreateSetupIntentArgs = {
  cart_uuid: Scalars['String'];
};

export type QueryDiscountCodesListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<DiscountCodesPaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryEstimateGetArgs = {
  uuid: Scalars['String'];
};

export type QueryEstimateListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<EstimatePaginationSortBy>;
  status?: InputMaybe<Scalars['String']>;
  take: Scalars['Int'];
};

export type QueryFaqPageGetArgs = {
  uuid: Scalars['String'];
};

export type QueryFaqPageListArgs = {
  faq_page_list_uuid?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<FaqPagePaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryFaqPageListGetArgs = {
  uuid: Scalars['String'];
};

export type QueryFaqPageListListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  page_uuid?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<FaqPageListPaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryFaqsListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<FaqsPaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryFindAllSquidstudioShapeArgs = {
  uuid: Scalars['String'];
};

export type QueryFontGetArgs = {
  uuid: Scalars['String'];
};

export type QueryFontListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<FontPaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryGetProductsYearsStatsArgs = {
  year: Scalars['Float'];
};

export type QueryGetSquidStudioDataArgs = {
  uuid: Scalars['ID'];
};

export type QueryListAllVersionLateralArgs = {
  car_version_uuid?: InputMaybe<Scalars['String']>;
};

export type QueryListSideGlazingsArgs = {
  version_lateral_uuid?: InputMaybe<Scalars['String']>;
};

export type QueryLogoGetArgs = {
  uuid: Scalars['String'];
};

export type QueryLogoListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<LogoPaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryModelYearDetailArgs = {
  uuid: Scalars['String'];
};

export type QueryModelYearsListArgs = {
  brand_model_uuid?: InputMaybe<Scalars['String']>;
};

export type QueryOrderGetArgs = {
  uuid: Scalars['String'];
};

export type QueryOrdersListArgs = {
  filterByDeliveryMode?: InputMaybe<Delivery_Modes>;
  filterByPaymentMethod?: InputMaybe<Payment_Methods>;
  filterByQuote?: Scalars['Boolean'];
  filterByStatus?: InputMaybe<Ordering_Status>;
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<OrdersPaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryPagesGetArgs = {
  uuid: Scalars['String'];
};

export type QueryPagesListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  slug?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<PagePaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryPagesListWithoutAuthArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  slug?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<PagePaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryProductGetArgs = {
  uuid: Scalars['String'];
};

export type QueryProductGetBySlugArgs = {
  slug: Scalars['String'];
};

export type QueryProductsListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<ProductsPaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryProductsListAdminArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<ProductsPaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryQuestionPageGetArgs = {
  uuid: Scalars['String'];
};

export type QueryQuestionPageListArgs = {
  faq_page_list_uuid?: InputMaybe<Scalars['String']>;
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<QuestionPagePaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryQuestionPageListGetArgs = {
  uuid: Scalars['String'];
};

export type QueryQuestionPageListListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  page_uuid?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<QuestionPageListPaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryReviewArgs = {
  uuid: Scalars['String'];
};

export type QueryReviewListArgs = {
  display_on_home?: InputMaybe<Scalars['Boolean']>;
  display_on_product?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['Float']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<ReviewPaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryReviewListByOrderArgs = {
  display_on_home?: InputMaybe<Scalars['Boolean']>;
  display_on_product?: InputMaybe<Scalars['Boolean']>;
  keyword?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['Float']>;
  orderUuid: Scalars['String'];
  skip: Scalars['Int'];
  sortBy?: InputMaybe<ReviewPaginationSortBy>;
  take: Scalars['Int'];
};

export type QuerySideGlazingDetailArgs = {
  uuid: Scalars['String'];
};

export type QuerySquidstudioCategoriesListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<SquidstudioCategoryPaginationSortBy>;
  take: Scalars['Int'];
};

export type QuerySquidstudioCategoriesListUnAuthentifiedArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<SquidstudioCategoryPaginationSortBy>;
  take: Scalars['Int'];
};

export type QuerySquidstudioCategoryGetArgs = {
  uuid: Scalars['String'];
};

export type QuerySquidstudioCategoryGetUnAuthentifiedArgs = {
  uuid: Scalars['String'];
};

export type QuerySquidstudioShapeCategoriesListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<SquidstudioShapeCategoryPaginationSortBy>;
  take: Scalars['Int'];
};

export type QuerySquidstudioShapeCategoriesListUnAuthentifiedArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<SquidstudioShapeCategoryPaginationSortBy>;
  take: Scalars['Int'];
};

export type QuerySquidstudioShapeCategoryGetArgs = {
  uuid: Scalars['String'];
};

export type QuerySquidstudioShapeCategoryGetUnAuthentifiedArgs = {
  uuid: Scalars['String'];
};

export type QuerySquidstudioShapeGetArgs = {
  uuid: Scalars['String'];
};

export type QuerySquidstudioTemplatesListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<SquidstudioTemplatePaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryStorageGetArgs = {
  uuid: Scalars['String'];
};

export type QueryStoragesListArgs = {
  filterByMimeType?: InputMaybe<Array<Scalars['String']>>;
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<StoragesPaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryUserGetArgs = {
  uuid: Scalars['String'];
};

export type QueryUsersListArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<UsersPaginationSortBy>;
  take: Scalars['Int'];
};

export type QueryVersionLateralDetailArgs = {
  uuid: Scalars['String'];
};

export type QueryWithoutAuthenticationCartGetArgs = {
  browser_uuid: Scalars['String'];
};

export type QuestionPage = {
  __typename?: 'QuestionPage';
  createdAt: Scalars['DateTime'];
  question: Scalars['String'];
  response: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type QuestionPageList = {
  __typename?: 'QuestionPageList';
  createdAt: Scalars['DateTime'];
  questionsPages?: Maybe<Array<QuestionPage>>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type QuestionPageListPagination = {
  __typename?: 'QuestionPageListPagination';
  nodes: Array<QuestionPageList>;
  totalCount: Scalars['Int'];
};

export type QuestionPageListPaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type QuestionPagePagination = {
  __typename?: 'QuestionPagePagination';
  nodes: Array<QuestionPage>;
  totalCount: Scalars['Int'];
};

export type QuestionPagePaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  question?: InputMaybe<SortDirection>;
  response?: InputMaybe<SortDirection>;
};

export type RegisterConfigsInput = {
  country?: InputMaybe<Array<DeliveryCountryInput>>;
  facebook_social_link?: Scalars['String'];
  graphic_service_cost?: InputMaybe<Scalars['Float']>;
  instagram_social_link?: Scalars['String'];
  installer_price?: InputMaybe<Scalars['Float']>;
  shipping_express_cost_percentage?: InputMaybe<Scalars['Float']>;
  shipping_express_days_number?: InputMaybe<Scalars['Float']>;
  shipping_standard_cost?: InputMaybe<Array<DeliveryStandardCostInput>>;
  shipping_standard_days_number?: InputMaybe<Scalars['Float']>;
  symetrique_design_cost?: InputMaybe<Scalars['Float']>;
  vat?: InputMaybe<Scalars['Float']>;
  verification_cost?: InputMaybe<Scalars['Float']>;
  youtube_social_link?: Scalars['String'];
};

export type RegisterConfigsOutput = {
  __typename?: 'RegisterConfigsOutput';
  configs: Configs;
};

export type RegisterEstimateInput = {
  company_name: Scalars['String'];
  description_of_project?: Scalars['String'];
  file_uuid?: InputMaybe<Scalars['String']>;
  mail: Scalars['String'];
  marquetry?: Scalars['String'];
  name: Scalars['String'];
  phonenumber: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  subject: Scalars['String'];
  vehicle?: Scalars['String'];
  year_of_your_vehicle?: Scalars['String'];
  your_mark?: Scalars['String'];
  your_model?: Scalars['String'];
};

export type RegisterEstimateOutput = {
  __typename?: 'RegisterEstimateOutput';
  estimate: Estimate;
};

export type RegisterFaqPage = {
  is_swipe?: Scalars['Boolean'];
  page_uuid: Scalars['ID'];
  questions_answers: RegisterFaqPageItem;
};

export type RegisterFaqPageInput = {
  faq_page_list_uuid?: InputMaybe<Scalars['String']>;
  question: Scalars['String'];
  response: Scalars['String'];
};

export type RegisterFaqPageItem = {
  question: Scalars['String'];
  response: Scalars['String'];
};

export type RegisterFaqPageListInput = {
  page_uuid?: InputMaybe<Scalars['String']>;
  title?: Scalars['String'];
};

export type RegisterFaqPageListOutput = {
  __typename?: 'RegisterFaqPageListOutput';
  faq_page_list: FaqPageList;
  page: Page;
};

export type RegisterFaqPageOutput = {
  __typename?: 'RegisterFaqPageOutput';
  faq_page: FaqPage;
  faq_page_list: FaqPageList;
};

export type RegisterFontInput = {
  response: Scalars['String'];
  title: Scalars['String'];
};

export type RegisterFontOutput = {
  __typename?: 'RegisterFontOutput';
  font: Font;
};

export type RegisterLogoInput = {
  link: Scalars['String'];
  logo: CreateStorageInput;
  name: Scalars['String'];
};

export type RegisterLogoOutput = {
  __typename?: 'RegisterLogoOutput';
  logo: Logo;
};

export type RegisterPageInput = {
  meta_description?: Scalars['String'];
  meta_title?: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type RegisterPageOutput = {
  __typename?: 'RegisterPageOutput';
  page: Page;
};

export type RegisterQuestionPageInput = {
  question: Scalars['String'];
  question_page_list_uuid: Scalars['String'];
  response: Scalars['String'];
};

export type RegisterQuestionPageListInput = {
  page_uuid?: InputMaybe<Scalars['String']>;
  title?: Scalars['String'];
};

export type RegisterQuestionPageListOutput = {
  __typename?: 'RegisterQuestionPageListOutput';
  question_page_list: QuestionPageList;
};

export type RegisterQuestionPageOutput = {
  __typename?: 'RegisterQuestionPageOutput';
  question_page: QuestionPage;
};

export type RegisterUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};

export type RemoveCouponCodeToCartInput = {
  coupon_code?: InputMaybe<Scalars['String']>;
  uuid: Scalars['ID'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
};

export type ResetPasswordOutput = {
  __typename?: 'ResetPasswordOutput';
  message: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  createdAt: Scalars['DateTime'];
  display_on_home: Scalars['Boolean'];
  display_on_product: Scalars['Boolean'];
  note: Scalars['Float'];
  order?: Maybe<Order>;
  products?: Maybe<Array<Product>>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  uuid: Scalars['ID'];
};

export type ReviewPagination = {
  __typename?: 'ReviewPagination';
  nodes: Array<Review>;
  totalCount: Scalars['Int'];
};

export type ReviewPaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  note?: InputMaybe<SortDirection>;
};

export type ReviewUpdateOutput = {
  __typename?: 'ReviewUpdateOutput';
  review: Review;
};

export enum Roles {
  Admin = 'admin',
  Customer = 'customer',
  Edimaster = 'edimaster',
}

export type Section = {
  __typename?: 'Section';
  can_have_background: Scalars['Boolean'];
  canvas_height: Scalars['Int'];
  canvas_radius: Scalars['Int'];
  canvas_width: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  depends_on_sizes?: Maybe<Array<Size>>;
  extra_price: Scalars['Float'];
  extra_price_size: Array<ExtraSectionSizePrice>;
  help: Scalars['String'];
  help_title: Scalars['String'];
  installation_difficulty: Scalars['Float'];
  overview: Scalars['String'];
  pictogram?: Maybe<Storage>;
  subtitle: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  video_url: Scalars['String'];
};

export type SectionUpdateDependenciesInput = {
  depends_on_sizes: Array<Scalars['ID']>;
};

export type SectionUpdateInput = {
  can_have_background?: Scalars['Boolean'];
  canvas_height?: InputMaybe<Scalars['Int']>;
  canvas_radius?: InputMaybe<Scalars['Int']>;
  canvas_width?: InputMaybe<Scalars['Int']>;
  extra_price?: InputMaybe<Scalars['Float']>;
  extra_price_ssize?: InputMaybe<Array<ExtraSectionSizePriceInput>>;
  help?: InputMaybe<Scalars['String']>;
  help_title?: InputMaybe<Scalars['String']>;
  installation_difficulty?: Scalars['Float'];
  overview?: Scalars['String'];
  pictogram_uuid?: InputMaybe<Scalars['ID']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  video_url?: Scalars['String'];
};

export type SideGlazing = {
  __typename?: 'SideGlazing';
  createdAt: Scalars['DateTime'];
  extra_price: Scalars['Float'];
  info_description?: Maybe<Scalars['String']>;
  info_title?: Maybe<Scalars['String']>;
  installer_extra_price: Scalars['Float'];
  pictogram?: Maybe<Storage>;
  preview_image?: Maybe<Storage>;
  preview_text?: Maybe<Scalars['String']>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  version_lateral?: Maybe<VersionLateral>;
};

export type SideGlazingPagination = {
  __typename?: 'SideGlazingPagination';
  nodes: Array<SideGlazing>;
  totalCount: Scalars['Int'];
};

export type Size = {
  __typename?: 'Size';
  canvas_height: Scalars['Int'];
  canvas_radius: Scalars['Int'];
  canvas_width: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  depends_on_supports?: Maybe<Array<Support>>;
  design_choice_possible: Scalars['Boolean'];
  help: Scalars['String'];
  help_title: Scalars['String'];
  image?: Maybe<Storage>;
  index: Scalars['Float'];
  installation_difficulty: Scalars['Float'];
  installer_price: Scalars['Float'];
  need_vehicle_info: Scalars['Boolean'];
  overview: Scalars['String'];
  pictogram?: Maybe<Storage>;
  price: Scalars['Float'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  video_url?: Maybe<Scalars['String']>;
};

export type SizeUpdateDependenciesInput = {
  depends_on_supports: Array<Scalars['ID']>;
};

export type SizeUpdateInput = {
  canvas_height?: InputMaybe<Scalars['Int']>;
  canvas_radius?: InputMaybe<Scalars['Int']>;
  canvas_width?: InputMaybe<Scalars['Int']>;
  design_choice_possible?: InputMaybe<Scalars['Boolean']>;
  help?: InputMaybe<Scalars['String']>;
  help_title?: InputMaybe<Scalars['String']>;
  image_uuid?: InputMaybe<Scalars['ID']>;
  index?: Scalars['Float'];
  installation_difficulty?: Scalars['Float'];
  installer_price?: InputMaybe<Scalars['Float']>;
  need_vehicle_info?: InputMaybe<Scalars['Boolean']>;
  overview?: Scalars['String'];
  pictogram_uuid?: InputMaybe<Scalars['ID']>;
  price?: InputMaybe<Scalars['Float']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  video_url?: Scalars['String'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SquidStudioData = {
  __typename?: 'SquidStudioData';
  can_have_background: Scalars['Boolean'];
  data?: Maybe<Scalars['JSON']>;
  height: Scalars['Int'];
  note: Scalars['String'];
  radius: Scalars['Int'];
  verification: Scalars['Boolean'];
  width: Scalars['Int'];
};

export type SquidstudioCategory = {
  __typename?: 'SquidstudioCategory';
  createdAt: Scalars['DateTime'];
  files?: Maybe<Array<Storage>>;
  icon?: Maybe<Storage>;
  title: Scalars['String'];
  type: Squidstudio_Categories_Types;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type SquidstudioCategoryOutput = {
  __typename?: 'SquidstudioCategoryOutput';
  squidstudio_category: SquidstudioCategory;
};

export type SquidstudioCategoryPagination = {
  __typename?: 'SquidstudioCategoryPagination';
  nodes: Array<SquidstudioCategory>;
  totalCount: Scalars['Int'];
};

export type SquidstudioCategoryPaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type SquidstudioShape = {
  __typename?: 'SquidstudioShape';
  background: Scalars['String'];
  clipPath: Scalars['String'];
  createdAt: Scalars['DateTime'];
  desc: Scalars['String'];
  height: Scalars['String'];
  img?: Maybe<Storage>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  width: Scalars['String'];
};

export type SquidstudioShapeCategory = {
  __typename?: 'SquidstudioShapeCategory';
  createdAt: Scalars['DateTime'];
  icon?: Maybe<Storage>;
  shapes?: Maybe<Array<SquidstudioShape>>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type SquidstudioShapeCategoryOutput = {
  __typename?: 'SquidstudioShapeCategoryOutput';
  squidstudio_shape_category: SquidstudioShapeCategory;
};

export type SquidstudioShapeCategoryPagination = {
  __typename?: 'SquidstudioShapeCategoryPagination';
  nodes: Array<SquidstudioShapeCategory>;
  totalCount: Scalars['Int'];
};

export type SquidstudioShapeCategoryPaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type SquidstudioShapeOutput = {
  __typename?: 'SquidstudioShapeOutput';
  squidstudio_shape: SquidstudioShape;
};

export type SquidstudioTemplate = {
  __typename?: 'SquidstudioTemplate';
  createdAt: Scalars['DateTime'];
  data: Scalars['String'];
  desc: Scalars['String'];
  img?: Maybe<Storage>;
  sizes: Array<SquidstudioTemplateSizeInput>;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type SquidstudioTemplateOutput = {
  __typename?: 'SquidstudioTemplateOutput';
  squidstudio_template: SquidstudioTemplate;
};

export type SquidstudioTemplatePagination = {
  __typename?: 'SquidstudioTemplatePagination';
  nodes: Array<SquidstudioTemplate>;
  totalCount: Scalars['Int'];
};

export type SquidstudioTemplatePaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type SquidstudioTemplateSize = {
  height?: Scalars['Int'];
  width?: Scalars['Int'];
};

export type SquidstudioTemplateSizeInput = {
  __typename?: 'SquidstudioTemplateSizeInput';
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type StatsObject = {
  __typename?: 'StatsObject';
  quantity: Scalars['Int'];
  total: Scalars['Float'];
};

export type StatsOrderOutput = {
  __typename?: 'StatsOrderOutput';
  delivered: StatsObject;
  draft: StatsObject;
  order_in_printing: StatsObject;
  order_in_verification: StatsObject;
  paid: StatsObject;
  shipped: StatsObject;
  total: StatsObject;
  waiting: StatsObject;
  waiting_for_bat: StatsObject;
  waiting_for_visuel: StatsObject;
};

export type Storage = {
  __typename?: 'Storage';
  alternative_title: Scalars['String'];
  caption: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  file_name: Scalars['String'];
  height: Scalars['Int'];
  medium: StorageThumbnail;
  mime_type: Scalars['String'];
  size: Scalars['Int'];
  thumbnail: StorageThumbnail;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
  uuid: Scalars['ID'];
  width: Scalars['Int'];
};

export type StorageDeleteOutput = {
  __typename?: 'StorageDeleteOutput';
  uuid: Scalars['ID'];
};

export type StorageThumbnail = {
  __typename?: 'StorageThumbnail';
  file_name: Scalars['String'];
  height: Scalars['Int'];
  size: Scalars['Int'];
  url: Scalars['String'];
  width: Scalars['Int'];
};

export type StoragesPagination = {
  __typename?: 'StoragesPagination';
  nodes: Array<Storage>;
  totalCount: Scalars['Int'];
};

export type StoragesPaginationSortBy = {
  alternative_title?: InputMaybe<SortDirection>;
  caption?: InputMaybe<SortDirection>;
  createdAt?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  file_name?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type StripePaymentData = {
  __typename?: 'StripePaymentData';
  balance_transaction?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  payment_link?: Maybe<Scalars['String']>;
  payment_method?: Maybe<Scalars['String']>;
  receipt_url?: Maybe<Scalars['String']>;
  source?: Maybe<StripePaymentSource>;
  status?: Maybe<Scalars['String']>;
};

export type StripePaymentSource = {
  __typename?: 'StripePaymentSource';
  brand?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
};

export type Support = {
  __typename?: 'Support';
  can_have_background: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  extra_price: Scalars['Float'];
  installation_difficulty: Scalars['Float'];
  overview: Scalars['String'];
  pictogram?: Maybe<Storage>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  video_url: Scalars['String'];
};

export type SupportUpdateInput = {
  can_have_background?: InputMaybe<Scalars['Boolean']>;
  installation_difficulty?: Scalars['Float'];
  overview?: Scalars['String'];
  pictogram_uuid?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  video_url?: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  createdAt: Scalars['DateTime'];
  file?: Maybe<Storage>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type TemplateUpdateInput = {
  file_uuid?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateArticleCategoryInput = {
  category: Scalars['String'];
};

export type UpdateArticleCategoryOutput = {
  __typename?: 'UpdateArticleCategoryOutput';
  category: ArticleCategory;
};

export type UpdateArticleInput = {
  category_uuid?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  enabled?: Scalars['Boolean'];
  image_uuid?: InputMaybe<Scalars['ID']>;
  meta_description?: InputMaybe<Scalars['String']>;
  meta_title?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateArticleOutput = {
  __typename?: 'UpdateArticleOutput';
  article: Article;
};

export type UpdateBrandModelInput = {
  car_brand_uuid: Scalars['String'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  model_name: Scalars['String'];
};

export type UpdateBrandModelOutput = {
  __typename?: 'UpdateBrandModelOutput';
  brandModel: BrandModel;
};

export type UpdateCarBackTypeOutput = {
  __typename?: 'UpdateCarBackTypeOutput';
  carBackwardType: CarBackwardType;
};

export type UpdateCarBackwardInput = {
  extra_price: Scalars['Float'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  installation_difficulty?: InputMaybe<Scalars['Float']>;
  installer_extra_price?: InputMaybe<Scalars['Float']>;
  pictogram_uuid: Scalars['ID'];
  preview_image_uuid: Scalars['ID'];
  preview_text?: InputMaybe<Scalars['String']>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
  version_lateral_uuid: Scalars['String'];
};

export type UpdateCarBackwardOutput = {
  __typename?: 'UpdateCarBackwardOutput';
  carBackward: CarBackward;
};

export type UpdateCarBackwardTypeInput = {
  car_backward_uuid: Scalars['String'];
  has_rear_camera?: Scalars['Boolean'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  pictogram_uuid: Scalars['ID'];
  preview_image_uuid: Scalars['ID'];
  sub_title: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateCarBrandInput = {
  brand_name: Scalars['String'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  pictogram_dark_uuid: Scalars['ID'];
  pictogram_uuid: Scalars['ID'];
};

export type UpdateCarBrandOutput = {
  __typename?: 'UpdateCarBrandOutput';
  carBrand: CarBrand;
};

export type UpdateCarVersionInput = {
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  model_year_uuid: Scalars['String'];
  pictogram_uuid: Scalars['ID'];
  preview_text?: InputMaybe<Scalars['String']>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateCarVersionOutput = {
  __typename?: 'UpdateCarVersionOutput';
  carVersion: CarVersion;
};

export type UpdateCartInput = {
  billing_address?: InputMaybe<OrderAddressInput>;
  delivery_address?: InputMaybe<OrderAddressInput>;
  delivery_mode?: InputMaybe<Delivery_Modes>;
  uuid: Scalars['ID'];
};

export type UpdateCartProductInput = {
  cart_product_uuid: Scalars['ID'];
  cart_uuid: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type UpdateDiscountCodeOutput = {
  __typename?: 'UpdateDiscountCodeOutput';
  discount_code: DiscountCode;
};

export type UpdateEstimateInput = {
  company_name?: InputMaybe<Scalars['String']>;
  description_of_project?: InputMaybe<Scalars['String']>;
  file_uuid?: InputMaybe<Scalars['String']>;
  mail?: InputMaybe<Scalars['String']>;
  marquetry?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phonenumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Scalars['String']>;
  vehicle?: InputMaybe<Scalars['String']>;
  year_of_your_vehicle?: InputMaybe<Scalars['String']>;
  your_mark?: InputMaybe<Scalars['String']>;
  your_model?: InputMaybe<Scalars['String']>;
};

export type UpdateExtraStickerDependenciesOutput = {
  __typename?: 'UpdateExtraStickerDependenciesOutput';
  extra_sticker: ExtraSticker;
};

export type UpdateExtraStickerOutput = {
  __typename?: 'UpdateExtraStickerOutput';
  extra_sticker: ExtraSticker;
};

export type UpdateFaqOutput = {
  __typename?: 'UpdateFaqOutput';
  faq: Faq;
};

export type UpdateFaqPage = {
  is_swipe: Scalars['Boolean'];
  page_uuid: Scalars['String'];
  questions_answers: UpdateFaqPageItem;
};

export type UpdateFaqPageInput = {
  faq_page_list_uuid?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
};

export type UpdateFaqPageItem = {
  question: Scalars['String'];
  response: Scalars['String'];
  uuid: Scalars['String'];
};

export type UpdateFaqPageListInput = {
  page_uuid?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateModelYearInput = {
  brand_model_uuid: Scalars['String'];
  end_year: Scalars['String'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  start_year: Scalars['String'];
};

export type UpdateModelyearOutput = {
  __typename?: 'UpdateModelyearOutput';
  modelYear: ModelYear;
};

export type UpdateMyPasswordDto = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type UpdateOrderQuoteInput = {
  has_quote: Scalars['Boolean'];
  order_uuid: Scalars['String'];
  payment_method?: InputMaybe<Payment_Methods>;
};

export type UpdateOrderQuoteOutput = {
  __typename?: 'UpdateOrderQuoteOutput';
  order: Order;
};

export type UpdatePageInput = {
  meta_description?: InputMaybe<Scalars['String']>;
  meta_title?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type UpdateProductOutput = {
  __typename?: 'UpdateProductOutput';
  product: Product;
};

export type UpdateProfileDto = {
  address?: Scalars['String'];
  city?: Scalars['String'];
  company_name?: Scalars['String'];
  country?: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  phonenumber?: Scalars['String'];
  postal_code?: Scalars['String'];
  vehicles?: InputMaybe<Array<CreateVehicleInformation>>;
};

export type UpdateQuestionPageInput = {
  question?: InputMaybe<Scalars['String']>;
  question_page_list_uuid?: InputMaybe<Scalars['String']>;
  response?: InputMaybe<Scalars['String']>;
};

export type UpdateQuestionPageListInput = {
  page_uuid?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateReviewInput = {
  display_on_home?: Scalars['Boolean'];
  display_on_product?: Scalars['Boolean'];
};

export type UpdateSectionDependenciesOutput = {
  __typename?: 'UpdateSectionDependenciesOutput';
  section: Section;
};

export type UpdateSectionOutput = {
  __typename?: 'UpdateSectionOutput';
  section: Section;
};

export type UpdateSideGlazingInput = {
  extra_price: Scalars['Float'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  installer_extra_price?: InputMaybe<Scalars['Float']>;
  pictogram_uuid: Scalars['ID'];
  preview_image_uuid: Scalars['ID'];
  preview_text?: InputMaybe<Scalars['String']>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
  version_lateral_uuid: Scalars['String'];
};

export type UpdateSideGlazingOutput = {
  __typename?: 'UpdateSideGlazingOutput';
  sideGlazing: SideGlazing;
};

export type UpdateSizeDependenciesOutput = {
  __typename?: 'UpdateSizeDependenciesOutput';
  size: Size;
};

export type UpdateSizeOutput = {
  __typename?: 'UpdateSizeOutput';
  size: Size;
};

export type UpdateSquidstudioCategoryInput = {
  files_uuid?: Array<Scalars['String']>;
  icon_uuid: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type UpdateSquidstudioShapeCategoryInput = {
  icon_uuid: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type UpdateSquidstudioShapeInput = {
  background: Scalars['String'];
  category_uuid: Scalars['String'];
  clip_path: Scalars['String'];
  desc: Scalars['String'];
  height: Scalars['String'];
  img_uuid: Scalars['String'];
  width: Scalars['String'];
};

export type UpdateSquidstudioTemplateInput = {
  data: Scalars['String'];
  desc: Scalars['String'];
  img_uuid: Scalars['String'];
  sizes?: InputMaybe<Array<SquidstudioTemplateSize>>;
};

export type UpdateStorageInput = {
  alternative_title?: Scalars['String'];
  caption?: Scalars['String'];
  description?: Scalars['String'];
  file_name: Scalars['String'];
};

export type UpdateStorageOutput = {
  __typename?: 'UpdateStorageOutput';
  storage: Storage;
};

export type UpdateSupportOutput = {
  __typename?: 'UpdateSupportOutput';
  support: Support;
};

export type UpdateTemplateOutput = {
  __typename?: 'UpdateTemplateOutput';
  template: Template;
};

export type UpdateVersionLateralInput = {
  car_version_uui: Scalars['String'];
  extra_price: Scalars['Float'];
  info_description?: InputMaybe<Scalars['String']>;
  info_title?: InputMaybe<Scalars['String']>;
  installation_difficulty?: InputMaybe<Scalars['Float']>;
  installer_extra_price?: InputMaybe<Scalars['Float']>;
  pictogram_uuid: Scalars['ID'];
  preview_image_uuid: Scalars['ID'];
  preview_text?: InputMaybe<Scalars['String']>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateVersionLateralOutput = {
  __typename?: 'UpdateVersionLateralOutput';
  versionLateral: VersionLateral;
};

export type User = {
  __typename?: 'User';
  activated: Scalars['Boolean'];
  address: Scalars['String'];
  city: Scalars['String'];
  company_name: Scalars['String'];
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phonenumber: Scalars['String'];
  postal_code: Scalars['String'];
  role: Roles;
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
  vehicles: Array<VehicleInformation>;
};

export type UserDtoUpdate = {
  activated?: Scalars['Boolean'];
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export type UsersPagination = {
  __typename?: 'UsersPagination';
  nodes: Array<User>;
  totalCount: Scalars['Int'];
};

export type UsersPaginationSortBy = {
  createdAt?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  firstName?: InputMaybe<SortDirection>;
  lastName?: InputMaybe<SortDirection>;
  role?: InputMaybe<SortDirection>;
};

export type ValidateUserInput = {
  token: Scalars['String'];
};

export type VehicleInformation = {
  __typename?: 'VehicleInformation';
  brand?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type VerifyPaypalOutput = {
  __typename?: 'VerifyPaypalOutput';
  status: Scalars['String'];
};

export type VersionLateral = {
  __typename?: 'VersionLateral';
  car_version?: Maybe<CarVersion>;
  cars_backwards?: Maybe<Array<CarBackward>>;
  createdAt: Scalars['DateTime'];
  extra_price: Scalars['Float'];
  info_description?: Maybe<Scalars['String']>;
  info_title?: Maybe<Scalars['String']>;
  installation_difficulty: Scalars['Float'];
  installer_extra_price: Scalars['Float'];
  pictogram?: Maybe<Storage>;
  preview_image?: Maybe<Storage>;
  preview_text?: Maybe<Scalars['String']>;
  side_glazings?: Maybe<Array<SideGlazing>>;
  sub_title: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  uuid: Scalars['ID'];
};

export type VersionLateralPagination = {
  __typename?: 'VersionLateralPagination';
  nodes: Array<VersionLateral>;
  totalCount: Scalars['Int'];
};

export enum Country_Type {
  DomTom = 'DOM_TOM',
  EuroCountries = 'EURO_COUNTRIES',
}

export enum Delivery_Modes {
  Express = 'EXPRESS',
  Standard = 'STANDARD',
}

export enum Estimates_Status {
  Concluant = 'CONCLUANT',
  EncoursDeTraitement = 'ENCOURS_DE_TRAITEMENT',
  Envoyer = 'ENVOYER',
  NonConcluant = 'NON_CONCLUANT',
  Nouveau = 'NOUVEAU',
}

export type GetProductsMonsthStatsObject = {
  __typename?: 'getProductsMonsthStatsObject';
  quantity: Array<Product>;
  quantityPercent: Scalars['Float'];
  totalHT: Scalars['Float'];
  totalPercent: Scalars['Float'];
  totalTTC: Scalars['Float'];
};

export type GetProductsMonthStatsOutput = {
  __typename?: 'getProductsMonthStatsOutput';
  stats: GetProductsMonsthStatsObject;
};

export type GetProductsYearStatsObject = {
  __typename?: 'getProductsYearStatsObject';
  quantityPercent: Scalars['Float'];
  quantityTab: Array<Scalars['Int']>;
};

export type GetProductsYearStatsOutput = {
  __typename?: 'getProductsYearStatsOutput';
  stats: GetProductsYearStatsObject;
};

export enum Ordering_Status {
  Cancelled = 'CANCELLED',
  Deelivered = 'DEELIVERED',
  Failed = 'FAILED',
  OrderInPrinting = 'ORDER_IN_PRINTING',
  OrderInVerification = 'ORDER_IN_VERIFICATION',
  Paid = 'PAID',
  Pending = 'PENDING',
  Shipped = 'SHIPPED',
  Waiting = 'WAITING',
  WaitingForBat = 'WAITING_FOR_BAT',
  WaitingForTheValidationOfTheProof = 'WAITING_FOR_THE_VALIDATION_OF_THE_PROOF',
  WaitingForVisuel = 'WAITING_FOR_VISUEL',
}

export enum Payment_Methods {
  BankTransfer = 'BANK_TRANSFER',
  NoPayment = 'NO_PAYMENT',
  Paypal = 'PAYPAL',
  Stripe = 'STRIPE',
}

export enum Squidstudio_Categories_Types {
  Background = 'BACKGROUND',
  Font = 'FONT',
  ImageBank = 'IMAGE_BANK',
  Pictogram = 'PICTOGRAM',
  Shape = 'SHAPE',
}

export type AuthLoginAdminMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;

export type AuthLoginAdminMutation = {
  __typename?: 'Mutation';
  authLoginAdmin: {
    __typename?: 'AuthLoginOutput';
    accessToken: string;
    user: {
      __typename?: 'User';
      uuid: string;
      email: string;
      firstName: string;
      lastName: string;
      activated: boolean;
      enabled: boolean;
      role: Roles;
    };
  };
};

export type ResetPasswordInitMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;

export type ResetPasswordInitMutation = {
  __typename?: 'Mutation';
  resetPasswordInit: { __typename?: 'ResetPasswordOutput'; message: string };
};

export type ArticleCategoryCreateMutationVariables = Exact<{
  input: CreateArticleCategoryInput;
}>;

export type ArticleCategoryCreateMutation = {
  __typename?: 'Mutation';
  articleCategoryCreate: {
    __typename?: 'CreateArticleCategoryOutput';
    category: {
      __typename?: 'ArticleCategory';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      category: string;
      articles?: Array<{
        __typename?: 'Article';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        duration: string;
        text: string;
        enabled: boolean;
        slug?: string | null;
        meta_title?: string | null;
        meta_description?: string | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        article_category?: {
          __typename?: 'ArticleCategory';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          category: string;
          articles?: Array<{
            __typename?: 'Article';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            duration: string;
            text: string;
            enabled: boolean;
            slug?: string | null;
            meta_title?: string | null;
            meta_description?: string | null;
          }> | null;
        } | null;
      }> | null;
    };
  };
};

export type ArticleCategoryDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type ArticleCategoryDeleteMutation = {
  __typename?: 'Mutation';
  articleCategoryDelete: {
    __typename?: 'DeleteArticleCategoryOutput';
    uuid: string;
  };
};

export type ArticleCategoryUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: UpdateArticleCategoryInput;
}>;

export type ArticleCategoryUpdateMutation = {
  __typename?: 'Mutation';
  articleCategoryUpdate: {
    __typename?: 'UpdateArticleCategoryOutput';
    category: {
      __typename?: 'ArticleCategory';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      category: string;
      articles?: Array<{
        __typename?: 'Article';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        duration: string;
        text: string;
        enabled: boolean;
        slug?: string | null;
        meta_title?: string | null;
        meta_description?: string | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        article_category?: {
          __typename?: 'ArticleCategory';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          category: string;
          articles?: Array<{
            __typename?: 'Article';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            duration: string;
            text: string;
            enabled: boolean;
            slug?: string | null;
            meta_title?: string | null;
            meta_description?: string | null;
          }> | null;
        } | null;
      }> | null;
    };
  };
};

export type ArticleCreateMutationVariables = Exact<{
  input: CreateArticleInput;
}>;

export type ArticleCreateMutation = {
  __typename?: 'Mutation';
  articleCreate: {
    __typename?: 'CreateArticleOutput';
    article: {
      __typename?: 'Article';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      duration: string;
      text: string;
      enabled: boolean;
      slug?: string | null;
      meta_title?: string | null;
      meta_description?: string | null;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      article_category?: {
        __typename?: 'ArticleCategory';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        category: string;
        articles?: Array<{
          __typename?: 'Article';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          duration: string;
          text: string;
          enabled: boolean;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          article_category?: {
            __typename?: 'ArticleCategory';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            category: string;
          } | null;
        }> | null;
      } | null;
    };
  };
};

export type ArticleDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type ArticleDeleteMutation = {
  __typename?: 'Mutation';
  articleDelete: { __typename?: 'DeleteArticleOutput'; uuid: string };
};

export type ArticleUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: UpdateArticleInput;
}>;

export type ArticleUpdateMutation = {
  __typename?: 'Mutation';
  articleUpdate: {
    __typename?: 'UpdateArticleOutput';
    article: {
      __typename?: 'Article';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      duration: string;
      text: string;
      enabled: boolean;
      slug?: string | null;
      meta_title?: string | null;
      meta_description?: string | null;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      article_category?: {
        __typename?: 'ArticleCategory';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        category: string;
        articles?: Array<{
          __typename?: 'Article';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          duration: string;
          text: string;
          enabled: boolean;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          article_category?: {
            __typename?: 'ArticleCategory';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            category: string;
          } | null;
        }> | null;
      } | null;
    };
  };
};

export type CartAddDiscountCodeMutationVariables = Exact<{
  input: AddCouponCodeToCartInput;
}>;

export type CartAddDiscountCodeMutation = {
  __typename?: 'Mutation';
  cartAddDiscountCode: {
    __typename?: 'CreateCartProductOutput';
    cart_product_uuid?: string | null;
    cart: {
      __typename?: 'Cart';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      browser_uuid: string;
      order_code: string;
      sub_total: number;
      shipping_cost: number;
      total_without_vat: number;
      vat: number;
      total_all_vat: number;
      delivery_mode: Delivery_Modes;
      discount_amount: number;
      discount_code?: {
        __typename?: 'DiscountCode';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        code: string;
        value: number;
        use_before?: any | null;
        max_use_times: number;
        used_times: number;
        saved_money: number;
        total_buy_amount: number;
        is_percentage: boolean;
      } | null;
      delivery_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        tva_number: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      billing_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        tva_number: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      cart_products?: Array<{
        __typename?: 'CartProduct';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        note: string;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        synthetic_design: boolean;
        custom_design: boolean;
        design_is_finished: boolean;
        quantity: number;
        total: number;
        squid_studio_data: {
          __typename?: 'SquidStudioData';
          width: number;
          height: number;
          radius: number;
          can_have_background: boolean;
          data?: any | null;
        };
        vehicle_information: {
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        };
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_pdf?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_svg?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        product?: {
          __typename?: 'Product';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          type: ProductTypes;
          description: string;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          enabled: boolean;
          index: number;
          start_price: number;
          is_perfect_squid: boolean;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
            }> | null;
          }> | null;
          sections?: Array<{
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_size: Array<{
              __typename?: 'ExtraSectionSizePrice';
              size_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              depends_on_supports?: Array<{
                __typename?: 'Support';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                can_have_background: boolean;
                extra_price: number;
                overview: string;
                video_url: string;
                installation_difficulty: number;
              }> | null;
            }> | null;
          }> | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_sections: Array<{
              __typename?: 'ExtraStickerSectionPrice';
              section_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
          }> | null;
          templates?: Array<{
            __typename?: 'Template';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          product_images?: Array<{
            __typename?: 'ProductImage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            support_uuid?: string | null;
            size_uuid?: string | null;
            section_uuid?: string | null;
            extra_sticker_uuid?: string | null;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        } | null;
        size?: {
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        } | null;
        support?: {
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        } | null;
        section?: {
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        } | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
        }> | null;
        car_brand?: {
          __typename?: 'CarBrand';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          brand_name: string;
          info_title?: string | null;
          info_description?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          pictogram_dark?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          brand_models?: Array<{
            __typename?: 'BrandModel';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            model_name: string;
            info_title?: string | null;
            info_description?: string | null;
            car_brand?: {
              __typename?: 'CarBrand';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              brand_name: string;
              info_title?: string | null;
              info_description?: string | null;
            } | null;
            model_years?: Array<{
              __typename?: 'ModelYear';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              start_year: string;
              end_year: string;
              info_title?: string | null;
              info_description?: string | null;
              brand_model?: {
                __typename?: 'BrandModel';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                model_name: string;
                info_title?: string | null;
                info_description?: string | null;
                car_brand?: {
                  __typename?: 'CarBrand';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  brand_name: string;
                  info_title?: string | null;
                  info_description?: string | null;
                } | null;
                model_years?: Array<{
                  __typename?: 'ModelYear';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  start_year: string;
                  end_year: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  car_versions?: Array<{
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    version_laterals?: Array<{
                      __typename?: 'VersionLateral';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                        thumbnail: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                        medium: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                      } | null;
                      side_glazings?: Array<{
                        __typename?: 'SideGlazing';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          side_glazings?: Array<{
                            __typename?: 'SideGlazing';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          }> | null;
                          cars_backwards?: Array<{
                            __typename?: 'CarBackward';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            preview_text?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            preview_image?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            version_lateral?: {
                              __typename?: 'VersionLateral';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              extra_price: number;
                              installer_extra_price: number;
                              installation_difficulty: number;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              car_version?: {
                                __typename?: 'CarVersion';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                pictogram?: {
                                  __typename?: 'Storage';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  file_name: string;
                                  url: string;
                                  mime_type: string;
                                  width: number;
                                  height: number;
                                  size: number;
                                  caption: string;
                                  description: string;
                                  alternative_title: string;
                                } | null;
                                version_laterals?: Array<{
                                  __typename?: 'VersionLateral';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  cars_backwards?: Array<{
                                    __typename?: 'CarBackward';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    extra_price: number;
                                    installer_extra_price: number;
                                    installation_difficulty: number;
                                    preview_text?: string | null;
                                    car_backward_types?: Array<{
                                      __typename?: 'CarBackwardType';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      has_rear_camera: boolean;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      car_backward?: {
                                        __typename?: 'CarBackward';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        preview_text?: string | null;
                                        pictogram?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        preview_image?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        version_lateral?: {
                                          __typename?: 'VersionLateral';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          extra_price: number;
                                          installer_extra_price: number;
                                          installation_difficulty: number;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        } | null;
                                        car_backward_types?: Array<{
                                          __typename?: 'CarBackwardType';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          has_rear_camera: boolean;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                  car_version?: {
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      brand_model?: {
                                        __typename?: 'BrandModel';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        model_name: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                        model_year?: {
                                          __typename?: 'ModelYear';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          start_year: string;
                                          end_year: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                        } | null;
                                      }> | null;
                                    } | null;
                                  } | null;
                                }> | null;
                              } | null;
                            } | null;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                  }> | null;
                }> | null;
              } | null;
            }> | null;
          }> | null;
        } | null;
        brand_model?: {
          __typename?: 'BrandModel';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          model_name: string;
          info_title?: string | null;
          info_description?: string | null;
        } | null;
        model_year?: {
          __typename?: 'ModelYear';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          start_year: string;
          end_year: string;
          info_title?: string | null;
          info_description?: string | null;
        } | null;
        car_version?: {
          __typename?: 'CarVersion';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
        } | null;
        version_lateral?: {
          __typename?: 'VersionLateral';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
        } | null;
        side_glazing?: {
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
          } | null;
        } | null;
        car_backwards?: {
          __typename?: 'CarBackward';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          info_title?: string | null;
          info_description?: string | null;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
          } | null;
          car_backward_types?: Array<{
            __typename?: 'CarBackwardType';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            info_title?: string | null;
            info_description?: string | null;
            has_rear_camera: boolean;
          }> | null;
        } | null;
        car_backward_type?: {
          __typename?: 'CarBackwardType';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          info_title?: string | null;
          info_description?: string | null;
          has_rear_camera: boolean;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          car_backward?: {
            __typename?: 'CarBackward';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            info_title?: string | null;
            info_description?: string | null;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            preview_text?: string | null;
          } | null;
        } | null;
      }> | null;
    };
  };
};

export type CartProductCreateMutationVariables = Exact<{
  input: CreateCartProductInput;
}>;

export type CartProductCreateMutation = {
  __typename?: 'Mutation';
  cartProductCreate: {
    __typename?: 'CreateCartProductOutput';
    cart_product_uuid?: string | null;
    cart: {
      __typename?: 'Cart';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      browser_uuid: string;
      order_code: string;
      sub_total: number;
      shipping_cost: number;
      total_without_vat: number;
      vat: number;
      total_all_vat: number;
      delivery_mode: Delivery_Modes;
      discount_amount: number;
      discount_code?: {
        __typename?: 'DiscountCode';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        code: string;
        value: number;
        use_before?: any | null;
        max_use_times: number;
        used_times: number;
        saved_money: number;
        total_buy_amount: number;
        is_percentage: boolean;
      } | null;
      delivery_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        tva_number: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      billing_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        tva_number: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      cart_products?: Array<{
        __typename?: 'CartProduct';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        note: string;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        synthetic_design: boolean;
        custom_design: boolean;
        design_is_finished: boolean;
        quantity: number;
        total: number;
        squid_studio_data: {
          __typename?: 'SquidStudioData';
          width: number;
          height: number;
          radius: number;
          can_have_background: boolean;
          data?: any | null;
        };
        vehicle_information: {
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        };
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_pdf?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_svg?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        product?: {
          __typename?: 'Product';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          type: ProductTypes;
          description: string;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          enabled: boolean;
          index: number;
          start_price: number;
          is_perfect_squid: boolean;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
            }> | null;
          }> | null;
          sections?: Array<{
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_size: Array<{
              __typename?: 'ExtraSectionSizePrice';
              size_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              depends_on_supports?: Array<{
                __typename?: 'Support';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                can_have_background: boolean;
                extra_price: number;
                overview: string;
                video_url: string;
                installation_difficulty: number;
              }> | null;
            }> | null;
          }> | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_sections: Array<{
              __typename?: 'ExtraStickerSectionPrice';
              section_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
          }> | null;
          templates?: Array<{
            __typename?: 'Template';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          product_images?: Array<{
            __typename?: 'ProductImage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            support_uuid?: string | null;
            size_uuid?: string | null;
            section_uuid?: string | null;
            extra_sticker_uuid?: string | null;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        } | null;
        size?: {
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        } | null;
        support?: {
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        } | null;
        section?: {
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        } | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
        }> | null;
        car_brand?: {
          __typename?: 'CarBrand';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          brand_name: string;
          info_title?: string | null;
          info_description?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          pictogram_dark?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          brand_models?: Array<{
            __typename?: 'BrandModel';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            model_name: string;
            info_title?: string | null;
            info_description?: string | null;
            car_brand?: {
              __typename?: 'CarBrand';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              brand_name: string;
              info_title?: string | null;
              info_description?: string | null;
            } | null;
            model_years?: Array<{
              __typename?: 'ModelYear';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              start_year: string;
              end_year: string;
              info_title?: string | null;
              info_description?: string | null;
              brand_model?: {
                __typename?: 'BrandModel';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                model_name: string;
                info_title?: string | null;
                info_description?: string | null;
                car_brand?: {
                  __typename?: 'CarBrand';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  brand_name: string;
                  info_title?: string | null;
                  info_description?: string | null;
                } | null;
                model_years?: Array<{
                  __typename?: 'ModelYear';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  start_year: string;
                  end_year: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  car_versions?: Array<{
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    version_laterals?: Array<{
                      __typename?: 'VersionLateral';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                        thumbnail: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                        medium: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                      } | null;
                      side_glazings?: Array<{
                        __typename?: 'SideGlazing';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          side_glazings?: Array<{
                            __typename?: 'SideGlazing';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          }> | null;
                          cars_backwards?: Array<{
                            __typename?: 'CarBackward';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            preview_text?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            preview_image?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            version_lateral?: {
                              __typename?: 'VersionLateral';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              extra_price: number;
                              installer_extra_price: number;
                              installation_difficulty: number;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              car_version?: {
                                __typename?: 'CarVersion';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                pictogram?: {
                                  __typename?: 'Storage';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  file_name: string;
                                  url: string;
                                  mime_type: string;
                                  width: number;
                                  height: number;
                                  size: number;
                                  caption: string;
                                  description: string;
                                  alternative_title: string;
                                } | null;
                                version_laterals?: Array<{
                                  __typename?: 'VersionLateral';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  cars_backwards?: Array<{
                                    __typename?: 'CarBackward';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    extra_price: number;
                                    installer_extra_price: number;
                                    installation_difficulty: number;
                                    preview_text?: string | null;
                                    car_backward_types?: Array<{
                                      __typename?: 'CarBackwardType';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      has_rear_camera: boolean;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      car_backward?: {
                                        __typename?: 'CarBackward';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        preview_text?: string | null;
                                        pictogram?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        preview_image?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        version_lateral?: {
                                          __typename?: 'VersionLateral';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          extra_price: number;
                                          installer_extra_price: number;
                                          installation_difficulty: number;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        } | null;
                                        car_backward_types?: Array<{
                                          __typename?: 'CarBackwardType';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          has_rear_camera: boolean;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                  car_version?: {
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      brand_model?: {
                                        __typename?: 'BrandModel';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        model_name: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                        model_year?: {
                                          __typename?: 'ModelYear';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          start_year: string;
                                          end_year: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                        } | null;
                                      }> | null;
                                    } | null;
                                  } | null;
                                }> | null;
                              } | null;
                            } | null;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                  }> | null;
                }> | null;
              } | null;
            }> | null;
          }> | null;
        } | null;
        brand_model?: {
          __typename?: 'BrandModel';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          model_name: string;
          info_title?: string | null;
          info_description?: string | null;
        } | null;
        model_year?: {
          __typename?: 'ModelYear';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          start_year: string;
          end_year: string;
          info_title?: string | null;
          info_description?: string | null;
        } | null;
        car_version?: {
          __typename?: 'CarVersion';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
        } | null;
        version_lateral?: {
          __typename?: 'VersionLateral';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
        } | null;
        side_glazing?: {
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
          } | null;
        } | null;
        car_backwards?: {
          __typename?: 'CarBackward';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          info_title?: string | null;
          info_description?: string | null;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
          } | null;
          car_backward_types?: Array<{
            __typename?: 'CarBackwardType';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            info_title?: string | null;
            info_description?: string | null;
            has_rear_camera: boolean;
          }> | null;
        } | null;
        car_backward_type?: {
          __typename?: 'CarBackwardType';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          info_title?: string | null;
          info_description?: string | null;
          has_rear_camera: boolean;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          car_backward?: {
            __typename?: 'CarBackward';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            info_title?: string | null;
            info_description?: string | null;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            preview_text?: string | null;
          } | null;
        } | null;
      }> | null;
    };
  };
};

export type CartProductCreateUnAuthentifiedMutationVariables = Exact<{
  input: CreateCartProductInput;
}>;

export type CartProductCreateUnAuthentifiedMutation = {
  __typename?: 'Mutation';
  cartProductCreateUnAuthentified: {
    __typename?: 'CreateCartProductOutput';
    cart_product_uuid?: string | null;
    cart: {
      __typename?: 'Cart';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      browser_uuid: string;
      order_code: string;
      sub_total: number;
      shipping_cost: number;
      total_without_vat: number;
      vat: number;
      total_all_vat: number;
      delivery_mode: Delivery_Modes;
      discount_amount: number;
      discount_code?: {
        __typename?: 'DiscountCode';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        code: string;
        value: number;
        use_before?: any | null;
        max_use_times: number;
        used_times: number;
        saved_money: number;
        total_buy_amount: number;
        is_percentage: boolean;
      } | null;
      delivery_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        tva_number: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      billing_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        tva_number: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      cart_products?: Array<{
        __typename?: 'CartProduct';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        note: string;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        synthetic_design: boolean;
        custom_design: boolean;
        design_is_finished: boolean;
        quantity: number;
        total: number;
        squid_studio_data: {
          __typename?: 'SquidStudioData';
          width: number;
          height: number;
          radius: number;
          can_have_background: boolean;
          data?: any | null;
        };
        vehicle_information: {
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        };
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_pdf?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_svg?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        product?: {
          __typename?: 'Product';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          type: ProductTypes;
          description: string;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          enabled: boolean;
          index: number;
          start_price: number;
          is_perfect_squid: boolean;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
            }> | null;
          }> | null;
          sections?: Array<{
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_size: Array<{
              __typename?: 'ExtraSectionSizePrice';
              size_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              depends_on_supports?: Array<{
                __typename?: 'Support';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                can_have_background: boolean;
                extra_price: number;
                overview: string;
                video_url: string;
                installation_difficulty: number;
              }> | null;
            }> | null;
          }> | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_sections: Array<{
              __typename?: 'ExtraStickerSectionPrice';
              section_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
          }> | null;
          templates?: Array<{
            __typename?: 'Template';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          product_images?: Array<{
            __typename?: 'ProductImage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            support_uuid?: string | null;
            size_uuid?: string | null;
            section_uuid?: string | null;
            extra_sticker_uuid?: string | null;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        } | null;
        size?: {
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        } | null;
        support?: {
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        } | null;
        section?: {
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        } | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
        }> | null;
        car_brand?: {
          __typename?: 'CarBrand';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          brand_name: string;
          info_title?: string | null;
          info_description?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          pictogram_dark?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          brand_models?: Array<{
            __typename?: 'BrandModel';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            model_name: string;
            info_title?: string | null;
            info_description?: string | null;
            car_brand?: {
              __typename?: 'CarBrand';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              brand_name: string;
              info_title?: string | null;
              info_description?: string | null;
            } | null;
            model_years?: Array<{
              __typename?: 'ModelYear';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              start_year: string;
              end_year: string;
              info_title?: string | null;
              info_description?: string | null;
              brand_model?: {
                __typename?: 'BrandModel';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                model_name: string;
                info_title?: string | null;
                info_description?: string | null;
                car_brand?: {
                  __typename?: 'CarBrand';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  brand_name: string;
                  info_title?: string | null;
                  info_description?: string | null;
                } | null;
                model_years?: Array<{
                  __typename?: 'ModelYear';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  start_year: string;
                  end_year: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  car_versions?: Array<{
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    version_laterals?: Array<{
                      __typename?: 'VersionLateral';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                        thumbnail: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                        medium: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                      } | null;
                      side_glazings?: Array<{
                        __typename?: 'SideGlazing';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          side_glazings?: Array<{
                            __typename?: 'SideGlazing';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          }> | null;
                          cars_backwards?: Array<{
                            __typename?: 'CarBackward';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            preview_text?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            preview_image?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            version_lateral?: {
                              __typename?: 'VersionLateral';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              extra_price: number;
                              installer_extra_price: number;
                              installation_difficulty: number;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              car_version?: {
                                __typename?: 'CarVersion';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                pictogram?: {
                                  __typename?: 'Storage';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  file_name: string;
                                  url: string;
                                  mime_type: string;
                                  width: number;
                                  height: number;
                                  size: number;
                                  caption: string;
                                  description: string;
                                  alternative_title: string;
                                } | null;
                                version_laterals?: Array<{
                                  __typename?: 'VersionLateral';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  cars_backwards?: Array<{
                                    __typename?: 'CarBackward';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    extra_price: number;
                                    installer_extra_price: number;
                                    installation_difficulty: number;
                                    preview_text?: string | null;
                                    car_backward_types?: Array<{
                                      __typename?: 'CarBackwardType';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      has_rear_camera: boolean;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      car_backward?: {
                                        __typename?: 'CarBackward';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        preview_text?: string | null;
                                        pictogram?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        preview_image?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        version_lateral?: {
                                          __typename?: 'VersionLateral';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          extra_price: number;
                                          installer_extra_price: number;
                                          installation_difficulty: number;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        } | null;
                                        car_backward_types?: Array<{
                                          __typename?: 'CarBackwardType';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          has_rear_camera: boolean;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                  car_version?: {
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      brand_model?: {
                                        __typename?: 'BrandModel';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        model_name: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                        model_year?: {
                                          __typename?: 'ModelYear';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          start_year: string;
                                          end_year: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                        } | null;
                                      }> | null;
                                    } | null;
                                  } | null;
                                }> | null;
                              } | null;
                            } | null;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                  }> | null;
                }> | null;
              } | null;
            }> | null;
          }> | null;
        } | null;
        brand_model?: {
          __typename?: 'BrandModel';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          model_name: string;
          info_title?: string | null;
          info_description?: string | null;
        } | null;
        model_year?: {
          __typename?: 'ModelYear';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          start_year: string;
          end_year: string;
          info_title?: string | null;
          info_description?: string | null;
        } | null;
        car_version?: {
          __typename?: 'CarVersion';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
        } | null;
        version_lateral?: {
          __typename?: 'VersionLateral';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
        } | null;
        side_glazing?: {
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
          } | null;
        } | null;
        car_backwards?: {
          __typename?: 'CarBackward';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          info_title?: string | null;
          info_description?: string | null;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
          } | null;
          car_backward_types?: Array<{
            __typename?: 'CarBackwardType';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            info_title?: string | null;
            info_description?: string | null;
            has_rear_camera: boolean;
          }> | null;
        } | null;
        car_backward_type?: {
          __typename?: 'CarBackwardType';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          info_title?: string | null;
          info_description?: string | null;
          has_rear_camera: boolean;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          car_backward?: {
            __typename?: 'CarBackward';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            info_title?: string | null;
            info_description?: string | null;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            preview_text?: string | null;
          } | null;
        } | null;
      }> | null;
    };
  };
};

export type CartProductUpdateMutationVariables = Exact<{
  input: UpdateCartProductInput;
}>;

export type CartProductUpdateMutation = {
  __typename?: 'Mutation';
  cartProductUpdate: {
    __typename?: 'CreateCartProductOutput';
    cart_product_uuid?: string | null;
    cart: {
      __typename?: 'Cart';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      browser_uuid: string;
      order_code: string;
      sub_total: number;
      shipping_cost: number;
      total_without_vat: number;
      vat: number;
      total_all_vat: number;
      delivery_mode: Delivery_Modes;
      discount_amount: number;
      discount_code?: {
        __typename?: 'DiscountCode';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        code: string;
        value: number;
        use_before?: any | null;
        max_use_times: number;
        used_times: number;
        saved_money: number;
        total_buy_amount: number;
        is_percentage: boolean;
      } | null;
      delivery_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      billing_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      cart_products?: Array<{
        __typename?: 'CartProduct';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        synthetic_design: boolean;
        custom_design: boolean;
        design_is_finished: boolean;
        quantity: number;
        total: number;
        squid_studio_data: {
          __typename?: 'SquidStudioData';
          width: number;
          height: number;
          radius: number;
          can_have_background: boolean;
          data?: any | null;
        };
        vehicle_information: {
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        };
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_pdf?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_svg?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        product?: {
          __typename?: 'Product';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          type: ProductTypes;
          description: string;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          enabled: boolean;
          index: number;
          start_price: number;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
            }> | null;
          }> | null;
          sections?: Array<{
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_size: Array<{
              __typename?: 'ExtraSectionSizePrice';
              size_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              depends_on_supports?: Array<{
                __typename?: 'Support';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                can_have_background: boolean;
                extra_price: number;
                overview: string;
                video_url: string;
                installation_difficulty: number;
              }> | null;
            }> | null;
          }> | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_sections: Array<{
              __typename?: 'ExtraStickerSectionPrice';
              section_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
          }> | null;
          templates?: Array<{
            __typename?: 'Template';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          product_images?: Array<{
            __typename?: 'ProductImage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            support_uuid?: string | null;
            size_uuid?: string | null;
            section_uuid?: string | null;
            extra_sticker_uuid?: string | null;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        } | null;
        size?: {
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        } | null;
        support?: {
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        } | null;
        section?: {
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        } | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
        }> | null;
      }> | null;
    };
  };
};

export type CartRemoveDiscountCodeMutationVariables = Exact<{
  input: RemoveCouponCodeToCartInput;
}>;

export type CartRemoveDiscountCodeMutation = {
  __typename?: 'Mutation';
  cartRemoveDiscountCode: {
    __typename?: 'CreateCartProductOutput';
    cart_product_uuid?: string | null;
    cart: {
      __typename?: 'Cart';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      browser_uuid: string;
      order_code: string;
      sub_total: number;
      shipping_cost: number;
      total_without_vat: number;
      vat: number;
      total_all_vat: number;
      delivery_mode: Delivery_Modes;
      discount_amount: number;
      discount_code?: {
        __typename?: 'DiscountCode';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        code: string;
        value: number;
        use_before?: any | null;
        max_use_times: number;
        used_times: number;
        saved_money: number;
        total_buy_amount: number;
        is_percentage: boolean;
      } | null;
      delivery_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      billing_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      cart_products?: Array<{
        __typename?: 'CartProduct';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        synthetic_design: boolean;
        custom_design: boolean;
        design_is_finished: boolean;
        quantity: number;
        total: number;
        squid_studio_data: {
          __typename?: 'SquidStudioData';
          width: number;
          height: number;
          radius: number;
          can_have_background: boolean;
          data?: any | null;
        };
        vehicle_information: {
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        };
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_pdf?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_svg?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        product?: {
          __typename?: 'Product';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          type: ProductTypes;
          description: string;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          enabled: boolean;
          index: number;
          start_price: number;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
            }> | null;
          }> | null;
          sections?: Array<{
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_size: Array<{
              __typename?: 'ExtraSectionSizePrice';
              size_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              depends_on_supports?: Array<{
                __typename?: 'Support';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                can_have_background: boolean;
                extra_price: number;
                overview: string;
                video_url: string;
                installation_difficulty: number;
              }> | null;
            }> | null;
          }> | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_sections: Array<{
              __typename?: 'ExtraStickerSectionPrice';
              section_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
          }> | null;
          templates?: Array<{
            __typename?: 'Template';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          product_images?: Array<{
            __typename?: 'ProductImage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            support_uuid?: string | null;
            size_uuid?: string | null;
            section_uuid?: string | null;
            extra_sticker_uuid?: string | null;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        } | null;
        size?: {
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        } | null;
        support?: {
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        } | null;
        section?: {
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        } | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
        }> | null;
      }> | null;
    };
  };
};

export type CartUpdateMutationVariables = Exact<{
  input: UpdateCartInput;
}>;

export type CartUpdateMutation = {
  __typename?: 'Mutation';
  cartUpdate: {
    __typename?: 'CreateCartProductOutput';
    cart_product_uuid?: string | null;
    cart: {
      __typename?: 'Cart';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      browser_uuid: string;
      order_code: string;
      sub_total: number;
      shipping_cost: number;
      total_without_vat: number;
      vat: number;
      total_all_vat: number;
      delivery_mode: Delivery_Modes;
      discount_amount: number;
      discount_code?: {
        __typename?: 'DiscountCode';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        code: string;
        value: number;
        use_before?: any | null;
        max_use_times: number;
        used_times: number;
        saved_money: number;
        total_buy_amount: number;
        is_percentage: boolean;
      } | null;
      delivery_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      billing_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      cart_products?: Array<{
        __typename?: 'CartProduct';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        synthetic_design: boolean;
        custom_design: boolean;
        design_is_finished: boolean;
        quantity: number;
        total: number;
        squid_studio_data: {
          __typename?: 'SquidStudioData';
          width: number;
          height: number;
          radius: number;
          can_have_background: boolean;
          data?: any | null;
        };
        vehicle_information: {
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        };
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_pdf?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_svg?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        product?: {
          __typename?: 'Product';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          type: ProductTypes;
          description: string;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          enabled: boolean;
          index: number;
          start_price: number;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
            }> | null;
          }> | null;
          sections?: Array<{
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_size: Array<{
              __typename?: 'ExtraSectionSizePrice';
              size_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              depends_on_supports?: Array<{
                __typename?: 'Support';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                can_have_background: boolean;
                extra_price: number;
                overview: string;
                video_url: string;
                installation_difficulty: number;
              }> | null;
            }> | null;
          }> | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_sections: Array<{
              __typename?: 'ExtraStickerSectionPrice';
              section_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
          }> | null;
          templates?: Array<{
            __typename?: 'Template';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          product_images?: Array<{
            __typename?: 'ProductImage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            support_uuid?: string | null;
            size_uuid?: string | null;
            section_uuid?: string | null;
            extra_sticker_uuid?: string | null;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        } | null;
        size?: {
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        } | null;
        support?: {
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        } | null;
        section?: {
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        } | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
        }> | null;
      }> | null;
    };
  };
};

export type ConfigsUpdateMutationVariables = Exact<{
  input: RegisterConfigsInput;
}>;

export type ConfigsUpdateMutation = {
  __typename?: 'Mutation';
  ConfigsUpdate: {
    __typename?: 'RegisterConfigsOutput';
    configs: {
      __typename?: 'Configs';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      shipping_standard_days_number?: number | null;
      shipping_express_cost_percentage?: number | null;
      shipping_express_days_number?: number | null;
      verification_cost: number;
      symetrique_design_cost: number;
      graphic_service_cost: number;
      installer_price: number;
      vat: number;
      facebook_social_link: string;
      instagram_social_link: string;
      youtube_social_link: string;
      shipping_standard_cost: Array<{
        __typename?: 'DeliveryStandardCost';
        start_amount: number;
        end_amount: number;
        cost: number;
      }>;
    };
  };
};

export type DiscountCodeCreateMutationVariables = Exact<{
  input: CreateDiscountCodeInput;
}>;

export type DiscountCodeCreateMutation = {
  __typename?: 'Mutation';
  discountCodeCreate: {
    __typename?: 'CreateDiscountCodeOutput';
    discount_code: {
      __typename?: 'DiscountCode';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      code: string;
      value: number;
      use_before?: any | null;
      max_use_times: number;
      used_times: number;
      saved_money: number;
      total_buy_amount: number;
      is_percentage: boolean;
    };
  };
};

export type DiscountCodeDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type DiscountCodeDeleteMutation = {
  __typename?: 'Mutation';
  discountCodeDelete: { __typename?: 'DeleteDiscountCodeOutput'; uuid: string };
};

export type DiscountCodeUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: DiscountCodeUpdateInput;
}>;

export type DiscountCodeUpdateMutation = {
  __typename?: 'Mutation';
  discountCodeUpdate: {
    __typename?: 'UpdateDiscountCodeOutput';
    discount_code: {
      __typename?: 'DiscountCode';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      code: string;
      value: number;
      use_before?: any | null;
      max_use_times: number;
      used_times: number;
      saved_money: number;
      total_buy_amount: number;
      is_percentage: boolean;
    };
  };
};

export type DeleteFaqPageMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type DeleteFaqPageMutation = {
  __typename?: 'Mutation';
  deleteFaqPage: string;
};

export type FaqCreateMutationVariables = Exact<{
  input: CreateFaqInput;
}>;

export type FaqCreateMutation = {
  __typename?: 'Mutation';
  faqCreate: {
    __typename?: 'CreateFaqOutput';
    faq: {
      __typename?: 'Faq';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      response: string;
      category: FaqCategoriesTypes;
      author?: {
        __typename?: 'User';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        email: string;
        firstName: string;
        lastName: string;
        activated: boolean;
        enabled: boolean;
        role: Roles;
      } | null;
    };
  };
};

export type FaqDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type FaqDeleteMutation = {
  __typename?: 'Mutation';
  faqDelete: { __typename?: 'DeleteFaqOutput'; uuid: string };
};

export type FaqPageRegisterMutationVariables = Exact<{
  input: RegisterFaqPage;
}>;

export type FaqPageRegisterMutation = {
  __typename?: 'Mutation';
  faqPageRegister: {
    __typename?: 'RegisterPageOutput';
    page: {
      __typename?: 'Page';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      slug: string;
      faqPageLists?: Array<{
        __typename?: 'FaqPageList';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title?: string | null;
        faqPages?: Array<{
          __typename?: 'FaqPage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          question: string;
          response: string;
        }> | null;
      }> | null;
      questionPageLists?: Array<{
        __typename?: 'QuestionPageList';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title?: string | null;
        questionsPages?: Array<{
          __typename?: 'QuestionPage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          question: string;
          response: string;
        }> | null;
      }> | null;
    };
  };
};

export type FaqTitleMutationMutationVariables = Exact<{
  input: MutationFaqTitle;
}>;

export type FaqTitleMutationMutation = {
  __typename?: 'Mutation';
  faqTitleMutation: {
    __typename?: 'RegisterPageOutput';
    page: {
      __typename?: 'Page';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      slug: string;
      meta_title: string;
      meta_description: string;
      faqPageLists?: Array<{
        __typename?: 'FaqPageList';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title?: string | null;
        faqPages?: Array<{
          __typename?: 'FaqPage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          question: string;
          response: string;
        }> | null;
      }> | null;
      questionPageLists?: Array<{
        __typename?: 'QuestionPageList';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title?: string | null;
        questionsPages?: Array<{
          __typename?: 'QuestionPage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          question: string;
          response: string;
        }> | null;
      }> | null;
    };
  };
};

export type FaqUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: FaqUpdateInput;
}>;

export type FaqUpdateMutation = {
  __typename?: 'Mutation';
  faqUpdate: {
    __typename?: 'UpdateFaqOutput';
    faq: {
      __typename?: 'Faq';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      response: string;
      category: FaqCategoriesTypes;
      author?: {
        __typename?: 'User';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        email: string;
        firstName: string;
        lastName: string;
        activated: boolean;
        enabled: boolean;
        role: Roles;
      } | null;
    };
  };
};

export type UpdateFaqPageMutationVariables = Exact<{
  input: UpdateFaqPage;
}>;

export type UpdateFaqPageMutation = {
  __typename?: 'Mutation';
  updateFaqPage: {
    __typename?: 'RegisterPageOutput';
    page: {
      __typename?: 'Page';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      slug: string;
      faqPageLists?: Array<{
        __typename?: 'FaqPageList';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title?: string | null;
        faqPages?: Array<{
          __typename?: 'FaqPage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          question: string;
          response: string;
        }> | null;
      }> | null;
      questionPageLists?: Array<{
        __typename?: 'QuestionPageList';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title?: string | null;
        questionsPages?: Array<{
          __typename?: 'QuestionPage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          question: string;
          response: string;
        }> | null;
      }> | null;
    };
  };
};

export type FontCreateMutationVariables = Exact<{
  input: RegisterFontInput;
}>;

export type FontCreateMutation = {
  __typename?: 'Mutation';
  fontCreate: {
    __typename?: 'RegisterFontOutput';
    font: {
      __typename?: 'Font';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      response: string;
    };
  };
};

export type FontDeleteMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type FontDeleteMutation = {
  __typename?: 'Mutation';
  fontDelete: { __typename?: 'MessageOutput'; message: string };
};

export type FontUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: RegisterFontInput;
}>;

export type FontUpdateMutation = {
  __typename?: 'Mutation';
  fontUpdate: {
    __typename?: 'RegisterFontOutput';
    font: {
      __typename?: 'Font';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      response: string;
    };
  };
};

export type RemoveStorageMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type RemoveStorageMutation = {
  __typename?: 'Mutation';
  removeStorage: { __typename?: 'StorageDeleteOutput'; uuid: string };
};

export type UpdateFileMutationVariables = Exact<{
  uuid: Scalars['ID'];
  updateStorageInput: UpdateStorageInput;
}>;

export type UpdateFileMutation = {
  __typename?: 'Mutation';
  updateFile: {
    __typename?: 'UpdateStorageOutput';
    storage: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    };
  };
};

export type UploadFileMutationVariables = Exact<{
  createStorageInput: CreateStorageInput;
}>;

export type UploadFileMutation = {
  __typename?: 'Mutation';
  uploadFile: {
    __typename?: 'CreateStorageOutput';
    storage: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    };
  };
};

export type DeleteAllNotificationsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DeleteAllNotificationsMutation = {
  __typename?: 'Mutation';
  deleteAllNotifications: {
    __typename?: 'DeleteAllNotificationOutput';
    message: string;
  };
};

export type DeleteEstimatesNotificationsMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type DeleteEstimatesNotificationsMutation = {
  __typename?: 'Mutation';
  deleteEstimatesNotifications: {
    __typename?: 'DeleteEstimateNotificationOutput';
    estimateUuid: string;
  };
};

export type DeleteOrdersNotificationsMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type DeleteOrdersNotificationsMutation = {
  __typename?: 'Mutation';
  deleteOrdersNotifications: {
    __typename?: 'DeleteOrderNotificationOutput';
    orderUuid: string;
  };
};

export type AddInvoiceMutationVariables = Exact<{
  input: AddInvoiceInput;
}>;

export type AddInvoiceMutation = {
  __typename?: 'Mutation';
  addInvoice: {
    __typename?: 'Order';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    order_code: string;
    already_add_review: boolean;
    sub_total: number;
    shipping_cost: number;
    total_without_vat: number;
    start_date_of_delivery?: any | null;
    end_date_of_delivery?: any | null;
    vat: number;
    total_all_vat: number;
    discount_amount: number;
    payment_method: Payment_Methods;
    ordering_status: Ordering_Status;
    delivery_mode: Delivery_Modes;
    has_quote: boolean;
    invoice?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    discount_code?: {
      __typename?: 'DiscountCode';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      code: string;
      value: number;
      use_before?: any | null;
      max_use_times: number;
      used_times: number;
      saved_money: number;
      total_buy_amount: number;
      is_percentage: boolean;
    } | null;
    stripe_payment_data?: {
      __typename?: 'StripePaymentData';
      status?: string | null;
      id?: string | null;
      payment_link?: string | null;
      balance_transaction?: string | null;
      payment_method?: string | null;
      receipt_url?: string | null;
      source?: {
        __typename?: 'StripePaymentSource';
        id?: string | null;
        brand?: string | null;
        country?: string | null;
        last4?: string | null;
        exp_month?: number | null;
        exp_year?: number | null;
      } | null;
    } | null;
    user?: {
      __typename?: 'User';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      email: string;
      firstName: string;
      lastName: string;
      company_name: string;
      address: string;
      postal_code: string;
      city: string;
      country: string;
      phonenumber: string;
      activated: boolean;
      enabled: boolean;
      role: Roles;
      vehicles: Array<{
        __typename?: 'VehicleInformation';
        brand?: string | null;
        model?: string | null;
        year?: string | null;
        color?: string | null;
      }>;
    } | null;
    delivery_address?: {
      __typename?: 'OrderAddress';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      firstname: string;
      lastname: string;
      company_name: string;
      address: string;
      postal_code: string;
      city: string;
      country: string;
      phonenumber: string;
      email: string;
    } | null;
    billing_address?: {
      __typename?: 'OrderAddress';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      firstname: string;
      lastname: string;
      company_name: string;
      address: string;
      postal_code: string;
      city: string;
      country: string;
      phonenumber: string;
      email: string;
    } | null;
    order_products?: Array<{
      __typename?: 'OrderProduct';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      verification: boolean;
      graphic_service: boolean;
      professional_installer: boolean;
      synthetic_design: boolean;
      custom_design: boolean;
      design_is_finished: boolean;
      quantity: number;
      total: number;
      squid_studio_data: {
        __typename?: 'SquidStudioData';
        width: number;
        height: number;
        radius: number;
        can_have_background: boolean;
        data?: any | null;
      };
      vehicle_information: {
        __typename?: 'VehicleInformation';
        brand?: string | null;
        model?: string | null;
        year?: string | null;
        color?: string | null;
      };
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      image_pdf?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      image_svg?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      product?: {
        __typename?: 'Product';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        type: ProductTypes;
        description: string;
        slug?: string | null;
        meta_title?: string | null;
        meta_description?: string | null;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        enabled: boolean;
        index: number;
        start_price: number;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        }> | null;
        sections?: Array<{
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
          }> | null;
        }> | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_sections: Array<{
            __typename?: 'ExtraStickerSectionPrice';
            section_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        }> | null;
        templates?: Array<{
          __typename?: 'Template';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        product_images?: Array<{
          __typename?: 'ProductImage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          support_uuid?: string | null;
          size_uuid?: string | null;
          section_uuid?: string | null;
          extra_sticker_uuid?: string | null;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      } | null;
      size?: {
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
        }> | null;
      } | null;
      support?: {
        __typename?: 'Support';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        can_have_background: boolean;
        extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
      } | null;
      section?: {
        __typename?: 'Section';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        can_have_background: boolean;
        extra_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        extra_price_size: Array<{
          __typename?: 'ExtraSectionSizePrice';
          size_uuid: string;
          extra_price: number;
        }>;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
        }> | null;
      } | null;
      extra_stickers?: Array<{
        __typename?: 'ExtraSticker';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        design_choice_possible: boolean;
        extra_price: number;
        installer_extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
      }> | null;
    }> | null;
  };
};

export type CountryUpdateMutationVariables = Exact<{
  country_code: Scalars['String'];
  input: DeliveryCountryInput;
}>;

export type CountryUpdateMutation = {
  __typename?: 'Mutation';
  countryUpdate: {
    __typename?: 'RegisterConfigsOutput';
    configs: {
      __typename?: 'Configs';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      shipping_standard_days_number?: number | null;
      shipping_express_cost_percentage?: number | null;
      shipping_express_days_number?: number | null;
      verification_cost: number;
      symetrique_design_cost: number;
      graphic_service_cost: number;
      installer_price: number;
      vat: number;
      facebook_social_link: string;
      instagram_social_link: string;
      youtube_social_link: string;
      shipping_standard_cost: Array<{
        __typename?: 'DeliveryStandardCost';
        start_amount: number;
        end_amount: number;
        cost: number;
      }>;
      country: Array<{
        __typename?: 'DeliveryCountry';
        country_type: Country_Type;
        country_name: string;
        country_code: string;
        vat: number;
        delivery_cost: number;
        shipping_days_number?: number | null;
      }>;
    };
  };
};

export type DeleteCountryMutationVariables = Exact<{
  country_code: Scalars['String'];
}>;

export type DeleteCountryMutation = {
  __typename?: 'Mutation';
  deleteCountry: {
    __typename?: 'RegisterConfigsOutput';
    configs: {
      __typename?: 'Configs';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      shipping_standard_days_number?: number | null;
      shipping_express_cost_percentage?: number | null;
      shipping_express_days_number?: number | null;
      verification_cost: number;
      symetrique_design_cost: number;
      graphic_service_cost: number;
      installer_price: number;
      vat: number;
      facebook_social_link: string;
      instagram_social_link: string;
      youtube_social_link: string;
      shipping_standard_cost: Array<{
        __typename?: 'DeliveryStandardCost';
        start_amount: number;
        end_amount: number;
        cost: number;
      }>;
      country: Array<{
        __typename?: 'DeliveryCountry';
        country_type: Country_Type;
        country_name: string;
        country_code: string;
        vat: number;
        delivery_cost: number;
        shipping_days_number?: number | null;
      }>;
    };
  };
};

export type DeleteInvoiceMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type DeleteInvoiceMutation = {
  __typename?: 'Mutation';
  deleteInvoice: string;
};

export type OrderDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type OrderDeleteMutation = {
  __typename?: 'Mutation';
  orderDelete: { __typename?: 'DeleteOrderOutput'; uuid: string };
};

export type OrdersListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<OrdersPaginationSortBy>;
  filterByQuote: Scalars['Boolean'];
  filterByStatus?: InputMaybe<Ordering_Status>;
  filterByDeliveryMode?: InputMaybe<Delivery_Modes>;
  filterByPaymentMethod?: InputMaybe<Payment_Methods>;
}>;

export type OrdersListQuery = {
  __typename?: 'Query';
  ordersList: {
    __typename?: 'OrdersPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Order';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      order_code: string;
      already_add_review: boolean;
      sub_total: number;
      shipping_cost: number;
      total_without_vat: number;
      start_date_of_delivery?: any | null;
      end_date_of_delivery?: any | null;
      vat: number;
      total_all_vat: number;
      discount_amount: number;
      payment_method: Payment_Methods;
      ordering_status: Ordering_Status;
      delivery_mode: Delivery_Modes;
    }>;
  };
};

export type UpdateOrderQuoteMutationVariables = Exact<{
  input: UpdateOrderQuoteInput;
}>;

export type UpdateOrderQuoteMutation = {
  __typename?: 'Mutation';
  updateOrderQuote: {
    __typename?: 'UpdateOrderQuoteOutput';
    order: {
      __typename?: 'Order';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      order_code: string;
      already_add_review: boolean;
      sub_total: number;
      shipping_cost: number;
      total_without_vat: number;
      start_date_of_delivery?: any | null;
      end_date_of_delivery?: any | null;
      vat: number;
      total_all_vat: number;
      discount_amount: number;
      payment_method: Payment_Methods;
      ordering_status: Ordering_Status;
      delivery_mode: Delivery_Modes;
      has_quote: boolean;
      invoice?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      discount_code?: {
        __typename?: 'DiscountCode';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        code: string;
        value: number;
        use_before?: any | null;
        max_use_times: number;
        used_times: number;
        saved_money: number;
        total_buy_amount: number;
        is_percentage: boolean;
      } | null;
      stripe_payment_data?: {
        __typename?: 'StripePaymentData';
        status?: string | null;
        id?: string | null;
        payment_link?: string | null;
        balance_transaction?: string | null;
        payment_method?: string | null;
        receipt_url?: string | null;
        source?: {
          __typename?: 'StripePaymentSource';
          id?: string | null;
          brand?: string | null;
          country?: string | null;
          last4?: string | null;
          exp_month?: number | null;
          exp_year?: number | null;
        } | null;
      } | null;
      user?: {
        __typename?: 'User';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        email: string;
        firstName: string;
        lastName: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        activated: boolean;
        enabled: boolean;
        role: Roles;
        vehicles: Array<{
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        }>;
      } | null;
      delivery_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      billing_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      order_products?: Array<{
        __typename?: 'OrderProduct';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        synthetic_design: boolean;
        custom_design: boolean;
        design_is_finished: boolean;
        quantity: number;
        total: number;
        squid_studio_data: {
          __typename?: 'SquidStudioData';
          width: number;
          height: number;
          radius: number;
          can_have_background: boolean;
          data?: any | null;
        };
        vehicle_information: {
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        };
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_pdf?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_svg?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        product?: {
          __typename?: 'Product';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          type: ProductTypes;
          description: string;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          enabled: boolean;
          index: number;
          start_price: number;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
            }> | null;
          }> | null;
          sections?: Array<{
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_size: Array<{
              __typename?: 'ExtraSectionSizePrice';
              size_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              depends_on_supports?: Array<{
                __typename?: 'Support';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                can_have_background: boolean;
                extra_price: number;
                overview: string;
                video_url: string;
                installation_difficulty: number;
              }> | null;
            }> | null;
          }> | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_sections: Array<{
              __typename?: 'ExtraStickerSectionPrice';
              section_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
          }> | null;
          templates?: Array<{
            __typename?: 'Template';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          product_images?: Array<{
            __typename?: 'ProductImage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            support_uuid?: string | null;
            size_uuid?: string | null;
            section_uuid?: string | null;
            extra_sticker_uuid?: string | null;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        } | null;
        size?: {
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        } | null;
        support?: {
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        } | null;
        section?: {
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        } | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
        }> | null;
      }> | null;
    };
  };
};

export type UpdateOrderStatusMutationVariables = Exact<{
  input: ChangeOrderStatusInput;
}>;

export type UpdateOrderStatusMutation = {
  __typename?: 'Mutation';
  updateOrderStatus: {
    __typename?: 'ChangeOrderStatusOutput';
    order: {
      __typename?: 'Order';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      order_code: string;
      already_add_review: boolean;
      sub_total: number;
      shipping_cost: number;
      total_without_vat: number;
      start_date_of_delivery?: any | null;
      end_date_of_delivery?: any | null;
      vat: number;
      total_all_vat: number;
      discount_amount: number;
      payment_method: Payment_Methods;
      ordering_status: Ordering_Status;
      delivery_mode: Delivery_Modes;
      has_quote: boolean;
      invoice?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      discount_code?: {
        __typename?: 'DiscountCode';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        code: string;
        value: number;
        use_before?: any | null;
        max_use_times: number;
        used_times: number;
        saved_money: number;
        total_buy_amount: number;
        is_percentage: boolean;
      } | null;
      stripe_payment_data?: {
        __typename?: 'StripePaymentData';
        status?: string | null;
        id?: string | null;
        payment_link?: string | null;
        balance_transaction?: string | null;
        payment_method?: string | null;
        receipt_url?: string | null;
        source?: {
          __typename?: 'StripePaymentSource';
          id?: string | null;
          brand?: string | null;
          country?: string | null;
          last4?: string | null;
          exp_month?: number | null;
          exp_year?: number | null;
        } | null;
      } | null;
      user?: {
        __typename?: 'User';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        email: string;
        firstName: string;
        lastName: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        activated: boolean;
        enabled: boolean;
        role: Roles;
        vehicles: Array<{
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        }>;
      } | null;
      delivery_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      billing_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      order_products?: Array<{
        __typename?: 'OrderProduct';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        synthetic_design: boolean;
        custom_design: boolean;
        design_is_finished: boolean;
        quantity: number;
        total: number;
        squid_studio_data: {
          __typename?: 'SquidStudioData';
          width: number;
          height: number;
          radius: number;
          can_have_background: boolean;
          data?: any | null;
        };
        vehicle_information: {
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        };
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_pdf?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_svg?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        product?: {
          __typename?: 'Product';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          type: ProductTypes;
          description: string;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          enabled: boolean;
          index: number;
          start_price: number;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
            }> | null;
          }> | null;
          sections?: Array<{
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_size: Array<{
              __typename?: 'ExtraSectionSizePrice';
              size_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              depends_on_supports?: Array<{
                __typename?: 'Support';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                can_have_background: boolean;
                extra_price: number;
                overview: string;
                video_url: string;
                installation_difficulty: number;
              }> | null;
            }> | null;
          }> | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_sections: Array<{
              __typename?: 'ExtraStickerSectionPrice';
              section_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
          }> | null;
          templates?: Array<{
            __typename?: 'Template';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          product_images?: Array<{
            __typename?: 'ProductImage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            support_uuid?: string | null;
            size_uuid?: string | null;
            section_uuid?: string | null;
            extra_sticker_uuid?: string | null;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        } | null;
        size?: {
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        } | null;
        support?: {
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        } | null;
        section?: {
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        } | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
        }> | null;
      }> | null;
    };
  };
};

export type BrandModelCreateMutationVariables = Exact<{
  input: CreateBrandModelInput;
}>;

export type BrandModelCreateMutation = {
  __typename?: 'Mutation';
  brandModelCreate: {
    __typename?: 'CreateBrandModelOutput';
    brandModel: {
      __typename?: 'BrandModel';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      model_name: string;
      info_title?: string | null;
      info_description?: string | null;
      car_brand?: {
        __typename?: 'CarBrand';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        brand_name: string;
        info_title?: string | null;
        info_description?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        pictogram_dark?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        brand_models?: Array<{
          __typename?: 'BrandModel';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          model_name: string;
          info_title?: string | null;
          info_description?: string | null;
          car_brand?: {
            __typename?: 'CarBrand';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            brand_name: string;
            info_title?: string | null;
            info_description?: string | null;
          } | null;
          model_years?: Array<{
            __typename?: 'ModelYear';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            start_year: string;
            end_year: string;
            info_title?: string | null;
            info_description?: string | null;
            brand_model?: {
              __typename?: 'BrandModel';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              model_name: string;
              info_title?: string | null;
              info_description?: string | null;
              car_brand?: {
                __typename?: 'CarBrand';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                brand_name: string;
                info_title?: string | null;
                info_description?: string | null;
              } | null;
              model_years?: Array<{
                __typename?: 'ModelYear';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                start_year: string;
                end_year: string;
                info_title?: string | null;
                info_description?: string | null;
                car_versions?: Array<{
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                      thumbnail: {
                        __typename?: 'StorageThumbnail';
                        width: number;
                        height: number;
                        size: number;
                        url: string;
                        file_name: string;
                      };
                      medium: {
                        __typename?: 'StorageThumbnail';
                        width: number;
                        height: number;
                        size: number;
                        url: string;
                        file_name: string;
                      };
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      version_lateral?: {
                        __typename?: 'VersionLateral';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        side_glazings?: Array<{
                          __typename?: 'SideGlazing';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                        }> | null;
                        cars_backwards?: Array<{
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                            car_version?: {
                              __typename?: 'CarVersion';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              pictogram?: {
                                __typename?: 'Storage';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                file_name: string;
                                url: string;
                                mime_type: string;
                                width: number;
                                height: number;
                                size: number;
                                caption: string;
                                description: string;
                                alternative_title: string;
                              } | null;
                              version_laterals?: Array<{
                                __typename?: 'VersionLateral';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                extra_price: number;
                                installer_extra_price: number;
                                installation_difficulty: number;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                cars_backwards?: Array<{
                                  __typename?: 'CarBackward';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  preview_text?: string | null;
                                  car_backward_types?: Array<{
                                    __typename?: 'CarBackwardType';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    has_rear_camera: boolean;
                                    pictogram?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    preview_image?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    car_backward?: {
                                      __typename?: 'CarBackward';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      extra_price: number;
                                      installer_extra_price: number;
                                      installation_difficulty: number;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_lateral?: {
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      } | null;
                                      car_backward_types?: Array<{
                                        __typename?: 'CarBackwardType';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        has_rear_camera: boolean;
                                      }> | null;
                                    } | null;
                                  }> | null;
                                }> | null;
                                car_version?: {
                                  __typename?: 'CarVersion';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  model_year?: {
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    brand_model?: {
                                      __typename?: 'BrandModel';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      model_name: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                    } | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                    }> | null;
                                  } | null;
                                } | null;
                              }> | null;
                            } | null;
                          } | null;
                        }> | null;
                      } | null;
                    }> | null;
                  }> | null;
                }> | null;
              }> | null;
            } | null;
          }> | null;
        }> | null;
      } | null;
      model_years?: Array<{
        __typename?: 'ModelYear';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        start_year: string;
        end_year: string;
        info_title?: string | null;
        info_description?: string | null;
      }> | null;
    };
  };
};

export type BrandModelDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type BrandModelDeleteMutation = {
  __typename?: 'Mutation';
  brandModelDelete: { __typename?: 'DeleteBrandModelOutput'; uuid: string };
};

export type BrandModelUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: UpdateBrandModelInput;
}>;

export type BrandModelUpdateMutation = {
  __typename?: 'Mutation';
  brandModelUpdate: {
    __typename?: 'UpdateBrandModelOutput';
    brandModel: {
      __typename?: 'BrandModel';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      model_name: string;
      info_title?: string | null;
      info_description?: string | null;
      car_brand?: {
        __typename?: 'CarBrand';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        brand_name: string;
        info_title?: string | null;
        info_description?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        pictogram_dark?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        brand_models?: Array<{
          __typename?: 'BrandModel';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          model_name: string;
          info_title?: string | null;
          info_description?: string | null;
          car_brand?: {
            __typename?: 'CarBrand';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            brand_name: string;
            info_title?: string | null;
            info_description?: string | null;
          } | null;
          model_years?: Array<{
            __typename?: 'ModelYear';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            start_year: string;
            end_year: string;
            info_title?: string | null;
            info_description?: string | null;
            brand_model?: {
              __typename?: 'BrandModel';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              model_name: string;
              info_title?: string | null;
              info_description?: string | null;
              car_brand?: {
                __typename?: 'CarBrand';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                brand_name: string;
                info_title?: string | null;
                info_description?: string | null;
              } | null;
              model_years?: Array<{
                __typename?: 'ModelYear';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                start_year: string;
                end_year: string;
                info_title?: string | null;
                info_description?: string | null;
                car_versions?: Array<{
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                      thumbnail: {
                        __typename?: 'StorageThumbnail';
                        width: number;
                        height: number;
                        size: number;
                        url: string;
                        file_name: string;
                      };
                      medium: {
                        __typename?: 'StorageThumbnail';
                        width: number;
                        height: number;
                        size: number;
                        url: string;
                        file_name: string;
                      };
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      version_lateral?: {
                        __typename?: 'VersionLateral';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        side_glazings?: Array<{
                          __typename?: 'SideGlazing';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                        }> | null;
                        cars_backwards?: Array<{
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                            car_version?: {
                              __typename?: 'CarVersion';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              pictogram?: {
                                __typename?: 'Storage';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                file_name: string;
                                url: string;
                                mime_type: string;
                                width: number;
                                height: number;
                                size: number;
                                caption: string;
                                description: string;
                                alternative_title: string;
                              } | null;
                              version_laterals?: Array<{
                                __typename?: 'VersionLateral';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                extra_price: number;
                                installer_extra_price: number;
                                installation_difficulty: number;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                cars_backwards?: Array<{
                                  __typename?: 'CarBackward';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  preview_text?: string | null;
                                  car_backward_types?: Array<{
                                    __typename?: 'CarBackwardType';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    has_rear_camera: boolean;
                                    pictogram?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    preview_image?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    car_backward?: {
                                      __typename?: 'CarBackward';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      extra_price: number;
                                      installer_extra_price: number;
                                      installation_difficulty: number;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_lateral?: {
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      } | null;
                                      car_backward_types?: Array<{
                                        __typename?: 'CarBackwardType';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        has_rear_camera: boolean;
                                      }> | null;
                                    } | null;
                                  }> | null;
                                }> | null;
                                car_version?: {
                                  __typename?: 'CarVersion';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  model_year?: {
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    brand_model?: {
                                      __typename?: 'BrandModel';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      model_name: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                    } | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                    }> | null;
                                  } | null;
                                } | null;
                              }> | null;
                            } | null;
                          } | null;
                        }> | null;
                      } | null;
                    }> | null;
                  }> | null;
                }> | null;
              }> | null;
            } | null;
          }> | null;
        }> | null;
      } | null;
      model_years?: Array<{
        __typename?: 'ModelYear';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        start_year: string;
        end_year: string;
        info_title?: string | null;
        info_description?: string | null;
      }> | null;
    };
  };
};

export type CarBackwardCreateMutationVariables = Exact<{
  input: CreateCarBackwardInput;
}>;

export type CarBackwardCreateMutation = {
  __typename?: 'Mutation';
  carBackwardCreate: {
    __typename?: 'CreateCarBackwardOutput';
    carBackward: {
      __typename?: 'CarBackward';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      extra_price: number;
      installer_extra_price: number;
      installation_difficulty: number;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      version_lateral?: {
        __typename?: 'VersionLateral';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        side_glazings?: Array<{
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            cars_backwards?: Array<{
              __typename?: 'CarBackward';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              info_title?: string | null;
              info_description?: string | null;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              preview_text?: string | null;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              preview_image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              version_lateral?: {
                __typename?: 'VersionLateral';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                car_version?: {
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                    }> | null;
                    cars_backwards?: Array<{
                      __typename?: 'CarBackward';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      preview_text?: string | null;
                      car_backward_types?: Array<{
                        __typename?: 'CarBackwardType';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        has_rear_camera: boolean;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        car_backward?: {
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          } | null;
                          car_backward_types?: Array<{
                            __typename?: 'CarBackwardType';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            has_rear_camera: boolean;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                    car_version?: {
                      __typename?: 'CarVersion';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      model_year?: {
                        __typename?: 'ModelYear';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        start_year: string;
                        end_year: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        brand_model?: {
                          __typename?: 'BrandModel';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          model_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          car_brand?: {
                            __typename?: 'CarBrand';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            brand_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            pictogram_dark?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                              thumbnail: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                              medium: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                            } | null;
                            brand_models?: Array<{
                              __typename?: 'BrandModel';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              model_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              car_brand?: {
                                __typename?: 'CarBrand';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                brand_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                              } | null;
                              model_years?: Array<{
                                __typename?: 'ModelYear';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                start_year: string;
                                end_year: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                brand_model?: {
                                  __typename?: 'BrandModel';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  model_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  model_years?: Array<{
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_laterals?: Array<{
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        car_versions?: Array<{
                                          __typename?: 'CarVersion';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                } | null;
                              }> | null;
                            }> | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
        }> | null;
      } | null;
      car_backward_types?: Array<{
        __typename?: 'CarBackwardType';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        has_rear_camera: boolean;
      }> | null;
    };
  };
};

export type CarBackwardDeleteMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type CarBackwardDeleteMutation = {
  __typename?: 'Mutation';
  carBackwardDelete: { __typename?: 'DeleteCarBackwardOutput'; uuid: string };
};

export type CarBackwardTypeCreateMutationVariables = Exact<{
  input: CreateCarBackwardTypeInput;
}>;

export type CarBackwardTypeCreateMutation = {
  __typename?: 'Mutation';
  carBackwardTypeCreate: {
    __typename?: 'CreateCarBackwardTypeOutput';
    carBackwardType: {
      __typename?: 'CarBackwardType';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      has_rear_camera: boolean;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      car_backward?: {
        __typename?: 'CarBackward';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        version_lateral?: {
          __typename?: 'VersionLateral';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          side_glazings?: Array<{
            __typename?: 'SideGlazing';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            preview_image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            version_lateral?: {
              __typename?: 'VersionLateral';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              info_title?: string | null;
              info_description?: string | null;
              preview_text?: string | null;
              cars_backwards?: Array<{
                __typename?: 'CarBackward';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                info_title?: string | null;
                info_description?: string | null;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                preview_text?: string | null;
                pictogram?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                preview_image?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                version_lateral?: {
                  __typename?: 'VersionLateral';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  extra_price: number;
                  installer_extra_price: number;
                  installation_difficulty: number;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  car_version?: {
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    version_laterals?: Array<{
                      __typename?: 'VersionLateral';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      side_glazings?: Array<{
                        __typename?: 'SideGlazing';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                      }> | null;
                      cars_backwards?: Array<{
                        __typename?: 'CarBackward';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        preview_text?: string | null;
                        car_backward_types?: Array<{
                          __typename?: 'CarBackwardType';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          has_rear_camera: boolean;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          car_backward?: {
                            __typename?: 'CarBackward';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            preview_text?: string | null;
                            car_backward_types?: Array<{
                              __typename?: 'CarBackwardType';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              has_rear_camera: boolean;
                            }> | null;
                          } | null;
                        }> | null;
                      }> | null;
                      car_version?: {
                        __typename?: 'CarVersion';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        model_year?: {
                          __typename?: 'ModelYear';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          start_year: string;
                          end_year: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          brand_model?: {
                            __typename?: 'BrandModel';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            model_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            car_brand?: {
                              __typename?: 'CarBrand';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              brand_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              pictogram?: {
                                __typename?: 'Storage';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                file_name: string;
                                url: string;
                                mime_type: string;
                                width: number;
                                height: number;
                                size: number;
                                caption: string;
                                description: string;
                                alternative_title: string;
                              } | null;
                              pictogram_dark?: {
                                __typename?: 'Storage';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                file_name: string;
                                url: string;
                                mime_type: string;
                                width: number;
                                height: number;
                                size: number;
                                caption: string;
                                description: string;
                                alternative_title: string;
                                thumbnail: {
                                  __typename?: 'StorageThumbnail';
                                  width: number;
                                  height: number;
                                  size: number;
                                  url: string;
                                  file_name: string;
                                };
                                medium: {
                                  __typename?: 'StorageThumbnail';
                                  width: number;
                                  height: number;
                                  size: number;
                                  url: string;
                                  file_name: string;
                                };
                              } | null;
                              brand_models?: Array<{
                                __typename?: 'BrandModel';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                model_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                car_brand?: {
                                  __typename?: 'CarBrand';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  brand_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                } | null;
                                model_years?: Array<{
                                  __typename?: 'ModelYear';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  start_year: string;
                                  end_year: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  brand_model?: {
                                    __typename?: 'BrandModel';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    model_name: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    model_years?: Array<{
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                        pictogram?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        version_laterals?: Array<{
                                          __typename?: 'VersionLateral';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          extra_price: number;
                                          installer_extra_price: number;
                                          installation_difficulty: number;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                        model_year?: {
                                          __typename?: 'ModelYear';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          start_year: string;
                                          end_year: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          car_versions?: Array<{
                                            __typename?: 'CarVersion';
                                            uuid: string;
                                            createdAt: any;
                                            updatedAt: any;
                                            title: string;
                                            sub_title: string;
                                            info_title?: string | null;
                                            info_description?: string | null;
                                            preview_text?: string | null;
                                          }> | null;
                                        } | null;
                                      }> | null;
                                    }> | null;
                                  } | null;
                                }> | null;
                              }> | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    }> | null;
                  } | null;
                } | null;
              }> | null;
            } | null;
          }> | null;
        } | null;
      } | null;
    };
  };
};

export type CarBackwardTypeDeleteMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type CarBackwardTypeDeleteMutation = {
  __typename?: 'Mutation';
  carBackwardTypeDelete: {
    __typename?: 'DeleteCarBackwardTypeOutput';
    uuid: string;
  };
};

export type CarBackwardTypeUpdateMutationVariables = Exact<{
  uuid: Scalars['String'];
  input: UpdateCarBackwardTypeInput;
}>;

export type CarBackwardTypeUpdateMutation = {
  __typename?: 'Mutation';
  carBackwardTypeUpdate: {
    __typename?: 'UpdateCarBackTypeOutput';
    carBackwardType: {
      __typename?: 'CarBackwardType';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      has_rear_camera: boolean;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      car_backward?: {
        __typename?: 'CarBackward';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        version_lateral?: {
          __typename?: 'VersionLateral';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          side_glazings?: Array<{
            __typename?: 'SideGlazing';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            preview_image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            version_lateral?: {
              __typename?: 'VersionLateral';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              info_title?: string | null;
              info_description?: string | null;
              preview_text?: string | null;
              cars_backwards?: Array<{
                __typename?: 'CarBackward';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                info_title?: string | null;
                info_description?: string | null;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                preview_text?: string | null;
                pictogram?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                preview_image?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                version_lateral?: {
                  __typename?: 'VersionLateral';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  extra_price: number;
                  installer_extra_price: number;
                  installation_difficulty: number;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  car_version?: {
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    version_laterals?: Array<{
                      __typename?: 'VersionLateral';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      side_glazings?: Array<{
                        __typename?: 'SideGlazing';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                      }> | null;
                      cars_backwards?: Array<{
                        __typename?: 'CarBackward';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        preview_text?: string | null;
                        car_backward_types?: Array<{
                          __typename?: 'CarBackwardType';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          has_rear_camera: boolean;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          car_backward?: {
                            __typename?: 'CarBackward';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            preview_text?: string | null;
                            car_backward_types?: Array<{
                              __typename?: 'CarBackwardType';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              has_rear_camera: boolean;
                            }> | null;
                          } | null;
                        }> | null;
                      }> | null;
                      car_version?: {
                        __typename?: 'CarVersion';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        model_year?: {
                          __typename?: 'ModelYear';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          start_year: string;
                          end_year: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          brand_model?: {
                            __typename?: 'BrandModel';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            model_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            car_brand?: {
                              __typename?: 'CarBrand';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              brand_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              pictogram?: {
                                __typename?: 'Storage';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                file_name: string;
                                url: string;
                                mime_type: string;
                                width: number;
                                height: number;
                                size: number;
                                caption: string;
                                description: string;
                                alternative_title: string;
                              } | null;
                              pictogram_dark?: {
                                __typename?: 'Storage';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                file_name: string;
                                url: string;
                                mime_type: string;
                                width: number;
                                height: number;
                                size: number;
                                caption: string;
                                description: string;
                                alternative_title: string;
                                thumbnail: {
                                  __typename?: 'StorageThumbnail';
                                  width: number;
                                  height: number;
                                  size: number;
                                  url: string;
                                  file_name: string;
                                };
                                medium: {
                                  __typename?: 'StorageThumbnail';
                                  width: number;
                                  height: number;
                                  size: number;
                                  url: string;
                                  file_name: string;
                                };
                              } | null;
                              brand_models?: Array<{
                                __typename?: 'BrandModel';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                model_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                car_brand?: {
                                  __typename?: 'CarBrand';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  brand_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                } | null;
                                model_years?: Array<{
                                  __typename?: 'ModelYear';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  start_year: string;
                                  end_year: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  brand_model?: {
                                    __typename?: 'BrandModel';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    model_name: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    model_years?: Array<{
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                        pictogram?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        version_laterals?: Array<{
                                          __typename?: 'VersionLateral';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          extra_price: number;
                                          installer_extra_price: number;
                                          installation_difficulty: number;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                        model_year?: {
                                          __typename?: 'ModelYear';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          start_year: string;
                                          end_year: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          car_versions?: Array<{
                                            __typename?: 'CarVersion';
                                            uuid: string;
                                            createdAt: any;
                                            updatedAt: any;
                                            title: string;
                                            sub_title: string;
                                            info_title?: string | null;
                                            info_description?: string | null;
                                            preview_text?: string | null;
                                          }> | null;
                                        } | null;
                                      }> | null;
                                    }> | null;
                                  } | null;
                                }> | null;
                              }> | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    }> | null;
                  } | null;
                } | null;
              }> | null;
            } | null;
          }> | null;
        } | null;
      } | null;
    };
  };
};

export type CarBackwardUpdateMutationVariables = Exact<{
  uuid: Scalars['String'];
  input: UpdateCarBackwardInput;
}>;

export type CarBackwardUpdateMutation = {
  __typename?: 'Mutation';
  carBackwardUpdate: {
    __typename?: 'UpdateCarBackwardOutput';
    carBackward: {
      __typename?: 'CarBackward';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      extra_price: number;
      installer_extra_price: number;
      installation_difficulty: number;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      version_lateral?: {
        __typename?: 'VersionLateral';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        side_glazings?: Array<{
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            cars_backwards?: Array<{
              __typename?: 'CarBackward';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              info_title?: string | null;
              info_description?: string | null;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              preview_text?: string | null;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              preview_image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              version_lateral?: {
                __typename?: 'VersionLateral';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                car_version?: {
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                    }> | null;
                    cars_backwards?: Array<{
                      __typename?: 'CarBackward';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      preview_text?: string | null;
                      car_backward_types?: Array<{
                        __typename?: 'CarBackwardType';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        has_rear_camera: boolean;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        car_backward?: {
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          } | null;
                          car_backward_types?: Array<{
                            __typename?: 'CarBackwardType';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            has_rear_camera: boolean;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                    car_version?: {
                      __typename?: 'CarVersion';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      model_year?: {
                        __typename?: 'ModelYear';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        start_year: string;
                        end_year: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        brand_model?: {
                          __typename?: 'BrandModel';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          model_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          car_brand?: {
                            __typename?: 'CarBrand';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            brand_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            pictogram_dark?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                              thumbnail: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                              medium: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                            } | null;
                            brand_models?: Array<{
                              __typename?: 'BrandModel';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              model_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              car_brand?: {
                                __typename?: 'CarBrand';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                brand_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                              } | null;
                              model_years?: Array<{
                                __typename?: 'ModelYear';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                start_year: string;
                                end_year: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                brand_model?: {
                                  __typename?: 'BrandModel';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  model_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  model_years?: Array<{
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_laterals?: Array<{
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        car_versions?: Array<{
                                          __typename?: 'CarVersion';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                } | null;
                              }> | null;
                            }> | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
        }> | null;
      } | null;
      car_backward_types?: Array<{
        __typename?: 'CarBackwardType';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        has_rear_camera: boolean;
      }> | null;
    };
  };
};

export type CarBrandCreateMutationVariables = Exact<{
  input: CreateCarBrandInput;
}>;

export type CarBrandCreateMutation = {
  __typename?: 'Mutation';
  carBrandCreate: {
    __typename?: 'CreateCarBrandOutput';
    carBrand: {
      __typename?: 'CarBrand';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      brand_name: string;
      info_title?: string | null;
      info_description?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      pictogram_dark?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      brand_models?: Array<{
        __typename?: 'BrandModel';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        model_name: string;
        info_title?: string | null;
        info_description?: string | null;
        car_brand?: {
          __typename?: 'CarBrand';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          brand_name: string;
          info_title?: string | null;
          info_description?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          pictogram_dark?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          brand_models?: Array<{
            __typename?: 'BrandModel';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            model_name: string;
            info_title?: string | null;
            info_description?: string | null;
            model_years?: Array<{
              __typename?: 'ModelYear';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              start_year: string;
              end_year: string;
              info_title?: string | null;
              info_description?: string | null;
              brand_model?: {
                __typename?: 'BrandModel';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                model_name: string;
                info_title?: string | null;
                info_description?: string | null;
                car_brand?: {
                  __typename?: 'CarBrand';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  brand_name: string;
                  info_title?: string | null;
                  info_description?: string | null;
                } | null;
                model_years?: Array<{
                  __typename?: 'ModelYear';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  start_year: string;
                  end_year: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  car_versions?: Array<{
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    version_laterals?: Array<{
                      __typename?: 'VersionLateral';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                        thumbnail: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                        medium: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                      } | null;
                      side_glazings?: Array<{
                        __typename?: 'SideGlazing';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          side_glazings?: Array<{
                            __typename?: 'SideGlazing';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          }> | null;
                          cars_backwards?: Array<{
                            __typename?: 'CarBackward';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            preview_text?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            preview_image?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            version_lateral?: {
                              __typename?: 'VersionLateral';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              extra_price: number;
                              installer_extra_price: number;
                              installation_difficulty: number;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              car_version?: {
                                __typename?: 'CarVersion';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                pictogram?: {
                                  __typename?: 'Storage';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  file_name: string;
                                  url: string;
                                  mime_type: string;
                                  width: number;
                                  height: number;
                                  size: number;
                                  caption: string;
                                  description: string;
                                  alternative_title: string;
                                } | null;
                                version_laterals?: Array<{
                                  __typename?: 'VersionLateral';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  cars_backwards?: Array<{
                                    __typename?: 'CarBackward';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    extra_price: number;
                                    installer_extra_price: number;
                                    installation_difficulty: number;
                                    preview_text?: string | null;
                                    car_backward_types?: Array<{
                                      __typename?: 'CarBackwardType';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      has_rear_camera: boolean;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      car_backward?: {
                                        __typename?: 'CarBackward';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        preview_text?: string | null;
                                        pictogram?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        preview_image?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        version_lateral?: {
                                          __typename?: 'VersionLateral';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          extra_price: number;
                                          installer_extra_price: number;
                                          installation_difficulty: number;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        } | null;
                                        car_backward_types?: Array<{
                                          __typename?: 'CarBackwardType';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          has_rear_camera: boolean;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                  car_version?: {
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      brand_model?: {
                                        __typename?: 'BrandModel';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        model_name: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                        model_year?: {
                                          __typename?: 'ModelYear';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          start_year: string;
                                          end_year: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                        } | null;
                                      }> | null;
                                    } | null;
                                  } | null;
                                }> | null;
                              } | null;
                            } | null;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                  }> | null;
                }> | null;
              } | null;
            }> | null;
          }> | null;
        } | null;
      }> | null;
    };
  };
};

export type CarBrandDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type CarBrandDeleteMutation = {
  __typename?: 'Mutation';
  carBrandDelete: { __typename?: 'DeleteCarBrandOutput'; uuid: string };
};

export type CarBrandUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: UpdateCarBrandInput;
}>;

export type CarBrandUpdateMutation = {
  __typename?: 'Mutation';
  carBrandUpdate: {
    __typename?: 'UpdateCarBrandOutput';
    carBrand: {
      __typename?: 'CarBrand';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      brand_name: string;
      info_title?: string | null;
      info_description?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      pictogram_dark?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      brand_models?: Array<{
        __typename?: 'BrandModel';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        model_name: string;
        info_title?: string | null;
        info_description?: string | null;
        car_brand?: {
          __typename?: 'CarBrand';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          brand_name: string;
          info_title?: string | null;
          info_description?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          pictogram_dark?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          brand_models?: Array<{
            __typename?: 'BrandModel';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            model_name: string;
            info_title?: string | null;
            info_description?: string | null;
            model_years?: Array<{
              __typename?: 'ModelYear';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              start_year: string;
              end_year: string;
              info_title?: string | null;
              info_description?: string | null;
              brand_model?: {
                __typename?: 'BrandModel';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                model_name: string;
                info_title?: string | null;
                info_description?: string | null;
                car_brand?: {
                  __typename?: 'CarBrand';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  brand_name: string;
                  info_title?: string | null;
                  info_description?: string | null;
                } | null;
                model_years?: Array<{
                  __typename?: 'ModelYear';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  start_year: string;
                  end_year: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  car_versions?: Array<{
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    version_laterals?: Array<{
                      __typename?: 'VersionLateral';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                        thumbnail: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                        medium: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                      } | null;
                      side_glazings?: Array<{
                        __typename?: 'SideGlazing';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          side_glazings?: Array<{
                            __typename?: 'SideGlazing';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          }> | null;
                          cars_backwards?: Array<{
                            __typename?: 'CarBackward';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            preview_text?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            preview_image?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            version_lateral?: {
                              __typename?: 'VersionLateral';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              extra_price: number;
                              installer_extra_price: number;
                              installation_difficulty: number;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              car_version?: {
                                __typename?: 'CarVersion';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                pictogram?: {
                                  __typename?: 'Storage';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  file_name: string;
                                  url: string;
                                  mime_type: string;
                                  width: number;
                                  height: number;
                                  size: number;
                                  caption: string;
                                  description: string;
                                  alternative_title: string;
                                } | null;
                                version_laterals?: Array<{
                                  __typename?: 'VersionLateral';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  cars_backwards?: Array<{
                                    __typename?: 'CarBackward';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    extra_price: number;
                                    installer_extra_price: number;
                                    installation_difficulty: number;
                                    preview_text?: string | null;
                                    car_backward_types?: Array<{
                                      __typename?: 'CarBackwardType';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      has_rear_camera: boolean;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      car_backward?: {
                                        __typename?: 'CarBackward';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        preview_text?: string | null;
                                        pictogram?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        preview_image?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        version_lateral?: {
                                          __typename?: 'VersionLateral';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          extra_price: number;
                                          installer_extra_price: number;
                                          installation_difficulty: number;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        } | null;
                                        car_backward_types?: Array<{
                                          __typename?: 'CarBackwardType';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          has_rear_camera: boolean;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                  car_version?: {
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      brand_model?: {
                                        __typename?: 'BrandModel';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        model_name: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                        model_year?: {
                                          __typename?: 'ModelYear';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          start_year: string;
                                          end_year: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                        } | null;
                                      }> | null;
                                    } | null;
                                  } | null;
                                }> | null;
                              } | null;
                            } | null;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                  }> | null;
                }> | null;
              } | null;
            }> | null;
          }> | null;
        } | null;
      }> | null;
    };
  };
};

export type CarVersionCreateMutationVariables = Exact<{
  input: CreateCarVersionInput;
}>;

export type CarVersionCreateMutation = {
  __typename?: 'Mutation';
  carVersionCreate: {
    __typename?: 'CreateCarVersionOutput';
    carVersion: {
      __typename?: 'CarVersion';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      version_laterals?: Array<{
        __typename?: 'VersionLateral';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        side_glazings?: Array<{
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            preview_image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            side_glazings?: Array<{
              __typename?: 'SideGlazing';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              extra_price: number;
              installer_extra_price: number;
              info_title?: string | null;
              info_description?: string | null;
              preview_text?: string | null;
            }> | null;
            cars_backwards?: Array<{
              __typename?: 'CarBackward';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              info_title?: string | null;
              info_description?: string | null;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              preview_text?: string | null;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              preview_image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              version_lateral?: {
                __typename?: 'VersionLateral';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                car_version?: {
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    cars_backwards?: Array<{
                      __typename?: 'CarBackward';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      preview_text?: string | null;
                      car_backward_types?: Array<{
                        __typename?: 'CarBackwardType';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        has_rear_camera: boolean;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        car_backward?: {
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          } | null;
                          car_backward_types?: Array<{
                            __typename?: 'CarBackwardType';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            has_rear_camera: boolean;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                    car_version?: {
                      __typename?: 'CarVersion';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      model_year?: {
                        __typename?: 'ModelYear';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        start_year: string;
                        end_year: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        brand_model?: {
                          __typename?: 'BrandModel';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          model_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          car_brand?: {
                            __typename?: 'CarBrand';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            brand_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            pictogram_dark?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                              thumbnail: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                              medium: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                            } | null;
                            brand_models?: Array<{
                              __typename?: 'BrandModel';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              model_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              car_brand?: {
                                __typename?: 'CarBrand';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                brand_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                              } | null;
                              model_years?: Array<{
                                __typename?: 'ModelYear';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                start_year: string;
                                end_year: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                brand_model?: {
                                  __typename?: 'BrandModel';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  model_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  model_years?: Array<{
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_laterals?: Array<{
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        car_versions?: Array<{
                                          __typename?: 'CarVersion';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                } | null;
                              }> | null;
                            }> | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
        }> | null;
      }> | null;
      model_year?: {
        __typename?: 'ModelYear';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        start_year: string;
        end_year: string;
        info_title?: string | null;
        info_description?: string | null;
      } | null;
    };
  };
};

export type CarVersionRemoveMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type CarVersionRemoveMutation = {
  __typename?: 'Mutation';
  carVersionRemove: { __typename?: 'DeleteCArVersionOutput'; uuid: string };
};

export type CarVersionUpdateMutationVariables = Exact<{
  uuid: Scalars['String'];
  input: UpdateCarVersionInput;
}>;

export type CarVersionUpdateMutation = {
  __typename?: 'Mutation';
  carVersionUpdate: {
    __typename?: 'UpdateCarVersionOutput';
    carVersion: {
      __typename?: 'CarVersion';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      version_laterals?: Array<{
        __typename?: 'VersionLateral';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        side_glazings?: Array<{
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            preview_image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            side_glazings?: Array<{
              __typename?: 'SideGlazing';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              extra_price: number;
              installer_extra_price: number;
              info_title?: string | null;
              info_description?: string | null;
              preview_text?: string | null;
            }> | null;
            cars_backwards?: Array<{
              __typename?: 'CarBackward';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              info_title?: string | null;
              info_description?: string | null;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              preview_text?: string | null;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              preview_image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              version_lateral?: {
                __typename?: 'VersionLateral';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                car_version?: {
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    cars_backwards?: Array<{
                      __typename?: 'CarBackward';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      preview_text?: string | null;
                      car_backward_types?: Array<{
                        __typename?: 'CarBackwardType';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        has_rear_camera: boolean;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        car_backward?: {
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          } | null;
                          car_backward_types?: Array<{
                            __typename?: 'CarBackwardType';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            has_rear_camera: boolean;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                    car_version?: {
                      __typename?: 'CarVersion';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      model_year?: {
                        __typename?: 'ModelYear';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        start_year: string;
                        end_year: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        brand_model?: {
                          __typename?: 'BrandModel';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          model_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          car_brand?: {
                            __typename?: 'CarBrand';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            brand_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            pictogram_dark?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                              thumbnail: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                              medium: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                            } | null;
                            brand_models?: Array<{
                              __typename?: 'BrandModel';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              model_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              car_brand?: {
                                __typename?: 'CarBrand';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                brand_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                              } | null;
                              model_years?: Array<{
                                __typename?: 'ModelYear';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                start_year: string;
                                end_year: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                brand_model?: {
                                  __typename?: 'BrandModel';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  model_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  model_years?: Array<{
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_laterals?: Array<{
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        car_versions?: Array<{
                                          __typename?: 'CarVersion';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                } | null;
                              }> | null;
                            }> | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
        }> | null;
      }> | null;
      model_year?: {
        __typename?: 'ModelYear';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        start_year: string;
        end_year: string;
        info_title?: string | null;
        info_description?: string | null;
      } | null;
    };
  };
};

export type Extra_StickerCreateMutationVariables = Exact<{
  input: CreateExtraStickersInput;
}>;

export type Extra_StickerCreateMutation = {
  __typename?: 'Mutation';
  extra_stickerCreate: {
    __typename?: 'CreateExtraStickersOutput';
    product_uuid: string;
    extra_stickers: Array<{
      __typename?: 'ExtraSticker';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      design_choice_possible: boolean;
      extra_price: number;
      installer_extra_price: number;
      overview: string;
      video_url: string;
      installation_difficulty: number;
      extra_price_sections: Array<{
        __typename?: 'ExtraStickerSectionPrice';
        section_uuid: string;
        extra_price: number;
      }>;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      depends_on_sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
    }>;
  };
};

export type Extra_StickerDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type Extra_StickerDeleteMutation = {
  __typename?: 'Mutation';
  extra_stickerDelete: {
    __typename?: 'DeleteExtraStickerOutput';
    uuid: string;
  };
};

export type Extra_StickerUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: ExtraStickerUpdateInput;
}>;

export type Extra_StickerUpdateMutation = {
  __typename?: 'Mutation';
  extra_stickerUpdate: {
    __typename?: 'UpdateExtraStickerOutput';
    extra_sticker: {
      __typename?: 'ExtraSticker';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      design_choice_possible: boolean;
      extra_price: number;
      installer_extra_price: number;
      overview: string;
      video_url: string;
      installation_difficulty: number;
      extra_price_sections: Array<{
        __typename?: 'ExtraStickerSectionPrice';
        section_uuid: string;
        extra_price: number;
      }>;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      depends_on_sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
    };
  };
};

export type Extra_StickerUpdateDependenciesMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: ExtraStickerUpdateDependenciesInput;
}>;

export type Extra_StickerUpdateDependenciesMutation = {
  __typename?: 'Mutation';
  extra_stickerUpdateDependencies: {
    __typename?: 'UpdateExtraStickerDependenciesOutput';
    extra_sticker: {
      __typename?: 'ExtraSticker';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      design_choice_possible: boolean;
      extra_price: number;
      installer_extra_price: number;
      overview: string;
      video_url: string;
      installation_difficulty: number;
      extra_price_sections: Array<{
        __typename?: 'ExtraStickerSectionPrice';
        section_uuid: string;
        extra_price: number;
      }>;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      depends_on_sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
    };
  };
};

export type ModelYearCreateMutationVariables = Exact<{
  input: CreateModelYearInput;
}>;

export type ModelYearCreateMutation = {
  __typename?: 'Mutation';
  modelYearCreate: {
    __typename?: 'CreateModelYearOutput';
    modelYear: {
      __typename?: 'ModelYear';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      start_year: string;
      end_year: string;
      info_title?: string | null;
      info_description?: string | null;
      brand_model?: {
        __typename?: 'BrandModel';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        model_name: string;
        info_title?: string | null;
        info_description?: string | null;
        car_brand?: {
          __typename?: 'CarBrand';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          brand_name: string;
          info_title?: string | null;
          info_description?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          pictogram_dark?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          brand_models?: Array<{
            __typename?: 'BrandModel';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            model_name: string;
            info_title?: string | null;
            info_description?: string | null;
            car_brand?: {
              __typename?: 'CarBrand';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              brand_name: string;
              info_title?: string | null;
              info_description?: string | null;
            } | null;
            model_years?: Array<{
              __typename?: 'ModelYear';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              start_year: string;
              end_year: string;
              info_title?: string | null;
              info_description?: string | null;
              brand_model?: {
                __typename?: 'BrandModel';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                model_name: string;
                info_title?: string | null;
                info_description?: string | null;
                model_years?: Array<{
                  __typename?: 'ModelYear';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  start_year: string;
                  end_year: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  car_versions?: Array<{
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    version_laterals?: Array<{
                      __typename?: 'VersionLateral';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                        thumbnail: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                        medium: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                      } | null;
                      side_glazings?: Array<{
                        __typename?: 'SideGlazing';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          side_glazings?: Array<{
                            __typename?: 'SideGlazing';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          }> | null;
                          cars_backwards?: Array<{
                            __typename?: 'CarBackward';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            preview_text?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            preview_image?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            version_lateral?: {
                              __typename?: 'VersionLateral';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              extra_price: number;
                              installer_extra_price: number;
                              installation_difficulty: number;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              car_version?: {
                                __typename?: 'CarVersion';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                pictogram?: {
                                  __typename?: 'Storage';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  file_name: string;
                                  url: string;
                                  mime_type: string;
                                  width: number;
                                  height: number;
                                  size: number;
                                  caption: string;
                                  description: string;
                                  alternative_title: string;
                                } | null;
                                version_laterals?: Array<{
                                  __typename?: 'VersionLateral';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  cars_backwards?: Array<{
                                    __typename?: 'CarBackward';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    extra_price: number;
                                    installer_extra_price: number;
                                    installation_difficulty: number;
                                    preview_text?: string | null;
                                    car_backward_types?: Array<{
                                      __typename?: 'CarBackwardType';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      has_rear_camera: boolean;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      car_backward?: {
                                        __typename?: 'CarBackward';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        preview_text?: string | null;
                                        pictogram?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        preview_image?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        version_lateral?: {
                                          __typename?: 'VersionLateral';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          extra_price: number;
                                          installer_extra_price: number;
                                          installation_difficulty: number;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        } | null;
                                        car_backward_types?: Array<{
                                          __typename?: 'CarBackwardType';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          has_rear_camera: boolean;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                  car_version?: {
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      brand_model?: {
                                        __typename?: 'BrandModel';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        model_name: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                        model_year?: {
                                          __typename?: 'ModelYear';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          start_year: string;
                                          end_year: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                        } | null;
                                      }> | null;
                                    } | null;
                                  } | null;
                                }> | null;
                              } | null;
                            } | null;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                  }> | null;
                }> | null;
              } | null;
            }> | null;
          }> | null;
        } | null;
      } | null;
      car_versions?: Array<{
        __typename?: 'CarVersion';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
      }> | null;
    };
  };
};

export type ModelYearDeleteMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ModelYearDeleteMutation = {
  __typename?: 'Mutation';
  modelYearDelete: { __typename?: 'DeleteModelYear'; uuid: string };
};

export type ModelYearUpdateMutationVariables = Exact<{
  uuid: Scalars['String'];
  input: UpdateModelYearInput;
}>;

export type ModelYearUpdateMutation = {
  __typename?: 'Mutation';
  modelYearUpdate: {
    __typename?: 'UpdateModelyearOutput';
    modelYear: {
      __typename?: 'ModelYear';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      start_year: string;
      end_year: string;
      info_title?: string | null;
      info_description?: string | null;
      brand_model?: {
        __typename?: 'BrandModel';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        model_name: string;
        info_title?: string | null;
        info_description?: string | null;
        car_brand?: {
          __typename?: 'CarBrand';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          brand_name: string;
          info_title?: string | null;
          info_description?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          pictogram_dark?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          brand_models?: Array<{
            __typename?: 'BrandModel';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            model_name: string;
            info_title?: string | null;
            info_description?: string | null;
            car_brand?: {
              __typename?: 'CarBrand';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              brand_name: string;
              info_title?: string | null;
              info_description?: string | null;
            } | null;
            model_years?: Array<{
              __typename?: 'ModelYear';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              start_year: string;
              end_year: string;
              info_title?: string | null;
              info_description?: string | null;
              brand_model?: {
                __typename?: 'BrandModel';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                model_name: string;
                info_title?: string | null;
                info_description?: string | null;
                model_years?: Array<{
                  __typename?: 'ModelYear';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  start_year: string;
                  end_year: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  car_versions?: Array<{
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    version_laterals?: Array<{
                      __typename?: 'VersionLateral';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                        thumbnail: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                        medium: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                      } | null;
                      side_glazings?: Array<{
                        __typename?: 'SideGlazing';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          side_glazings?: Array<{
                            __typename?: 'SideGlazing';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          }> | null;
                          cars_backwards?: Array<{
                            __typename?: 'CarBackward';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            preview_text?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            preview_image?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            version_lateral?: {
                              __typename?: 'VersionLateral';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              extra_price: number;
                              installer_extra_price: number;
                              installation_difficulty: number;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              car_version?: {
                                __typename?: 'CarVersion';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                pictogram?: {
                                  __typename?: 'Storage';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  file_name: string;
                                  url: string;
                                  mime_type: string;
                                  width: number;
                                  height: number;
                                  size: number;
                                  caption: string;
                                  description: string;
                                  alternative_title: string;
                                } | null;
                                version_laterals?: Array<{
                                  __typename?: 'VersionLateral';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  cars_backwards?: Array<{
                                    __typename?: 'CarBackward';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    extra_price: number;
                                    installer_extra_price: number;
                                    installation_difficulty: number;
                                    preview_text?: string | null;
                                    car_backward_types?: Array<{
                                      __typename?: 'CarBackwardType';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      has_rear_camera: boolean;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      car_backward?: {
                                        __typename?: 'CarBackward';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        preview_text?: string | null;
                                        pictogram?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        preview_image?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        version_lateral?: {
                                          __typename?: 'VersionLateral';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          extra_price: number;
                                          installer_extra_price: number;
                                          installation_difficulty: number;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        } | null;
                                        car_backward_types?: Array<{
                                          __typename?: 'CarBackwardType';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          has_rear_camera: boolean;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                  car_version?: {
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      brand_model?: {
                                        __typename?: 'BrandModel';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        model_name: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                        model_year?: {
                                          __typename?: 'ModelYear';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          start_year: string;
                                          end_year: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                        } | null;
                                      }> | null;
                                    } | null;
                                  } | null;
                                }> | null;
                              } | null;
                            } | null;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                  }> | null;
                }> | null;
              } | null;
            }> | null;
          }> | null;
        } | null;
      } | null;
      car_versions?: Array<{
        __typename?: 'CarVersion';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
      }> | null;
    };
  };
};

export type ProductCreateMutationVariables = Exact<{
  input: CreateProductInput;
}>;

export type ProductCreateMutation = {
  __typename?: 'Mutation';
  productCreate: {
    __typename?: 'CreateProductOutput';
    product: {
      __typename?: 'Product';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      type: ProductTypes;
      description: string;
      slug?: string | null;
      meta_title?: string | null;
      meta_description?: string | null;
      verification: boolean;
      graphic_service: boolean;
      professional_installer: boolean;
      enabled: boolean;
      index: number;
      start_price: number;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      supports?: Array<{
        __typename?: 'Support';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        can_have_background: boolean;
        extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
      sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
      sections?: Array<{
        __typename?: 'Section';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        can_have_background: boolean;
        extra_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        extra_price_size: Array<{
          __typename?: 'ExtraSectionSizePrice';
          size_uuid: string;
          extra_price: number;
        }>;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        }> | null;
      }> | null;
      extra_stickers?: Array<{
        __typename?: 'ExtraSticker';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        design_choice_possible: boolean;
        extra_price: number;
        installer_extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        extra_price_sections: Array<{
          __typename?: 'ExtraStickerSectionPrice';
          section_uuid: string;
          extra_price: number;
        }>;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
        }> | null;
      }> | null;
      templates?: Array<{
        __typename?: 'Template';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        file?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
      product_images?: Array<{
        __typename?: 'ProductImage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        support_uuid?: string | null;
        size_uuid?: string | null;
        section_uuid?: string | null;
        extra_sticker_uuid?: string | null;
        file?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
      }> | null;
    };
  };
};

export type ProductDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type ProductDeleteMutation = {
  __typename?: 'Mutation';
  productDelete: { __typename?: 'DeleteProductOutput'; uuid: string };
};

export type ProductImageCreateMutationVariables = Exact<{
  input: CreateProductImageInput;
}>;

export type ProductImageCreateMutation = {
  __typename?: 'Mutation';
  productImageCreate: {
    __typename?: 'CreateProductImageOutput';
    product_image: {
      __typename?: 'ProductImage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      support_uuid?: string | null;
      size_uuid?: string | null;
      section_uuid?: string | null;
      extra_sticker_uuid?: string | null;
      file?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    };
  };
};

export type ProductImageDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type ProductImageDeleteMutation = {
  __typename?: 'Mutation';
  productImageDelete: { __typename?: 'DeleteProductImageOutput'; uuid: string };
};

export type ProductUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: ProductUpdateInput;
}>;

export type ProductUpdateMutation = {
  __typename?: 'Mutation';
  productUpdate: {
    __typename?: 'UpdateProductOutput';
    product: {
      __typename?: 'Product';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      type: ProductTypes;
      description: string;
      slug?: string | null;
      meta_title?: string | null;
      meta_description?: string | null;
      verification: boolean;
      graphic_service: boolean;
      professional_installer: boolean;
      enabled: boolean;
      index: number;
      start_price: number;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      supports?: Array<{
        __typename?: 'Support';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        can_have_background: boolean;
        extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
      sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
      sections?: Array<{
        __typename?: 'Section';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        can_have_background: boolean;
        extra_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        extra_price_size: Array<{
          __typename?: 'ExtraSectionSizePrice';
          size_uuid: string;
          extra_price: number;
        }>;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        }> | null;
      }> | null;
      extra_stickers?: Array<{
        __typename?: 'ExtraSticker';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        design_choice_possible: boolean;
        extra_price: number;
        installer_extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        extra_price_sections: Array<{
          __typename?: 'ExtraStickerSectionPrice';
          section_uuid: string;
          extra_price: number;
        }>;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
        }> | null;
      }> | null;
      templates?: Array<{
        __typename?: 'Template';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        file?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
      product_images?: Array<{
        __typename?: 'ProductImage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        support_uuid?: string | null;
        size_uuid?: string | null;
        section_uuid?: string | null;
        extra_sticker_uuid?: string | null;
        file?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
      }> | null;
    };
  };
};

export type SectionCreateMutationVariables = Exact<{
  input: CreateSectionsInput;
}>;

export type SectionCreateMutation = {
  __typename?: 'Mutation';
  sectionCreate: {
    __typename?: 'CreateSectionsOutput';
    product_uuid: string;
    sections: Array<{
      __typename?: 'Section';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      can_have_background: boolean;
      extra_price: number;
      canvas_width: number;
      canvas_height: number;
      canvas_radius: number;
      overview: string;
      video_url: string;
      installation_difficulty: number;
      extra_price_size: Array<{
        __typename?: 'ExtraSectionSizePrice';
        size_uuid: string;
        extra_price: number;
      }>;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      depends_on_sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
    }>;
  };
};

export type SectionDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type SectionDeleteMutation = {
  __typename?: 'Mutation';
  sectionDelete: { __typename?: 'DeleteSectionOutput'; uuid: string };
};

export type SectionUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: SectionUpdateInput;
}>;

export type SectionUpdateMutation = {
  __typename?: 'Mutation';
  sectionUpdate: {
    __typename?: 'UpdateSectionOutput';
    section: {
      __typename?: 'Section';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      can_have_background: boolean;
      extra_price: number;
      canvas_width: number;
      canvas_height: number;
      canvas_radius: number;
      overview: string;
      video_url: string;
      installation_difficulty: number;
      extra_price_size: Array<{
        __typename?: 'ExtraSectionSizePrice';
        size_uuid: string;
        extra_price: number;
      }>;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      depends_on_sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
    };
  };
};

export type SectionUpdateDependenciesMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: SectionUpdateDependenciesInput;
}>;

export type SectionUpdateDependenciesMutation = {
  __typename?: 'Mutation';
  sectionUpdateDependencies: {
    __typename?: 'UpdateSectionDependenciesOutput';
    section: {
      __typename?: 'Section';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      can_have_background: boolean;
      extra_price: number;
      canvas_width: number;
      canvas_height: number;
      canvas_radius: number;
      overview: string;
      video_url: string;
      installation_difficulty: number;
      extra_price_size: Array<{
        __typename?: 'ExtraSectionSizePrice';
        size_uuid: string;
        extra_price: number;
      }>;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      depends_on_sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
    };
  };
};

export type SideGlazingCreateMutationVariables = Exact<{
  input: CreateSideGlazingInput;
}>;

export type SideGlazingCreateMutation = {
  __typename?: 'Mutation';
  sideGlazingCreate: {
    __typename?: 'CreateSideGlazingOutput';
    sideGlazing: {
      __typename?: 'SideGlazing';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      extra_price: number;
      installer_extra_price: number;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      version_lateral?: {
        __typename?: 'VersionLateral';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        side_glazings?: Array<{
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            cars_backwards?: Array<{
              __typename?: 'CarBackward';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              info_title?: string | null;
              info_description?: string | null;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              preview_text?: string | null;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              preview_image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              version_lateral?: {
                __typename?: 'VersionLateral';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                car_version?: {
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                    }> | null;
                    cars_backwards?: Array<{
                      __typename?: 'CarBackward';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      preview_text?: string | null;
                      car_backward_types?: Array<{
                        __typename?: 'CarBackwardType';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        has_rear_camera: boolean;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        car_backward?: {
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          } | null;
                          car_backward_types?: Array<{
                            __typename?: 'CarBackwardType';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            has_rear_camera: boolean;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                    car_version?: {
                      __typename?: 'CarVersion';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      model_year?: {
                        __typename?: 'ModelYear';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        start_year: string;
                        end_year: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        brand_model?: {
                          __typename?: 'BrandModel';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          model_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          car_brand?: {
                            __typename?: 'CarBrand';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            brand_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            pictogram_dark?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                              thumbnail: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                              medium: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                            } | null;
                            brand_models?: Array<{
                              __typename?: 'BrandModel';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              model_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              car_brand?: {
                                __typename?: 'CarBrand';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                brand_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                              } | null;
                              model_years?: Array<{
                                __typename?: 'ModelYear';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                start_year: string;
                                end_year: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                brand_model?: {
                                  __typename?: 'BrandModel';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  model_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  model_years?: Array<{
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_laterals?: Array<{
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        car_versions?: Array<{
                                          __typename?: 'CarVersion';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                } | null;
                              }> | null;
                            }> | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
        }> | null;
      } | null;
    };
  };
};

export type SideGlazingDeleteMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type SideGlazingDeleteMutation = {
  __typename?: 'Mutation';
  sideGlazingDelete: { __typename?: 'DeleteSideGlazingOutput'; uuid: string };
};

export type SideGlazingUpdateMutationVariables = Exact<{
  uuid: Scalars['String'];
  input: UpdateSideGlazingInput;
}>;

export type SideGlazingUpdateMutation = {
  __typename?: 'Mutation';
  sideGlazingUpdate: {
    __typename?: 'UpdateSideGlazingOutput';
    sideGlazing: {
      __typename?: 'SideGlazing';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      extra_price: number;
      installer_extra_price: number;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      version_lateral?: {
        __typename?: 'VersionLateral';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        side_glazings?: Array<{
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            cars_backwards?: Array<{
              __typename?: 'CarBackward';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              info_title?: string | null;
              info_description?: string | null;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              preview_text?: string | null;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              preview_image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              version_lateral?: {
                __typename?: 'VersionLateral';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                car_version?: {
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                    }> | null;
                    cars_backwards?: Array<{
                      __typename?: 'CarBackward';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      preview_text?: string | null;
                      car_backward_types?: Array<{
                        __typename?: 'CarBackwardType';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        has_rear_camera: boolean;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        car_backward?: {
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          } | null;
                          car_backward_types?: Array<{
                            __typename?: 'CarBackwardType';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            has_rear_camera: boolean;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                    car_version?: {
                      __typename?: 'CarVersion';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      model_year?: {
                        __typename?: 'ModelYear';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        start_year: string;
                        end_year: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        brand_model?: {
                          __typename?: 'BrandModel';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          model_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          car_brand?: {
                            __typename?: 'CarBrand';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            brand_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            pictogram_dark?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                              thumbnail: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                              medium: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                            } | null;
                            brand_models?: Array<{
                              __typename?: 'BrandModel';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              model_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              car_brand?: {
                                __typename?: 'CarBrand';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                brand_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                              } | null;
                              model_years?: Array<{
                                __typename?: 'ModelYear';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                start_year: string;
                                end_year: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                brand_model?: {
                                  __typename?: 'BrandModel';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  model_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  model_years?: Array<{
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_laterals?: Array<{
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        car_versions?: Array<{
                                          __typename?: 'CarVersion';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                } | null;
                              }> | null;
                            }> | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
        }> | null;
      } | null;
    };
  };
};

export type SizeCreateMutationVariables = Exact<{
  input: CreateSizesInput;
}>;

export type SizeCreateMutation = {
  __typename?: 'Mutation';
  sizeCreate: {
    __typename?: 'CreateSizesOutput';
    product_uuid: string;
    sizes: Array<{
      __typename?: 'Size';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      need_vehicle_info: boolean;
      price: number;
      index: number;
      installer_price: number;
      canvas_width: number;
      canvas_height: number;
      canvas_radius: number;
      overview: string;
      video_url?: string | null;
      design_choice_possible: boolean;
      installation_difficulty: number;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      depends_on_supports?: Array<{
        __typename?: 'Support';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        can_have_background: boolean;
        extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
      }> | null;
    }>;
  };
};

export type SizeDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type SizeDeleteMutation = {
  __typename?: 'Mutation';
  sizeDelete: { __typename?: 'DeleteSizeOutput'; uuid: string };
};

export type SizeUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: SizeUpdateInput;
}>;

export type SizeUpdateMutation = {
  __typename?: 'Mutation';
  sizeUpdate: {
    __typename?: 'UpdateSizeOutput';
    size: {
      __typename?: 'Size';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      need_vehicle_info: boolean;
      price: number;
      index: number;
      installer_price: number;
      canvas_width: number;
      canvas_height: number;
      canvas_radius: number;
      overview: string;
      video_url?: string | null;
      design_choice_possible: boolean;
      installation_difficulty: number;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      depends_on_supports?: Array<{
        __typename?: 'Support';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        can_have_background: boolean;
        extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
      }> | null;
    };
  };
};

export type SizeUpdateDependenciesMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: SizeUpdateDependenciesInput;
}>;

export type SizeUpdateDependenciesMutation = {
  __typename?: 'Mutation';
  sizeUpdateDependencies: {
    __typename?: 'UpdateSizeDependenciesOutput';
    size: {
      __typename?: 'Size';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      need_vehicle_info: boolean;
      price: number;
      index: number;
      installer_price: number;
      canvas_width: number;
      canvas_height: number;
      canvas_radius: number;
      overview: string;
      video_url?: string | null;
      design_choice_possible: boolean;
      installation_difficulty: number;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      depends_on_supports?: Array<{
        __typename?: 'Support';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        can_have_background: boolean;
        extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
      }> | null;
    };
  };
};

export type SquidstudioCategoryCreateMutationVariables = Exact<{
  input: CreateSquidstudioCategoryInput;
}>;

export type SquidstudioCategoryCreateMutation = {
  __typename?: 'Mutation';
  squidstudioCategoryCreate: {
    __typename?: 'SquidstudioCategoryOutput';
    squidstudio_category: {
      __typename?: 'SquidstudioCategory';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      type: Squidstudio_Categories_Types;
      icon?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      files?: Array<{
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      }> | null;
    };
  };
};

export type SquidstudioCategoryDeleteMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type SquidstudioCategoryDeleteMutation = {
  __typename?: 'Mutation';
  squidstudioCategoryDelete: { __typename?: 'MessageOutput'; message: string };
};

export type SquidstudioCategoryUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: UpdateSquidstudioCategoryInput;
}>;

export type SquidstudioCategoryUpdateMutation = {
  __typename?: 'Mutation';
  squidstudioCategoryUpdate: {
    __typename?: 'SquidstudioCategoryOutput';
    squidstudio_category: {
      __typename?: 'SquidstudioCategory';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      type: Squidstudio_Categories_Types;
      icon?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      files?: Array<{
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      }> | null;
    };
  };
};

export type SupportCreateMutationVariables = Exact<{
  input: CreateSupportsInput;
}>;

export type SupportCreateMutation = {
  __typename?: 'Mutation';
  supportCreate: {
    __typename?: 'CreateSupportsOutput';
    product_uuid: string;
    supports: Array<{
      __typename?: 'Support';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      can_have_background: boolean;
      extra_price: number;
      overview: string;
      video_url: string;
      installation_difficulty: number;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    }>;
  };
};

export type SupportDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type SupportDeleteMutation = {
  __typename?: 'Mutation';
  supportDelete: { __typename?: 'DeleteSupportOutput'; uuid: string };
};

export type SupportUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: SupportUpdateInput;
}>;

export type SupportUpdateMutation = {
  __typename?: 'Mutation';
  supportUpdate: {
    __typename?: 'UpdateSupportOutput';
    support: {
      __typename?: 'Support';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      can_have_background: boolean;
      extra_price: number;
      overview: string;
      video_url: string;
      installation_difficulty: number;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    };
  };
};

export type TemplateCreateMutationVariables = Exact<{
  input: CreateTemplatesInput;
}>;

export type TemplateCreateMutation = {
  __typename?: 'Mutation';
  templateCreate: {
    __typename?: 'CreateTemplatesOutput';
    product_uuid: string;
    templates: Array<{
      __typename?: 'Template';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      file?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    }>;
  };
};

export type TemplateDeleteMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type TemplateDeleteMutation = {
  __typename?: 'Mutation';
  templateDelete: { __typename?: 'DeleteTemplateOutput'; uuid: string };
};

export type UpdateTemplateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: TemplateUpdateInput;
}>;

export type UpdateTemplateMutation = {
  __typename?: 'Mutation';
  updateTemplate: {
    __typename?: 'UpdateTemplateOutput';
    template: {
      __typename?: 'Template';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      file?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    };
  };
};

export type VersionLateralCreateMutationVariables = Exact<{
  input: CreateVersionLateralInput;
}>;

export type VersionLateralCreateMutation = {
  __typename?: 'Mutation';
  versionLateralCreate: {
    __typename?: 'CreateVersionLateralOutput';
    versionLateral: {
      __typename?: 'VersionLateral';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      extra_price: number;
      installer_extra_price: number;
      installation_difficulty: number;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      side_glazings?: Array<{
        __typename?: 'SideGlazing';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        version_lateral?: {
          __typename?: 'VersionLateral';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          side_glazings?: Array<{
            __typename?: 'SideGlazing';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
          }> | null;
          cars_backwards?: Array<{
            __typename?: 'CarBackward';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            info_title?: string | null;
            info_description?: string | null;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            preview_text?: string | null;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            preview_image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            version_lateral?: {
              __typename?: 'VersionLateral';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              info_title?: string | null;
              info_description?: string | null;
              preview_text?: string | null;
              car_version?: {
                __typename?: 'CarVersion';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                pictogram?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                version_laterals?: Array<{
                  __typename?: 'VersionLateral';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  extra_price: number;
                  installer_extra_price: number;
                  installation_difficulty: number;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  preview_image?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  side_glazings?: Array<{
                    __typename?: 'SideGlazing';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                  }> | null;
                  cars_backwards?: Array<{
                    __typename?: 'CarBackward';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    preview_text?: string | null;
                    car_backward_types?: Array<{
                      __typename?: 'CarBackwardType';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      has_rear_camera: boolean;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      car_backward?: {
                        __typename?: 'CarBackward';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                        } | null;
                        car_backward_types?: Array<{
                          __typename?: 'CarBackwardType';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          has_rear_camera: boolean;
                        }> | null;
                      } | null;
                    }> | null;
                  }> | null;
                  car_version?: {
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    model_year?: {
                      __typename?: 'ModelYear';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      start_year: string;
                      end_year: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      brand_model?: {
                        __typename?: 'BrandModel';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        model_name: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        car_brand?: {
                          __typename?: 'CarBrand';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          brand_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          pictogram_dark?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                            thumbnail: {
                              __typename?: 'StorageThumbnail';
                              width: number;
                              height: number;
                              size: number;
                              url: string;
                              file_name: string;
                            };
                            medium: {
                              __typename?: 'StorageThumbnail';
                              width: number;
                              height: number;
                              size: number;
                              url: string;
                              file_name: string;
                            };
                          } | null;
                          brand_models?: Array<{
                            __typename?: 'BrandModel';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            model_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            car_brand?: {
                              __typename?: 'CarBrand';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              brand_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                            } | null;
                            model_years?: Array<{
                              __typename?: 'ModelYear';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              start_year: string;
                              end_year: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              brand_model?: {
                                __typename?: 'BrandModel';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                model_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                model_years?: Array<{
                                  __typename?: 'ModelYear';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  start_year: string;
                                  end_year: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  car_versions?: Array<{
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    pictogram?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    version_laterals?: Array<{
                                      __typename?: 'VersionLateral';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      extra_price: number;
                                      installer_extra_price: number;
                                      installation_difficulty: number;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                    }> | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                    } | null;
                                  }> | null;
                                }> | null;
                              } | null;
                            }> | null;
                          }> | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }> | null;
              } | null;
            } | null;
          }> | null;
        } | null;
      }> | null;
      cars_backwards?: Array<{
        __typename?: 'CarBackward';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        preview_text?: string | null;
      }> | null;
      car_version?: {
        __typename?: 'CarVersion';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
      } | null;
    };
  };
};

export type VersionLateralDeleteMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type VersionLateralDeleteMutation = {
  __typename?: 'Mutation';
  versionLateralDelete: {
    __typename?: 'DeleteVersionLateralOutput';
    uuid: string;
  };
};

export type VersionLateralUpdateMutationVariables = Exact<{
  uuid: Scalars['String'];
  input: UpdateVersionLateralInput;
}>;

export type VersionLateralUpdateMutation = {
  __typename?: 'Mutation';
  versionLateralUpdate: {
    __typename?: 'UpdateVersionLateralOutput';
    versionLateral: {
      __typename?: 'VersionLateral';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      extra_price: number;
      installer_extra_price: number;
      installation_difficulty: number;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      side_glazings?: Array<{
        __typename?: 'SideGlazing';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        version_lateral?: {
          __typename?: 'VersionLateral';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          side_glazings?: Array<{
            __typename?: 'SideGlazing';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
          }> | null;
          cars_backwards?: Array<{
            __typename?: 'CarBackward';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            info_title?: string | null;
            info_description?: string | null;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            preview_text?: string | null;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            preview_image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            version_lateral?: {
              __typename?: 'VersionLateral';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              info_title?: string | null;
              info_description?: string | null;
              preview_text?: string | null;
              car_version?: {
                __typename?: 'CarVersion';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                pictogram?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                version_laterals?: Array<{
                  __typename?: 'VersionLateral';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  extra_price: number;
                  installer_extra_price: number;
                  installation_difficulty: number;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  preview_image?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  side_glazings?: Array<{
                    __typename?: 'SideGlazing';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                  }> | null;
                  cars_backwards?: Array<{
                    __typename?: 'CarBackward';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    preview_text?: string | null;
                    car_backward_types?: Array<{
                      __typename?: 'CarBackwardType';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      has_rear_camera: boolean;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      car_backward?: {
                        __typename?: 'CarBackward';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                        } | null;
                        car_backward_types?: Array<{
                          __typename?: 'CarBackwardType';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          has_rear_camera: boolean;
                        }> | null;
                      } | null;
                    }> | null;
                  }> | null;
                  car_version?: {
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    model_year?: {
                      __typename?: 'ModelYear';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      start_year: string;
                      end_year: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      brand_model?: {
                        __typename?: 'BrandModel';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        model_name: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        car_brand?: {
                          __typename?: 'CarBrand';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          brand_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          pictogram_dark?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                            thumbnail: {
                              __typename?: 'StorageThumbnail';
                              width: number;
                              height: number;
                              size: number;
                              url: string;
                              file_name: string;
                            };
                            medium: {
                              __typename?: 'StorageThumbnail';
                              width: number;
                              height: number;
                              size: number;
                              url: string;
                              file_name: string;
                            };
                          } | null;
                          brand_models?: Array<{
                            __typename?: 'BrandModel';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            model_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            car_brand?: {
                              __typename?: 'CarBrand';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              brand_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                            } | null;
                            model_years?: Array<{
                              __typename?: 'ModelYear';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              start_year: string;
                              end_year: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              brand_model?: {
                                __typename?: 'BrandModel';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                model_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                model_years?: Array<{
                                  __typename?: 'ModelYear';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  start_year: string;
                                  end_year: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  car_versions?: Array<{
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    pictogram?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    version_laterals?: Array<{
                                      __typename?: 'VersionLateral';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      extra_price: number;
                                      installer_extra_price: number;
                                      installation_difficulty: number;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                    }> | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                    } | null;
                                  }> | null;
                                }> | null;
                              } | null;
                            }> | null;
                          }> | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }> | null;
              } | null;
            } | null;
          }> | null;
        } | null;
      }> | null;
      cars_backwards?: Array<{
        __typename?: 'CarBackward';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        preview_text?: string | null;
      }> | null;
      car_version?: {
        __typename?: 'CarVersion';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
      } | null;
    };
  };
};

export type ConfirmResetPasswordMutationVariables = Exact<{
  input: ConfirmResetPasswordInput;
}>;

export type ConfirmResetPasswordMutation = {
  __typename?: 'Mutation';
  confirmResetPassword: { __typename?: 'ResetPasswordOutput'; message: string };
};

export type UpdateMyPasswordMutationVariables = Exact<{
  input: UpdateMyPasswordDto;
}>;

export type UpdateMyPasswordMutation = {
  __typename?: 'Mutation';
  updateMyPassword: { __typename?: 'MessageOutput'; message: string };
};

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileDto;
}>;

export type UpdateProfileMutation = {
  __typename?: 'Mutation';
  updateProfile: {
    __typename?: 'CreateUserOutput';
    user: {
      __typename?: 'User';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      email: string;
      firstName: string;
      lastName: string;
      company_name: string;
      address: string;
      postal_code: string;
      city: string;
      country: string;
      phonenumber: string;
      activated: boolean;
      enabled: boolean;
      role: Roles;
      vehicles: Array<{
        __typename?: 'VehicleInformation';
        brand?: string | null;
        model?: string | null;
        year?: string | null;
        color?: string | null;
      }>;
    };
  };
};

export type EstimateDeleteMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type EstimateDeleteMutation = {
  __typename?: 'Mutation';
  estimateDelete: { __typename?: 'MessageOutput'; message: string };
};

export type EstimateUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: UpdateEstimateInput;
}>;

export type EstimateUpdateMutation = {
  __typename?: 'Mutation';
  estimateUpdate: {
    __typename?: 'RegisterEstimateOutput';
    estimate: {
      __typename?: 'Estimate';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      company_name: string;
      phonenumber: string;
      mail: string;
      subject: string;
      status: Estimates_Status;
      vehicle: string;
      description_of_project: string;
      your_mark: string;
      your_model: string;
      year_of_your_vehicle: string;
      marquetry: string;
      file?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    };
  };
};

export type CreateReviewMutationVariables = Exact<{
  input: CreateReviewInput;
}>;

export type CreateReviewMutation = {
  __typename?: 'Mutation';
  createReview: {
    __typename?: 'CreateReviewOutput';
    review: {
      __typename?: 'Review';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      text: string;
      note: number;
      display_on_home: boolean;
      display_on_product: boolean;
      products?: Array<{
        __typename?: 'Product';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        type: ProductTypes;
        description: string;
        slug?: string | null;
        meta_title?: string | null;
        meta_description?: string | null;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        enabled: boolean;
        index: number;
        start_price: number;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        }> | null;
        sections?: Array<{
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
          }> | null;
        }> | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_sections: Array<{
            __typename?: 'ExtraStickerSectionPrice';
            section_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        }> | null;
        templates?: Array<{
          __typename?: 'Template';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        product_images?: Array<{
          __typename?: 'ProductImage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          support_uuid?: string | null;
          size_uuid?: string | null;
          section_uuid?: string | null;
          extra_sticker_uuid?: string | null;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
      user?: {
        __typename?: 'User';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        email: string;
        firstName: string;
        lastName: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        activated: boolean;
        enabled: boolean;
        role: Roles;
        vehicles: Array<{
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        }>;
      } | null;
      order?: {
        __typename?: 'Order';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        order_code: string;
        already_add_review: boolean;
        sub_total: number;
        shipping_cost: number;
        total_without_vat: number;
        start_date_of_delivery?: any | null;
        end_date_of_delivery?: any | null;
        vat: number;
        total_all_vat: number;
        discount_amount: number;
        payment_method: Payment_Methods;
        ordering_status: Ordering_Status;
        delivery_mode: Delivery_Modes;
        has_quote: boolean;
        invoice?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        discount_code?: {
          __typename?: 'DiscountCode';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          code: string;
          value: number;
          use_before?: any | null;
          max_use_times: number;
          used_times: number;
          saved_money: number;
          total_buy_amount: number;
          is_percentage: boolean;
        } | null;
        stripe_payment_data?: {
          __typename?: 'StripePaymentData';
          status?: string | null;
          id?: string | null;
          payment_link?: string | null;
          balance_transaction?: string | null;
          payment_method?: string | null;
          receipt_url?: string | null;
          source?: {
            __typename?: 'StripePaymentSource';
            id?: string | null;
            brand?: string | null;
            country?: string | null;
            last4?: string | null;
            exp_month?: number | null;
            exp_year?: number | null;
          } | null;
        } | null;
        user?: {
          __typename?: 'User';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          email: string;
          firstName: string;
          lastName: string;
          company_name: string;
          address: string;
          postal_code: string;
          city: string;
          country: string;
          phonenumber: string;
          activated: boolean;
          enabled: boolean;
          role: Roles;
        } | null;
        delivery_address?: {
          __typename?: 'OrderAddress';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          firstname: string;
          lastname: string;
          company_name: string;
          address: string;
          postal_code: string;
          city: string;
          country: string;
          phonenumber: string;
          email: string;
        } | null;
        billing_address?: {
          __typename?: 'OrderAddress';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          firstname: string;
          lastname: string;
          company_name: string;
          address: string;
          postal_code: string;
          city: string;
          country: string;
          phonenumber: string;
          email: string;
        } | null;
        order_products?: Array<{
          __typename?: 'OrderProduct';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          synthetic_design: boolean;
          custom_design: boolean;
          design_is_finished: boolean;
          quantity: number;
          total: number;
          squid_studio_data: {
            __typename?: 'SquidStudioData';
            width: number;
            height: number;
            radius: number;
            can_have_background: boolean;
            data?: any | null;
          };
          vehicle_information: {
            __typename?: 'VehicleInformation';
            brand?: string | null;
            model?: string | null;
            year?: string | null;
            color?: string | null;
          };
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image_pdf?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          image_svg?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          product?: {
            __typename?: 'Product';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            type: ProductTypes;
            description: string;
            slug?: string | null;
            meta_title?: string | null;
            meta_description?: string | null;
            verification: boolean;
            graphic_service: boolean;
            professional_installer: boolean;
            enabled: boolean;
            index: number;
            start_price: number;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
            sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
            sections?: Array<{
              __typename?: 'Section';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              can_have_background: boolean;
              extra_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
            extra_stickers?: Array<{
              __typename?: 'ExtraSticker';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              design_choice_possible: boolean;
              extra_price: number;
              installer_extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
            templates?: Array<{
              __typename?: 'Template';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
            }> | null;
            product_images?: Array<{
              __typename?: 'ProductImage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              support_uuid?: string | null;
              size_uuid?: string | null;
              section_uuid?: string | null;
              extra_sticker_uuid?: string | null;
            }> | null;
          } | null;
          size?: {
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
          } | null;
          support?: {
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          } | null;
          section?: {
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_size: Array<{
              __typename?: 'ExtraSectionSizePrice';
              size_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
          } | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        }> | null;
      } | null;
    };
  };
};

export type UpdateReviewMutationVariables = Exact<{
  uuid: Scalars['String'];
  input: UpdateReviewInput;
}>;

export type UpdateReviewMutation = {
  __typename?: 'Mutation';
  updateReview: {
    __typename?: 'ReviewUpdateOutput';
    review: {
      __typename?: 'Review';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      text: string;
      note: number;
      display_on_home: boolean;
      display_on_product: boolean;
      products?: Array<{
        __typename?: 'Product';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        type: ProductTypes;
        description: string;
        slug?: string | null;
        meta_title?: string | null;
        meta_description?: string | null;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        enabled: boolean;
        index: number;
        start_price: number;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        }> | null;
        sections?: Array<{
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
          }> | null;
        }> | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_sections: Array<{
            __typename?: 'ExtraStickerSectionPrice';
            section_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        }> | null;
        templates?: Array<{
          __typename?: 'Template';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        product_images?: Array<{
          __typename?: 'ProductImage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          support_uuid?: string | null;
          size_uuid?: string | null;
          section_uuid?: string | null;
          extra_sticker_uuid?: string | null;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
      user?: {
        __typename?: 'User';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        email: string;
        firstName: string;
        lastName: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        activated: boolean;
        enabled: boolean;
        role: Roles;
        vehicles: Array<{
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        }>;
      } | null;
      order?: {
        __typename?: 'Order';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        order_code: string;
        already_add_review: boolean;
        sub_total: number;
        shipping_cost: number;
        total_without_vat: number;
        start_date_of_delivery?: any | null;
        end_date_of_delivery?: any | null;
        vat: number;
        total_all_vat: number;
        discount_amount: number;
        payment_method: Payment_Methods;
        ordering_status: Ordering_Status;
        delivery_mode: Delivery_Modes;
        has_quote: boolean;
        invoice?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        discount_code?: {
          __typename?: 'DiscountCode';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          code: string;
          value: number;
          use_before?: any | null;
          max_use_times: number;
          used_times: number;
          saved_money: number;
          total_buy_amount: number;
          is_percentage: boolean;
        } | null;
        stripe_payment_data?: {
          __typename?: 'StripePaymentData';
          status?: string | null;
          id?: string | null;
          payment_link?: string | null;
          balance_transaction?: string | null;
          payment_method?: string | null;
          receipt_url?: string | null;
          source?: {
            __typename?: 'StripePaymentSource';
            id?: string | null;
            brand?: string | null;
            country?: string | null;
            last4?: string | null;
            exp_month?: number | null;
            exp_year?: number | null;
          } | null;
        } | null;
        user?: {
          __typename?: 'User';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          email: string;
          firstName: string;
          lastName: string;
          company_name: string;
          address: string;
          postal_code: string;
          city: string;
          country: string;
          phonenumber: string;
          activated: boolean;
          enabled: boolean;
          role: Roles;
        } | null;
        delivery_address?: {
          __typename?: 'OrderAddress';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          firstname: string;
          lastname: string;
          company_name: string;
          address: string;
          postal_code: string;
          city: string;
          country: string;
          phonenumber: string;
          email: string;
        } | null;
        billing_address?: {
          __typename?: 'OrderAddress';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          firstname: string;
          lastname: string;
          company_name: string;
          address: string;
          postal_code: string;
          city: string;
          country: string;
          phonenumber: string;
          email: string;
        } | null;
        order_products?: Array<{
          __typename?: 'OrderProduct';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          synthetic_design: boolean;
          custom_design: boolean;
          design_is_finished: boolean;
          quantity: number;
          total: number;
          squid_studio_data: {
            __typename?: 'SquidStudioData';
            width: number;
            height: number;
            radius: number;
            can_have_background: boolean;
            data?: any | null;
          };
          vehicle_information: {
            __typename?: 'VehicleInformation';
            brand?: string | null;
            model?: string | null;
            year?: string | null;
            color?: string | null;
          };
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image_pdf?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          image_svg?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          product?: {
            __typename?: 'Product';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            type: ProductTypes;
            description: string;
            slug?: string | null;
            meta_title?: string | null;
            meta_description?: string | null;
            verification: boolean;
            graphic_service: boolean;
            professional_installer: boolean;
            enabled: boolean;
            index: number;
            start_price: number;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
            sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
            sections?: Array<{
              __typename?: 'Section';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              can_have_background: boolean;
              extra_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
            extra_stickers?: Array<{
              __typename?: 'ExtraSticker';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              design_choice_possible: boolean;
              extra_price: number;
              installer_extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
            templates?: Array<{
              __typename?: 'Template';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
            }> | null;
            product_images?: Array<{
              __typename?: 'ProductImage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              support_uuid?: string | null;
              size_uuid?: string | null;
              section_uuid?: string | null;
              extra_sticker_uuid?: string | null;
            }> | null;
          } | null;
          size?: {
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
          } | null;
          support?: {
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          } | null;
          section?: {
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_size: Array<{
              __typename?: 'ExtraSectionSizePrice';
              size_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
          } | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        }> | null;
      } | null;
    };
  };
};

export type SquidstudioShapeCategoryCreateMutationVariables = Exact<{
  input: CreateSquidstudioShapeCategoryInput;
}>;

export type SquidstudioShapeCategoryCreateMutation = {
  __typename?: 'Mutation';
  squidstudioShapeCategoryCreate: {
    __typename?: 'SquidstudioShapeCategoryOutput';
    squidstudio_shape_category: {
      __typename?: 'SquidstudioShapeCategory';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      icon?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      shapes?: Array<{
        __typename?: 'SquidstudioShape';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        clipPath: string;
        width: string;
        height: string;
        desc: string;
        background: string;
        img?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
    };
  };
};

export type SquidstudioShapeCategoryDeleteMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type SquidstudioShapeCategoryDeleteMutation = {
  __typename?: 'Mutation';
  squidstudioShapeCategoryDelete: {
    __typename?: 'MessageOutput';
    message: string;
  };
};

export type SquidstudioShapeCategoryUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: UpdateSquidstudioShapeCategoryInput;
}>;

export type SquidstudioShapeCategoryUpdateMutation = {
  __typename?: 'Mutation';
  squidstudioShapeCategoryUpdate: {
    __typename?: 'SquidstudioShapeCategoryOutput';
    squidstudio_shape_category: {
      __typename?: 'SquidstudioShapeCategory';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      icon?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      shapes?: Array<{
        __typename?: 'SquidstudioShape';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        clipPath: string;
        width: string;
        height: string;
        desc: string;
        background: string;
        img?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
    };
  };
};

export type SquidstudioShapeCreateMutationVariables = Exact<{
  input: CreateSquidstudioShapeInput;
}>;

export type SquidstudioShapeCreateMutation = {
  __typename?: 'Mutation';
  squidstudioShapeCreate: {
    __typename?: 'SquidstudioShapeOutput';
    squidstudio_shape: {
      __typename?: 'SquidstudioShape';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      clipPath: string;
      width: string;
      height: string;
      desc: string;
      background: string;
      img?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    };
  };
};

export type SquidstudioShapeDeleteMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type SquidstudioShapeDeleteMutation = {
  __typename?: 'Mutation';
  squidstudioShapeDelete: { __typename?: 'MessageOutput'; message: string };
};

export type SquidstudioShapeUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: UpdateSquidstudioShapeInput;
}>;

export type SquidstudioShapeUpdateMutation = {
  __typename?: 'Mutation';
  squidstudioShapeUpdate: {
    __typename?: 'SquidstudioShapeOutput';
    squidstudio_shape: {
      __typename?: 'SquidstudioShape';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      clipPath: string;
      width: string;
      height: string;
      desc: string;
      background: string;
      img?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    };
  };
};

export type SquidstudioTemplateCreateMutationVariables = Exact<{
  input: CreateSquidstudioTemplateInput;
}>;

export type SquidstudioTemplateCreateMutation = {
  __typename?: 'Mutation';
  squidstudioTemplateCreate: {
    __typename?: 'SquidstudioTemplateOutput';
    squidstudio_template: {
      __typename?: 'SquidstudioTemplate';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      data: string;
      desc: string;
      sizes: Array<{
        __typename?: 'SquidstudioTemplateSizeInput';
        width: number;
        height: number;
      }>;
      img?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    };
  };
};

export type SquidstudioTemplateDeleteMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type SquidstudioTemplateDeleteMutation = {
  __typename?: 'Mutation';
  squidstudioTemplateDelete: { __typename?: 'MessageOutput'; message: string };
};

export type SquidstudioTemplateUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: UpdateSquidstudioTemplateInput;
}>;

export type SquidstudioTemplateUpdateMutation = {
  __typename?: 'Mutation';
  squidstudioTemplateUpdate: {
    __typename?: 'SquidstudioTemplateOutput';
    squidstudio_template: {
      __typename?: 'SquidstudioTemplate';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      data: string;
      desc: string;
      sizes: Array<{
        __typename?: 'SquidstudioTemplateSizeInput';
        width: number;
        height: number;
      }>;
      img?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    };
  };
};

export type UserCreateMutationVariables = Exact<{
  input: CreateUserInput;
}>;

export type UserCreateMutation = {
  __typename?: 'Mutation';
  userCreate: {
    __typename?: 'CreateUserOutput';
    user: {
      __typename?: 'User';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      email: string;
      firstName: string;
      lastName: string;
      activated: boolean;
      enabled: boolean;
      role: Roles;
    };
  };
};

export type UserDeleteMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type UserDeleteMutation = {
  __typename?: 'Mutation';
  userDelete: { __typename?: 'MessageOutput'; message: string };
};

export type UserUpdateMutationVariables = Exact<{
  uuid: Scalars['ID'];
  input: UserDtoUpdate;
}>;

export type UserUpdateMutation = {
  __typename?: 'Mutation';
  userUpdate: {
    __typename?: 'CreateUserOutput';
    user: {
      __typename?: 'User';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      email: string;
      firstName: string;
      lastName: string;
      activated: boolean;
      enabled: boolean;
      role: Roles;
    };
  };
};

export type ArticleCategoryGetQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ArticleCategoryGetQuery = {
  __typename?: 'Query';
  articleCategoryGet: {
    __typename?: 'ArticleCategory';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    category: string;
    articles?: Array<{
      __typename?: 'Article';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      duration: string;
      text: string;
      enabled: boolean;
      slug?: string | null;
      meta_title?: string | null;
      meta_description?: string | null;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      article_category?: {
        __typename?: 'ArticleCategory';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        category: string;
        articles?: Array<{
          __typename?: 'Article';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          duration: string;
          text: string;
          enabled: boolean;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
        }> | null;
      } | null;
    }> | null;
  };
};

export type ArticleGetQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ArticleGetQuery = {
  __typename?: 'Query';
  articleGet: {
    __typename?: 'Article';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    subtitle: string;
    duration: string;
    text: string;
    enabled: boolean;
    slug?: string | null;
    meta_title?: string | null;
    meta_description?: string | null;
    image?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    article_category?: {
      __typename?: 'ArticleCategory';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      category: string;
      articles?: Array<{
        __typename?: 'Article';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        duration: string;
        text: string;
        enabled: boolean;
        slug?: string | null;
        meta_title?: string | null;
        meta_description?: string | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        article_category?: {
          __typename?: 'ArticleCategory';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          category: string;
        } | null;
      }> | null;
    } | null;
  };
};

export type ArticleGetBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type ArticleGetBySlugQuery = {
  __typename?: 'Query';
  articleGetBySlug: {
    __typename?: 'Article';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    subtitle: string;
    duration: string;
    text: string;
    enabled: boolean;
    slug?: string | null;
    meta_title?: string | null;
    meta_description?: string | null;
    image?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    article_category?: {
      __typename?: 'ArticleCategory';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      category: string;
      articles?: Array<{
        __typename?: 'Article';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        duration: string;
        text: string;
        enabled: boolean;
        slug?: string | null;
        meta_title?: string | null;
        meta_description?: string | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        article_category?: {
          __typename?: 'ArticleCategory';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          category: string;
        } | null;
      }> | null;
    } | null;
  };
};

export type ArticlesCategoriesListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ArticleCategoryPaginationSortBy>;
}>;

export type ArticlesCategoriesListQuery = {
  __typename?: 'Query';
  articlesCategoriesList: {
    __typename?: 'ArticleCategoryPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'ArticleCategory';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      category: string;
      articles?: Array<{
        __typename?: 'Article';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        duration: string;
        text: string;
        enabled: boolean;
        slug?: string | null;
        meta_title?: string | null;
        meta_description?: string | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        article_category?: {
          __typename?: 'ArticleCategory';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          category: string;
          articles?: Array<{
            __typename?: 'Article';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            duration: string;
            text: string;
            enabled: boolean;
            slug?: string | null;
            meta_title?: string | null;
            meta_description?: string | null;
          }> | null;
        } | null;
      }> | null;
    }>;
  };
};

export type ArticlesListAdminQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ArticlePaginationSortBy>;
}>;

export type ArticlesListAdminQuery = {
  __typename?: 'Query';
  articlesListAdmin: {
    __typename?: 'ArticlePagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Article';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      duration: string;
      text: string;
      enabled: boolean;
      slug?: string | null;
      meta_title?: string | null;
      meta_description?: string | null;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      article_category?: {
        __typename?: 'ArticleCategory';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        category: string;
        articles?: Array<{
          __typename?: 'Article';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          duration: string;
          text: string;
          enabled: boolean;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          article_category?: {
            __typename?: 'ArticleCategory';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            category: string;
          } | null;
        }> | null;
      } | null;
    }>;
  };
};

export type ConfigsGetQueryVariables = Exact<{ [key: string]: never }>;

export type ConfigsGetQuery = {
  __typename?: 'Query';
  configsGet: {
    __typename?: 'Configs';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    shipping_standard_days_number?: number | null;
    shipping_express_cost_percentage?: number | null;
    shipping_express_days_number?: number | null;
    verification_cost: number;
    symetrique_design_cost: number;
    graphic_service_cost: number;
    installer_price: number;
    vat: number;
    facebook_social_link: string;
    instagram_social_link: string;
    youtube_social_link: string;
    shipping_standard_cost: Array<{
      __typename?: 'DeliveryStandardCost';
      start_amount: number;
      end_amount: number;
      cost: number;
    }>;
    country: Array<{
      __typename?: 'DeliveryCountry';
      country_type: Country_Type;
      country_name: string;
      country_code: string;
      vat: number;
      delivery_cost: number;
      shipping_days_number?: number | null;
    }>;
  };
};

export type DiscountCodesListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<DiscountCodesPaginationSortBy>;
}>;

export type DiscountCodesListQuery = {
  __typename?: 'Query';
  discountCodesList: {
    __typename?: 'DiscountCodesPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'DiscountCode';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      code: string;
      value: number;
      use_before?: any | null;
      max_use_times: number;
      used_times: number;
      saved_money: number;
      total_buy_amount: number;
      is_percentage: boolean;
    }>;
  };
};

export type FaqsListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<FaqsPaginationSortBy>;
}>;

export type FaqsListQuery = {
  __typename?: 'Query';
  faqsList: {
    __typename?: 'FaqsPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Faq';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      response: string;
      category: FaqCategoriesTypes;
      author?: {
        __typename?: 'User';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        email: string;
        firstName: string;
        lastName: string;
        activated: boolean;
        enabled: boolean;
        role: Roles;
      } | null;
    }>;
  };
};

export type FontGetQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type FontGetQuery = {
  __typename?: 'Query';
  fontGet: {
    __typename?: 'Font';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    response: string;
  };
};

export type FontListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<FontPaginationSortBy>;
}>;

export type FontListQuery = {
  __typename?: 'Query';
  fontList: {
    __typename?: 'FontPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Font';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      response: string;
    }>;
  };
};

export type StorageGetQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type StorageGetQuery = {
  __typename?: 'Query';
  storageGet: {
    __typename?: 'Storage';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    file_name: string;
    url: string;
    mime_type: string;
    width: number;
    height: number;
    size: number;
    caption: string;
    description: string;
    alternative_title: string;
    thumbnail: {
      __typename?: 'StorageThumbnail';
      width: number;
      height: number;
      size: number;
      url: string;
      file_name: string;
    };
    medium: {
      __typename?: 'StorageThumbnail';
      width: number;
      height: number;
      size: number;
      url: string;
      file_name: string;
    };
  };
};

export type StoragesListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<StoragesPaginationSortBy>;
  filterByMimeType?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type StoragesListQuery = {
  __typename?: 'Query';
  storagesList: {
    __typename?: 'StoragesPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    }>;
  };
};

export type EstimatesNotificationsGetQueryVariables = Exact<{
  [key: string]: never;
}>;

export type EstimatesNotificationsGetQuery = {
  __typename?: 'Query';
  estimatesNotificationsGet: Array<{
    __typename?: 'EstimatesNotification';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    estimate?: {
      __typename?: 'Estimate';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      company_name: string;
      phonenumber: string;
      mail: string;
      subject: string;
      status: Estimates_Status;
      vehicle: string;
      description_of_project: string;
      your_mark: string;
      your_model: string;
      year_of_your_vehicle: string;
      marquetry: string;
      file?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    } | null;
  }>;
};

export type OrdersGetStatsQueryVariables = Exact<{ [key: string]: never }>;

export type OrdersGetStatsQuery = {
  __typename?: 'Query';
  ordersGetStats: {
    __typename?: 'StatsOrderOutput';
    total: { __typename?: 'StatsObject'; quantity: number; total: number };
    paid: { __typename?: 'StatsObject'; quantity: number; total: number };
    waiting: { __typename?: 'StatsObject'; quantity: number; total: number };
    delivered: { __typename?: 'StatsObject'; quantity: number; total: number };
    draft: { __typename?: 'StatsObject'; quantity: number; total: number };
    shipped: { __typename?: 'StatsObject'; quantity: number; total: number };
    waiting_for_visuel: {
      __typename?: 'StatsObject';
      quantity: number;
      total: number;
    };
    waiting_for_bat: {
      __typename?: 'StatsObject';
      quantity: number;
      total: number;
    };
    order_in_verification: {
      __typename?: 'StatsObject';
      quantity: number;
      total: number;
    };
    order_in_printing: {
      __typename?: 'StatsObject';
      quantity: number;
      total: number;
    };
  };
};

export type OrdersNotificationsGetQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OrdersNotificationsGetQuery = {
  __typename?: 'Query';
  ordersNotificationsGet: Array<{
    __typename?: 'OrdersNotification';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    order?: {
      __typename?: 'Order';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      order_code: string;
      already_add_review: boolean;
      sub_total: number;
      shipping_cost: number;
      total_without_vat: number;
      start_date_of_delivery?: any | null;
      end_date_of_delivery?: any | null;
      vat: number;
      total_all_vat: number;
      discount_amount: number;
      payment_method: Payment_Methods;
      ordering_status: Ordering_Status;
      delivery_mode: Delivery_Modes;
      has_quote: boolean;
      invoice?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      discount_code?: {
        __typename?: 'DiscountCode';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        code: string;
        value: number;
        use_before?: any | null;
        max_use_times: number;
        used_times: number;
        saved_money: number;
        total_buy_amount: number;
        is_percentage: boolean;
      } | null;
      stripe_payment_data?: {
        __typename?: 'StripePaymentData';
        status?: string | null;
        id?: string | null;
        payment_link?: string | null;
        balance_transaction?: string | null;
        payment_method?: string | null;
        receipt_url?: string | null;
        source?: {
          __typename?: 'StripePaymentSource';
          id?: string | null;
          brand?: string | null;
          country?: string | null;
          last4?: string | null;
          exp_month?: number | null;
          exp_year?: number | null;
        } | null;
      } | null;
      user?: {
        __typename?: 'User';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        email: string;
        firstName: string;
        lastName: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        activated: boolean;
        enabled: boolean;
        role: Roles;
        vehicles: Array<{
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        }>;
      } | null;
      delivery_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      billing_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      order_products?: Array<{
        __typename?: 'OrderProduct';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        synthetic_design: boolean;
        custom_design: boolean;
        design_is_finished: boolean;
        quantity: number;
        total: number;
        squid_studio_data: {
          __typename?: 'SquidStudioData';
          width: number;
          height: number;
          radius: number;
          can_have_background: boolean;
          data?: any | null;
        };
        vehicle_information: {
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        };
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_pdf?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_svg?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        product?: {
          __typename?: 'Product';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          type: ProductTypes;
          description: string;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          enabled: boolean;
          index: number;
          start_price: number;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
            }> | null;
          }> | null;
          sections?: Array<{
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_size: Array<{
              __typename?: 'ExtraSectionSizePrice';
              size_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              depends_on_supports?: Array<{
                __typename?: 'Support';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                can_have_background: boolean;
                extra_price: number;
                overview: string;
                video_url: string;
                installation_difficulty: number;
              }> | null;
            }> | null;
          }> | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_sections: Array<{
              __typename?: 'ExtraStickerSectionPrice';
              section_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
          }> | null;
          templates?: Array<{
            __typename?: 'Template';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
              thumbnail: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
              medium: {
                __typename?: 'StorageThumbnail';
                width: number;
                height: number;
                size: number;
                url: string;
                file_name: string;
              };
            } | null;
          }> | null;
          product_images?: Array<{
            __typename?: 'ProductImage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            support_uuid?: string | null;
            size_uuid?: string | null;
            section_uuid?: string | null;
            extra_sticker_uuid?: string | null;
            file?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        } | null;
        size?: {
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        } | null;
        support?: {
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        } | null;
        section?: {
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        } | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
        }> | null;
      }> | null;
    } | null;
  }>;
};

export type GetStatsByDayQueryVariables = Exact<{ [key: string]: never }>;

export type GetStatsByDayQuery = {
  __typename?: 'Query';
  getStatsByDay: {
    __typename?: 'OrderByDayOutput';
    stats: {
      __typename?: 'OrderByDayObject';
      quantity: number;
      quantityPercent: number;
    };
  };
};

export type OrderGetQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type OrderGetQuery = {
  __typename?: 'Query';
  orderGet: {
    __typename?: 'Order';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    order_code: string;
    already_add_review: boolean;
    sub_total: number;
    shipping_cost: number;
    total_without_vat: number;
    start_date_of_delivery?: any | null;
    end_date_of_delivery?: any | null;
    vat: number;
    total_all_vat: number;
    discount_amount: number;
    payment_method: Payment_Methods;
    ordering_status: Ordering_Status;
    delivery_mode: Delivery_Modes;
    has_quote: boolean;
    invoice?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    discount_code?: {
      __typename?: 'DiscountCode';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      code: string;
      value: number;
      use_before?: any | null;
      max_use_times: number;
      used_times: number;
      saved_money: number;
      total_buy_amount: number;
      is_percentage: boolean;
    } | null;
    stripe_payment_data?: {
      __typename?: 'StripePaymentData';
      status?: string | null;
      id?: string | null;
      payment_link?: string | null;
      balance_transaction?: string | null;
      payment_method?: string | null;
      receipt_url?: string | null;
      source?: {
        __typename?: 'StripePaymentSource';
        id?: string | null;
        brand?: string | null;
        country?: string | null;
        last4?: string | null;
        exp_month?: number | null;
        exp_year?: number | null;
      } | null;
    } | null;
    user?: {
      __typename?: 'User';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      email: string;
      firstName: string;
      lastName: string;
      company_name: string;
      address: string;
      postal_code: string;
      city: string;
      country: string;
      phonenumber: string;
      activated: boolean;
      enabled: boolean;
      role: Roles;
      vehicles: Array<{
        __typename?: 'VehicleInformation';
        brand?: string | null;
        model?: string | null;
        year?: string | null;
        color?: string | null;
      }>;
    } | null;
    delivery_address?: {
      __typename?: 'OrderAddress';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      firstname: string;
      lastname: string;
      company_name: string;
      tva_number: string;
      address: string;
      postal_code: string;
      city: string;
      country: string;
      phonenumber: string;
      email: string;
    } | null;
    billing_address?: {
      __typename?: 'OrderAddress';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      firstname: string;
      lastname: string;
      company_name: string;
      tva_number: string;
      address: string;
      postal_code: string;
      city: string;
      country: string;
      phonenumber: string;
      email: string;
    } | null;
    order_products?: Array<{
      __typename?: 'OrderProduct';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      verification: boolean;
      graphic_service: boolean;
      professional_installer: boolean;
      synthetic_design: boolean;
      custom_design: boolean;
      design_is_finished: boolean;
      quantity: number;
      total: number;
      note: string;
      is_rear_camera_selected: boolean;
      squid_studio_data: {
        __typename?: 'SquidStudioData';
        width: number;
        height: number;
        radius: number;
        can_have_background: boolean;
        data?: any | null;
      };
      vehicle_information: {
        __typename?: 'VehicleInformation';
        brand?: string | null;
        model?: string | null;
        year?: string | null;
        color?: string | null;
      };
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      image_pdf?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      image_svg?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      product?: {
        __typename?: 'Product';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        type: ProductTypes;
        description: string;
        slug?: string | null;
        meta_title?: string | null;
        meta_description?: string | null;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        enabled: boolean;
        index: number;
        start_price: number;
        is_perfect_squid: boolean;
        canvas_width?: number | null;
        canvas_height?: number | null;
        canvas_radius?: number | null;
        video_url?: string | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        }> | null;
        sections?: Array<{
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
          }> | null;
        }> | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_sections: Array<{
            __typename?: 'ExtraStickerSectionPrice';
            section_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        }> | null;
        templates?: Array<{
          __typename?: 'Template';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        product_images?: Array<{
          __typename?: 'ProductImage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          support_uuid?: string | null;
          size_uuid?: string | null;
          section_uuid?: string | null;
          extra_sticker_uuid?: string | null;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      } | null;
      size?: {
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
        }> | null;
      } | null;
      support?: {
        __typename?: 'Support';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        can_have_background: boolean;
        extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
      } | null;
      section?: {
        __typename?: 'Section';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        can_have_background: boolean;
        extra_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        extra_price_size: Array<{
          __typename?: 'ExtraSectionSizePrice';
          size_uuid: string;
          extra_price: number;
        }>;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
        }> | null;
      } | null;
      extra_stickers?: Array<{
        __typename?: 'ExtraSticker';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        design_choice_possible: boolean;
        extra_price: number;
        installer_extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
      }> | null;
      car_brand?: {
        __typename?: 'CarBrand';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        brand_name: string;
        info_title?: string | null;
        info_description?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        pictogram_dark?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        brand_models?: Array<{
          __typename?: 'BrandModel';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          model_name: string;
          info_title?: string | null;
          info_description?: string | null;
          car_brand?: {
            __typename?: 'CarBrand';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            brand_name: string;
            info_title?: string | null;
            info_description?: string | null;
          } | null;
          model_years?: Array<{
            __typename?: 'ModelYear';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            start_year: string;
            end_year: string;
            info_title?: string | null;
            info_description?: string | null;
            brand_model?: {
              __typename?: 'BrandModel';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              model_name: string;
              info_title?: string | null;
              info_description?: string | null;
              car_brand?: {
                __typename?: 'CarBrand';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                brand_name: string;
                info_title?: string | null;
                info_description?: string | null;
              } | null;
              model_years?: Array<{
                __typename?: 'ModelYear';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                start_year: string;
                end_year: string;
                info_title?: string | null;
                info_description?: string | null;
                car_versions?: Array<{
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                      thumbnail: {
                        __typename?: 'StorageThumbnail';
                        width: number;
                        height: number;
                        size: number;
                        url: string;
                        file_name: string;
                      };
                      medium: {
                        __typename?: 'StorageThumbnail';
                        width: number;
                        height: number;
                        size: number;
                        url: string;
                        file_name: string;
                      };
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      version_lateral?: {
                        __typename?: 'VersionLateral';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        side_glazings?: Array<{
                          __typename?: 'SideGlazing';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                        }> | null;
                        cars_backwards?: Array<{
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                            car_version?: {
                              __typename?: 'CarVersion';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              pictogram?: {
                                __typename?: 'Storage';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                file_name: string;
                                url: string;
                                mime_type: string;
                                width: number;
                                height: number;
                                size: number;
                                caption: string;
                                description: string;
                                alternative_title: string;
                              } | null;
                              version_laterals?: Array<{
                                __typename?: 'VersionLateral';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                extra_price: number;
                                installer_extra_price: number;
                                installation_difficulty: number;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                cars_backwards?: Array<{
                                  __typename?: 'CarBackward';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  preview_text?: string | null;
                                  car_backward_types?: Array<{
                                    __typename?: 'CarBackwardType';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    has_rear_camera: boolean;
                                    pictogram?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    preview_image?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    car_backward?: {
                                      __typename?: 'CarBackward';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      extra_price: number;
                                      installer_extra_price: number;
                                      installation_difficulty: number;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_lateral?: {
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      } | null;
                                      car_backward_types?: Array<{
                                        __typename?: 'CarBackwardType';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        has_rear_camera: boolean;
                                      }> | null;
                                    } | null;
                                  }> | null;
                                }> | null;
                                car_version?: {
                                  __typename?: 'CarVersion';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  model_year?: {
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    brand_model?: {
                                      __typename?: 'BrandModel';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      model_name: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                    } | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                    }> | null;
                                  } | null;
                                } | null;
                              }> | null;
                            } | null;
                          } | null;
                        }> | null;
                      } | null;
                    }> | null;
                  }> | null;
                }> | null;
              }> | null;
            } | null;
          }> | null;
        }> | null;
      } | null;
      brand_model?: {
        __typename?: 'BrandModel';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        model_name: string;
        info_title?: string | null;
        info_description?: string | null;
      } | null;
      model_year?: {
        __typename?: 'ModelYear';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        start_year: string;
        end_year: string;
        info_title?: string | null;
        info_description?: string | null;
      } | null;
      car_version?: {
        __typename?: 'CarVersion';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
      } | null;
      version_lateral?: {
        __typename?: 'VersionLateral';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
      } | null;
      side_glazing?: {
        __typename?: 'SideGlazing';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        version_lateral?: {
          __typename?: 'VersionLateral';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
        } | null;
      } | null;
      car_backward?: {
        __typename?: 'CarBackward';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        preview_text?: string | null;
      } | null;
      car_backward_type?: {
        __typename?: 'CarBackwardType';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        has_rear_camera: boolean;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        car_backward?: {
          __typename?: 'CarBackward';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          info_title?: string | null;
          info_description?: string | null;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          preview_text?: string | null;
        } | null;
      } | null;
    }> | null;
  };
};

export type PagesListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<PagePaginationSortBy>;
}>;

export type PagesListQuery = {
  __typename?: 'Query';
  pagesList: {
    __typename?: 'PagePagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Page';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      slug: string;
      meta_title: string;
      meta_description: string;
    }>;
  };
};

export type PagesListWithoutAuthQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<PagePaginationSortBy>;
  slug?: InputMaybe<Scalars['String']>;
}>;

export type PagesListWithoutAuthQuery = {
  __typename?: 'Query';
  pagesListWithoutAuth: {
    __typename?: 'PagePagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Page';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      slug: string;
      meta_title: string;
      meta_description: string;
      faqPageLists?: Array<{
        __typename?: 'FaqPageList';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title?: string | null;
        faqPages?: Array<{
          __typename?: 'FaqPage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          question: string;
          response: string;
        }> | null;
      }> | null;
      questionPageLists?: Array<{
        __typename?: 'QuestionPageList';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title?: string | null;
        questionsPages?: Array<{
          __typename?: 'QuestionPage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          question: string;
          response: string;
        }> | null;
      }> | null;
    }>;
  };
};

export type BrandModelDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type BrandModelDetailQuery = {
  __typename?: 'Query';
  brandModelDetail: {
    __typename?: 'BrandModel';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    model_name: string;
    info_title?: string | null;
    info_description?: string | null;
    car_brand?: {
      __typename?: 'CarBrand';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      brand_name: string;
      info_title?: string | null;
      info_description?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      pictogram_dark?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      brand_models?: Array<{
        __typename?: 'BrandModel';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        model_name: string;
        info_title?: string | null;
        info_description?: string | null;
        car_brand?: {
          __typename?: 'CarBrand';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          brand_name: string;
          info_title?: string | null;
          info_description?: string | null;
        } | null;
        model_years?: Array<{
          __typename?: 'ModelYear';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          start_year: string;
          end_year: string;
          info_title?: string | null;
          info_description?: string | null;
          brand_model?: {
            __typename?: 'BrandModel';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            model_name: string;
            info_title?: string | null;
            info_description?: string | null;
            car_brand?: {
              __typename?: 'CarBrand';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              brand_name: string;
              info_title?: string | null;
              info_description?: string | null;
            } | null;
            model_years?: Array<{
              __typename?: 'ModelYear';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              start_year: string;
              end_year: string;
              info_title?: string | null;
              info_description?: string | null;
              car_versions?: Array<{
                __typename?: 'CarVersion';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                pictogram?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                version_laterals?: Array<{
                  __typename?: 'VersionLateral';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  extra_price: number;
                  installer_extra_price: number;
                  installation_difficulty: number;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  preview_image?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                    thumbnail: {
                      __typename?: 'StorageThumbnail';
                      width: number;
                      height: number;
                      size: number;
                      url: string;
                      file_name: string;
                    };
                    medium: {
                      __typename?: 'StorageThumbnail';
                      width: number;
                      height: number;
                      size: number;
                      url: string;
                      file_name: string;
                    };
                  } | null;
                  side_glazings?: Array<{
                    __typename?: 'SideGlazing';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    version_lateral?: {
                      __typename?: 'VersionLateral';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      side_glazings?: Array<{
                        __typename?: 'SideGlazing';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                      }> | null;
                      cars_backwards?: Array<{
                        __typename?: 'CarBackward';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                          car_version?: {
                            __typename?: 'CarVersion';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            version_laterals?: Array<{
                              __typename?: 'VersionLateral';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              extra_price: number;
                              installer_extra_price: number;
                              installation_difficulty: number;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              cars_backwards?: Array<{
                                __typename?: 'CarBackward';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                extra_price: number;
                                installer_extra_price: number;
                                installation_difficulty: number;
                                preview_text?: string | null;
                                car_backward_types?: Array<{
                                  __typename?: 'CarBackwardType';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  has_rear_camera: boolean;
                                  pictogram?: {
                                    __typename?: 'Storage';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    file_name: string;
                                    url: string;
                                    mime_type: string;
                                    width: number;
                                    height: number;
                                    size: number;
                                    caption: string;
                                    description: string;
                                    alternative_title: string;
                                  } | null;
                                  preview_image?: {
                                    __typename?: 'Storage';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    file_name: string;
                                    url: string;
                                    mime_type: string;
                                    width: number;
                                    height: number;
                                    size: number;
                                    caption: string;
                                    description: string;
                                    alternative_title: string;
                                  } | null;
                                  car_backward?: {
                                    __typename?: 'CarBackward';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    extra_price: number;
                                    installer_extra_price: number;
                                    installation_difficulty: number;
                                    preview_text?: string | null;
                                    pictogram?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    preview_image?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    version_lateral?: {
                                      __typename?: 'VersionLateral';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      extra_price: number;
                                      installer_extra_price: number;
                                      installation_difficulty: number;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                    } | null;
                                    car_backward_types?: Array<{
                                      __typename?: 'CarBackwardType';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      has_rear_camera: boolean;
                                    }> | null;
                                  } | null;
                                }> | null;
                              }> | null;
                              car_version?: {
                                __typename?: 'CarVersion';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                model_year?: {
                                  __typename?: 'ModelYear';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  start_year: string;
                                  end_year: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  brand_model?: {
                                    __typename?: 'BrandModel';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    model_name: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                  } | null;
                                  car_versions?: Array<{
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                    } | null;
                                  }> | null;
                                } | null;
                              } | null;
                            }> | null;
                          } | null;
                        } | null;
                      }> | null;
                    } | null;
                  }> | null;
                }> | null;
              }> | null;
            }> | null;
          } | null;
        }> | null;
      }> | null;
    } | null;
    model_years?: Array<{
      __typename?: 'ModelYear';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      start_year: string;
      end_year: string;
      info_title?: string | null;
      info_description?: string | null;
    }> | null;
  };
};

export type BrandModelListQueryVariables = Exact<{
  car_brand_uuid?: InputMaybe<Scalars['String']>;
}>;

export type BrandModelListQuery = {
  __typename?: 'Query';
  brandModelList: {
    __typename?: 'BrandModelPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'BrandModel';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      model_name: string;
      info_title?: string | null;
      info_description?: string | null;
      car_brand?: {
        __typename?: 'CarBrand';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        brand_name: string;
        info_title?: string | null;
        info_description?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        pictogram_dark?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        brand_models?: Array<{
          __typename?: 'BrandModel';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          model_name: string;
          info_title?: string | null;
          info_description?: string | null;
          car_brand?: {
            __typename?: 'CarBrand';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            brand_name: string;
            info_title?: string | null;
            info_description?: string | null;
          } | null;
          model_years?: Array<{
            __typename?: 'ModelYear';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            start_year: string;
            end_year: string;
            info_title?: string | null;
            info_description?: string | null;
            brand_model?: {
              __typename?: 'BrandModel';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              model_name: string;
              info_title?: string | null;
              info_description?: string | null;
              car_brand?: {
                __typename?: 'CarBrand';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                brand_name: string;
                info_title?: string | null;
                info_description?: string | null;
              } | null;
              model_years?: Array<{
                __typename?: 'ModelYear';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                start_year: string;
                end_year: string;
                info_title?: string | null;
                info_description?: string | null;
                car_versions?: Array<{
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                      thumbnail: {
                        __typename?: 'StorageThumbnail';
                        width: number;
                        height: number;
                        size: number;
                        url: string;
                        file_name: string;
                      };
                      medium: {
                        __typename?: 'StorageThumbnail';
                        width: number;
                        height: number;
                        size: number;
                        url: string;
                        file_name: string;
                      };
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      version_lateral?: {
                        __typename?: 'VersionLateral';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        side_glazings?: Array<{
                          __typename?: 'SideGlazing';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                        }> | null;
                        cars_backwards?: Array<{
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                            car_version?: {
                              __typename?: 'CarVersion';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              pictogram?: {
                                __typename?: 'Storage';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                file_name: string;
                                url: string;
                                mime_type: string;
                                width: number;
                                height: number;
                                size: number;
                                caption: string;
                                description: string;
                                alternative_title: string;
                              } | null;
                              version_laterals?: Array<{
                                __typename?: 'VersionLateral';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                extra_price: number;
                                installer_extra_price: number;
                                installation_difficulty: number;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                cars_backwards?: Array<{
                                  __typename?: 'CarBackward';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  preview_text?: string | null;
                                  car_backward_types?: Array<{
                                    __typename?: 'CarBackwardType';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    has_rear_camera: boolean;
                                    pictogram?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    preview_image?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    car_backward?: {
                                      __typename?: 'CarBackward';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      extra_price: number;
                                      installer_extra_price: number;
                                      installation_difficulty: number;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_lateral?: {
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      } | null;
                                      car_backward_types?: Array<{
                                        __typename?: 'CarBackwardType';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        has_rear_camera: boolean;
                                      }> | null;
                                    } | null;
                                  }> | null;
                                }> | null;
                                car_version?: {
                                  __typename?: 'CarVersion';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  model_year?: {
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    brand_model?: {
                                      __typename?: 'BrandModel';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      model_name: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                    } | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                    }> | null;
                                  } | null;
                                } | null;
                              }> | null;
                            } | null;
                          } | null;
                        }> | null;
                      } | null;
                    }> | null;
                  }> | null;
                }> | null;
              }> | null;
            } | null;
          }> | null;
        }> | null;
      } | null;
      model_years?: Array<{
        __typename?: 'ModelYear';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        start_year: string;
        end_year: string;
        info_title?: string | null;
        info_description?: string | null;
      }> | null;
    }>;
  };
};

export type CarBackwardDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type CarBackwardDetailQuery = {
  __typename?: 'Query';
  carBackwardDetail: {
    __typename?: 'CarBackwardOutput';
    carBackward: {
      __typename?: 'CarBackward';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      extra_price: number;
      installer_extra_price: number;
      installation_difficulty: number;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      version_lateral?: {
        __typename?: 'VersionLateral';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        side_glazings?: Array<{
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            cars_backwards?: Array<{
              __typename?: 'CarBackward';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              info_title?: string | null;
              info_description?: string | null;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              preview_text?: string | null;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              preview_image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              version_lateral?: {
                __typename?: 'VersionLateral';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                car_version?: {
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                    }> | null;
                    cars_backwards?: Array<{
                      __typename?: 'CarBackward';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      preview_text?: string | null;
                      car_backward_types?: Array<{
                        __typename?: 'CarBackwardType';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        has_rear_camera: boolean;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        car_backward?: {
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          } | null;
                          car_backward_types?: Array<{
                            __typename?: 'CarBackwardType';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            has_rear_camera: boolean;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                    car_version?: {
                      __typename?: 'CarVersion';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      model_year?: {
                        __typename?: 'ModelYear';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        start_year: string;
                        end_year: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        brand_model?: {
                          __typename?: 'BrandModel';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          model_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          car_brand?: {
                            __typename?: 'CarBrand';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            brand_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            pictogram_dark?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                              thumbnail: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                              medium: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                            } | null;
                            brand_models?: Array<{
                              __typename?: 'BrandModel';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              model_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              car_brand?: {
                                __typename?: 'CarBrand';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                brand_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                              } | null;
                              model_years?: Array<{
                                __typename?: 'ModelYear';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                start_year: string;
                                end_year: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                brand_model?: {
                                  __typename?: 'BrandModel';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  model_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  model_years?: Array<{
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_laterals?: Array<{
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        car_versions?: Array<{
                                          __typename?: 'CarVersion';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                } | null;
                              }> | null;
                            }> | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
        }> | null;
      } | null;
      car_backward_types?: Array<{
        __typename?: 'CarBackwardType';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        has_rear_camera: boolean;
      }> | null;
    };
  };
};

export type CarBackwardListQueryVariables = Exact<{
  version_lateral_uuid?: InputMaybe<Scalars['String']>;
}>;

export type CarBackwardListQuery = {
  __typename?: 'Query';
  carBackwardList: {
    __typename?: 'CarBackwardPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'CarBackward';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      extra_price: number;
      installer_extra_price: number;
      installation_difficulty: number;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      version_lateral?: {
        __typename?: 'VersionLateral';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        side_glazings?: Array<{
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            cars_backwards?: Array<{
              __typename?: 'CarBackward';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              info_title?: string | null;
              info_description?: string | null;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              preview_text?: string | null;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              preview_image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              version_lateral?: {
                __typename?: 'VersionLateral';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                car_version?: {
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                    }> | null;
                    cars_backwards?: Array<{
                      __typename?: 'CarBackward';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      preview_text?: string | null;
                      car_backward_types?: Array<{
                        __typename?: 'CarBackwardType';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        has_rear_camera: boolean;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        car_backward?: {
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          } | null;
                          car_backward_types?: Array<{
                            __typename?: 'CarBackwardType';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            has_rear_camera: boolean;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                    car_version?: {
                      __typename?: 'CarVersion';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      model_year?: {
                        __typename?: 'ModelYear';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        start_year: string;
                        end_year: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        brand_model?: {
                          __typename?: 'BrandModel';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          model_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          car_brand?: {
                            __typename?: 'CarBrand';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            brand_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            pictogram_dark?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                              thumbnail: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                              medium: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                            } | null;
                            brand_models?: Array<{
                              __typename?: 'BrandModel';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              model_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              car_brand?: {
                                __typename?: 'CarBrand';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                brand_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                              } | null;
                              model_years?: Array<{
                                __typename?: 'ModelYear';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                start_year: string;
                                end_year: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                brand_model?: {
                                  __typename?: 'BrandModel';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  model_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  model_years?: Array<{
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_laterals?: Array<{
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        car_versions?: Array<{
                                          __typename?: 'CarVersion';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                } | null;
                              }> | null;
                            }> | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
        }> | null;
      } | null;
      car_backward_types?: Array<{
        __typename?: 'CarBackwardType';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        has_rear_camera: boolean;
      }> | null;
    }>;
  };
};

export type CarBackwardTypeDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type CarBackwardTypeDetailQuery = {
  __typename?: 'Query';
  carBackwardTypeDetail: {
    __typename?: 'CarBackwardType';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    sub_title: string;
    info_title?: string | null;
    info_description?: string | null;
    has_rear_camera: boolean;
    pictogram?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    preview_image?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    car_backward?: {
      __typename?: 'CarBackward';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      extra_price: number;
      installer_extra_price: number;
      installation_difficulty: number;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      version_lateral?: {
        __typename?: 'VersionLateral';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        side_glazings?: Array<{
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            cars_backwards?: Array<{
              __typename?: 'CarBackward';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              info_title?: string | null;
              info_description?: string | null;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              preview_text?: string | null;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              preview_image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              version_lateral?: {
                __typename?: 'VersionLateral';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                car_version?: {
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                    }> | null;
                    cars_backwards?: Array<{
                      __typename?: 'CarBackward';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      preview_text?: string | null;
                      car_backward_types?: Array<{
                        __typename?: 'CarBackwardType';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        has_rear_camera: boolean;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        car_backward?: {
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          car_backward_types?: Array<{
                            __typename?: 'CarBackwardType';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            has_rear_camera: boolean;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                    car_version?: {
                      __typename?: 'CarVersion';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      model_year?: {
                        __typename?: 'ModelYear';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        start_year: string;
                        end_year: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        brand_model?: {
                          __typename?: 'BrandModel';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          model_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          car_brand?: {
                            __typename?: 'CarBrand';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            brand_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            pictogram_dark?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                              thumbnail: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                              medium: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                            } | null;
                            brand_models?: Array<{
                              __typename?: 'BrandModel';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              model_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              car_brand?: {
                                __typename?: 'CarBrand';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                brand_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                              } | null;
                              model_years?: Array<{
                                __typename?: 'ModelYear';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                start_year: string;
                                end_year: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                brand_model?: {
                                  __typename?: 'BrandModel';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  model_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  model_years?: Array<{
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_laterals?: Array<{
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        car_versions?: Array<{
                                          __typename?: 'CarVersion';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                } | null;
                              }> | null;
                            }> | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
        }> | null;
      } | null;
    } | null;
  };
};

export type CarBackwardTypeListQueryVariables = Exact<{
  car_backward_uuid?: InputMaybe<Scalars['String']>;
}>;

export type CarBackwardTypeListQuery = {
  __typename?: 'Query';
  carBackwardTypeList: {
    __typename?: 'CarBackwardTypePagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'CarBackwardType';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      has_rear_camera: boolean;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      car_backward?: {
        __typename?: 'CarBackward';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        version_lateral?: {
          __typename?: 'VersionLateral';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          side_glazings?: Array<{
            __typename?: 'SideGlazing';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            preview_image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            version_lateral?: {
              __typename?: 'VersionLateral';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              info_title?: string | null;
              info_description?: string | null;
              preview_text?: string | null;
              cars_backwards?: Array<{
                __typename?: 'CarBackward';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                info_title?: string | null;
                info_description?: string | null;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                preview_text?: string | null;
                pictogram?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                preview_image?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                version_lateral?: {
                  __typename?: 'VersionLateral';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  extra_price: number;
                  installer_extra_price: number;
                  installation_difficulty: number;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  car_version?: {
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    version_laterals?: Array<{
                      __typename?: 'VersionLateral';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      side_glazings?: Array<{
                        __typename?: 'SideGlazing';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                      }> | null;
                      cars_backwards?: Array<{
                        __typename?: 'CarBackward';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        preview_text?: string | null;
                        car_backward_types?: Array<{
                          __typename?: 'CarBackwardType';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          has_rear_camera: boolean;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          car_backward?: {
                            __typename?: 'CarBackward';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            preview_text?: string | null;
                            car_backward_types?: Array<{
                              __typename?: 'CarBackwardType';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              has_rear_camera: boolean;
                            }> | null;
                          } | null;
                        }> | null;
                      }> | null;
                      car_version?: {
                        __typename?: 'CarVersion';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        model_year?: {
                          __typename?: 'ModelYear';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          start_year: string;
                          end_year: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          brand_model?: {
                            __typename?: 'BrandModel';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            model_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            car_brand?: {
                              __typename?: 'CarBrand';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              brand_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              pictogram?: {
                                __typename?: 'Storage';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                file_name: string;
                                url: string;
                                mime_type: string;
                                width: number;
                                height: number;
                                size: number;
                                caption: string;
                                description: string;
                                alternative_title: string;
                              } | null;
                              pictogram_dark?: {
                                __typename?: 'Storage';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                file_name: string;
                                url: string;
                                mime_type: string;
                                width: number;
                                height: number;
                                size: number;
                                caption: string;
                                description: string;
                                alternative_title: string;
                                thumbnail: {
                                  __typename?: 'StorageThumbnail';
                                  width: number;
                                  height: number;
                                  size: number;
                                  url: string;
                                  file_name: string;
                                };
                                medium: {
                                  __typename?: 'StorageThumbnail';
                                  width: number;
                                  height: number;
                                  size: number;
                                  url: string;
                                  file_name: string;
                                };
                              } | null;
                              brand_models?: Array<{
                                __typename?: 'BrandModel';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                model_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                car_brand?: {
                                  __typename?: 'CarBrand';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  brand_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                } | null;
                                model_years?: Array<{
                                  __typename?: 'ModelYear';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  start_year: string;
                                  end_year: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  brand_model?: {
                                    __typename?: 'BrandModel';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    model_name: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    model_years?: Array<{
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                        pictogram?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        version_laterals?: Array<{
                                          __typename?: 'VersionLateral';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          extra_price: number;
                                          installer_extra_price: number;
                                          installation_difficulty: number;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                        model_year?: {
                                          __typename?: 'ModelYear';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          start_year: string;
                                          end_year: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          car_versions?: Array<{
                                            __typename?: 'CarVersion';
                                            uuid: string;
                                            createdAt: any;
                                            updatedAt: any;
                                            title: string;
                                            sub_title: string;
                                            info_title?: string | null;
                                            info_description?: string | null;
                                            preview_text?: string | null;
                                          }> | null;
                                        } | null;
                                      }> | null;
                                    }> | null;
                                  } | null;
                                }> | null;
                              }> | null;
                            } | null;
                          } | null;
                        } | null;
                      } | null;
                    }> | null;
                  } | null;
                } | null;
              }> | null;
            } | null;
          }> | null;
        } | null;
      } | null;
    }>;
  };
};

export type CarBrandDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type CarBrandDetailQuery = {
  __typename?: 'Query';
  carBrandDetail: {
    __typename?: 'CarBrand';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    brand_name: string;
    info_title?: string | null;
    info_description?: string | null;
    pictogram?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    pictogram_dark?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    brand_models?: Array<{
      __typename?: 'BrandModel';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      model_name: string;
      info_title?: string | null;
      info_description?: string | null;
      car_brand?: {
        __typename?: 'CarBrand';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        brand_name: string;
        info_title?: string | null;
        info_description?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        pictogram_dark?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        brand_models?: Array<{
          __typename?: 'BrandModel';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          model_name: string;
          info_title?: string | null;
          info_description?: string | null;
          model_years?: Array<{
            __typename?: 'ModelYear';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            start_year: string;
            end_year: string;
            info_title?: string | null;
            info_description?: string | null;
            brand_model?: {
              __typename?: 'BrandModel';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              model_name: string;
              info_title?: string | null;
              info_description?: string | null;
              car_brand?: {
                __typename?: 'CarBrand';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                brand_name: string;
                info_title?: string | null;
                info_description?: string | null;
              } | null;
              model_years?: Array<{
                __typename?: 'ModelYear';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                start_year: string;
                end_year: string;
                info_title?: string | null;
                info_description?: string | null;
                car_versions?: Array<{
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                      thumbnail: {
                        __typename?: 'StorageThumbnail';
                        width: number;
                        height: number;
                        size: number;
                        url: string;
                        file_name: string;
                      };
                      medium: {
                        __typename?: 'StorageThumbnail';
                        width: number;
                        height: number;
                        size: number;
                        url: string;
                        file_name: string;
                      };
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      version_lateral?: {
                        __typename?: 'VersionLateral';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        side_glazings?: Array<{
                          __typename?: 'SideGlazing';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                        }> | null;
                        cars_backwards?: Array<{
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                            car_version?: {
                              __typename?: 'CarVersion';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              pictogram?: {
                                __typename?: 'Storage';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                file_name: string;
                                url: string;
                                mime_type: string;
                                width: number;
                                height: number;
                                size: number;
                                caption: string;
                                description: string;
                                alternative_title: string;
                              } | null;
                              version_laterals?: Array<{
                                __typename?: 'VersionLateral';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                extra_price: number;
                                installer_extra_price: number;
                                installation_difficulty: number;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                cars_backwards?: Array<{
                                  __typename?: 'CarBackward';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  preview_text?: string | null;
                                  car_backward_types?: Array<{
                                    __typename?: 'CarBackwardType';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    has_rear_camera: boolean;
                                    pictogram?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    preview_image?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    car_backward?: {
                                      __typename?: 'CarBackward';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      extra_price: number;
                                      installer_extra_price: number;
                                      installation_difficulty: number;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_lateral?: {
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      } | null;
                                      car_backward_types?: Array<{
                                        __typename?: 'CarBackwardType';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        has_rear_camera: boolean;
                                      }> | null;
                                    } | null;
                                  }> | null;
                                }> | null;
                                car_version?: {
                                  __typename?: 'CarVersion';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  model_year?: {
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    brand_model?: {
                                      __typename?: 'BrandModel';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      model_name: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                    } | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                    }> | null;
                                  } | null;
                                } | null;
                              }> | null;
                            } | null;
                          } | null;
                        }> | null;
                      } | null;
                    }> | null;
                  }> | null;
                }> | null;
              }> | null;
            } | null;
          }> | null;
        }> | null;
      } | null;
    }> | null;
  };
};

export type CarBrandListQueryVariables = Exact<{ [key: string]: never }>;

export type CarBrandListQuery = {
  __typename?: 'Query';
  carBrandList: {
    __typename?: 'CarBrandPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'CarBrand';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      brand_name: string;
      info_title?: string | null;
      info_description?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      pictogram_dark?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      brand_models?: Array<{
        __typename?: 'BrandModel';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        model_name: string;
        info_title?: string | null;
        info_description?: string | null;
        car_brand?: {
          __typename?: 'CarBrand';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          brand_name: string;
          info_title?: string | null;
          info_description?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          pictogram_dark?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          brand_models?: Array<{
            __typename?: 'BrandModel';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            model_name: string;
            info_title?: string | null;
            info_description?: string | null;
            model_years?: Array<{
              __typename?: 'ModelYear';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              start_year: string;
              end_year: string;
              info_title?: string | null;
              info_description?: string | null;
              brand_model?: {
                __typename?: 'BrandModel';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                model_name: string;
                info_title?: string | null;
                info_description?: string | null;
                car_brand?: {
                  __typename?: 'CarBrand';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  brand_name: string;
                  info_title?: string | null;
                  info_description?: string | null;
                } | null;
                model_years?: Array<{
                  __typename?: 'ModelYear';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  start_year: string;
                  end_year: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  car_versions?: Array<{
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    version_laterals?: Array<{
                      __typename?: 'VersionLateral';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                        thumbnail: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                        medium: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                      } | null;
                      side_glazings?: Array<{
                        __typename?: 'SideGlazing';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          side_glazings?: Array<{
                            __typename?: 'SideGlazing';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          }> | null;
                          cars_backwards?: Array<{
                            __typename?: 'CarBackward';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            preview_text?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            preview_image?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            version_lateral?: {
                              __typename?: 'VersionLateral';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              extra_price: number;
                              installer_extra_price: number;
                              installation_difficulty: number;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              car_version?: {
                                __typename?: 'CarVersion';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                pictogram?: {
                                  __typename?: 'Storage';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  file_name: string;
                                  url: string;
                                  mime_type: string;
                                  width: number;
                                  height: number;
                                  size: number;
                                  caption: string;
                                  description: string;
                                  alternative_title: string;
                                } | null;
                                version_laterals?: Array<{
                                  __typename?: 'VersionLateral';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  cars_backwards?: Array<{
                                    __typename?: 'CarBackward';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    extra_price: number;
                                    installer_extra_price: number;
                                    installation_difficulty: number;
                                    preview_text?: string | null;
                                    car_backward_types?: Array<{
                                      __typename?: 'CarBackwardType';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      has_rear_camera: boolean;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      car_backward?: {
                                        __typename?: 'CarBackward';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        preview_text?: string | null;
                                        pictogram?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        preview_image?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        version_lateral?: {
                                          __typename?: 'VersionLateral';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          extra_price: number;
                                          installer_extra_price: number;
                                          installation_difficulty: number;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        } | null;
                                        car_backward_types?: Array<{
                                          __typename?: 'CarBackwardType';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          has_rear_camera: boolean;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                  car_version?: {
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      brand_model?: {
                                        __typename?: 'BrandModel';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        model_name: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                        model_year?: {
                                          __typename?: 'ModelYear';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          start_year: string;
                                          end_year: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                        } | null;
                                      }> | null;
                                    } | null;
                                  } | null;
                                }> | null;
                              } | null;
                            } | null;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                  }> | null;
                }> | null;
              } | null;
            }> | null;
          }> | null;
        } | null;
      }> | null;
    }>;
  };
};

export type CarVersionDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type CarVersionDetailQuery = {
  __typename?: 'Query';
  carVersionDetail: {
    __typename?: 'CarVersion';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    sub_title: string;
    info_title?: string | null;
    info_description?: string | null;
    preview_text?: string | null;
    pictogram?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    version_laterals?: Array<{
      __typename?: 'VersionLateral';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      extra_price: number;
      installer_extra_price: number;
      installation_difficulty: number;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      side_glazings?: Array<{
        __typename?: 'SideGlazing';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        version_lateral?: {
          __typename?: 'VersionLateral';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          side_glazings?: Array<{
            __typename?: 'SideGlazing';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
          }> | null;
          cars_backwards?: Array<{
            __typename?: 'CarBackward';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            info_title?: string | null;
            info_description?: string | null;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            preview_text?: string | null;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            preview_image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            version_lateral?: {
              __typename?: 'VersionLateral';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              info_title?: string | null;
              info_description?: string | null;
              preview_text?: string | null;
              car_version?: {
                __typename?: 'CarVersion';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                pictogram?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                version_laterals?: Array<{
                  __typename?: 'VersionLateral';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  extra_price: number;
                  installer_extra_price: number;
                  installation_difficulty: number;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  cars_backwards?: Array<{
                    __typename?: 'CarBackward';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    preview_text?: string | null;
                    car_backward_types?: Array<{
                      __typename?: 'CarBackwardType';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      has_rear_camera: boolean;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      car_backward?: {
                        __typename?: 'CarBackward';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                        } | null;
                        car_backward_types?: Array<{
                          __typename?: 'CarBackwardType';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          has_rear_camera: boolean;
                        }> | null;
                      } | null;
                    }> | null;
                  }> | null;
                  car_version?: {
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    model_year?: {
                      __typename?: 'ModelYear';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      start_year: string;
                      end_year: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      brand_model?: {
                        __typename?: 'BrandModel';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        model_name: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        car_brand?: {
                          __typename?: 'CarBrand';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          brand_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          pictogram_dark?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                            thumbnail: {
                              __typename?: 'StorageThumbnail';
                              width: number;
                              height: number;
                              size: number;
                              url: string;
                              file_name: string;
                            };
                            medium: {
                              __typename?: 'StorageThumbnail';
                              width: number;
                              height: number;
                              size: number;
                              url: string;
                              file_name: string;
                            };
                          } | null;
                          brand_models?: Array<{
                            __typename?: 'BrandModel';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            model_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            car_brand?: {
                              __typename?: 'CarBrand';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              brand_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                            } | null;
                            model_years?: Array<{
                              __typename?: 'ModelYear';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              start_year: string;
                              end_year: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              brand_model?: {
                                __typename?: 'BrandModel';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                model_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                model_years?: Array<{
                                  __typename?: 'ModelYear';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  start_year: string;
                                  end_year: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  car_versions?: Array<{
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    pictogram?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    version_laterals?: Array<{
                                      __typename?: 'VersionLateral';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      extra_price: number;
                                      installer_extra_price: number;
                                      installation_difficulty: number;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                    }> | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                    } | null;
                                  }> | null;
                                }> | null;
                              } | null;
                            }> | null;
                          }> | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }> | null;
              } | null;
            } | null;
          }> | null;
        } | null;
      }> | null;
    }> | null;
    model_year?: {
      __typename?: 'ModelYear';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      start_year: string;
      end_year: string;
      info_title?: string | null;
      info_description?: string | null;
    } | null;
  };
};

export type CarVersionListQueryVariables = Exact<{
  model_year_uuid?: InputMaybe<Scalars['String']>;
}>;

export type CarVersionListQuery = {
  __typename?: 'Query';
  carVersionList: {
    __typename?: 'CarVersionPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'CarVersion';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      version_laterals?: Array<{
        __typename?: 'VersionLateral';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        side_glazings?: Array<{
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            preview_image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            side_glazings?: Array<{
              __typename?: 'SideGlazing';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              extra_price: number;
              installer_extra_price: number;
              info_title?: string | null;
              info_description?: string | null;
              preview_text?: string | null;
            }> | null;
            cars_backwards?: Array<{
              __typename?: 'CarBackward';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              info_title?: string | null;
              info_description?: string | null;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              preview_text?: string | null;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              preview_image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              version_lateral?: {
                __typename?: 'VersionLateral';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                car_version?: {
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    cars_backwards?: Array<{
                      __typename?: 'CarBackward';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      preview_text?: string | null;
                      car_backward_types?: Array<{
                        __typename?: 'CarBackwardType';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        has_rear_camera: boolean;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        car_backward?: {
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          } | null;
                          car_backward_types?: Array<{
                            __typename?: 'CarBackwardType';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            has_rear_camera: boolean;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                    car_version?: {
                      __typename?: 'CarVersion';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      model_year?: {
                        __typename?: 'ModelYear';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        start_year: string;
                        end_year: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        brand_model?: {
                          __typename?: 'BrandModel';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          model_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          car_brand?: {
                            __typename?: 'CarBrand';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            brand_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            pictogram_dark?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                              thumbnail: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                              medium: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                            } | null;
                            brand_models?: Array<{
                              __typename?: 'BrandModel';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              model_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              car_brand?: {
                                __typename?: 'CarBrand';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                brand_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                              } | null;
                              model_years?: Array<{
                                __typename?: 'ModelYear';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                start_year: string;
                                end_year: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                brand_model?: {
                                  __typename?: 'BrandModel';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  model_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  model_years?: Array<{
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_laterals?: Array<{
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        car_versions?: Array<{
                                          __typename?: 'CarVersion';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                } | null;
                              }> | null;
                            }> | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
        }> | null;
      }> | null;
      model_year?: {
        __typename?: 'ModelYear';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        start_year: string;
        end_year: string;
        info_title?: string | null;
        info_description?: string | null;
      } | null;
    }>;
  };
};

export type GetProductsMonthStatsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetProductsMonthStatsQuery = {
  __typename?: 'Query';
  getProductsMonthStats: {
    __typename?: 'getProductsMonthStatsOutput';
    stats: {
      __typename?: 'getProductsMonsthStatsObject';
      totalTTC: number;
      totalHT: number;
      quantityPercent: number;
      totalPercent: number;
      quantity: Array<{
        __typename?: 'Product';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        type: ProductTypes;
        description: string;
        slug?: string | null;
        meta_title?: string | null;
        meta_description?: string | null;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        enabled: boolean;
        index: number;
        start_price: number;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        }> | null;
        sections?: Array<{
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
          }> | null;
        }> | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_sections: Array<{
            __typename?: 'ExtraStickerSectionPrice';
            section_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        }> | null;
        templates?: Array<{
          __typename?: 'Template';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        product_images?: Array<{
          __typename?: 'ProductImage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          support_uuid?: string | null;
          size_uuid?: string | null;
          section_uuid?: string | null;
          extra_sticker_uuid?: string | null;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }>;
    };
  };
};

export type GetProductsYearsStatsQueryVariables = Exact<{
  year: Scalars['Float'];
}>;

export type GetProductsYearsStatsQuery = {
  __typename?: 'Query';
  getProductsYearsStats: {
    __typename?: 'getProductsYearStatsOutput';
    stats: {
      __typename?: 'getProductsYearStatsObject';
      quantityTab: Array<number>;
      quantityPercent: number;
    };
  };
};

export type ListAllVersionLateralQueryVariables = Exact<{
  car_version_uuid?: InputMaybe<Scalars['String']>;
}>;

export type ListAllVersionLateralQuery = {
  __typename?: 'Query';
  listAllVersionLateral: {
    __typename?: 'VersionLateralPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'VersionLateral';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      extra_price: number;
      installer_extra_price: number;
      installation_difficulty: number;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      side_glazings?: Array<{
        __typename?: 'SideGlazing';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        version_lateral?: {
          __typename?: 'VersionLateral';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          side_glazings?: Array<{
            __typename?: 'SideGlazing';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
          }> | null;
          cars_backwards?: Array<{
            __typename?: 'CarBackward';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            info_title?: string | null;
            info_description?: string | null;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            preview_text?: string | null;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            preview_image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            version_lateral?: {
              __typename?: 'VersionLateral';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              info_title?: string | null;
              info_description?: string | null;
              preview_text?: string | null;
              car_version?: {
                __typename?: 'CarVersion';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                pictogram?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                version_laterals?: Array<{
                  __typename?: 'VersionLateral';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  extra_price: number;
                  installer_extra_price: number;
                  installation_difficulty: number;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  preview_image?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  side_glazings?: Array<{
                    __typename?: 'SideGlazing';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                  }> | null;
                  cars_backwards?: Array<{
                    __typename?: 'CarBackward';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    preview_text?: string | null;
                    car_backward_types?: Array<{
                      __typename?: 'CarBackwardType';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      has_rear_camera: boolean;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      car_backward?: {
                        __typename?: 'CarBackward';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                        } | null;
                        car_backward_types?: Array<{
                          __typename?: 'CarBackwardType';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          has_rear_camera: boolean;
                        }> | null;
                      } | null;
                    }> | null;
                  }> | null;
                  car_version?: {
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    model_year?: {
                      __typename?: 'ModelYear';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      start_year: string;
                      end_year: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      brand_model?: {
                        __typename?: 'BrandModel';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        model_name: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        car_brand?: {
                          __typename?: 'CarBrand';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          brand_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          pictogram_dark?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                            thumbnail: {
                              __typename?: 'StorageThumbnail';
                              width: number;
                              height: number;
                              size: number;
                              url: string;
                              file_name: string;
                            };
                            medium: {
                              __typename?: 'StorageThumbnail';
                              width: number;
                              height: number;
                              size: number;
                              url: string;
                              file_name: string;
                            };
                          } | null;
                          brand_models?: Array<{
                            __typename?: 'BrandModel';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            model_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            car_brand?: {
                              __typename?: 'CarBrand';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              brand_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                            } | null;
                            model_years?: Array<{
                              __typename?: 'ModelYear';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              start_year: string;
                              end_year: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              brand_model?: {
                                __typename?: 'BrandModel';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                model_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                model_years?: Array<{
                                  __typename?: 'ModelYear';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  start_year: string;
                                  end_year: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  car_versions?: Array<{
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    pictogram?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    version_laterals?: Array<{
                                      __typename?: 'VersionLateral';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      extra_price: number;
                                      installer_extra_price: number;
                                      installation_difficulty: number;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                    }> | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                    } | null;
                                  }> | null;
                                }> | null;
                              } | null;
                            }> | null;
                          }> | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }> | null;
              } | null;
            } | null;
          }> | null;
        } | null;
      }> | null;
      cars_backwards?: Array<{
        __typename?: 'CarBackward';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        preview_text?: string | null;
      }> | null;
      car_version?: {
        __typename?: 'CarVersion';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
      } | null;
    }>;
  };
};

export type ListSideGlazingsQueryVariables = Exact<{
  version_lateral_uuid?: InputMaybe<Scalars['String']>;
}>;

export type ListSideGlazingsQuery = {
  __typename?: 'Query';
  listSideGlazings: {
    __typename?: 'SideGlazingPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'SideGlazing';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      extra_price: number;
      installer_extra_price: number;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      version_lateral?: {
        __typename?: 'VersionLateral';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        side_glazings?: Array<{
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            cars_backwards?: Array<{
              __typename?: 'CarBackward';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              info_title?: string | null;
              info_description?: string | null;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              preview_text?: string | null;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              preview_image?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              version_lateral?: {
                __typename?: 'VersionLateral';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                car_version?: {
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                    }> | null;
                    cars_backwards?: Array<{
                      __typename?: 'CarBackward';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      preview_text?: string | null;
                      car_backward_types?: Array<{
                        __typename?: 'CarBackwardType';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        has_rear_camera: boolean;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        car_backward?: {
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          } | null;
                          car_backward_types?: Array<{
                            __typename?: 'CarBackwardType';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            has_rear_camera: boolean;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                    car_version?: {
                      __typename?: 'CarVersion';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      model_year?: {
                        __typename?: 'ModelYear';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        start_year: string;
                        end_year: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        brand_model?: {
                          __typename?: 'BrandModel';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          model_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          car_brand?: {
                            __typename?: 'CarBrand';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            brand_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            pictogram_dark?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                              thumbnail: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                              medium: {
                                __typename?: 'StorageThumbnail';
                                width: number;
                                height: number;
                                size: number;
                                url: string;
                                file_name: string;
                              };
                            } | null;
                            brand_models?: Array<{
                              __typename?: 'BrandModel';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              model_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              car_brand?: {
                                __typename?: 'CarBrand';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                brand_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                              } | null;
                              model_years?: Array<{
                                __typename?: 'ModelYear';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                start_year: string;
                                end_year: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                brand_model?: {
                                  __typename?: 'BrandModel';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  model_name: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  model_years?: Array<{
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_laterals?: Array<{
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        car_versions?: Array<{
                                          __typename?: 'CarVersion';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                } | null;
                              }> | null;
                            }> | null;
                          } | null;
                        } | null;
                      } | null;
                    } | null;
                  }> | null;
                } | null;
              } | null;
            }> | null;
          } | null;
        }> | null;
      } | null;
    }>;
  };
};

export type ModelYearDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ModelYearDetailQuery = {
  __typename?: 'Query';
  modelYearDetail: {
    __typename?: 'ModelYear';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    start_year: string;
    end_year: string;
    info_title?: string | null;
    info_description?: string | null;
    brand_model?: {
      __typename?: 'BrandModel';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      model_name: string;
      info_title?: string | null;
      info_description?: string | null;
      car_brand?: {
        __typename?: 'CarBrand';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        brand_name: string;
        info_title?: string | null;
        info_description?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        pictogram_dark?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        brand_models?: Array<{
          __typename?: 'BrandModel';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          model_name: string;
          info_title?: string | null;
          info_description?: string | null;
          car_brand?: {
            __typename?: 'CarBrand';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            brand_name: string;
            info_title?: string | null;
            info_description?: string | null;
          } | null;
          model_years?: Array<{
            __typename?: 'ModelYear';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            start_year: string;
            end_year: string;
            info_title?: string | null;
            info_description?: string | null;
            brand_model?: {
              __typename?: 'BrandModel';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              model_name: string;
              info_title?: string | null;
              info_description?: string | null;
              model_years?: Array<{
                __typename?: 'ModelYear';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                start_year: string;
                end_year: string;
                info_title?: string | null;
                info_description?: string | null;
                car_versions?: Array<{
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  version_laterals?: Array<{
                    __typename?: 'VersionLateral';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                      thumbnail: {
                        __typename?: 'StorageThumbnail';
                        width: number;
                        height: number;
                        size: number;
                        url: string;
                        file_name: string;
                      };
                      medium: {
                        __typename?: 'StorageThumbnail';
                        width: number;
                        height: number;
                        size: number;
                        url: string;
                        file_name: string;
                      };
                    } | null;
                    side_glazings?: Array<{
                      __typename?: 'SideGlazing';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      version_lateral?: {
                        __typename?: 'VersionLateral';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        side_glazings?: Array<{
                          __typename?: 'SideGlazing';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                        }> | null;
                        cars_backwards?: Array<{
                          __typename?: 'CarBackward';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          version_lateral?: {
                            __typename?: 'VersionLateral';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                            car_version?: {
                              __typename?: 'CarVersion';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              pictogram?: {
                                __typename?: 'Storage';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                file_name: string;
                                url: string;
                                mime_type: string;
                                width: number;
                                height: number;
                                size: number;
                                caption: string;
                                description: string;
                                alternative_title: string;
                              } | null;
                              version_laterals?: Array<{
                                __typename?: 'VersionLateral';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                extra_price: number;
                                installer_extra_price: number;
                                installation_difficulty: number;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                cars_backwards?: Array<{
                                  __typename?: 'CarBackward';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  preview_text?: string | null;
                                  car_backward_types?: Array<{
                                    __typename?: 'CarBackwardType';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    has_rear_camera: boolean;
                                    pictogram?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    preview_image?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    car_backward?: {
                                      __typename?: 'CarBackward';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      extra_price: number;
                                      installer_extra_price: number;
                                      installation_difficulty: number;
                                      preview_text?: string | null;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      version_lateral?: {
                                        __typename?: 'VersionLateral';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      } | null;
                                      car_backward_types?: Array<{
                                        __typename?: 'CarBackwardType';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        has_rear_camera: boolean;
                                      }> | null;
                                    } | null;
                                  }> | null;
                                }> | null;
                                car_version?: {
                                  __typename?: 'CarVersion';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  model_year?: {
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    brand_model?: {
                                      __typename?: 'BrandModel';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      model_name: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                    } | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                      model_year?: {
                                        __typename?: 'ModelYear';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        start_year: string;
                                        end_year: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                    }> | null;
                                  } | null;
                                } | null;
                              }> | null;
                            } | null;
                          } | null;
                        }> | null;
                      } | null;
                    }> | null;
                  }> | null;
                }> | null;
              }> | null;
            } | null;
          }> | null;
        }> | null;
      } | null;
    } | null;
    car_versions?: Array<{
      __typename?: 'CarVersion';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
    }> | null;
  };
};

export type ModelYearsListQueryVariables = Exact<{
  brand_model_uuid?: InputMaybe<Scalars['String']>;
}>;

export type ModelYearsListQuery = {
  __typename?: 'Query';
  modelYearsList: {
    __typename?: 'ModelYearPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'ModelYear';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      start_year: string;
      end_year: string;
      info_title?: string | null;
      info_description?: string | null;
      brand_model?: {
        __typename?: 'BrandModel';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        model_name: string;
        info_title?: string | null;
        info_description?: string | null;
        car_brand?: {
          __typename?: 'CarBrand';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          brand_name: string;
          info_title?: string | null;
          info_description?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          pictogram_dark?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          brand_models?: Array<{
            __typename?: 'BrandModel';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            model_name: string;
            info_title?: string | null;
            info_description?: string | null;
            car_brand?: {
              __typename?: 'CarBrand';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              brand_name: string;
              info_title?: string | null;
              info_description?: string | null;
            } | null;
            model_years?: Array<{
              __typename?: 'ModelYear';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              start_year: string;
              end_year: string;
              info_title?: string | null;
              info_description?: string | null;
              brand_model?: {
                __typename?: 'BrandModel';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                model_name: string;
                info_title?: string | null;
                info_description?: string | null;
                model_years?: Array<{
                  __typename?: 'ModelYear';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  start_year: string;
                  end_year: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  car_versions?: Array<{
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    version_laterals?: Array<{
                      __typename?: 'VersionLateral';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      info_title?: string | null;
                      info_description?: string | null;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                        thumbnail: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                        medium: {
                          __typename?: 'StorageThumbnail';
                          width: number;
                          height: number;
                          size: number;
                          url: string;
                          file_name: string;
                        };
                      } | null;
                      side_glazings?: Array<{
                        __typename?: 'SideGlazing';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          preview_image?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          side_glazings?: Array<{
                            __typename?: 'SideGlazing';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            extra_price: number;
                            installer_extra_price: number;
                            info_title?: string | null;
                            info_description?: string | null;
                            preview_text?: string | null;
                          }> | null;
                          cars_backwards?: Array<{
                            __typename?: 'CarBackward';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            title: string;
                            sub_title: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            extra_price: number;
                            installer_extra_price: number;
                            installation_difficulty: number;
                            preview_text?: string | null;
                            pictogram?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            preview_image?: {
                              __typename?: 'Storage';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              file_name: string;
                              url: string;
                              mime_type: string;
                              width: number;
                              height: number;
                              size: number;
                              caption: string;
                              description: string;
                              alternative_title: string;
                            } | null;
                            version_lateral?: {
                              __typename?: 'VersionLateral';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              title: string;
                              sub_title: string;
                              extra_price: number;
                              installer_extra_price: number;
                              installation_difficulty: number;
                              info_title?: string | null;
                              info_description?: string | null;
                              preview_text?: string | null;
                              car_version?: {
                                __typename?: 'CarVersion';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                title: string;
                                sub_title: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                preview_text?: string | null;
                                pictogram?: {
                                  __typename?: 'Storage';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  file_name: string;
                                  url: string;
                                  mime_type: string;
                                  width: number;
                                  height: number;
                                  size: number;
                                  caption: string;
                                  description: string;
                                  alternative_title: string;
                                } | null;
                                version_laterals?: Array<{
                                  __typename?: 'VersionLateral';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  extra_price: number;
                                  installer_extra_price: number;
                                  installation_difficulty: number;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  cars_backwards?: Array<{
                                    __typename?: 'CarBackward';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    extra_price: number;
                                    installer_extra_price: number;
                                    installation_difficulty: number;
                                    preview_text?: string | null;
                                    car_backward_types?: Array<{
                                      __typename?: 'CarBackwardType';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      has_rear_camera: boolean;
                                      pictogram?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      preview_image?: {
                                        __typename?: 'Storage';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        file_name: string;
                                        url: string;
                                        mime_type: string;
                                        width: number;
                                        height: number;
                                        size: number;
                                        caption: string;
                                        description: string;
                                        alternative_title: string;
                                      } | null;
                                      car_backward?: {
                                        __typename?: 'CarBackward';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        extra_price: number;
                                        installer_extra_price: number;
                                        installation_difficulty: number;
                                        preview_text?: string | null;
                                        pictogram?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        preview_image?: {
                                          __typename?: 'Storage';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          file_name: string;
                                          url: string;
                                          mime_type: string;
                                          width: number;
                                          height: number;
                                          size: number;
                                          caption: string;
                                          description: string;
                                          alternative_title: string;
                                        } | null;
                                        version_lateral?: {
                                          __typename?: 'VersionLateral';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          extra_price: number;
                                          installer_extra_price: number;
                                          installation_difficulty: number;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          preview_text?: string | null;
                                        } | null;
                                        car_backward_types?: Array<{
                                          __typename?: 'CarBackwardType';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          title: string;
                                          sub_title: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                          has_rear_camera: boolean;
                                        }> | null;
                                      } | null;
                                    }> | null;
                                  }> | null;
                                  car_version?: {
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      brand_model?: {
                                        __typename?: 'BrandModel';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        model_name: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                      } | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                        model_year?: {
                                          __typename?: 'ModelYear';
                                          uuid: string;
                                          createdAt: any;
                                          updatedAt: any;
                                          start_year: string;
                                          end_year: string;
                                          info_title?: string | null;
                                          info_description?: string | null;
                                        } | null;
                                      }> | null;
                                    } | null;
                                  } | null;
                                }> | null;
                              } | null;
                            } | null;
                          }> | null;
                        } | null;
                      }> | null;
                    }> | null;
                  }> | null;
                }> | null;
              } | null;
            }> | null;
          }> | null;
        } | null;
      } | null;
      car_versions?: Array<{
        __typename?: 'CarVersion';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
      }> | null;
    }>;
  };
};

export type ProductGetQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ProductGetQuery = {
  __typename?: 'Query';
  productGet: {
    __typename?: 'Product';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    subtitle: string;
    type: ProductTypes;
    description: string;
    slug?: string | null;
    meta_title?: string | null;
    meta_description?: string | null;
    verification: boolean;
    graphic_service: boolean;
    professional_installer: boolean;
    enabled: boolean;
    index: number;
    start_price: number;
    is_perfect_squid: boolean;
    canvas_width?: number | null;
    canvas_height?: number | null;
    canvas_radius?: number | null;
    video_url?: string | null;
    image?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    supports?: Array<{
      __typename?: 'Support';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      can_have_background: boolean;
      extra_price: number;
      overview: string;
      video_url: string;
      installation_difficulty: number;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    }> | null;
    sizes?: Array<{
      __typename?: 'Size';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      need_vehicle_info: boolean;
      price: number;
      index: number;
      installer_price: number;
      canvas_width: number;
      canvas_height: number;
      canvas_radius: number;
      overview: string;
      video_url?: string | null;
      design_choice_possible: boolean;
      installation_difficulty: number;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      depends_on_supports?: Array<{
        __typename?: 'Support';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        can_have_background: boolean;
        extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
      }> | null;
    }> | null;
    sections?: Array<{
      __typename?: 'Section';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      can_have_background: boolean;
      extra_price: number;
      canvas_width: number;
      canvas_height: number;
      canvas_radius: number;
      overview: string;
      video_url: string;
      installation_difficulty: number;
      extra_price_size: Array<{
        __typename?: 'ExtraSectionSizePrice';
        size_uuid: string;
        extra_price: number;
      }>;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      depends_on_sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
        }> | null;
      }> | null;
    }> | null;
    extra_stickers?: Array<{
      __typename?: 'ExtraSticker';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      design_choice_possible: boolean;
      extra_price: number;
      installer_extra_price: number;
      overview: string;
      video_url: string;
      installation_difficulty: number;
      extra_price_sections: Array<{
        __typename?: 'ExtraStickerSectionPrice';
        section_uuid: string;
        extra_price: number;
      }>;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      depends_on_sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        installation_difficulty: number;
      }> | null;
    }> | null;
    templates?: Array<{
      __typename?: 'Template';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      file?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    }> | null;
    product_images?: Array<{
      __typename?: 'ProductImage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      support_uuid?: string | null;
      size_uuid?: string | null;
      section_uuid?: string | null;
      extra_sticker_uuid?: string | null;
      file?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
    }> | null;
  };
};

export type ProductGetBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type ProductGetBySlugQuery = {
  __typename?: 'Query';
  productGetBySlug: {
    __typename?: 'Product';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    subtitle: string;
    type: ProductTypes;
    description: string;
    slug?: string | null;
    meta_title?: string | null;
    meta_description?: string | null;
    verification: boolean;
    graphic_service: boolean;
    professional_installer: boolean;
    enabled: boolean;
    index: number;
    start_price: number;
    image?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    supports?: Array<{
      __typename?: 'Support';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      can_have_background: boolean;
      extra_price: number;
      installation_difficulty: number;
      overview: string;
      video_url: string;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    }> | null;
    sizes?: Array<{
      __typename?: 'Size';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      need_vehicle_info: boolean;
      price: number;
      index: number;
      installation_difficulty: number;
      installer_price: number;
      canvas_width: number;
      canvas_height: number;
      canvas_radius: number;
      overview: string;
      video_url?: string | null;
      design_choice_possible: boolean;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      depends_on_supports?: Array<{
        __typename?: 'Support';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        can_have_background: boolean;
        extra_price: number;
        overview: string;
        video_url: string;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
      }> | null;
    }> | null;
    sections?: Array<{
      __typename?: 'Section';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      can_have_background: boolean;
      extra_price: number;
      canvas_width: number;
      canvas_height: number;
      canvas_radius: number;
      installation_difficulty: number;
      overview: string;
      video_url: string;
      extra_price_size: Array<{
        __typename?: 'ExtraSectionSizePrice';
        size_uuid: string;
        extra_price: number;
      }>;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      depends_on_sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
        }> | null;
      }> | null;
    }> | null;
    extra_stickers?: Array<{
      __typename?: 'ExtraSticker';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      help_title: string;
      help: string;
      design_choice_possible: boolean;
      extra_price: number;
      installer_extra_price: number;
      installation_difficulty: number;
      overview: string;
      video_url: string;
      extra_price_sections: Array<{
        __typename?: 'ExtraStickerSectionPrice';
        section_uuid: string;
        extra_price: number;
      }>;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      depends_on_sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
      }> | null;
    }> | null;
    templates?: Array<{
      __typename?: 'Template';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      file?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    }> | null;
    product_images?: Array<{
      __typename?: 'ProductImage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      support_uuid?: string | null;
      size_uuid?: string | null;
      section_uuid?: string | null;
      extra_sticker_uuid?: string | null;
      file?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
    }> | null;
  };
};

export type ProductsListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ProductsPaginationSortBy>;
}>;

export type ProductsListQuery = {
  __typename?: 'Query';
  productsList: {
    __typename?: 'ProductsPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Product';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      type: ProductTypes;
      description: string;
      slug?: string | null;
      meta_title?: string | null;
      meta_description?: string | null;
      verification: boolean;
      graphic_service: boolean;
      professional_installer: boolean;
      enabled: boolean;
      index: number;
      start_price: number;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      supports?: Array<{
        __typename?: 'Support';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        can_have_background: boolean;
        extra_price: number;
        installation_difficulty: number;
        overview: string;
        video_url: string;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
      sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installation_difficulty: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
      sections?: Array<{
        __typename?: 'Section';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        can_have_background: boolean;
        extra_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        installation_difficulty: number;
        overview: string;
        video_url: string;
        extra_price_size: Array<{
          __typename?: 'ExtraSectionSizePrice';
          size_uuid: string;
          extra_price: number;
        }>;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
          }> | null;
        }> | null;
      }> | null;
      extra_stickers?: Array<{
        __typename?: 'ExtraSticker';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        design_choice_possible: boolean;
        installation_difficulty: number;
        extra_price: number;
        installer_extra_price: number;
        overview: string;
        video_url: string;
        extra_price_sections: Array<{
          __typename?: 'ExtraStickerSectionPrice';
          section_uuid: string;
          extra_price: number;
        }>;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
        }> | null;
      }> | null;
      templates?: Array<{
        __typename?: 'Template';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        file?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
      product_images?: Array<{
        __typename?: 'ProductImage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        support_uuid?: string | null;
        size_uuid?: string | null;
        section_uuid?: string | null;
        extra_sticker_uuid?: string | null;
        file?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
      }> | null;
    }>;
  };
};

export type ProductsListAdminQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ProductsPaginationSortBy>;
}>;

export type ProductsListAdminQuery = {
  __typename?: 'Query';
  productsListAdmin: {
    __typename?: 'ProductsPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Product';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      type: ProductTypes;
      description: string;
      slug?: string | null;
      meta_title?: string | null;
      meta_description?: string | null;
      verification: boolean;
      graphic_service: boolean;
      professional_installer: boolean;
      enabled: boolean;
      index: number;
      start_price: number;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      supports?: Array<{
        __typename?: 'Support';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        can_have_background: boolean;
        installation_difficulty: number;
        extra_price: number;
        overview: string;
        video_url: string;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
      sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        installation_difficulty: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
      sections?: Array<{
        __typename?: 'Section';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        can_have_background: boolean;
        extra_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        installation_difficulty: number;
        overview: string;
        video_url: string;
        extra_price_size: Array<{
          __typename?: 'ExtraSectionSizePrice';
          size_uuid: string;
          extra_price: number;
        }>;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
          }> | null;
        }> | null;
      }> | null;
      extra_stickers?: Array<{
        __typename?: 'ExtraSticker';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        design_choice_possible: boolean;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        overview: string;
        video_url: string;
        extra_price_sections: Array<{
          __typename?: 'ExtraStickerSectionPrice';
          section_uuid: string;
          extra_price: number;
        }>;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
        }> | null;
      }> | null;
      templates?: Array<{
        __typename?: 'Template';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        file?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
      product_images?: Array<{
        __typename?: 'ProductImage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        support_uuid?: string | null;
        size_uuid?: string | null;
        section_uuid?: string | null;
        extra_sticker_uuid?: string | null;
        file?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
      }> | null;
    }>;
  };
};

export type SideGlazingDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type SideGlazingDetailQuery = {
  __typename?: 'Query';
  sideGlazingDetail: {
    __typename?: 'SideGlazing';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    sub_title: string;
    extra_price: number;
    installer_extra_price: number;
    info_title?: string | null;
    info_description?: string | null;
    preview_text?: string | null;
    pictogram?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    preview_image?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    version_lateral?: {
      __typename?: 'VersionLateral';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      extra_price: number;
      installer_extra_price: number;
      installation_difficulty: number;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      side_glazings?: Array<{
        __typename?: 'SideGlazing';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        version_lateral?: {
          __typename?: 'VersionLateral';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
          cars_backwards?: Array<{
            __typename?: 'CarBackward';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            info_title?: string | null;
            info_description?: string | null;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            preview_text?: string | null;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            preview_image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            version_lateral?: {
              __typename?: 'VersionLateral';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              extra_price: number;
              installer_extra_price: number;
              installation_difficulty: number;
              info_title?: string | null;
              info_description?: string | null;
              preview_text?: string | null;
              car_version?: {
                __typename?: 'CarVersion';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                pictogram?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                version_laterals?: Array<{
                  __typename?: 'VersionLateral';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  extra_price: number;
                  installer_extra_price: number;
                  installation_difficulty: number;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  pictogram?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  preview_image?: {
                    __typename?: 'Storage';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    file_name: string;
                    url: string;
                    mime_type: string;
                    width: number;
                    height: number;
                    size: number;
                    caption: string;
                    description: string;
                    alternative_title: string;
                  } | null;
                  side_glazings?: Array<{
                    __typename?: 'SideGlazing';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    extra_price: number;
                    installer_extra_price: number;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                  }> | null;
                  cars_backwards?: Array<{
                    __typename?: 'CarBackward';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    extra_price: number;
                    installer_extra_price: number;
                    installation_difficulty: number;
                    preview_text?: string | null;
                    car_backward_types?: Array<{
                      __typename?: 'CarBackwardType';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      has_rear_camera: boolean;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      car_backward?: {
                        __typename?: 'CarBackward';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        preview_text?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        preview_image?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        version_lateral?: {
                          __typename?: 'VersionLateral';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          extra_price: number;
                          installer_extra_price: number;
                          installation_difficulty: number;
                          info_title?: string | null;
                          info_description?: string | null;
                          preview_text?: string | null;
                        } | null;
                        car_backward_types?: Array<{
                          __typename?: 'CarBackwardType';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          title: string;
                          sub_title: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          has_rear_camera: boolean;
                        }> | null;
                      } | null;
                    }> | null;
                  }> | null;
                  car_version?: {
                    __typename?: 'CarVersion';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    preview_text?: string | null;
                    model_year?: {
                      __typename?: 'ModelYear';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      start_year: string;
                      end_year: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      brand_model?: {
                        __typename?: 'BrandModel';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        model_name: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        car_brand?: {
                          __typename?: 'CarBrand';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          brand_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          pictogram?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                          } | null;
                          pictogram_dark?: {
                            __typename?: 'Storage';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            file_name: string;
                            url: string;
                            mime_type: string;
                            width: number;
                            height: number;
                            size: number;
                            caption: string;
                            description: string;
                            alternative_title: string;
                            thumbnail: {
                              __typename?: 'StorageThumbnail';
                              width: number;
                              height: number;
                              size: number;
                              url: string;
                              file_name: string;
                            };
                            medium: {
                              __typename?: 'StorageThumbnail';
                              width: number;
                              height: number;
                              size: number;
                              url: string;
                              file_name: string;
                            };
                          } | null;
                          brand_models?: Array<{
                            __typename?: 'BrandModel';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            model_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            car_brand?: {
                              __typename?: 'CarBrand';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              brand_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                            } | null;
                            model_years?: Array<{
                              __typename?: 'ModelYear';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              start_year: string;
                              end_year: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              brand_model?: {
                                __typename?: 'BrandModel';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                model_name: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                model_years?: Array<{
                                  __typename?: 'ModelYear';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  start_year: string;
                                  end_year: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  car_versions?: Array<{
                                    __typename?: 'CarVersion';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                    pictogram?: {
                                      __typename?: 'Storage';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      file_name: string;
                                      url: string;
                                      mime_type: string;
                                      width: number;
                                      height: number;
                                      size: number;
                                      caption: string;
                                      description: string;
                                      alternative_title: string;
                                    } | null;
                                    version_laterals?: Array<{
                                      __typename?: 'VersionLateral';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      extra_price: number;
                                      installer_extra_price: number;
                                      installation_difficulty: number;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                    }> | null;
                                    model_year?: {
                                      __typename?: 'ModelYear';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      start_year: string;
                                      end_year: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      car_versions?: Array<{
                                        __typename?: 'CarVersion';
                                        uuid: string;
                                        createdAt: any;
                                        updatedAt: any;
                                        title: string;
                                        sub_title: string;
                                        info_title?: string | null;
                                        info_description?: string | null;
                                        preview_text?: string | null;
                                      }> | null;
                                    } | null;
                                  }> | null;
                                }> | null;
                              } | null;
                            }> | null;
                          }> | null;
                        } | null;
                      } | null;
                    } | null;
                  } | null;
                }> | null;
              } | null;
            } | null;
          }> | null;
        } | null;
      }> | null;
    } | null;
  };
};

export type SquidstudioCategoriesListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SquidstudioCategoryPaginationSortBy>;
}>;

export type SquidstudioCategoriesListQuery = {
  __typename?: 'Query';
  squidstudioCategoriesList: {
    __typename?: 'SquidstudioCategoryPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'SquidstudioCategory';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      type: Squidstudio_Categories_Types;
      icon?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      files?: Array<{
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      }> | null;
    }>;
  };
};

export type SquidstudioCategoriesListUnAuthentifiedQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SquidstudioCategoryPaginationSortBy>;
}>;

export type SquidstudioCategoriesListUnAuthentifiedQuery = {
  __typename?: 'Query';
  squidstudioCategoriesListUnAuthentified: {
    __typename?: 'SquidstudioCategoryPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'SquidstudioCategory';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      type: Squidstudio_Categories_Types;
      icon?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      files?: Array<{
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      }> | null;
    }>;
  };
};

export type SquidstudioCategoryGetQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type SquidstudioCategoryGetQuery = {
  __typename?: 'Query';
  squidstudioCategoryGet: {
    __typename?: 'SquidstudioCategory';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    type: Squidstudio_Categories_Types;
    icon?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    files?: Array<{
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    }> | null;
  };
};

export type SquidstudioCategoryGetUnAuthentifiedQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type SquidstudioCategoryGetUnAuthentifiedQuery = {
  __typename?: 'Query';
  squidstudioCategoryGetUnAuthentified: {
    __typename?: 'SquidstudioCategory';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    type: Squidstudio_Categories_Types;
    icon?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    files?: Array<{
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    }> | null;
  };
};

export type VersionLateralDetailQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type VersionLateralDetailQuery = {
  __typename?: 'Query';
  versionLateralDetail: {
    __typename?: 'VersionLateral';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    sub_title: string;
    extra_price: number;
    installer_extra_price: number;
    installation_difficulty: number;
    info_title?: string | null;
    info_description?: string | null;
    preview_text?: string | null;
    pictogram?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    preview_image?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    side_glazings?: Array<{
      __typename?: 'SideGlazing';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      extra_price: number;
      installer_extra_price: number;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
      pictogram?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      preview_image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
      } | null;
      version_lateral?: {
        __typename?: 'VersionLateral';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        sub_title: string;
        extra_price: number;
        installer_extra_price: number;
        installation_difficulty: number;
        info_title?: string | null;
        info_description?: string | null;
        preview_text?: string | null;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        preview_image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        side_glazings?: Array<{
          __typename?: 'SideGlazing';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          extra_price: number;
          installer_extra_price: number;
          info_title?: string | null;
          info_description?: string | null;
          preview_text?: string | null;
        }> | null;
        cars_backwards?: Array<{
          __typename?: 'CarBackward';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          sub_title: string;
          info_title?: string | null;
          info_description?: string | null;
          extra_price: number;
          installer_extra_price: number;
          installation_difficulty: number;
          preview_text?: string | null;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          preview_image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          version_lateral?: {
            __typename?: 'VersionLateral';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            sub_title: string;
            extra_price: number;
            installer_extra_price: number;
            installation_difficulty: number;
            info_title?: string | null;
            info_description?: string | null;
            preview_text?: string | null;
            car_version?: {
              __typename?: 'CarVersion';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              sub_title: string;
              info_title?: string | null;
              info_description?: string | null;
              preview_text?: string | null;
              pictogram?: {
                __typename?: 'Storage';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                file_name: string;
                url: string;
                mime_type: string;
                width: number;
                height: number;
                size: number;
                caption: string;
                description: string;
                alternative_title: string;
              } | null;
              version_laterals?: Array<{
                __typename?: 'VersionLateral';
                uuid: string;
                createdAt: any;
                updatedAt: any;
                title: string;
                sub_title: string;
                extra_price: number;
                installer_extra_price: number;
                installation_difficulty: number;
                info_title?: string | null;
                info_description?: string | null;
                preview_text?: string | null;
                pictogram?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                preview_image?: {
                  __typename?: 'Storage';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  file_name: string;
                  url: string;
                  mime_type: string;
                  width: number;
                  height: number;
                  size: number;
                  caption: string;
                  description: string;
                  alternative_title: string;
                } | null;
                side_glazings?: Array<{
                  __typename?: 'SideGlazing';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  extra_price: number;
                  installer_extra_price: number;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                }> | null;
                cars_backwards?: Array<{
                  __typename?: 'CarBackward';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  extra_price: number;
                  installer_extra_price: number;
                  installation_difficulty: number;
                  preview_text?: string | null;
                  car_backward_types?: Array<{
                    __typename?: 'CarBackwardType';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    title: string;
                    sub_title: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    has_rear_camera: boolean;
                    pictogram?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    preview_image?: {
                      __typename?: 'Storage';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      file_name: string;
                      url: string;
                      mime_type: string;
                      width: number;
                      height: number;
                      size: number;
                      caption: string;
                      description: string;
                      alternative_title: string;
                    } | null;
                    car_backward?: {
                      __typename?: 'CarBackward';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      title: string;
                      sub_title: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      extra_price: number;
                      installer_extra_price: number;
                      installation_difficulty: number;
                      preview_text?: string | null;
                      pictogram?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      preview_image?: {
                        __typename?: 'Storage';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        file_name: string;
                        url: string;
                        mime_type: string;
                        width: number;
                        height: number;
                        size: number;
                        caption: string;
                        description: string;
                        alternative_title: string;
                      } | null;
                      version_lateral?: {
                        __typename?: 'VersionLateral';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        extra_price: number;
                        installer_extra_price: number;
                        installation_difficulty: number;
                        info_title?: string | null;
                        info_description?: string | null;
                        preview_text?: string | null;
                      } | null;
                      car_backward_types?: Array<{
                        __typename?: 'CarBackwardType';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        title: string;
                        sub_title: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        has_rear_camera: boolean;
                      }> | null;
                    } | null;
                  }> | null;
                }> | null;
                car_version?: {
                  __typename?: 'CarVersion';
                  uuid: string;
                  createdAt: any;
                  updatedAt: any;
                  title: string;
                  sub_title: string;
                  info_title?: string | null;
                  info_description?: string | null;
                  preview_text?: string | null;
                  model_year?: {
                    __typename?: 'ModelYear';
                    uuid: string;
                    createdAt: any;
                    updatedAt: any;
                    start_year: string;
                    end_year: string;
                    info_title?: string | null;
                    info_description?: string | null;
                    brand_model?: {
                      __typename?: 'BrandModel';
                      uuid: string;
                      createdAt: any;
                      updatedAt: any;
                      model_name: string;
                      info_title?: string | null;
                      info_description?: string | null;
                      car_brand?: {
                        __typename?: 'CarBrand';
                        uuid: string;
                        createdAt: any;
                        updatedAt: any;
                        brand_name: string;
                        info_title?: string | null;
                        info_description?: string | null;
                        pictogram?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                        } | null;
                        pictogram_dark?: {
                          __typename?: 'Storage';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          file_name: string;
                          url: string;
                          mime_type: string;
                          width: number;
                          height: number;
                          size: number;
                          caption: string;
                          description: string;
                          alternative_title: string;
                          thumbnail: {
                            __typename?: 'StorageThumbnail';
                            width: number;
                            height: number;
                            size: number;
                            url: string;
                            file_name: string;
                          };
                          medium: {
                            __typename?: 'StorageThumbnail';
                            width: number;
                            height: number;
                            size: number;
                            url: string;
                            file_name: string;
                          };
                        } | null;
                        brand_models?: Array<{
                          __typename?: 'BrandModel';
                          uuid: string;
                          createdAt: any;
                          updatedAt: any;
                          model_name: string;
                          info_title?: string | null;
                          info_description?: string | null;
                          car_brand?: {
                            __typename?: 'CarBrand';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            brand_name: string;
                            info_title?: string | null;
                            info_description?: string | null;
                          } | null;
                          model_years?: Array<{
                            __typename?: 'ModelYear';
                            uuid: string;
                            createdAt: any;
                            updatedAt: any;
                            start_year: string;
                            end_year: string;
                            info_title?: string | null;
                            info_description?: string | null;
                            brand_model?: {
                              __typename?: 'BrandModel';
                              uuid: string;
                              createdAt: any;
                              updatedAt: any;
                              model_name: string;
                              info_title?: string | null;
                              info_description?: string | null;
                              model_years?: Array<{
                                __typename?: 'ModelYear';
                                uuid: string;
                                createdAt: any;
                                updatedAt: any;
                                start_year: string;
                                end_year: string;
                                info_title?: string | null;
                                info_description?: string | null;
                                car_versions?: Array<{
                                  __typename?: 'CarVersion';
                                  uuid: string;
                                  createdAt: any;
                                  updatedAt: any;
                                  title: string;
                                  sub_title: string;
                                  info_title?: string | null;
                                  info_description?: string | null;
                                  preview_text?: string | null;
                                  pictogram?: {
                                    __typename?: 'Storage';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    file_name: string;
                                    url: string;
                                    mime_type: string;
                                    width: number;
                                    height: number;
                                    size: number;
                                    caption: string;
                                    description: string;
                                    alternative_title: string;
                                  } | null;
                                  version_laterals?: Array<{
                                    __typename?: 'VersionLateral';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    title: string;
                                    sub_title: string;
                                    extra_price: number;
                                    installer_extra_price: number;
                                    installation_difficulty: number;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    preview_text?: string | null;
                                  }> | null;
                                  model_year?: {
                                    __typename?: 'ModelYear';
                                    uuid: string;
                                    createdAt: any;
                                    updatedAt: any;
                                    start_year: string;
                                    end_year: string;
                                    info_title?: string | null;
                                    info_description?: string | null;
                                    car_versions?: Array<{
                                      __typename?: 'CarVersion';
                                      uuid: string;
                                      createdAt: any;
                                      updatedAt: any;
                                      title: string;
                                      sub_title: string;
                                      info_title?: string | null;
                                      info_description?: string | null;
                                      preview_text?: string | null;
                                    }> | null;
                                  } | null;
                                }> | null;
                              }> | null;
                            } | null;
                          }> | null;
                        }> | null;
                      } | null;
                    } | null;
                  } | null;
                } | null;
              }> | null;
            } | null;
          } | null;
        }> | null;
      } | null;
    }> | null;
    cars_backwards?: Array<{
      __typename?: 'CarBackward';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      extra_price: number;
      installer_extra_price: number;
      installation_difficulty: number;
      preview_text?: string | null;
    }> | null;
    car_version?: {
      __typename?: 'CarVersion';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      sub_title: string;
      info_title?: string | null;
      info_description?: string | null;
      preview_text?: string | null;
    } | null;
  };
};

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfileQuery = {
  __typename?: 'Query';
  getProfile: {
    __typename?: 'CreateUserOutput';
    user: {
      __typename?: 'User';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      email: string;
      firstName: string;
      lastName: string;
      company_name: string;
      address: string;
      postal_code: string;
      city: string;
      country: string;
      phonenumber: string;
      activated: boolean;
      enabled: boolean;
      role: Roles;
      vehicles: Array<{
        __typename?: 'VehicleInformation';
        brand?: string | null;
        model?: string | null;
        year?: string | null;
        color?: string | null;
      }>;
    };
  };
};

export type EstimateGetQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type EstimateGetQuery = {
  __typename?: 'Query';
  estimateGet: {
    __typename?: 'Estimate';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    name: string;
    company_name: string;
    phonenumber: string;
    mail: string;
    subject: string;
    vehicle: string;
    description_of_project: string;
    your_mark: string;
    your_model: string;
    year_of_your_vehicle: string;
    marquetry: string;
    status: Estimates_Status;
    file?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
  };
};

export type EstimateListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<EstimatePaginationSortBy>;
  status?: InputMaybe<Scalars['String']>;
}>;

export type EstimateListQuery = {
  __typename?: 'Query';
  estimateList: {
    __typename?: 'EstimatePagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Estimate';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      name: string;
      company_name: string;
      phonenumber: string;
      mail: string;
      subject: string;
      status: Estimates_Status;
      vehicle: string;
      description_of_project: string;
      your_mark: string;
      your_model: string;
      year_of_your_vehicle: string;
      marquetry: string;
      file?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    }>;
  };
};

export type ReviewQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type ReviewQuery = {
  __typename?: 'Query';
  review: {
    __typename?: 'Review';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    text: string;
    note: number;
    display_on_home: boolean;
    display_on_product: boolean;
    products?: Array<{
      __typename?: 'Product';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      subtitle: string;
      type: ProductTypes;
      description: string;
      slug?: string | null;
      meta_title?: string | null;
      meta_description?: string | null;
      verification: boolean;
      graphic_service: boolean;
      professional_installer: boolean;
      enabled: boolean;
      index: number;
      start_price: number;
      image?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      supports?: Array<{
        __typename?: 'Support';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        can_have_background: boolean;
        extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
      sizes?: Array<{
        __typename?: 'Size';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        need_vehicle_info: boolean;
        price: number;
        index: number;
        installer_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url?: string | null;
        design_choice_possible: boolean;
        installation_difficulty: number;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
      sections?: Array<{
        __typename?: 'Section';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        can_have_background: boolean;
        extra_price: number;
        canvas_width: number;
        canvas_height: number;
        canvas_radius: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        extra_price_size: Array<{
          __typename?: 'ExtraSectionSizePrice';
          size_uuid: string;
          extra_price: number;
        }>;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        }> | null;
      }> | null;
      extra_stickers?: Array<{
        __typename?: 'ExtraSticker';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        help_title: string;
        help: string;
        design_choice_possible: boolean;
        extra_price: number;
        installer_extra_price: number;
        overview: string;
        video_url: string;
        installation_difficulty: number;
        extra_price_sections: Array<{
          __typename?: 'ExtraStickerSectionPrice';
          section_uuid: string;
          extra_price: number;
        }>;
        pictogram?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        depends_on_sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
        }> | null;
      }> | null;
      templates?: Array<{
        __typename?: 'Template';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        file?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
      product_images?: Array<{
        __typename?: 'ProductImage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        support_uuid?: string | null;
        size_uuid?: string | null;
        section_uuid?: string | null;
        extra_sticker_uuid?: string | null;
        file?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
      }> | null;
    }> | null;
    user?: {
      __typename?: 'User';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      email: string;
      firstName: string;
      lastName: string;
      company_name: string;
      address: string;
      postal_code: string;
      city: string;
      country: string;
      phonenumber: string;
      activated: boolean;
      enabled: boolean;
      role: Roles;
      vehicles: Array<{
        __typename?: 'VehicleInformation';
        brand?: string | null;
        model?: string | null;
        year?: string | null;
        color?: string | null;
      }>;
    } | null;
    order?: {
      __typename?: 'Order';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      order_code: string;
      already_add_review: boolean;
      sub_total: number;
      shipping_cost: number;
      total_without_vat: number;
      start_date_of_delivery?: any | null;
      end_date_of_delivery?: any | null;
      vat: number;
      total_all_vat: number;
      discount_amount: number;
      payment_method: Payment_Methods;
      ordering_status: Ordering_Status;
      delivery_mode: Delivery_Modes;
      has_quote: boolean;
      invoice?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      discount_code?: {
        __typename?: 'DiscountCode';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        code: string;
        value: number;
        use_before?: any | null;
        max_use_times: number;
        used_times: number;
        saved_money: number;
        total_buy_amount: number;
        is_percentage: boolean;
      } | null;
      stripe_payment_data?: {
        __typename?: 'StripePaymentData';
        status?: string | null;
        id?: string | null;
        payment_link?: string | null;
        balance_transaction?: string | null;
        payment_method?: string | null;
        receipt_url?: string | null;
        source?: {
          __typename?: 'StripePaymentSource';
          id?: string | null;
          brand?: string | null;
          country?: string | null;
          last4?: string | null;
          exp_month?: number | null;
          exp_year?: number | null;
        } | null;
      } | null;
      user?: {
        __typename?: 'User';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        email: string;
        firstName: string;
        lastName: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        activated: boolean;
        enabled: boolean;
        role: Roles;
      } | null;
      delivery_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      billing_address?: {
        __typename?: 'OrderAddress';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        firstname: string;
        lastname: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        email: string;
      } | null;
      order_products?: Array<{
        __typename?: 'OrderProduct';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        synthetic_design: boolean;
        custom_design: boolean;
        design_is_finished: boolean;
        quantity: number;
        total: number;
        squid_studio_data: {
          __typename?: 'SquidStudioData';
          width: number;
          height: number;
          radius: number;
          can_have_background: boolean;
          data?: any | null;
        };
        vehicle_information: {
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        };
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
        } | null;
        image_pdf?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        image_svg?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        product?: {
          __typename?: 'Product';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          type: ProductTypes;
          description: string;
          slug?: string | null;
          meta_title?: string | null;
          meta_description?: string | null;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          enabled: boolean;
          index: number;
          start_price: number;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
          sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
          sections?: Array<{
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
          templates?: Array<{
            __typename?: 'Template';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
          }> | null;
          product_images?: Array<{
            __typename?: 'ProductImage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            support_uuid?: string | null;
            size_uuid?: string | null;
            section_uuid?: string | null;
            extra_sticker_uuid?: string | null;
          }> | null;
        } | null;
        size?: {
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        } | null;
        support?: {
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        } | null;
        section?: {
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        } | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
        }> | null;
      }> | null;
    } | null;
  };
};

export type ReviewListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ReviewPaginationSortBy>;
  note?: InputMaybe<Scalars['Float']>;
  display_on_home?: InputMaybe<Scalars['Boolean']>;
  display_on_product?: InputMaybe<Scalars['Boolean']>;
}>;

export type ReviewListQuery = {
  __typename?: 'Query';
  reviewList: {
    __typename?: 'ReviewPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Review';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      text: string;
      note: number;
      display_on_home: boolean;
      display_on_product: boolean;
      products?: Array<{
        __typename?: 'Product';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        type: ProductTypes;
        description: string;
        slug?: string | null;
        meta_title?: string | null;
        meta_description?: string | null;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        enabled: boolean;
        index: number;
        start_price: number;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        }> | null;
        sections?: Array<{
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
          }> | null;
        }> | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_sections: Array<{
            __typename?: 'ExtraStickerSectionPrice';
            section_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        }> | null;
        templates?: Array<{
          __typename?: 'Template';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        product_images?: Array<{
          __typename?: 'ProductImage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          support_uuid?: string | null;
          size_uuid?: string | null;
          section_uuid?: string | null;
          extra_sticker_uuid?: string | null;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
      user?: {
        __typename?: 'User';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        email: string;
        firstName: string;
        lastName: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        activated: boolean;
        enabled: boolean;
        role: Roles;
        vehicles: Array<{
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        }>;
      } | null;
      order?: {
        __typename?: 'Order';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        order_code: string;
        already_add_review: boolean;
        sub_total: number;
        shipping_cost: number;
        total_without_vat: number;
        start_date_of_delivery?: any | null;
        end_date_of_delivery?: any | null;
        vat: number;
        total_all_vat: number;
        discount_amount: number;
        payment_method: Payment_Methods;
        ordering_status: Ordering_Status;
        delivery_mode: Delivery_Modes;
        has_quote: boolean;
        invoice?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        discount_code?: {
          __typename?: 'DiscountCode';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          code: string;
          value: number;
          use_before?: any | null;
          max_use_times: number;
          used_times: number;
          saved_money: number;
          total_buy_amount: number;
          is_percentage: boolean;
        } | null;
        stripe_payment_data?: {
          __typename?: 'StripePaymentData';
          status?: string | null;
          id?: string | null;
          payment_link?: string | null;
          balance_transaction?: string | null;
          payment_method?: string | null;
          receipt_url?: string | null;
          source?: {
            __typename?: 'StripePaymentSource';
            id?: string | null;
            brand?: string | null;
            country?: string | null;
            last4?: string | null;
            exp_month?: number | null;
            exp_year?: number | null;
          } | null;
        } | null;
        user?: {
          __typename?: 'User';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          email: string;
          firstName: string;
          lastName: string;
          company_name: string;
          address: string;
          postal_code: string;
          city: string;
          country: string;
          phonenumber: string;
          activated: boolean;
          enabled: boolean;
          role: Roles;
        } | null;
        delivery_address?: {
          __typename?: 'OrderAddress';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          firstname: string;
          lastname: string;
          company_name: string;
          address: string;
          postal_code: string;
          city: string;
          country: string;
          phonenumber: string;
          email: string;
        } | null;
        billing_address?: {
          __typename?: 'OrderAddress';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          firstname: string;
          lastname: string;
          company_name: string;
          address: string;
          postal_code: string;
          city: string;
          country: string;
          phonenumber: string;
          email: string;
        } | null;
        order_products?: Array<{
          __typename?: 'OrderProduct';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          synthetic_design: boolean;
          custom_design: boolean;
          design_is_finished: boolean;
          quantity: number;
          total: number;
          squid_studio_data: {
            __typename?: 'SquidStudioData';
            width: number;
            height: number;
            radius: number;
            can_have_background: boolean;
            data?: any | null;
          };
          vehicle_information: {
            __typename?: 'VehicleInformation';
            brand?: string | null;
            model?: string | null;
            year?: string | null;
            color?: string | null;
          };
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image_pdf?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          image_svg?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          product?: {
            __typename?: 'Product';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            type: ProductTypes;
            description: string;
            slug?: string | null;
            meta_title?: string | null;
            meta_description?: string | null;
            verification: boolean;
            graphic_service: boolean;
            professional_installer: boolean;
            enabled: boolean;
            index: number;
            start_price: number;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
            sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
            sections?: Array<{
              __typename?: 'Section';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              can_have_background: boolean;
              extra_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
            extra_stickers?: Array<{
              __typename?: 'ExtraSticker';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              design_choice_possible: boolean;
              extra_price: number;
              installer_extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
            templates?: Array<{
              __typename?: 'Template';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
            }> | null;
            product_images?: Array<{
              __typename?: 'ProductImage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              support_uuid?: string | null;
              size_uuid?: string | null;
              section_uuid?: string | null;
              extra_sticker_uuid?: string | null;
            }> | null;
          } | null;
          size?: {
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
          } | null;
          support?: {
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          } | null;
          section?: {
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_size: Array<{
              __typename?: 'ExtraSectionSizePrice';
              size_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
          } | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        }> | null;
      } | null;
    }>;
  };
};

export type ReviewListByOrderQueryVariables = Exact<{
  orderUuid: Scalars['String'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<ReviewPaginationSortBy>;
  note?: InputMaybe<Scalars['Float']>;
  display_on_home?: InputMaybe<Scalars['Boolean']>;
  display_on_product?: InputMaybe<Scalars['Boolean']>;
}>;

export type ReviewListByOrderQuery = {
  __typename?: 'Query';
  reviewListByOrder: {
    __typename?: 'ReviewPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Review';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      text: string;
      note: number;
      display_on_home: boolean;
      display_on_product: boolean;
      products?: Array<{
        __typename?: 'Product';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        title: string;
        subtitle: string;
        type: ProductTypes;
        description: string;
        slug?: string | null;
        meta_title?: string | null;
        meta_description?: string | null;
        verification: boolean;
        graphic_service: boolean;
        professional_installer: boolean;
        enabled: boolean;
        index: number;
        start_price: number;
        image?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        supports?: Array<{
          __typename?: 'Support';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          can_have_background: boolean;
          extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        sizes?: Array<{
          __typename?: 'Size';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          need_vehicle_info: boolean;
          price: number;
          index: number;
          installer_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url?: string | null;
          design_choice_possible: boolean;
          installation_difficulty: number;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_supports?: Array<{
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          }> | null;
        }> | null;
        sections?: Array<{
          __typename?: 'Section';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          can_have_background: boolean;
          extra_price: number;
          canvas_width: number;
          canvas_height: number;
          canvas_radius: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_size: Array<{
            __typename?: 'ExtraSectionSizePrice';
            size_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
          }> | null;
        }> | null;
        extra_stickers?: Array<{
          __typename?: 'ExtraSticker';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          subtitle: string;
          help_title: string;
          help: string;
          design_choice_possible: boolean;
          extra_price: number;
          installer_extra_price: number;
          overview: string;
          video_url: string;
          installation_difficulty: number;
          extra_price_sections: Array<{
            __typename?: 'ExtraStickerSectionPrice';
            section_uuid: string;
            extra_price: number;
          }>;
          pictogram?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          depends_on_sizes?: Array<{
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
          }> | null;
        }> | null;
        templates?: Array<{
          __typename?: 'Template';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          title: string;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
        }> | null;
        product_images?: Array<{
          __typename?: 'ProductImage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          support_uuid?: string | null;
          size_uuid?: string | null;
          section_uuid?: string | null;
          extra_sticker_uuid?: string | null;
          file?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
        }> | null;
      }> | null;
      user?: {
        __typename?: 'User';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        email: string;
        firstName: string;
        lastName: string;
        company_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
        phonenumber: string;
        activated: boolean;
        enabled: boolean;
        role: Roles;
        vehicles: Array<{
          __typename?: 'VehicleInformation';
          brand?: string | null;
          model?: string | null;
          year?: string | null;
          color?: string | null;
        }>;
      } | null;
      order?: {
        __typename?: 'Order';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        order_code: string;
        already_add_review: boolean;
        sub_total: number;
        shipping_cost: number;
        total_without_vat: number;
        start_date_of_delivery?: any | null;
        end_date_of_delivery?: any | null;
        vat: number;
        total_all_vat: number;
        discount_amount: number;
        payment_method: Payment_Methods;
        ordering_status: Ordering_Status;
        delivery_mode: Delivery_Modes;
        has_quote: boolean;
        invoice?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
        discount_code?: {
          __typename?: 'DiscountCode';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          code: string;
          value: number;
          use_before?: any | null;
          max_use_times: number;
          used_times: number;
          saved_money: number;
          total_buy_amount: number;
          is_percentage: boolean;
        } | null;
        stripe_payment_data?: {
          __typename?: 'StripePaymentData';
          status?: string | null;
          id?: string | null;
          payment_link?: string | null;
          balance_transaction?: string | null;
          payment_method?: string | null;
          receipt_url?: string | null;
          source?: {
            __typename?: 'StripePaymentSource';
            id?: string | null;
            brand?: string | null;
            country?: string | null;
            last4?: string | null;
            exp_month?: number | null;
            exp_year?: number | null;
          } | null;
        } | null;
        user?: {
          __typename?: 'User';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          email: string;
          firstName: string;
          lastName: string;
          company_name: string;
          address: string;
          postal_code: string;
          city: string;
          country: string;
          phonenumber: string;
          activated: boolean;
          enabled: boolean;
          role: Roles;
        } | null;
        delivery_address?: {
          __typename?: 'OrderAddress';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          firstname: string;
          lastname: string;
          company_name: string;
          address: string;
          postal_code: string;
          city: string;
          country: string;
          phonenumber: string;
          email: string;
        } | null;
        billing_address?: {
          __typename?: 'OrderAddress';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          firstname: string;
          lastname: string;
          company_name: string;
          address: string;
          postal_code: string;
          city: string;
          country: string;
          phonenumber: string;
          email: string;
        } | null;
        order_products?: Array<{
          __typename?: 'OrderProduct';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          verification: boolean;
          graphic_service: boolean;
          professional_installer: boolean;
          synthetic_design: boolean;
          custom_design: boolean;
          design_is_finished: boolean;
          quantity: number;
          total: number;
          squid_studio_data: {
            __typename?: 'SquidStudioData';
            width: number;
            height: number;
            radius: number;
            can_have_background: boolean;
            data?: any | null;
          };
          vehicle_information: {
            __typename?: 'VehicleInformation';
            brand?: string | null;
            model?: string | null;
            year?: string | null;
            color?: string | null;
          };
          image?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
          } | null;
          image_pdf?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          image_svg?: {
            __typename?: 'Storage';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            file_name: string;
            url: string;
            mime_type: string;
            width: number;
            height: number;
            size: number;
            caption: string;
            description: string;
            alternative_title: string;
            thumbnail: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
            medium: {
              __typename?: 'StorageThumbnail';
              width: number;
              height: number;
              size: number;
              url: string;
              file_name: string;
            };
          } | null;
          product?: {
            __typename?: 'Product';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            type: ProductTypes;
            description: string;
            slug?: string | null;
            meta_title?: string | null;
            meta_description?: string | null;
            verification: boolean;
            graphic_service: boolean;
            professional_installer: boolean;
            enabled: boolean;
            index: number;
            start_price: number;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
            sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
            sections?: Array<{
              __typename?: 'Section';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              can_have_background: boolean;
              extra_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
            extra_stickers?: Array<{
              __typename?: 'ExtraSticker';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              design_choice_possible: boolean;
              extra_price: number;
              installer_extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
            templates?: Array<{
              __typename?: 'Template';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
            }> | null;
            product_images?: Array<{
              __typename?: 'ProductImage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              support_uuid?: string | null;
              size_uuid?: string | null;
              section_uuid?: string | null;
              extra_sticker_uuid?: string | null;
            }> | null;
          } | null;
          size?: {
            __typename?: 'Size';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            need_vehicle_info: boolean;
            price: number;
            index: number;
            installer_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url?: string | null;
            design_choice_possible: boolean;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            image?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_supports?: Array<{
              __typename?: 'Support';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              can_have_background: boolean;
              extra_price: number;
              overview: string;
              video_url: string;
              installation_difficulty: number;
            }> | null;
          } | null;
          support?: {
            __typename?: 'Support';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            can_have_background: boolean;
            extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
          } | null;
          section?: {
            __typename?: 'Section';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            can_have_background: boolean;
            extra_price: number;
            canvas_width: number;
            canvas_height: number;
            canvas_radius: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
            extra_price_size: Array<{
              __typename?: 'ExtraSectionSizePrice';
              size_uuid: string;
              extra_price: number;
            }>;
            pictogram?: {
              __typename?: 'Storage';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              file_name: string;
              url: string;
              mime_type: string;
              width: number;
              height: number;
              size: number;
              caption: string;
              description: string;
              alternative_title: string;
            } | null;
            depends_on_sizes?: Array<{
              __typename?: 'Size';
              uuid: string;
              createdAt: any;
              updatedAt: any;
              title: string;
              subtitle: string;
              help_title: string;
              help: string;
              need_vehicle_info: boolean;
              price: number;
              index: number;
              installer_price: number;
              canvas_width: number;
              canvas_height: number;
              canvas_radius: number;
              overview: string;
              video_url?: string | null;
              design_choice_possible: boolean;
              installation_difficulty: number;
            }> | null;
          } | null;
          extra_stickers?: Array<{
            __typename?: 'ExtraSticker';
            uuid: string;
            createdAt: any;
            updatedAt: any;
            title: string;
            subtitle: string;
            help_title: string;
            help: string;
            design_choice_possible: boolean;
            extra_price: number;
            installer_extra_price: number;
            overview: string;
            video_url: string;
            installation_difficulty: number;
          }> | null;
        }> | null;
      } | null;
    }>;
  };
};

export type FindAllSquidstudioShapeQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type FindAllSquidstudioShapeQuery = {
  __typename?: 'Query';
  findAllSquidstudioShape: Array<{
    __typename?: 'SquidstudioShape';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    clipPath: string;
    width: string;
    height: string;
    desc: string;
    background: string;
    img?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
  }>;
};

export type SquidstudioShapeCategoriesListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SquidstudioShapeCategoryPaginationSortBy>;
}>;

export type SquidstudioShapeCategoriesListQuery = {
  __typename?: 'Query';
  squidstudioShapeCategoriesList: {
    __typename?: 'SquidstudioShapeCategoryPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'SquidstudioShapeCategory';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      icon?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      shapes?: Array<{
        __typename?: 'SquidstudioShape';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        clipPath: string;
        width: string;
        height: string;
        desc: string;
        background: string;
        img?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
    }>;
  };
};

export type SquidstudioShapeCategoriesListUnAuthentifiedQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SquidstudioShapeCategoryPaginationSortBy>;
}>;

export type SquidstudioShapeCategoriesListUnAuthentifiedQuery = {
  __typename?: 'Query';
  squidstudioShapeCategoriesListUnAuthentified: {
    __typename?: 'SquidstudioShapeCategoryPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'SquidstudioShapeCategory';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      title: string;
      icon?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
      shapes?: Array<{
        __typename?: 'SquidstudioShape';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        clipPath: string;
        width: string;
        height: string;
        desc: string;
        background: string;
        img?: {
          __typename?: 'Storage';
          uuid: string;
          createdAt: any;
          updatedAt: any;
          file_name: string;
          url: string;
          mime_type: string;
          width: number;
          height: number;
          size: number;
          caption: string;
          description: string;
          alternative_title: string;
          thumbnail: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
          medium: {
            __typename?: 'StorageThumbnail';
            width: number;
            height: number;
            size: number;
            url: string;
            file_name: string;
          };
        } | null;
      }> | null;
    }>;
  };
};

export type SquidstudioShapeCategoryGetQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type SquidstudioShapeCategoryGetQuery = {
  __typename?: 'Query';
  squidstudioShapeCategoryGet: {
    __typename?: 'SquidstudioShapeCategory';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    icon?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    shapes?: Array<{
      __typename?: 'SquidstudioShape';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      clipPath: string;
      width: string;
      height: string;
      desc: string;
      background: string;
      img?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    }> | null;
  };
};

export type SquidstudioShapeCategoryGetUnAuthentifiedQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type SquidstudioShapeCategoryGetUnAuthentifiedQuery = {
  __typename?: 'Query';
  squidstudioShapeCategoryGetUnAuthentified: {
    __typename?: 'SquidstudioShapeCategory';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    title: string;
    icon?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
    shapes?: Array<{
      __typename?: 'SquidstudioShape';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      clipPath: string;
      width: string;
      height: string;
      desc: string;
      background: string;
      img?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    }> | null;
  };
};

export type SquidstudioShapeGetQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type SquidstudioShapeGetQuery = {
  __typename?: 'Query';
  squidstudioShapeGet: {
    __typename?: 'SquidstudioShape';
    uuid: string;
    createdAt: any;
    updatedAt: any;
    clipPath: string;
    width: string;
    height: string;
    desc: string;
    background: string;
    img?: {
      __typename?: 'Storage';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      file_name: string;
      url: string;
      mime_type: string;
      width: number;
      height: number;
      size: number;
      caption: string;
      description: string;
      alternative_title: string;
      thumbnail: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
      medium: {
        __typename?: 'StorageThumbnail';
        width: number;
        height: number;
        size: number;
        url: string;
        file_name: string;
      };
    } | null;
  };
};

export type SquidstudioTemplatesListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SquidstudioTemplatePaginationSortBy>;
}>;

export type SquidstudioTemplatesListQuery = {
  __typename?: 'Query';
  squidstudioTemplatesList: {
    __typename?: 'SquidstudioTemplatePagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'SquidstudioTemplate';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      data: string;
      desc: string;
      sizes: Array<{
        __typename?: 'SquidstudioTemplateSizeInput';
        width: number;
        height: number;
      }>;
      img?: {
        __typename?: 'Storage';
        uuid: string;
        createdAt: any;
        updatedAt: any;
        file_name: string;
        url: string;
        mime_type: string;
        width: number;
        height: number;
        size: number;
        caption: string;
        description: string;
        alternative_title: string;
        thumbnail: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
        medium: {
          __typename?: 'StorageThumbnail';
          width: number;
          height: number;
          size: number;
          url: string;
          file_name: string;
        };
      } | null;
    }>;
  };
};

export type UsersListQueryVariables = Exact<{
  skip: Scalars['Int'];
  take: Scalars['Int'];
  keyword?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<UsersPaginationSortBy>;
}>;

export type UsersListQuery = {
  __typename?: 'Query';
  usersList: {
    __typename?: 'UsersPagination';
    totalCount: number;
    nodes: Array<{
      __typename?: 'User';
      uuid: string;
      createdAt: any;
      updatedAt: any;
      email: string;
      firstName: string;
      lastName: string;
      activated: boolean;
      enabled: boolean;
      role: Roles;
    }>;
  };
};

export const AuthLoginAdminDocument = gql`
  mutation authLoginAdmin($username: String!, $password: String!) {
    authLoginAdmin(username: $username, password: $password) {
      accessToken
      user {
        uuid
        email
        firstName
        lastName
        activated
        enabled
        role
      }
    }
  }
`;
export type AuthLoginAdminMutationFn = Apollo.MutationFunction<
  AuthLoginAdminMutation,
  AuthLoginAdminMutationVariables
>;

/**
 * __useAuthLoginAdminMutation__
 *
 * To run a mutation, you first call `useAuthLoginAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthLoginAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authLoginAdminMutation, { data, loading, error }] = useAuthLoginAdminMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthLoginAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthLoginAdminMutation,
    AuthLoginAdminMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthLoginAdminMutation,
    AuthLoginAdminMutationVariables
  >(AuthLoginAdminDocument, options);
}
export type AuthLoginAdminMutationHookResult = ReturnType<
  typeof useAuthLoginAdminMutation
>;
export type AuthLoginAdminMutationResult =
  Apollo.MutationResult<AuthLoginAdminMutation>;
export type AuthLoginAdminMutationOptions = Apollo.BaseMutationOptions<
  AuthLoginAdminMutation,
  AuthLoginAdminMutationVariables
>;
export const ResetPasswordInitDocument = gql`
  mutation resetPasswordInit($input: ResetPasswordInput!) {
    resetPasswordInit(input: $input) {
      message
    }
  }
`;
export type ResetPasswordInitMutationFn = Apollo.MutationFunction<
  ResetPasswordInitMutation,
  ResetPasswordInitMutationVariables
>;

/**
 * __useResetPasswordInitMutation__
 *
 * To run a mutation, you first call `useResetPasswordInitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordInitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordInitMutation, { data, loading, error }] = useResetPasswordInitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordInitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordInitMutation,
    ResetPasswordInitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordInitMutation,
    ResetPasswordInitMutationVariables
  >(ResetPasswordInitDocument, options);
}
export type ResetPasswordInitMutationHookResult = ReturnType<
  typeof useResetPasswordInitMutation
>;
export type ResetPasswordInitMutationResult =
  Apollo.MutationResult<ResetPasswordInitMutation>;
export type ResetPasswordInitMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordInitMutation,
  ResetPasswordInitMutationVariables
>;
export const ArticleCategoryCreateDocument = gql`
  mutation articleCategoryCreate($input: CreateArticleCategoryInput!) {
    articleCategoryCreate(input: $input) {
      category {
        uuid
        createdAt
        updatedAt
        category
        articles {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          duration
          text
          enabled
          slug
          meta_title
          meta_description
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          article_category {
            uuid
            createdAt
            updatedAt
            category
            articles {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              duration
              text
              enabled
              slug
              meta_title
              meta_description
            }
          }
        }
      }
    }
  }
`;
export type ArticleCategoryCreateMutationFn = Apollo.MutationFunction<
  ArticleCategoryCreateMutation,
  ArticleCategoryCreateMutationVariables
>;

/**
 * __useArticleCategoryCreateMutation__
 *
 * To run a mutation, you first call `useArticleCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleCategoryCreateMutation, { data, loading, error }] = useArticleCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleCategoryCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArticleCategoryCreateMutation,
    ArticleCategoryCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArticleCategoryCreateMutation,
    ArticleCategoryCreateMutationVariables
  >(ArticleCategoryCreateDocument, options);
}
export type ArticleCategoryCreateMutationHookResult = ReturnType<
  typeof useArticleCategoryCreateMutation
>;
export type ArticleCategoryCreateMutationResult =
  Apollo.MutationResult<ArticleCategoryCreateMutation>;
export type ArticleCategoryCreateMutationOptions = Apollo.BaseMutationOptions<
  ArticleCategoryCreateMutation,
  ArticleCategoryCreateMutationVariables
>;
export const ArticleCategoryDeleteDocument = gql`
  mutation articleCategoryDelete($uuid: ID!) {
    articleCategoryDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type ArticleCategoryDeleteMutationFn = Apollo.MutationFunction<
  ArticleCategoryDeleteMutation,
  ArticleCategoryDeleteMutationVariables
>;

/**
 * __useArticleCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useArticleCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleCategoryDeleteMutation, { data, loading, error }] = useArticleCategoryDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useArticleCategoryDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArticleCategoryDeleteMutation,
    ArticleCategoryDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArticleCategoryDeleteMutation,
    ArticleCategoryDeleteMutationVariables
  >(ArticleCategoryDeleteDocument, options);
}
export type ArticleCategoryDeleteMutationHookResult = ReturnType<
  typeof useArticleCategoryDeleteMutation
>;
export type ArticleCategoryDeleteMutationResult =
  Apollo.MutationResult<ArticleCategoryDeleteMutation>;
export type ArticleCategoryDeleteMutationOptions = Apollo.BaseMutationOptions<
  ArticleCategoryDeleteMutation,
  ArticleCategoryDeleteMutationVariables
>;
export const ArticleCategoryUpdateDocument = gql`
  mutation articleCategoryUpdate(
    $uuid: ID!
    $input: UpdateArticleCategoryInput!
  ) {
    articleCategoryUpdate(uuid: $uuid, input: $input) {
      category {
        uuid
        createdAt
        updatedAt
        category
        articles {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          duration
          text
          enabled
          slug
          meta_title
          meta_description
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          article_category {
            uuid
            createdAt
            updatedAt
            category
            articles {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              duration
              text
              enabled
              slug
              meta_title
              meta_description
            }
          }
        }
      }
    }
  }
`;
export type ArticleCategoryUpdateMutationFn = Apollo.MutationFunction<
  ArticleCategoryUpdateMutation,
  ArticleCategoryUpdateMutationVariables
>;

/**
 * __useArticleCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useArticleCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleCategoryUpdateMutation, { data, loading, error }] = useArticleCategoryUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleCategoryUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArticleCategoryUpdateMutation,
    ArticleCategoryUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArticleCategoryUpdateMutation,
    ArticleCategoryUpdateMutationVariables
  >(ArticleCategoryUpdateDocument, options);
}
export type ArticleCategoryUpdateMutationHookResult = ReturnType<
  typeof useArticleCategoryUpdateMutation
>;
export type ArticleCategoryUpdateMutationResult =
  Apollo.MutationResult<ArticleCategoryUpdateMutation>;
export type ArticleCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<
  ArticleCategoryUpdateMutation,
  ArticleCategoryUpdateMutationVariables
>;
export const ArticleCreateDocument = gql`
  mutation articleCreate($input: CreateArticleInput!) {
    articleCreate(input: $input) {
      article {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        duration
        text
        enabled
        slug
        meta_title
        meta_description
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        article_category {
          uuid
          createdAt
          updatedAt
          category
          articles {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            duration
            text
            enabled
            slug
            meta_title
            meta_description
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            article_category {
              uuid
              createdAt
              updatedAt
              category
            }
          }
        }
      }
    }
  }
`;
export type ArticleCreateMutationFn = Apollo.MutationFunction<
  ArticleCreateMutation,
  ArticleCreateMutationVariables
>;

/**
 * __useArticleCreateMutation__
 *
 * To run a mutation, you first call `useArticleCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleCreateMutation, { data, loading, error }] = useArticleCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArticleCreateMutation,
    ArticleCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArticleCreateMutation,
    ArticleCreateMutationVariables
  >(ArticleCreateDocument, options);
}
export type ArticleCreateMutationHookResult = ReturnType<
  typeof useArticleCreateMutation
>;
export type ArticleCreateMutationResult =
  Apollo.MutationResult<ArticleCreateMutation>;
export type ArticleCreateMutationOptions = Apollo.BaseMutationOptions<
  ArticleCreateMutation,
  ArticleCreateMutationVariables
>;
export const ArticleDeleteDocument = gql`
  mutation articleDelete($uuid: ID!) {
    articleDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type ArticleDeleteMutationFn = Apollo.MutationFunction<
  ArticleDeleteMutation,
  ArticleDeleteMutationVariables
>;

/**
 * __useArticleDeleteMutation__
 *
 * To run a mutation, you first call `useArticleDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleDeleteMutation, { data, loading, error }] = useArticleDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useArticleDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArticleDeleteMutation,
    ArticleDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArticleDeleteMutation,
    ArticleDeleteMutationVariables
  >(ArticleDeleteDocument, options);
}
export type ArticleDeleteMutationHookResult = ReturnType<
  typeof useArticleDeleteMutation
>;
export type ArticleDeleteMutationResult =
  Apollo.MutationResult<ArticleDeleteMutation>;
export type ArticleDeleteMutationOptions = Apollo.BaseMutationOptions<
  ArticleDeleteMutation,
  ArticleDeleteMutationVariables
>;
export const ArticleUpdateDocument = gql`
  mutation articleUpdate($uuid: ID!, $input: UpdateArticleInput!) {
    articleUpdate(uuid: $uuid, input: $input) {
      article {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        duration
        text
        enabled
        slug
        meta_title
        meta_description
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        article_category {
          uuid
          createdAt
          updatedAt
          category
          articles {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            duration
            text
            enabled
            slug
            meta_title
            meta_description
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            article_category {
              uuid
              createdAt
              updatedAt
              category
            }
          }
        }
      }
    }
  }
`;
export type ArticleUpdateMutationFn = Apollo.MutationFunction<
  ArticleUpdateMutation,
  ArticleUpdateMutationVariables
>;

/**
 * __useArticleUpdateMutation__
 *
 * To run a mutation, you first call `useArticleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArticleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [articleUpdateMutation, { data, loading, error }] = useArticleUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArticleUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ArticleUpdateMutation,
    ArticleUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ArticleUpdateMutation,
    ArticleUpdateMutationVariables
  >(ArticleUpdateDocument, options);
}
export type ArticleUpdateMutationHookResult = ReturnType<
  typeof useArticleUpdateMutation
>;
export type ArticleUpdateMutationResult =
  Apollo.MutationResult<ArticleUpdateMutation>;
export type ArticleUpdateMutationOptions = Apollo.BaseMutationOptions<
  ArticleUpdateMutation,
  ArticleUpdateMutationVariables
>;
export const CartAddDiscountCodeDocument = gql`
  mutation cartAddDiscountCode($input: AddCouponCodeToCartInput!) {
    cartAddDiscountCode(input: $input) {
      cart {
        uuid
        createdAt
        updatedAt
        browser_uuid
        order_code
        sub_total
        shipping_cost
        total_without_vat
        vat
        total_all_vat
        delivery_mode
        discount_amount
        discount_code {
          uuid
          createdAt
          updatedAt
          code
          value
          use_before
          max_use_times
          used_times
          saved_money
          total_buy_amount
          is_percentage
        }
        delivery_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          tva_number
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        billing_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          tva_number
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        cart_products {
          uuid
          createdAt
          updatedAt
          note
          verification
          graphic_service
          professional_installer
          synthetic_design
          custom_design
          design_is_finished
          quantity
          total
          squid_studio_data {
            width
            height
            radius
            can_have_background
            data
          }
          vehicle_information {
            brand
            model
            year
            color
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_pdf {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_svg {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          product {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            type
            description
            slug
            meta_title
            meta_description
            verification
            graphic_service
            professional_installer
            enabled
            index
            start_price
            is_perfect_squid
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
              }
            }
            sections {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              extra_price_size {
                size_uuid
                extra_price
              }
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                depends_on_supports {
                  uuid
                  createdAt
                  updatedAt
                  title
                  can_have_background
                  extra_price
                  overview
                  video_url
                  installation_difficulty
                }
              }
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              extra_price_sections {
                section_uuid
                extra_price
              }
              installer_extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
            }
            templates {
              uuid
              createdAt
              updatedAt
              title
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            product_images {
              uuid
              createdAt
              updatedAt
              support_uuid
              size_uuid
              section_uuid
              extra_sticker_uuid
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          size {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
          }
          support {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
          section {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            installer_extra_price
            overview
            video_url
            installation_difficulty
          }
          car_brand {
            uuid
            createdAt
            updatedAt
            brand_name
            info_title
            info_description
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            pictogram_dark {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            brand_models {
              uuid
              createdAt
              updatedAt
              model_name
              info_title
              info_description
              car_brand {
                uuid
                createdAt
                updatedAt
                brand_name
                info_title
                info_description
              }
              model_years {
                uuid
                createdAt
                updatedAt
                start_year
                end_year
                info_title
                info_description
                brand_model {
                  uuid
                  createdAt
                  updatedAt
                  model_name
                  info_title
                  info_description
                  car_brand {
                    uuid
                    createdAt
                    updatedAt
                    brand_name
                    info_title
                    info_description
                  }
                  model_years {
                    uuid
                    createdAt
                    updatedAt
                    start_year
                    end_year
                    info_title
                    info_description
                    car_versions {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      version_laterals {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                          thumbnail {
                            width
                            height
                            size
                            url
                            file_name
                          }
                          medium {
                            width
                            height
                            size
                            url
                            file_name
                          }
                        }
                        side_glazings {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            side_glazings {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              info_title
                              info_description
                              preview_text
                            }
                            cars_backwards {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              preview_text
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              preview_image {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              version_lateral {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                extra_price
                                installer_extra_price
                                installation_difficulty
                                info_title
                                info_description
                                preview_text
                                car_version {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  info_title
                                  info_description
                                  preview_text
                                  pictogram {
                                    uuid
                                    createdAt
                                    updatedAt
                                    file_name
                                    url
                                    mime_type
                                    width
                                    height
                                    size
                                    caption
                                    description
                                    alternative_title
                                  }
                                  version_laterals {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    info_title
                                    info_description
                                    preview_text
                                    cars_backwards {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      extra_price
                                      installer_extra_price
                                      installation_difficulty
                                      preview_text
                                      car_backward_types {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        has_rear_camera
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        car_backward {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          preview_text
                                          pictogram {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          preview_image {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          version_lateral {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            extra_price
                                            installer_extra_price
                                            installation_difficulty
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                          car_backward_types {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            has_rear_camera
                                          }
                                        }
                                      }
                                    }
                                    car_version {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        brand_model {
                                          uuid
                                          createdAt
                                          updatedAt
                                          model_name
                                          info_title
                                          info_description
                                        }
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                          model_year {
                                            uuid
                                            createdAt
                                            updatedAt
                                            start_year
                                            end_year
                                            info_title
                                            info_description
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          brand_model {
            uuid
            createdAt
            updatedAt
            model_name
            info_title
            info_description
          }
          model_year {
            uuid
            createdAt
            updatedAt
            start_year
            end_year
            info_title
            info_description
          }
          car_version {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            info_title
            info_description
            preview_text
          }
          version_lateral {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            installation_difficulty
            info_title
            info_description
            preview_text
          }
          side_glazing {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
            }
          }
          car_backwards {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            info_title
            info_description
            extra_price
            installer_extra_price
            installation_difficulty
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
            }
            car_backward_types {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              info_title
              info_description
              has_rear_camera
            }
          }
          car_backward_type {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            info_title
            info_description
            has_rear_camera
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            car_backward {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              info_title
              info_description
              extra_price
              installer_extra_price
              installation_difficulty
              preview_text
            }
          }
        }
      }
      cart_product_uuid
    }
  }
`;
export type CartAddDiscountCodeMutationFn = Apollo.MutationFunction<
  CartAddDiscountCodeMutation,
  CartAddDiscountCodeMutationVariables
>;

/**
 * __useCartAddDiscountCodeMutation__
 *
 * To run a mutation, you first call `useCartAddDiscountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartAddDiscountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartAddDiscountCodeMutation, { data, loading, error }] = useCartAddDiscountCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCartAddDiscountCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CartAddDiscountCodeMutation,
    CartAddDiscountCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CartAddDiscountCodeMutation,
    CartAddDiscountCodeMutationVariables
  >(CartAddDiscountCodeDocument, options);
}
export type CartAddDiscountCodeMutationHookResult = ReturnType<
  typeof useCartAddDiscountCodeMutation
>;
export type CartAddDiscountCodeMutationResult =
  Apollo.MutationResult<CartAddDiscountCodeMutation>;
export type CartAddDiscountCodeMutationOptions = Apollo.BaseMutationOptions<
  CartAddDiscountCodeMutation,
  CartAddDiscountCodeMutationVariables
>;
export const CartProductCreateDocument = gql`
  mutation cartProductCreate($input: CreateCartProductInput!) {
    cartProductCreate(input: $input) {
      cart {
        uuid
        createdAt
        updatedAt
        browser_uuid
        order_code
        sub_total
        shipping_cost
        total_without_vat
        vat
        total_all_vat
        delivery_mode
        discount_amount
        discount_code {
          uuid
          createdAt
          updatedAt
          code
          value
          use_before
          max_use_times
          used_times
          saved_money
          total_buy_amount
          is_percentage
        }
        delivery_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          tva_number
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        billing_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          tva_number
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        cart_products {
          uuid
          createdAt
          updatedAt
          note
          verification
          graphic_service
          professional_installer
          synthetic_design
          custom_design
          design_is_finished
          quantity
          total
          squid_studio_data {
            width
            height
            radius
            can_have_background
            data
          }
          vehicle_information {
            brand
            model
            year
            color
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_pdf {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_svg {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          product {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            type
            description
            slug
            meta_title
            meta_description
            verification
            graphic_service
            professional_installer
            enabled
            index
            start_price
            is_perfect_squid
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
              }
            }
            sections {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              extra_price_size {
                size_uuid
                extra_price
              }
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                depends_on_supports {
                  uuid
                  createdAt
                  updatedAt
                  title
                  can_have_background
                  extra_price
                  overview
                  video_url
                  installation_difficulty
                }
              }
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              extra_price_sections {
                section_uuid
                extra_price
              }
              installer_extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
            }
            templates {
              uuid
              createdAt
              updatedAt
              title
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            product_images {
              uuid
              createdAt
              updatedAt
              support_uuid
              size_uuid
              section_uuid
              extra_sticker_uuid
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          size {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
          }
          support {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
          section {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            installer_extra_price
            overview
            video_url
            installation_difficulty
          }
          car_brand {
            uuid
            createdAt
            updatedAt
            brand_name
            info_title
            info_description
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            pictogram_dark {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            brand_models {
              uuid
              createdAt
              updatedAt
              model_name
              info_title
              info_description
              car_brand {
                uuid
                createdAt
                updatedAt
                brand_name
                info_title
                info_description
              }
              model_years {
                uuid
                createdAt
                updatedAt
                start_year
                end_year
                info_title
                info_description
                brand_model {
                  uuid
                  createdAt
                  updatedAt
                  model_name
                  info_title
                  info_description
                  car_brand {
                    uuid
                    createdAt
                    updatedAt
                    brand_name
                    info_title
                    info_description
                  }
                  model_years {
                    uuid
                    createdAt
                    updatedAt
                    start_year
                    end_year
                    info_title
                    info_description
                    car_versions {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      version_laterals {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                          thumbnail {
                            width
                            height
                            size
                            url
                            file_name
                          }
                          medium {
                            width
                            height
                            size
                            url
                            file_name
                          }
                        }
                        side_glazings {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            side_glazings {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              info_title
                              info_description
                              preview_text
                            }
                            cars_backwards {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              preview_text
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              preview_image {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              version_lateral {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                extra_price
                                installer_extra_price
                                installation_difficulty
                                info_title
                                info_description
                                preview_text
                                car_version {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  info_title
                                  info_description
                                  preview_text
                                  pictogram {
                                    uuid
                                    createdAt
                                    updatedAt
                                    file_name
                                    url
                                    mime_type
                                    width
                                    height
                                    size
                                    caption
                                    description
                                    alternative_title
                                  }
                                  version_laterals {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    info_title
                                    info_description
                                    preview_text
                                    cars_backwards {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      extra_price
                                      installer_extra_price
                                      installation_difficulty
                                      preview_text
                                      car_backward_types {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        has_rear_camera
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        car_backward {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          preview_text
                                          pictogram {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          preview_image {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          version_lateral {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            extra_price
                                            installer_extra_price
                                            installation_difficulty
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                          car_backward_types {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            has_rear_camera
                                          }
                                        }
                                      }
                                    }
                                    car_version {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        brand_model {
                                          uuid
                                          createdAt
                                          updatedAt
                                          model_name
                                          info_title
                                          info_description
                                        }
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                          model_year {
                                            uuid
                                            createdAt
                                            updatedAt
                                            start_year
                                            end_year
                                            info_title
                                            info_description
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          brand_model {
            uuid
            createdAt
            updatedAt
            model_name
            info_title
            info_description
          }
          model_year {
            uuid
            createdAt
            updatedAt
            start_year
            end_year
            info_title
            info_description
          }
          car_version {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            info_title
            info_description
            preview_text
          }
          version_lateral {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            installation_difficulty
            info_title
            info_description
            preview_text
          }
          side_glazing {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
            }
          }
          car_backwards {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            info_title
            info_description
            extra_price
            installer_extra_price
            installation_difficulty
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
            }
            car_backward_types {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              info_title
              info_description
              has_rear_camera
            }
          }
          car_backward_type {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            info_title
            info_description
            has_rear_camera
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            car_backward {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              info_title
              info_description
              extra_price
              installer_extra_price
              installation_difficulty
              preview_text
            }
          }
        }
      }
      cart_product_uuid
    }
  }
`;
export type CartProductCreateMutationFn = Apollo.MutationFunction<
  CartProductCreateMutation,
  CartProductCreateMutationVariables
>;

/**
 * __useCartProductCreateMutation__
 *
 * To run a mutation, you first call `useCartProductCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartProductCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartProductCreateMutation, { data, loading, error }] = useCartProductCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCartProductCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CartProductCreateMutation,
    CartProductCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CartProductCreateMutation,
    CartProductCreateMutationVariables
  >(CartProductCreateDocument, options);
}
export type CartProductCreateMutationHookResult = ReturnType<
  typeof useCartProductCreateMutation
>;
export type CartProductCreateMutationResult =
  Apollo.MutationResult<CartProductCreateMutation>;
export type CartProductCreateMutationOptions = Apollo.BaseMutationOptions<
  CartProductCreateMutation,
  CartProductCreateMutationVariables
>;
export const CartProductCreateUnAuthentifiedDocument = gql`
  mutation cartProductCreateUnAuthentified($input: CreateCartProductInput!) {
    cartProductCreateUnAuthentified(input: $input) {
      cart {
        uuid
        createdAt
        updatedAt
        browser_uuid
        order_code
        sub_total
        shipping_cost
        total_without_vat
        vat
        total_all_vat
        delivery_mode
        discount_amount
        discount_code {
          uuid
          createdAt
          updatedAt
          code
          value
          use_before
          max_use_times
          used_times
          saved_money
          total_buy_amount
          is_percentage
        }
        delivery_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          tva_number
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        billing_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          tva_number
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        cart_products {
          uuid
          createdAt
          updatedAt
          note
          verification
          graphic_service
          professional_installer
          synthetic_design
          custom_design
          design_is_finished
          quantity
          total
          squid_studio_data {
            width
            height
            radius
            can_have_background
            data
          }
          vehicle_information {
            brand
            model
            year
            color
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_pdf {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_svg {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          product {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            type
            description
            slug
            meta_title
            meta_description
            verification
            graphic_service
            professional_installer
            enabled
            index
            start_price
            is_perfect_squid
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
              }
            }
            sections {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              extra_price_size {
                size_uuid
                extra_price
              }
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                depends_on_supports {
                  uuid
                  createdAt
                  updatedAt
                  title
                  can_have_background
                  extra_price
                  overview
                  video_url
                  installation_difficulty
                }
              }
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              extra_price_sections {
                section_uuid
                extra_price
              }
              installer_extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
            }
            templates {
              uuid
              createdAt
              updatedAt
              title
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            product_images {
              uuid
              createdAt
              updatedAt
              support_uuid
              size_uuid
              section_uuid
              extra_sticker_uuid
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          size {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
          }
          support {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
          section {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            installer_extra_price
            overview
            video_url
            installation_difficulty
          }
          car_brand {
            uuid
            createdAt
            updatedAt
            brand_name
            info_title
            info_description
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            pictogram_dark {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            brand_models {
              uuid
              createdAt
              updatedAt
              model_name
              info_title
              info_description
              car_brand {
                uuid
                createdAt
                updatedAt
                brand_name
                info_title
                info_description
              }
              model_years {
                uuid
                createdAt
                updatedAt
                start_year
                end_year
                info_title
                info_description
                brand_model {
                  uuid
                  createdAt
                  updatedAt
                  model_name
                  info_title
                  info_description
                  car_brand {
                    uuid
                    createdAt
                    updatedAt
                    brand_name
                    info_title
                    info_description
                  }
                  model_years {
                    uuid
                    createdAt
                    updatedAt
                    start_year
                    end_year
                    info_title
                    info_description
                    car_versions {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      version_laterals {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                          thumbnail {
                            width
                            height
                            size
                            url
                            file_name
                          }
                          medium {
                            width
                            height
                            size
                            url
                            file_name
                          }
                        }
                        side_glazings {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            side_glazings {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              info_title
                              info_description
                              preview_text
                            }
                            cars_backwards {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              preview_text
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              preview_image {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              version_lateral {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                extra_price
                                installer_extra_price
                                installation_difficulty
                                info_title
                                info_description
                                preview_text
                                car_version {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  info_title
                                  info_description
                                  preview_text
                                  pictogram {
                                    uuid
                                    createdAt
                                    updatedAt
                                    file_name
                                    url
                                    mime_type
                                    width
                                    height
                                    size
                                    caption
                                    description
                                    alternative_title
                                  }
                                  version_laterals {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    info_title
                                    info_description
                                    preview_text
                                    cars_backwards {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      extra_price
                                      installer_extra_price
                                      installation_difficulty
                                      preview_text
                                      car_backward_types {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        has_rear_camera
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        car_backward {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          preview_text
                                          pictogram {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          preview_image {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          version_lateral {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            extra_price
                                            installer_extra_price
                                            installation_difficulty
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                          car_backward_types {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            has_rear_camera
                                          }
                                        }
                                      }
                                    }
                                    car_version {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        brand_model {
                                          uuid
                                          createdAt
                                          updatedAt
                                          model_name
                                          info_title
                                          info_description
                                        }
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                          model_year {
                                            uuid
                                            createdAt
                                            updatedAt
                                            start_year
                                            end_year
                                            info_title
                                            info_description
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          brand_model {
            uuid
            createdAt
            updatedAt
            model_name
            info_title
            info_description
          }
          model_year {
            uuid
            createdAt
            updatedAt
            start_year
            end_year
            info_title
            info_description
          }
          car_version {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            info_title
            info_description
            preview_text
          }
          version_lateral {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            installation_difficulty
            info_title
            info_description
            preview_text
          }
          side_glazing {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
            }
          }
          car_backwards {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            info_title
            info_description
            extra_price
            installer_extra_price
            installation_difficulty
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
            }
            car_backward_types {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              info_title
              info_description
              has_rear_camera
            }
          }
          car_backward_type {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            info_title
            info_description
            has_rear_camera
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            car_backward {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              info_title
              info_description
              extra_price
              installer_extra_price
              installation_difficulty
              preview_text
            }
          }
        }
      }
      cart_product_uuid
    }
  }
`;
export type CartProductCreateUnAuthentifiedMutationFn = Apollo.MutationFunction<
  CartProductCreateUnAuthentifiedMutation,
  CartProductCreateUnAuthentifiedMutationVariables
>;

/**
 * __useCartProductCreateUnAuthentifiedMutation__
 *
 * To run a mutation, you first call `useCartProductCreateUnAuthentifiedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartProductCreateUnAuthentifiedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartProductCreateUnAuthentifiedMutation, { data, loading, error }] = useCartProductCreateUnAuthentifiedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCartProductCreateUnAuthentifiedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CartProductCreateUnAuthentifiedMutation,
    CartProductCreateUnAuthentifiedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CartProductCreateUnAuthentifiedMutation,
    CartProductCreateUnAuthentifiedMutationVariables
  >(CartProductCreateUnAuthentifiedDocument, options);
}
export type CartProductCreateUnAuthentifiedMutationHookResult = ReturnType<
  typeof useCartProductCreateUnAuthentifiedMutation
>;
export type CartProductCreateUnAuthentifiedMutationResult =
  Apollo.MutationResult<CartProductCreateUnAuthentifiedMutation>;
export type CartProductCreateUnAuthentifiedMutationOptions =
  Apollo.BaseMutationOptions<
    CartProductCreateUnAuthentifiedMutation,
    CartProductCreateUnAuthentifiedMutationVariables
  >;
export const CartProductUpdateDocument = gql`
  mutation cartProductUpdate($input: UpdateCartProductInput!) {
    cartProductUpdate(input: $input) {
      cart {
        uuid
        createdAt
        updatedAt
        browser_uuid
        order_code
        sub_total
        shipping_cost
        total_without_vat
        vat
        total_all_vat
        delivery_mode
        discount_amount
        discount_code {
          uuid
          createdAt
          updatedAt
          code
          value
          use_before
          max_use_times
          used_times
          saved_money
          total_buy_amount
          is_percentage
        }
        delivery_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        billing_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        cart_products {
          uuid
          createdAt
          updatedAt
          verification
          graphic_service
          professional_installer
          synthetic_design
          custom_design
          design_is_finished
          quantity
          total
          squid_studio_data {
            width
            height
            radius
            can_have_background
            data
          }
          vehicle_information {
            brand
            model
            year
            color
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_pdf {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_svg {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          product {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            type
            description
            slug
            meta_title
            meta_description
            verification
            graphic_service
            professional_installer
            enabled
            index
            start_price
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
              }
            }
            sections {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              extra_price_size {
                size_uuid
                extra_price
              }
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                depends_on_supports {
                  uuid
                  createdAt
                  updatedAt
                  title
                  can_have_background
                  extra_price
                  overview
                  video_url
                  installation_difficulty
                }
              }
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              extra_price_sections {
                section_uuid
                extra_price
              }
              installer_extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
            }
            templates {
              uuid
              createdAt
              updatedAt
              title
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            product_images {
              uuid
              createdAt
              updatedAt
              support_uuid
              size_uuid
              section_uuid
              extra_sticker_uuid
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          size {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
          }
          support {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
          section {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            installer_extra_price
            overview
            video_url
            installation_difficulty
          }
        }
      }
      cart_product_uuid
    }
  }
`;
export type CartProductUpdateMutationFn = Apollo.MutationFunction<
  CartProductUpdateMutation,
  CartProductUpdateMutationVariables
>;

/**
 * __useCartProductUpdateMutation__
 *
 * To run a mutation, you first call `useCartProductUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartProductUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartProductUpdateMutation, { data, loading, error }] = useCartProductUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCartProductUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CartProductUpdateMutation,
    CartProductUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CartProductUpdateMutation,
    CartProductUpdateMutationVariables
  >(CartProductUpdateDocument, options);
}
export type CartProductUpdateMutationHookResult = ReturnType<
  typeof useCartProductUpdateMutation
>;
export type CartProductUpdateMutationResult =
  Apollo.MutationResult<CartProductUpdateMutation>;
export type CartProductUpdateMutationOptions = Apollo.BaseMutationOptions<
  CartProductUpdateMutation,
  CartProductUpdateMutationVariables
>;
export const CartRemoveDiscountCodeDocument = gql`
  mutation cartRemoveDiscountCode($input: RemoveCouponCodeToCartInput!) {
    cartRemoveDiscountCode(input: $input) {
      cart {
        uuid
        createdAt
        updatedAt
        browser_uuid
        order_code
        sub_total
        shipping_cost
        total_without_vat
        vat
        total_all_vat
        delivery_mode
        discount_amount
        discount_code {
          uuid
          createdAt
          updatedAt
          code
          value
          use_before
          max_use_times
          used_times
          saved_money
          total_buy_amount
          is_percentage
        }
        delivery_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        billing_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        cart_products {
          uuid
          createdAt
          updatedAt
          verification
          graphic_service
          professional_installer
          synthetic_design
          custom_design
          design_is_finished
          quantity
          total
          squid_studio_data {
            width
            height
            radius
            can_have_background
            data
          }
          vehicle_information {
            brand
            model
            year
            color
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_pdf {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_svg {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          product {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            type
            description
            slug
            meta_title
            meta_description
            verification
            graphic_service
            professional_installer
            enabled
            index
            start_price
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
              }
            }
            sections {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              extra_price_size {
                size_uuid
                extra_price
              }
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                depends_on_supports {
                  uuid
                  createdAt
                  updatedAt
                  title
                  can_have_background
                  extra_price
                  overview
                  video_url
                  installation_difficulty
                }
              }
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              extra_price_sections {
                section_uuid
                extra_price
              }
              installer_extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
            }
            templates {
              uuid
              createdAt
              updatedAt
              title
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            product_images {
              uuid
              createdAt
              updatedAt
              support_uuid
              size_uuid
              section_uuid
              extra_sticker_uuid
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          size {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
          }
          support {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
          section {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            installer_extra_price
            overview
            video_url
            installation_difficulty
          }
        }
      }
      cart_product_uuid
    }
  }
`;
export type CartRemoveDiscountCodeMutationFn = Apollo.MutationFunction<
  CartRemoveDiscountCodeMutation,
  CartRemoveDiscountCodeMutationVariables
>;

/**
 * __useCartRemoveDiscountCodeMutation__
 *
 * To run a mutation, you first call `useCartRemoveDiscountCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartRemoveDiscountCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartRemoveDiscountCodeMutation, { data, loading, error }] = useCartRemoveDiscountCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCartRemoveDiscountCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CartRemoveDiscountCodeMutation,
    CartRemoveDiscountCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CartRemoveDiscountCodeMutation,
    CartRemoveDiscountCodeMutationVariables
  >(CartRemoveDiscountCodeDocument, options);
}
export type CartRemoveDiscountCodeMutationHookResult = ReturnType<
  typeof useCartRemoveDiscountCodeMutation
>;
export type CartRemoveDiscountCodeMutationResult =
  Apollo.MutationResult<CartRemoveDiscountCodeMutation>;
export type CartRemoveDiscountCodeMutationOptions = Apollo.BaseMutationOptions<
  CartRemoveDiscountCodeMutation,
  CartRemoveDiscountCodeMutationVariables
>;
export const CartUpdateDocument = gql`
  mutation cartUpdate($input: UpdateCartInput!) {
    cartUpdate(input: $input) {
      cart {
        uuid
        createdAt
        updatedAt
        browser_uuid
        order_code
        sub_total
        shipping_cost
        total_without_vat
        vat
        total_all_vat
        delivery_mode
        discount_amount
        discount_code {
          uuid
          createdAt
          updatedAt
          code
          value
          use_before
          max_use_times
          used_times
          saved_money
          total_buy_amount
          is_percentage
        }
        delivery_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        billing_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        cart_products {
          uuid
          createdAt
          updatedAt
          verification
          graphic_service
          professional_installer
          synthetic_design
          custom_design
          design_is_finished
          quantity
          total
          squid_studio_data {
            width
            height
            radius
            can_have_background
            data
          }
          vehicle_information {
            brand
            model
            year
            color
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_pdf {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_svg {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          product {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            type
            description
            slug
            meta_title
            meta_description
            verification
            graphic_service
            professional_installer
            enabled
            index
            start_price
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
              }
            }
            sections {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              extra_price_size {
                size_uuid
                extra_price
              }
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                depends_on_supports {
                  uuid
                  createdAt
                  updatedAt
                  title
                  can_have_background
                  extra_price
                  overview
                  video_url
                  installation_difficulty
                }
              }
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              extra_price_sections {
                section_uuid
                extra_price
              }
              installer_extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
            }
            templates {
              uuid
              createdAt
              updatedAt
              title
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            product_images {
              uuid
              createdAt
              updatedAt
              support_uuid
              size_uuid
              section_uuid
              extra_sticker_uuid
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          size {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
          }
          support {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
          section {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            installer_extra_price
            overview
            video_url
            installation_difficulty
          }
        }
      }
      cart_product_uuid
    }
  }
`;
export type CartUpdateMutationFn = Apollo.MutationFunction<
  CartUpdateMutation,
  CartUpdateMutationVariables
>;

/**
 * __useCartUpdateMutation__
 *
 * To run a mutation, you first call `useCartUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCartUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cartUpdateMutation, { data, loading, error }] = useCartUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCartUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CartUpdateMutation,
    CartUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CartUpdateMutation, CartUpdateMutationVariables>(
    CartUpdateDocument,
    options,
  );
}
export type CartUpdateMutationHookResult = ReturnType<
  typeof useCartUpdateMutation
>;
export type CartUpdateMutationResult =
  Apollo.MutationResult<CartUpdateMutation>;
export type CartUpdateMutationOptions = Apollo.BaseMutationOptions<
  CartUpdateMutation,
  CartUpdateMutationVariables
>;
export const ConfigsUpdateDocument = gql`
  mutation ConfigsUpdate($input: RegisterConfigsInput!) {
    ConfigsUpdate(input: $input) {
      configs {
        uuid
        createdAt
        updatedAt
        shipping_standard_cost {
          start_amount
          end_amount
          cost
        }
        shipping_standard_days_number
        shipping_express_cost_percentage
        shipping_express_days_number
        verification_cost
        symetrique_design_cost
        graphic_service_cost
        installer_price
        vat
        facebook_social_link
        instagram_social_link
        youtube_social_link
      }
    }
  }
`;
export type ConfigsUpdateMutationFn = Apollo.MutationFunction<
  ConfigsUpdateMutation,
  ConfigsUpdateMutationVariables
>;

/**
 * __useConfigsUpdateMutation__
 *
 * To run a mutation, you first call `useConfigsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configsUpdateMutation, { data, loading, error }] = useConfigsUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfigsUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfigsUpdateMutation,
    ConfigsUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfigsUpdateMutation,
    ConfigsUpdateMutationVariables
  >(ConfigsUpdateDocument, options);
}
export type ConfigsUpdateMutationHookResult = ReturnType<
  typeof useConfigsUpdateMutation
>;
export type ConfigsUpdateMutationResult =
  Apollo.MutationResult<ConfigsUpdateMutation>;
export type ConfigsUpdateMutationOptions = Apollo.BaseMutationOptions<
  ConfigsUpdateMutation,
  ConfigsUpdateMutationVariables
>;
export const DiscountCodeCreateDocument = gql`
  mutation discountCodeCreate($input: CreateDiscountCodeInput!) {
    discountCodeCreate(input: $input) {
      discount_code {
        uuid
        createdAt
        updatedAt
        code
        value
        use_before
        max_use_times
        used_times
        saved_money
        total_buy_amount
        is_percentage
      }
    }
  }
`;
export type DiscountCodeCreateMutationFn = Apollo.MutationFunction<
  DiscountCodeCreateMutation,
  DiscountCodeCreateMutationVariables
>;

/**
 * __useDiscountCodeCreateMutation__
 *
 * To run a mutation, you first call `useDiscountCodeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscountCodeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discountCodeCreateMutation, { data, loading, error }] = useDiscountCodeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiscountCodeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DiscountCodeCreateMutation,
    DiscountCodeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DiscountCodeCreateMutation,
    DiscountCodeCreateMutationVariables
  >(DiscountCodeCreateDocument, options);
}
export type DiscountCodeCreateMutationHookResult = ReturnType<
  typeof useDiscountCodeCreateMutation
>;
export type DiscountCodeCreateMutationResult =
  Apollo.MutationResult<DiscountCodeCreateMutation>;
export type DiscountCodeCreateMutationOptions = Apollo.BaseMutationOptions<
  DiscountCodeCreateMutation,
  DiscountCodeCreateMutationVariables
>;
export const DiscountCodeDeleteDocument = gql`
  mutation discountCodeDelete($uuid: ID!) {
    discountCodeDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type DiscountCodeDeleteMutationFn = Apollo.MutationFunction<
  DiscountCodeDeleteMutation,
  DiscountCodeDeleteMutationVariables
>;

/**
 * __useDiscountCodeDeleteMutation__
 *
 * To run a mutation, you first call `useDiscountCodeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscountCodeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discountCodeDeleteMutation, { data, loading, error }] = useDiscountCodeDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDiscountCodeDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DiscountCodeDeleteMutation,
    DiscountCodeDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DiscountCodeDeleteMutation,
    DiscountCodeDeleteMutationVariables
  >(DiscountCodeDeleteDocument, options);
}
export type DiscountCodeDeleteMutationHookResult = ReturnType<
  typeof useDiscountCodeDeleteMutation
>;
export type DiscountCodeDeleteMutationResult =
  Apollo.MutationResult<DiscountCodeDeleteMutation>;
export type DiscountCodeDeleteMutationOptions = Apollo.BaseMutationOptions<
  DiscountCodeDeleteMutation,
  DiscountCodeDeleteMutationVariables
>;
export const DiscountCodeUpdateDocument = gql`
  mutation discountCodeUpdate($uuid: ID!, $input: DiscountCodeUpdateInput!) {
    discountCodeUpdate(uuid: $uuid, input: $input) {
      discount_code {
        uuid
        createdAt
        updatedAt
        code
        value
        use_before
        max_use_times
        used_times
        saved_money
        total_buy_amount
        is_percentage
      }
    }
  }
`;
export type DiscountCodeUpdateMutationFn = Apollo.MutationFunction<
  DiscountCodeUpdateMutation,
  DiscountCodeUpdateMutationVariables
>;

/**
 * __useDiscountCodeUpdateMutation__
 *
 * To run a mutation, you first call `useDiscountCodeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDiscountCodeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [discountCodeUpdateMutation, { data, loading, error }] = useDiscountCodeUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDiscountCodeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DiscountCodeUpdateMutation,
    DiscountCodeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DiscountCodeUpdateMutation,
    DiscountCodeUpdateMutationVariables
  >(DiscountCodeUpdateDocument, options);
}
export type DiscountCodeUpdateMutationHookResult = ReturnType<
  typeof useDiscountCodeUpdateMutation
>;
export type DiscountCodeUpdateMutationResult =
  Apollo.MutationResult<DiscountCodeUpdateMutation>;
export type DiscountCodeUpdateMutationOptions = Apollo.BaseMutationOptions<
  DiscountCodeUpdateMutation,
  DiscountCodeUpdateMutationVariables
>;
export const DeleteFaqPageDocument = gql`
  mutation deleteFaqPage($uuid: String!) {
    deleteFaqPage(uuid: $uuid)
  }
`;
export type DeleteFaqPageMutationFn = Apollo.MutationFunction<
  DeleteFaqPageMutation,
  DeleteFaqPageMutationVariables
>;

/**
 * __useDeleteFaqPageMutation__
 *
 * To run a mutation, you first call `useDeleteFaqPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFaqPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFaqPageMutation, { data, loading, error }] = useDeleteFaqPageMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteFaqPageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFaqPageMutation,
    DeleteFaqPageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteFaqPageMutation,
    DeleteFaqPageMutationVariables
  >(DeleteFaqPageDocument, options);
}
export type DeleteFaqPageMutationHookResult = ReturnType<
  typeof useDeleteFaqPageMutation
>;
export type DeleteFaqPageMutationResult =
  Apollo.MutationResult<DeleteFaqPageMutation>;
export type DeleteFaqPageMutationOptions = Apollo.BaseMutationOptions<
  DeleteFaqPageMutation,
  DeleteFaqPageMutationVariables
>;
export const FaqCreateDocument = gql`
  mutation faqCreate($input: CreateFaqInput!) {
    faqCreate(input: $input) {
      faq {
        uuid
        createdAt
        updatedAt
        title
        response
        category
        author {
          uuid
          createdAt
          updatedAt
          email
          firstName
          lastName
          activated
          enabled
          role
        }
      }
    }
  }
`;
export type FaqCreateMutationFn = Apollo.MutationFunction<
  FaqCreateMutation,
  FaqCreateMutationVariables
>;

/**
 * __useFaqCreateMutation__
 *
 * To run a mutation, you first call `useFaqCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFaqCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [faqCreateMutation, { data, loading, error }] = useFaqCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFaqCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FaqCreateMutation,
    FaqCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FaqCreateMutation, FaqCreateMutationVariables>(
    FaqCreateDocument,
    options,
  );
}
export type FaqCreateMutationHookResult = ReturnType<
  typeof useFaqCreateMutation
>;
export type FaqCreateMutationResult = Apollo.MutationResult<FaqCreateMutation>;
export type FaqCreateMutationOptions = Apollo.BaseMutationOptions<
  FaqCreateMutation,
  FaqCreateMutationVariables
>;
export const FaqDeleteDocument = gql`
  mutation faqDelete($uuid: ID!) {
    faqDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type FaqDeleteMutationFn = Apollo.MutationFunction<
  FaqDeleteMutation,
  FaqDeleteMutationVariables
>;

/**
 * __useFaqDeleteMutation__
 *
 * To run a mutation, you first call `useFaqDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFaqDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [faqDeleteMutation, { data, loading, error }] = useFaqDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useFaqDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FaqDeleteMutation,
    FaqDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FaqDeleteMutation, FaqDeleteMutationVariables>(
    FaqDeleteDocument,
    options,
  );
}
export type FaqDeleteMutationHookResult = ReturnType<
  typeof useFaqDeleteMutation
>;
export type FaqDeleteMutationResult = Apollo.MutationResult<FaqDeleteMutation>;
export type FaqDeleteMutationOptions = Apollo.BaseMutationOptions<
  FaqDeleteMutation,
  FaqDeleteMutationVariables
>;
export const FaqPageRegisterDocument = gql`
  mutation faqPageRegister($input: RegisterFaqPage!) {
    faqPageRegister(input: $input) {
      page {
        uuid
        createdAt
        updatedAt
        name
        slug
        faqPageLists {
          uuid
          createdAt
          updatedAt
          title
          faqPages {
            uuid
            createdAt
            updatedAt
            question
            response
          }
        }
        questionPageLists {
          uuid
          createdAt
          updatedAt
          title
          questionsPages {
            uuid
            createdAt
            updatedAt
            question
            response
          }
        }
      }
    }
  }
`;
export type FaqPageRegisterMutationFn = Apollo.MutationFunction<
  FaqPageRegisterMutation,
  FaqPageRegisterMutationVariables
>;

/**
 * __useFaqPageRegisterMutation__
 *
 * To run a mutation, you first call `useFaqPageRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFaqPageRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [faqPageRegisterMutation, { data, loading, error }] = useFaqPageRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFaqPageRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FaqPageRegisterMutation,
    FaqPageRegisterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FaqPageRegisterMutation,
    FaqPageRegisterMutationVariables
  >(FaqPageRegisterDocument, options);
}
export type FaqPageRegisterMutationHookResult = ReturnType<
  typeof useFaqPageRegisterMutation
>;
export type FaqPageRegisterMutationResult =
  Apollo.MutationResult<FaqPageRegisterMutation>;
export type FaqPageRegisterMutationOptions = Apollo.BaseMutationOptions<
  FaqPageRegisterMutation,
  FaqPageRegisterMutationVariables
>;
export const FaqTitleMutationDocument = gql`
  mutation faqTitleMutation($input: MutationFaqTitle!) {
    faqTitleMutation(input: $input) {
      page {
        uuid
        createdAt
        updatedAt
        name
        slug
        meta_title
        meta_description
        faqPageLists {
          uuid
          createdAt
          updatedAt
          title
          faqPages {
            uuid
            createdAt
            updatedAt
            question
            response
          }
        }
        questionPageLists {
          uuid
          createdAt
          updatedAt
          title
          questionsPages {
            uuid
            createdAt
            updatedAt
            question
            response
          }
        }
      }
    }
  }
`;
export type FaqTitleMutationMutationFn = Apollo.MutationFunction<
  FaqTitleMutationMutation,
  FaqTitleMutationMutationVariables
>;

/**
 * __useFaqTitleMutationMutation__
 *
 * To run a mutation, you first call `useFaqTitleMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFaqTitleMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [faqTitleMutationMutation, { data, loading, error }] = useFaqTitleMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFaqTitleMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FaqTitleMutationMutation,
    FaqTitleMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    FaqTitleMutationMutation,
    FaqTitleMutationMutationVariables
  >(FaqTitleMutationDocument, options);
}
export type FaqTitleMutationMutationHookResult = ReturnType<
  typeof useFaqTitleMutationMutation
>;
export type FaqTitleMutationMutationResult =
  Apollo.MutationResult<FaqTitleMutationMutation>;
export type FaqTitleMutationMutationOptions = Apollo.BaseMutationOptions<
  FaqTitleMutationMutation,
  FaqTitleMutationMutationVariables
>;
export const FaqUpdateDocument = gql`
  mutation faqUpdate($uuid: ID!, $input: FaqUpdateInput!) {
    faqUpdate(uuid: $uuid, input: $input) {
      faq {
        uuid
        createdAt
        updatedAt
        title
        response
        category
        author {
          uuid
          createdAt
          updatedAt
          email
          firstName
          lastName
          activated
          enabled
          role
        }
      }
    }
  }
`;
export type FaqUpdateMutationFn = Apollo.MutationFunction<
  FaqUpdateMutation,
  FaqUpdateMutationVariables
>;

/**
 * __useFaqUpdateMutation__
 *
 * To run a mutation, you first call `useFaqUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFaqUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [faqUpdateMutation, { data, loading, error }] = useFaqUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFaqUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FaqUpdateMutation,
    FaqUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FaqUpdateMutation, FaqUpdateMutationVariables>(
    FaqUpdateDocument,
    options,
  );
}
export type FaqUpdateMutationHookResult = ReturnType<
  typeof useFaqUpdateMutation
>;
export type FaqUpdateMutationResult = Apollo.MutationResult<FaqUpdateMutation>;
export type FaqUpdateMutationOptions = Apollo.BaseMutationOptions<
  FaqUpdateMutation,
  FaqUpdateMutationVariables
>;
export const UpdateFaqPageDocument = gql`
  mutation updateFaqPage($input: UpdateFaqPage!) {
    updateFaqPage(input: $input) {
      page {
        uuid
        createdAt
        updatedAt
        name
        slug
        faqPageLists {
          uuid
          createdAt
          updatedAt
          title
          faqPages {
            uuid
            createdAt
            updatedAt
            question
            response
          }
        }
        questionPageLists {
          uuid
          createdAt
          updatedAt
          title
          questionsPages {
            uuid
            createdAt
            updatedAt
            question
            response
          }
        }
      }
    }
  }
`;
export type UpdateFaqPageMutationFn = Apollo.MutationFunction<
  UpdateFaqPageMutation,
  UpdateFaqPageMutationVariables
>;

/**
 * __useUpdateFaqPageMutation__
 *
 * To run a mutation, you first call `useUpdateFaqPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFaqPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFaqPageMutation, { data, loading, error }] = useUpdateFaqPageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFaqPageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFaqPageMutation,
    UpdateFaqPageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFaqPageMutation,
    UpdateFaqPageMutationVariables
  >(UpdateFaqPageDocument, options);
}
export type UpdateFaqPageMutationHookResult = ReturnType<
  typeof useUpdateFaqPageMutation
>;
export type UpdateFaqPageMutationResult =
  Apollo.MutationResult<UpdateFaqPageMutation>;
export type UpdateFaqPageMutationOptions = Apollo.BaseMutationOptions<
  UpdateFaqPageMutation,
  UpdateFaqPageMutationVariables
>;
export const FontCreateDocument = gql`
  mutation fontCreate($input: RegisterFontInput!) {
    fontCreate(input: $input) {
      font {
        uuid
        createdAt
        updatedAt
        title
        response
      }
    }
  }
`;
export type FontCreateMutationFn = Apollo.MutationFunction<
  FontCreateMutation,
  FontCreateMutationVariables
>;

/**
 * __useFontCreateMutation__
 *
 * To run a mutation, you first call `useFontCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFontCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fontCreateMutation, { data, loading, error }] = useFontCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFontCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FontCreateMutation,
    FontCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FontCreateMutation, FontCreateMutationVariables>(
    FontCreateDocument,
    options,
  );
}
export type FontCreateMutationHookResult = ReturnType<
  typeof useFontCreateMutation
>;
export type FontCreateMutationResult =
  Apollo.MutationResult<FontCreateMutation>;
export type FontCreateMutationOptions = Apollo.BaseMutationOptions<
  FontCreateMutation,
  FontCreateMutationVariables
>;
export const FontDeleteDocument = gql`
  mutation fontDelete($uuid: String!) {
    fontDelete(uuid: $uuid) {
      message
    }
  }
`;
export type FontDeleteMutationFn = Apollo.MutationFunction<
  FontDeleteMutation,
  FontDeleteMutationVariables
>;

/**
 * __useFontDeleteMutation__
 *
 * To run a mutation, you first call `useFontDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFontDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fontDeleteMutation, { data, loading, error }] = useFontDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useFontDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FontDeleteMutation,
    FontDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FontDeleteMutation, FontDeleteMutationVariables>(
    FontDeleteDocument,
    options,
  );
}
export type FontDeleteMutationHookResult = ReturnType<
  typeof useFontDeleteMutation
>;
export type FontDeleteMutationResult =
  Apollo.MutationResult<FontDeleteMutation>;
export type FontDeleteMutationOptions = Apollo.BaseMutationOptions<
  FontDeleteMutation,
  FontDeleteMutationVariables
>;
export const FontUpdateDocument = gql`
  mutation fontUpdate($uuid: ID!, $input: RegisterFontInput!) {
    fontUpdate(uuid: $uuid, input: $input) {
      font {
        uuid
        createdAt
        updatedAt
        title
        response
      }
    }
  }
`;
export type FontUpdateMutationFn = Apollo.MutationFunction<
  FontUpdateMutation,
  FontUpdateMutationVariables
>;

/**
 * __useFontUpdateMutation__
 *
 * To run a mutation, you first call `useFontUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFontUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fontUpdateMutation, { data, loading, error }] = useFontUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFontUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FontUpdateMutation,
    FontUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FontUpdateMutation, FontUpdateMutationVariables>(
    FontUpdateDocument,
    options,
  );
}
export type FontUpdateMutationHookResult = ReturnType<
  typeof useFontUpdateMutation
>;
export type FontUpdateMutationResult =
  Apollo.MutationResult<FontUpdateMutation>;
export type FontUpdateMutationOptions = Apollo.BaseMutationOptions<
  FontUpdateMutation,
  FontUpdateMutationVariables
>;
export const RemoveStorageDocument = gql`
  mutation removeStorage($uuid: String!) {
    removeStorage(uuid: $uuid) {
      uuid
    }
  }
`;
export type RemoveStorageMutationFn = Apollo.MutationFunction<
  RemoveStorageMutation,
  RemoveStorageMutationVariables
>;

/**
 * __useRemoveStorageMutation__
 *
 * To run a mutation, you first call `useRemoveStorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStorageMutation, { data, loading, error }] = useRemoveStorageMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveStorageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveStorageMutation,
    RemoveStorageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveStorageMutation,
    RemoveStorageMutationVariables
  >(RemoveStorageDocument, options);
}
export type RemoveStorageMutationHookResult = ReturnType<
  typeof useRemoveStorageMutation
>;
export type RemoveStorageMutationResult =
  Apollo.MutationResult<RemoveStorageMutation>;
export type RemoveStorageMutationOptions = Apollo.BaseMutationOptions<
  RemoveStorageMutation,
  RemoveStorageMutationVariables
>;
export const UpdateFileDocument = gql`
  mutation updateFile($uuid: ID!, $updateStorageInput: UpdateStorageInput!) {
    updateFile(uuid: $uuid, updateStorageInput: $updateStorageInput) {
      storage {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
    }
  }
`;
export type UpdateFileMutationFn = Apollo.MutationFunction<
  UpdateFileMutation,
  UpdateFileMutationVariables
>;

/**
 * __useUpdateFileMutation__
 *
 * To run a mutation, you first call `useUpdateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileMutation, { data, loading, error }] = useUpdateFileMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      updateStorageInput: // value for 'updateStorageInput'
 *   },
 * });
 */
export function useUpdateFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFileMutation,
    UpdateFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFileMutation, UpdateFileMutationVariables>(
    UpdateFileDocument,
    options,
  );
}
export type UpdateFileMutationHookResult = ReturnType<
  typeof useUpdateFileMutation
>;
export type UpdateFileMutationResult =
  Apollo.MutationResult<UpdateFileMutation>;
export type UpdateFileMutationOptions = Apollo.BaseMutationOptions<
  UpdateFileMutation,
  UpdateFileMutationVariables
>;
export const UploadFileDocument = gql`
  mutation uploadFile($createStorageInput: CreateStorageInput!) {
    uploadFile(createStorageInput: $createStorageInput) {
      storage {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
    }
  }
`;
export type UploadFileMutationFn = Apollo.MutationFunction<
  UploadFileMutation,
  UploadFileMutationVariables
>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      createStorageInput: // value for 'createStorageInput'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFileMutation,
    UploadFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(
    UploadFileDocument,
    options,
  );
}
export type UploadFileMutationHookResult = ReturnType<
  typeof useUploadFileMutation
>;
export type UploadFileMutationResult =
  Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;
export const DeleteAllNotificationsDocument = gql`
  mutation deleteAllNotifications {
    deleteAllNotifications {
      message
    }
  }
`;
export type DeleteAllNotificationsMutationFn = Apollo.MutationFunction<
  DeleteAllNotificationsMutation,
  DeleteAllNotificationsMutationVariables
>;

/**
 * __useDeleteAllNotificationsMutation__
 *
 * To run a mutation, you first call `useDeleteAllNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllNotificationsMutation, { data, loading, error }] = useDeleteAllNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAllNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAllNotificationsMutation,
    DeleteAllNotificationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAllNotificationsMutation,
    DeleteAllNotificationsMutationVariables
  >(DeleteAllNotificationsDocument, options);
}
export type DeleteAllNotificationsMutationHookResult = ReturnType<
  typeof useDeleteAllNotificationsMutation
>;
export type DeleteAllNotificationsMutationResult =
  Apollo.MutationResult<DeleteAllNotificationsMutation>;
export type DeleteAllNotificationsMutationOptions = Apollo.BaseMutationOptions<
  DeleteAllNotificationsMutation,
  DeleteAllNotificationsMutationVariables
>;
export const DeleteEstimatesNotificationsDocument = gql`
  mutation deleteEstimatesNotifications($uuid: String!) {
    deleteEstimatesNotifications(uuid: $uuid) {
      estimateUuid
    }
  }
`;
export type DeleteEstimatesNotificationsMutationFn = Apollo.MutationFunction<
  DeleteEstimatesNotificationsMutation,
  DeleteEstimatesNotificationsMutationVariables
>;

/**
 * __useDeleteEstimatesNotificationsMutation__
 *
 * To run a mutation, you first call `useDeleteEstimatesNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEstimatesNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEstimatesNotificationsMutation, { data, loading, error }] = useDeleteEstimatesNotificationsMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteEstimatesNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteEstimatesNotificationsMutation,
    DeleteEstimatesNotificationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteEstimatesNotificationsMutation,
    DeleteEstimatesNotificationsMutationVariables
  >(DeleteEstimatesNotificationsDocument, options);
}
export type DeleteEstimatesNotificationsMutationHookResult = ReturnType<
  typeof useDeleteEstimatesNotificationsMutation
>;
export type DeleteEstimatesNotificationsMutationResult =
  Apollo.MutationResult<DeleteEstimatesNotificationsMutation>;
export type DeleteEstimatesNotificationsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteEstimatesNotificationsMutation,
    DeleteEstimatesNotificationsMutationVariables
  >;
export const DeleteOrdersNotificationsDocument = gql`
  mutation deleteOrdersNotifications($uuid: String!) {
    deleteOrdersNotifications(uuid: $uuid) {
      orderUuid
    }
  }
`;
export type DeleteOrdersNotificationsMutationFn = Apollo.MutationFunction<
  DeleteOrdersNotificationsMutation,
  DeleteOrdersNotificationsMutationVariables
>;

/**
 * __useDeleteOrdersNotificationsMutation__
 *
 * To run a mutation, you first call `useDeleteOrdersNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrdersNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrdersNotificationsMutation, { data, loading, error }] = useDeleteOrdersNotificationsMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteOrdersNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrdersNotificationsMutation,
    DeleteOrdersNotificationsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOrdersNotificationsMutation,
    DeleteOrdersNotificationsMutationVariables
  >(DeleteOrdersNotificationsDocument, options);
}
export type DeleteOrdersNotificationsMutationHookResult = ReturnType<
  typeof useDeleteOrdersNotificationsMutation
>;
export type DeleteOrdersNotificationsMutationResult =
  Apollo.MutationResult<DeleteOrdersNotificationsMutation>;
export type DeleteOrdersNotificationsMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteOrdersNotificationsMutation,
    DeleteOrdersNotificationsMutationVariables
  >;
export const AddInvoiceDocument = gql`
  mutation addInvoice($input: AddInvoiceInput!) {
    addInvoice(input: $input) {
      uuid
      createdAt
      updatedAt
      order_code
      already_add_review
      sub_total
      shipping_cost
      total_without_vat
      start_date_of_delivery
      end_date_of_delivery
      vat
      total_all_vat
      discount_amount
      payment_method
      ordering_status
      delivery_mode
      invoice {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      discount_code {
        uuid
        createdAt
        updatedAt
        code
        value
        use_before
        max_use_times
        used_times
        saved_money
        total_buy_amount
        is_percentage
      }
      stripe_payment_data {
        status
        id
        payment_link
        balance_transaction
        payment_method
        receipt_url
        source {
          id
          brand
          country
          last4
          exp_month
          exp_year
        }
      }
      has_quote
      user {
        uuid
        createdAt
        updatedAt
        email
        firstName
        lastName
        company_name
        address
        postal_code
        city
        country
        phonenumber
        vehicles {
          brand
          model
          year
          color
        }
        activated
        enabled
        role
      }
      delivery_address {
        uuid
        createdAt
        updatedAt
        firstname
        lastname
        company_name
        address
        postal_code
        city
        country
        phonenumber
        email
      }
      billing_address {
        uuid
        createdAt
        updatedAt
        firstname
        lastname
        company_name
        address
        postal_code
        city
        country
        phonenumber
        email
      }
      order_products {
        uuid
        createdAt
        updatedAt
        verification
        graphic_service
        professional_installer
        synthetic_design
        custom_design
        design_is_finished
        quantity
        total
        squid_studio_data {
          width
          height
          radius
          can_have_background
          data
        }
        vehicle_information {
          brand
          model
          year
          color
        }
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        image_pdf {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        image_svg {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        product {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          type
          description
          slug
          meta_title
          meta_description
          verification
          graphic_service
          professional_installer
          enabled
          index
          start_price
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          sections {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            extra_price_sections {
              section_uuid
              extra_price
            }
            installer_extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          templates {
            uuid
            createdAt
            updatedAt
            title
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          product_images {
            uuid
            createdAt
            updatedAt
            support_uuid
            size_uuid
            section_uuid
            extra_sticker_uuid
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
        size {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
          }
        }
        support {
          uuid
          createdAt
          updatedAt
          title
          can_have_background
          extra_price
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
        }
        section {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          can_have_background
          extra_price
          canvas_width
          canvas_height
          canvas_radius
          extra_price_size {
            size_uuid
            extra_price
          }
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
          }
        }
        extra_stickers {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          design_choice_possible
          extra_price
          installer_extra_price
          overview
          video_url
          installation_difficulty
        }
      }
    }
  }
`;
export type AddInvoiceMutationFn = Apollo.MutationFunction<
  AddInvoiceMutation,
  AddInvoiceMutationVariables
>;

/**
 * __useAddInvoiceMutation__
 *
 * To run a mutation, you first call `useAddInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInvoiceMutation, { data, loading, error }] = useAddInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddInvoiceMutation,
    AddInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddInvoiceMutation, AddInvoiceMutationVariables>(
    AddInvoiceDocument,
    options,
  );
}
export type AddInvoiceMutationHookResult = ReturnType<
  typeof useAddInvoiceMutation
>;
export type AddInvoiceMutationResult =
  Apollo.MutationResult<AddInvoiceMutation>;
export type AddInvoiceMutationOptions = Apollo.BaseMutationOptions<
  AddInvoiceMutation,
  AddInvoiceMutationVariables
>;
export const CountryUpdateDocument = gql`
  mutation countryUpdate(
    $country_code: String!
    $input: DeliveryCountryInput!
  ) {
    countryUpdate(country_code: $country_code, input: $input) {
      configs {
        uuid
        createdAt
        updatedAt
        shipping_standard_cost {
          start_amount
          end_amount
          cost
        }
        country {
          country_type
          country_name
          country_code
          vat
          delivery_cost
          shipping_days_number
        }
        shipping_standard_days_number
        shipping_express_cost_percentage
        shipping_express_days_number
        verification_cost
        symetrique_design_cost
        graphic_service_cost
        installer_price
        vat
        facebook_social_link
        instagram_social_link
        youtube_social_link
      }
    }
  }
`;
export type CountryUpdateMutationFn = Apollo.MutationFunction<
  CountryUpdateMutation,
  CountryUpdateMutationVariables
>;

/**
 * __useCountryUpdateMutation__
 *
 * To run a mutation, you first call `useCountryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCountryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [countryUpdateMutation, { data, loading, error }] = useCountryUpdateMutation({
 *   variables: {
 *      country_code: // value for 'country_code'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCountryUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CountryUpdateMutation,
    CountryUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CountryUpdateMutation,
    CountryUpdateMutationVariables
  >(CountryUpdateDocument, options);
}
export type CountryUpdateMutationHookResult = ReturnType<
  typeof useCountryUpdateMutation
>;
export type CountryUpdateMutationResult =
  Apollo.MutationResult<CountryUpdateMutation>;
export type CountryUpdateMutationOptions = Apollo.BaseMutationOptions<
  CountryUpdateMutation,
  CountryUpdateMutationVariables
>;
export const DeleteCountryDocument = gql`
  mutation deleteCountry($country_code: String!) {
    deleteCountry(country_code: $country_code) {
      configs {
        uuid
        createdAt
        updatedAt
        shipping_standard_cost {
          start_amount
          end_amount
          cost
        }
        country {
          country_type
          country_name
          country_code
          vat
          delivery_cost
          shipping_days_number
        }
        shipping_standard_days_number
        shipping_express_cost_percentage
        shipping_express_days_number
        verification_cost
        symetrique_design_cost
        graphic_service_cost
        installer_price
        vat
        facebook_social_link
        instagram_social_link
        youtube_social_link
      }
    }
  }
`;
export type DeleteCountryMutationFn = Apollo.MutationFunction<
  DeleteCountryMutation,
  DeleteCountryMutationVariables
>;

/**
 * __useDeleteCountryMutation__
 *
 * To run a mutation, you first call `useDeleteCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCountryMutation, { data, loading, error }] = useDeleteCountryMutation({
 *   variables: {
 *      country_code: // value for 'country_code'
 *   },
 * });
 */
export function useDeleteCountryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCountryMutation,
    DeleteCountryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCountryMutation,
    DeleteCountryMutationVariables
  >(DeleteCountryDocument, options);
}
export type DeleteCountryMutationHookResult = ReturnType<
  typeof useDeleteCountryMutation
>;
export type DeleteCountryMutationResult =
  Apollo.MutationResult<DeleteCountryMutation>;
export type DeleteCountryMutationOptions = Apollo.BaseMutationOptions<
  DeleteCountryMutation,
  DeleteCountryMutationVariables
>;
export const DeleteInvoiceDocument = gql`
  mutation deleteInvoice($uuid: String!) {
    deleteInvoice(uuid: $uuid)
  }
`;
export type DeleteInvoiceMutationFn = Apollo.MutationFunction<
  DeleteInvoiceMutation,
  DeleteInvoiceMutationVariables
>;

/**
 * __useDeleteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceMutation, { data, loading, error }] = useDeleteInvoiceMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteInvoiceMutation,
    DeleteInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteInvoiceMutation,
    DeleteInvoiceMutationVariables
  >(DeleteInvoiceDocument, options);
}
export type DeleteInvoiceMutationHookResult = ReturnType<
  typeof useDeleteInvoiceMutation
>;
export type DeleteInvoiceMutationResult =
  Apollo.MutationResult<DeleteInvoiceMutation>;
export type DeleteInvoiceMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvoiceMutation,
  DeleteInvoiceMutationVariables
>;
export const OrderDeleteDocument = gql`
  mutation orderDelete($uuid: ID!) {
    orderDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type OrderDeleteMutationFn = Apollo.MutationFunction<
  OrderDeleteMutation,
  OrderDeleteMutationVariables
>;

/**
 * __useOrderDeleteMutation__
 *
 * To run a mutation, you first call `useOrderDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderDeleteMutation, { data, loading, error }] = useOrderDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useOrderDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderDeleteMutation,
    OrderDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderDeleteMutation, OrderDeleteMutationVariables>(
    OrderDeleteDocument,
    options,
  );
}
export type OrderDeleteMutationHookResult = ReturnType<
  typeof useOrderDeleteMutation
>;
export type OrderDeleteMutationResult =
  Apollo.MutationResult<OrderDeleteMutation>;
export type OrderDeleteMutationOptions = Apollo.BaseMutationOptions<
  OrderDeleteMutation,
  OrderDeleteMutationVariables
>;
export const OrdersListDocument = gql`
  query ordersList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: OrdersPaginationSortBy
    $filterByQuote: Boolean!
    $filterByStatus: ordering_status
    $filterByDeliveryMode: delivery_modes
    $filterByPaymentMethod: payment_methods
  ) {
    ordersList(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
      filterByQuote: $filterByQuote
      filterByStatus: $filterByStatus
      filterByDeliveryMode: $filterByDeliveryMode
      filterByPaymentMethod: $filterByPaymentMethod
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        order_code
        already_add_review
        sub_total
        shipping_cost
        total_without_vat
        start_date_of_delivery
        end_date_of_delivery
        vat
        total_all_vat
        discount_amount
        payment_method
        ordering_status
        delivery_mode
      }
    }
  }
`;

/**
 * __useOrdersListQuery__
 *
 * To run a query within a React component, call `useOrdersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *      filterByQuote: // value for 'filterByQuote'
 *      filterByStatus: // value for 'filterByStatus'
 *      filterByDeliveryMode: // value for 'filterByDeliveryMode'
 *      filterByPaymentMethod: // value for 'filterByPaymentMethod'
 *   },
 * });
 */
export function useOrdersListQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrdersListQuery,
    OrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrdersListQuery, OrdersListQueryVariables>(
    OrdersListDocument,
    options,
  );
}
export function useOrdersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersListQuery,
    OrdersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrdersListQuery, OrdersListQueryVariables>(
    OrdersListDocument,
    options,
  );
}
export type OrdersListQueryHookResult = ReturnType<typeof useOrdersListQuery>;
export type OrdersListLazyQueryHookResult = ReturnType<
  typeof useOrdersListLazyQuery
>;
export type OrdersListQueryResult = Apollo.QueryResult<
  OrdersListQuery,
  OrdersListQueryVariables
>;
export const UpdateOrderQuoteDocument = gql`
  mutation updateOrderQuote($input: UpdateOrderQuoteInput!) {
    updateOrderQuote(input: $input) {
      order {
        uuid
        createdAt
        updatedAt
        order_code
        already_add_review
        sub_total
        shipping_cost
        total_without_vat
        start_date_of_delivery
        end_date_of_delivery
        vat
        total_all_vat
        discount_amount
        payment_method
        ordering_status
        delivery_mode
        invoice {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        discount_code {
          uuid
          createdAt
          updatedAt
          code
          value
          use_before
          max_use_times
          used_times
          saved_money
          total_buy_amount
          is_percentage
        }
        stripe_payment_data {
          status
          id
          payment_link
          balance_transaction
          payment_method
          receipt_url
          source {
            id
            brand
            country
            last4
            exp_month
            exp_year
          }
        }
        has_quote
        user {
          uuid
          createdAt
          updatedAt
          email
          firstName
          lastName
          company_name
          address
          postal_code
          city
          country
          phonenumber
          vehicles {
            brand
            model
            year
            color
          }
          activated
          enabled
          role
        }
        delivery_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        billing_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        order_products {
          uuid
          createdAt
          updatedAt
          verification
          graphic_service
          professional_installer
          synthetic_design
          custom_design
          design_is_finished
          quantity
          total
          squid_studio_data {
            width
            height
            radius
            can_have_background
            data
          }
          vehicle_information {
            brand
            model
            year
            color
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_pdf {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_svg {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          product {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            type
            description
            slug
            meta_title
            meta_description
            verification
            graphic_service
            professional_installer
            enabled
            index
            start_price
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
              }
            }
            sections {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              extra_price_size {
                size_uuid
                extra_price
              }
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                depends_on_supports {
                  uuid
                  createdAt
                  updatedAt
                  title
                  can_have_background
                  extra_price
                  overview
                  video_url
                  installation_difficulty
                }
              }
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              extra_price_sections {
                section_uuid
                extra_price
              }
              installer_extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
            }
            templates {
              uuid
              createdAt
              updatedAt
              title
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            product_images {
              uuid
              createdAt
              updatedAt
              support_uuid
              size_uuid
              section_uuid
              extra_sticker_uuid
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          size {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
          }
          support {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
          section {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            installer_extra_price
            overview
            video_url
            installation_difficulty
          }
        }
      }
    }
  }
`;
export type UpdateOrderQuoteMutationFn = Apollo.MutationFunction<
  UpdateOrderQuoteMutation,
  UpdateOrderQuoteMutationVariables
>;

/**
 * __useUpdateOrderQuoteMutation__
 *
 * To run a mutation, you first call `useUpdateOrderQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderQuoteMutation, { data, loading, error }] = useUpdateOrderQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderQuoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderQuoteMutation,
    UpdateOrderQuoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrderQuoteMutation,
    UpdateOrderQuoteMutationVariables
  >(UpdateOrderQuoteDocument, options);
}
export type UpdateOrderQuoteMutationHookResult = ReturnType<
  typeof useUpdateOrderQuoteMutation
>;
export type UpdateOrderQuoteMutationResult =
  Apollo.MutationResult<UpdateOrderQuoteMutation>;
export type UpdateOrderQuoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderQuoteMutation,
  UpdateOrderQuoteMutationVariables
>;
export const UpdateOrderStatusDocument = gql`
  mutation updateOrderStatus($input: ChangeOrderStatusInput!) {
    updateOrderStatus(input: $input) {
      order {
        uuid
        createdAt
        updatedAt
        order_code
        already_add_review
        sub_total
        shipping_cost
        total_without_vat
        start_date_of_delivery
        end_date_of_delivery
        vat
        total_all_vat
        discount_amount
        payment_method
        ordering_status
        delivery_mode
        invoice {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        discount_code {
          uuid
          createdAt
          updatedAt
          code
          value
          use_before
          max_use_times
          used_times
          saved_money
          total_buy_amount
          is_percentage
        }
        stripe_payment_data {
          status
          id
          payment_link
          balance_transaction
          payment_method
          receipt_url
          source {
            id
            brand
            country
            last4
            exp_month
            exp_year
          }
        }
        has_quote
        user {
          uuid
          createdAt
          updatedAt
          email
          firstName
          lastName
          company_name
          address
          postal_code
          city
          country
          phonenumber
          vehicles {
            brand
            model
            year
            color
          }
          activated
          enabled
          role
        }
        delivery_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        billing_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        order_products {
          uuid
          createdAt
          updatedAt
          verification
          graphic_service
          professional_installer
          synthetic_design
          custom_design
          design_is_finished
          quantity
          total
          squid_studio_data {
            width
            height
            radius
            can_have_background
            data
          }
          vehicle_information {
            brand
            model
            year
            color
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_pdf {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_svg {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          product {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            type
            description
            slug
            meta_title
            meta_description
            verification
            graphic_service
            professional_installer
            enabled
            index
            start_price
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
              }
            }
            sections {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              extra_price_size {
                size_uuid
                extra_price
              }
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                depends_on_supports {
                  uuid
                  createdAt
                  updatedAt
                  title
                  can_have_background
                  extra_price
                  overview
                  video_url
                  installation_difficulty
                }
              }
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              extra_price_sections {
                section_uuid
                extra_price
              }
              installer_extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
            }
            templates {
              uuid
              createdAt
              updatedAt
              title
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            product_images {
              uuid
              createdAt
              updatedAt
              support_uuid
              size_uuid
              section_uuid
              extra_sticker_uuid
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          size {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
          }
          support {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
          section {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            installer_extra_price
            overview
            video_url
            installation_difficulty
          }
        }
      }
    }
  }
`;
export type UpdateOrderStatusMutationFn = Apollo.MutationFunction<
  UpdateOrderStatusMutation,
  UpdateOrderStatusMutationVariables
>;

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables
  >(UpdateOrderStatusDocument, options);
}
export type UpdateOrderStatusMutationHookResult = ReturnType<
  typeof useUpdateOrderStatusMutation
>;
export type UpdateOrderStatusMutationResult =
  Apollo.MutationResult<UpdateOrderStatusMutation>;
export type UpdateOrderStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderStatusMutation,
  UpdateOrderStatusMutationVariables
>;
export const BrandModelCreateDocument = gql`
  mutation brandModelCreate($input: CreateBrandModelInput!) {
    brandModelCreate(input: $input) {
      brandModel {
        uuid
        createdAt
        updatedAt
        model_name
        info_title
        info_description
        car_brand {
          uuid
          createdAt
          updatedAt
          brand_name
          info_title
          info_description
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          pictogram_dark {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          brand_models {
            uuid
            createdAt
            updatedAt
            model_name
            info_title
            info_description
            car_brand {
              uuid
              createdAt
              updatedAt
              brand_name
              info_title
              info_description
            }
            model_years {
              uuid
              createdAt
              updatedAt
              start_year
              end_year
              info_title
              info_description
              brand_model {
                uuid
                createdAt
                updatedAt
                model_name
                info_title
                info_description
                car_brand {
                  uuid
                  createdAt
                  updatedAt
                  brand_name
                  info_title
                  info_description
                }
                model_years {
                  uuid
                  createdAt
                  updatedAt
                  start_year
                  end_year
                  info_title
                  info_description
                  car_versions {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                        thumbnail {
                          width
                          height
                          size
                          url
                          file_name
                        }
                        medium {
                          width
                          height
                          size
                          url
                          file_name
                        }
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        version_lateral {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          side_glazings {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            info_title
                            info_description
                            preview_text
                          }
                          cars_backwards {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                              car_version {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                info_title
                                info_description
                                preview_text
                                pictogram {
                                  uuid
                                  createdAt
                                  updatedAt
                                  file_name
                                  url
                                  mime_type
                                  width
                                  height
                                  size
                                  caption
                                  description
                                  alternative_title
                                }
                                version_laterals {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  extra_price
                                  installer_extra_price
                                  installation_difficulty
                                  info_title
                                  info_description
                                  preview_text
                                  cars_backwards {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    preview_text
                                    car_backward_types {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      has_rear_camera
                                      pictogram {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      preview_image {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      car_backward {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        extra_price
                                        installer_extra_price
                                        installation_difficulty
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_lateral {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        car_backward_types {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          has_rear_camera
                                        }
                                      }
                                    }
                                  }
                                  car_version {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    preview_text
                                    model_year {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      brand_model {
                                        uuid
                                        createdAt
                                        updatedAt
                                        model_name
                                        info_title
                                        info_description
                                      }
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        model_years {
          uuid
          createdAt
          updatedAt
          start_year
          end_year
          info_title
          info_description
        }
      }
    }
  }
`;
export type BrandModelCreateMutationFn = Apollo.MutationFunction<
  BrandModelCreateMutation,
  BrandModelCreateMutationVariables
>;

/**
 * __useBrandModelCreateMutation__
 *
 * To run a mutation, you first call `useBrandModelCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandModelCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandModelCreateMutation, { data, loading, error }] = useBrandModelCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrandModelCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BrandModelCreateMutation,
    BrandModelCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BrandModelCreateMutation,
    BrandModelCreateMutationVariables
  >(BrandModelCreateDocument, options);
}
export type BrandModelCreateMutationHookResult = ReturnType<
  typeof useBrandModelCreateMutation
>;
export type BrandModelCreateMutationResult =
  Apollo.MutationResult<BrandModelCreateMutation>;
export type BrandModelCreateMutationOptions = Apollo.BaseMutationOptions<
  BrandModelCreateMutation,
  BrandModelCreateMutationVariables
>;
export const BrandModelDeleteDocument = gql`
  mutation brandModelDelete($uuid: ID!) {
    brandModelDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type BrandModelDeleteMutationFn = Apollo.MutationFunction<
  BrandModelDeleteMutation,
  BrandModelDeleteMutationVariables
>;

/**
 * __useBrandModelDeleteMutation__
 *
 * To run a mutation, you first call `useBrandModelDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandModelDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandModelDeleteMutation, { data, loading, error }] = useBrandModelDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useBrandModelDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BrandModelDeleteMutation,
    BrandModelDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BrandModelDeleteMutation,
    BrandModelDeleteMutationVariables
  >(BrandModelDeleteDocument, options);
}
export type BrandModelDeleteMutationHookResult = ReturnType<
  typeof useBrandModelDeleteMutation
>;
export type BrandModelDeleteMutationResult =
  Apollo.MutationResult<BrandModelDeleteMutation>;
export type BrandModelDeleteMutationOptions = Apollo.BaseMutationOptions<
  BrandModelDeleteMutation,
  BrandModelDeleteMutationVariables
>;
export const BrandModelUpdateDocument = gql`
  mutation brandModelUpdate($uuid: ID!, $input: UpdateBrandModelInput!) {
    brandModelUpdate(uuid: $uuid, input: $input) {
      brandModel {
        uuid
        createdAt
        updatedAt
        model_name
        info_title
        info_description
        car_brand {
          uuid
          createdAt
          updatedAt
          brand_name
          info_title
          info_description
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          pictogram_dark {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          brand_models {
            uuid
            createdAt
            updatedAt
            model_name
            info_title
            info_description
            car_brand {
              uuid
              createdAt
              updatedAt
              brand_name
              info_title
              info_description
            }
            model_years {
              uuid
              createdAt
              updatedAt
              start_year
              end_year
              info_title
              info_description
              brand_model {
                uuid
                createdAt
                updatedAt
                model_name
                info_title
                info_description
                car_brand {
                  uuid
                  createdAt
                  updatedAt
                  brand_name
                  info_title
                  info_description
                }
                model_years {
                  uuid
                  createdAt
                  updatedAt
                  start_year
                  end_year
                  info_title
                  info_description
                  car_versions {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                        thumbnail {
                          width
                          height
                          size
                          url
                          file_name
                        }
                        medium {
                          width
                          height
                          size
                          url
                          file_name
                        }
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        version_lateral {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          side_glazings {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            info_title
                            info_description
                            preview_text
                          }
                          cars_backwards {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                              car_version {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                info_title
                                info_description
                                preview_text
                                pictogram {
                                  uuid
                                  createdAt
                                  updatedAt
                                  file_name
                                  url
                                  mime_type
                                  width
                                  height
                                  size
                                  caption
                                  description
                                  alternative_title
                                }
                                version_laterals {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  extra_price
                                  installer_extra_price
                                  installation_difficulty
                                  info_title
                                  info_description
                                  preview_text
                                  cars_backwards {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    preview_text
                                    car_backward_types {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      has_rear_camera
                                      pictogram {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      preview_image {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      car_backward {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        extra_price
                                        installer_extra_price
                                        installation_difficulty
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_lateral {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        car_backward_types {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          has_rear_camera
                                        }
                                      }
                                    }
                                  }
                                  car_version {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    preview_text
                                    model_year {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      brand_model {
                                        uuid
                                        createdAt
                                        updatedAt
                                        model_name
                                        info_title
                                        info_description
                                      }
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        model_years {
          uuid
          createdAt
          updatedAt
          start_year
          end_year
          info_title
          info_description
        }
      }
    }
  }
`;
export type BrandModelUpdateMutationFn = Apollo.MutationFunction<
  BrandModelUpdateMutation,
  BrandModelUpdateMutationVariables
>;

/**
 * __useBrandModelUpdateMutation__
 *
 * To run a mutation, you first call `useBrandModelUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBrandModelUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [brandModelUpdateMutation, { data, loading, error }] = useBrandModelUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBrandModelUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BrandModelUpdateMutation,
    BrandModelUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BrandModelUpdateMutation,
    BrandModelUpdateMutationVariables
  >(BrandModelUpdateDocument, options);
}
export type BrandModelUpdateMutationHookResult = ReturnType<
  typeof useBrandModelUpdateMutation
>;
export type BrandModelUpdateMutationResult =
  Apollo.MutationResult<BrandModelUpdateMutation>;
export type BrandModelUpdateMutationOptions = Apollo.BaseMutationOptions<
  BrandModelUpdateMutation,
  BrandModelUpdateMutationVariables
>;
export const CarBackwardCreateDocument = gql`
  mutation carBackwardCreate($input: CreateCarBackwardInput!) {
    carBackwardCreate(input: $input) {
      carBackward {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        extra_price
        installer_extra_price
        installation_difficulty
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        version_lateral {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          installation_difficulty
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          side_glazings {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
              cars_backwards {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                info_title
                info_description
                extra_price
                installer_extra_price
                installation_difficulty
                preview_text
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                preview_image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                version_lateral {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  info_title
                  info_description
                  preview_text
                  car_version {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                      }
                      cars_backwards {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        preview_text
                        car_backward_types {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          has_rear_camera
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          car_backward {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                            }
                            car_backward_types {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              has_rear_camera
                            }
                          }
                        }
                      }
                      car_version {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        preview_text
                        model_year {
                          uuid
                          createdAt
                          updatedAt
                          start_year
                          end_year
                          info_title
                          info_description
                          brand_model {
                            uuid
                            createdAt
                            updatedAt
                            model_name
                            info_title
                            info_description
                            car_brand {
                              uuid
                              createdAt
                              updatedAt
                              brand_name
                              info_title
                              info_description
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              pictogram_dark {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                                thumbnail {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                                medium {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                              }
                              brand_models {
                                uuid
                                createdAt
                                updatedAt
                                model_name
                                info_title
                                info_description
                                car_brand {
                                  uuid
                                  createdAt
                                  updatedAt
                                  brand_name
                                  info_title
                                  info_description
                                }
                                model_years {
                                  uuid
                                  createdAt
                                  updatedAt
                                  start_year
                                  end_year
                                  info_title
                                  info_description
                                  brand_model {
                                    uuid
                                    createdAt
                                    updatedAt
                                    model_name
                                    info_title
                                    info_description
                                    model_years {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_laterals {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                          car_versions {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        car_backward_types {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          has_rear_camera
        }
      }
    }
  }
`;
export type CarBackwardCreateMutationFn = Apollo.MutationFunction<
  CarBackwardCreateMutation,
  CarBackwardCreateMutationVariables
>;

/**
 * __useCarBackwardCreateMutation__
 *
 * To run a mutation, you first call `useCarBackwardCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCarBackwardCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [carBackwardCreateMutation, { data, loading, error }] = useCarBackwardCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCarBackwardCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CarBackwardCreateMutation,
    CarBackwardCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CarBackwardCreateMutation,
    CarBackwardCreateMutationVariables
  >(CarBackwardCreateDocument, options);
}
export type CarBackwardCreateMutationHookResult = ReturnType<
  typeof useCarBackwardCreateMutation
>;
export type CarBackwardCreateMutationResult =
  Apollo.MutationResult<CarBackwardCreateMutation>;
export type CarBackwardCreateMutationOptions = Apollo.BaseMutationOptions<
  CarBackwardCreateMutation,
  CarBackwardCreateMutationVariables
>;
export const CarBackwardDeleteDocument = gql`
  mutation carBackwardDelete($uuid: String!) {
    carBackwardDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type CarBackwardDeleteMutationFn = Apollo.MutationFunction<
  CarBackwardDeleteMutation,
  CarBackwardDeleteMutationVariables
>;

/**
 * __useCarBackwardDeleteMutation__
 *
 * To run a mutation, you first call `useCarBackwardDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCarBackwardDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [carBackwardDeleteMutation, { data, loading, error }] = useCarBackwardDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCarBackwardDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CarBackwardDeleteMutation,
    CarBackwardDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CarBackwardDeleteMutation,
    CarBackwardDeleteMutationVariables
  >(CarBackwardDeleteDocument, options);
}
export type CarBackwardDeleteMutationHookResult = ReturnType<
  typeof useCarBackwardDeleteMutation
>;
export type CarBackwardDeleteMutationResult =
  Apollo.MutationResult<CarBackwardDeleteMutation>;
export type CarBackwardDeleteMutationOptions = Apollo.BaseMutationOptions<
  CarBackwardDeleteMutation,
  CarBackwardDeleteMutationVariables
>;
export const CarBackwardTypeCreateDocument = gql`
  mutation carBackwardTypeCreate($input: CreateCarBackwardTypeInput!) {
    carBackwardTypeCreate(input: $input) {
      carBackwardType {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        has_rear_camera
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        car_backward {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          extra_price
          installer_extra_price
          installation_difficulty
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          version_lateral {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            installation_difficulty
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            side_glazings {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              info_title
              info_description
              preview_text
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              preview_image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              version_lateral {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                extra_price
                installer_extra_price
                installation_difficulty
                info_title
                info_description
                preview_text
                cars_backwards {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  info_title
                  info_description
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  preview_text
                  pictogram {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  preview_image {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  version_lateral {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    extra_price
                    installer_extra_price
                    installation_difficulty
                    info_title
                    info_description
                    preview_text
                    car_version {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      version_laterals {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        side_glazings {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          info_title
                          info_description
                          preview_text
                        }
                        cars_backwards {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          preview_text
                          car_backward_types {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            has_rear_camera
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            car_backward {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              preview_text
                              car_backward_types {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                info_title
                                info_description
                                has_rear_camera
                              }
                            }
                          }
                        }
                        car_version {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          preview_text
                          model_year {
                            uuid
                            createdAt
                            updatedAt
                            start_year
                            end_year
                            info_title
                            info_description
                            brand_model {
                              uuid
                              createdAt
                              updatedAt
                              model_name
                              info_title
                              info_description
                              car_brand {
                                uuid
                                createdAt
                                updatedAt
                                brand_name
                                info_title
                                info_description
                                pictogram {
                                  uuid
                                  createdAt
                                  updatedAt
                                  file_name
                                  url
                                  mime_type
                                  width
                                  height
                                  size
                                  caption
                                  description
                                  alternative_title
                                }
                                pictogram_dark {
                                  uuid
                                  createdAt
                                  updatedAt
                                  file_name
                                  url
                                  mime_type
                                  width
                                  height
                                  size
                                  caption
                                  description
                                  alternative_title
                                  thumbnail {
                                    width
                                    height
                                    size
                                    url
                                    file_name
                                  }
                                  medium {
                                    width
                                    height
                                    size
                                    url
                                    file_name
                                  }
                                }
                                brand_models {
                                  uuid
                                  createdAt
                                  updatedAt
                                  model_name
                                  info_title
                                  info_description
                                  car_brand {
                                    uuid
                                    createdAt
                                    updatedAt
                                    brand_name
                                    info_title
                                    info_description
                                  }
                                  model_years {
                                    uuid
                                    createdAt
                                    updatedAt
                                    start_year
                                    end_year
                                    info_title
                                    info_description
                                    brand_model {
                                      uuid
                                      createdAt
                                      updatedAt
                                      model_name
                                      info_title
                                      info_description
                                      model_years {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                          pictogram {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          version_laterals {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            extra_price
                                            installer_extra_price
                                            installation_difficulty
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                          model_year {
                                            uuid
                                            createdAt
                                            updatedAt
                                            start_year
                                            end_year
                                            info_title
                                            info_description
                                            car_versions {
                                              uuid
                                              createdAt
                                              updatedAt
                                              title
                                              sub_title
                                              info_title
                                              info_description
                                              preview_text
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type CarBackwardTypeCreateMutationFn = Apollo.MutationFunction<
  CarBackwardTypeCreateMutation,
  CarBackwardTypeCreateMutationVariables
>;

/**
 * __useCarBackwardTypeCreateMutation__
 *
 * To run a mutation, you first call `useCarBackwardTypeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCarBackwardTypeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [carBackwardTypeCreateMutation, { data, loading, error }] = useCarBackwardTypeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCarBackwardTypeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CarBackwardTypeCreateMutation,
    CarBackwardTypeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CarBackwardTypeCreateMutation,
    CarBackwardTypeCreateMutationVariables
  >(CarBackwardTypeCreateDocument, options);
}
export type CarBackwardTypeCreateMutationHookResult = ReturnType<
  typeof useCarBackwardTypeCreateMutation
>;
export type CarBackwardTypeCreateMutationResult =
  Apollo.MutationResult<CarBackwardTypeCreateMutation>;
export type CarBackwardTypeCreateMutationOptions = Apollo.BaseMutationOptions<
  CarBackwardTypeCreateMutation,
  CarBackwardTypeCreateMutationVariables
>;
export const CarBackwardTypeDeleteDocument = gql`
  mutation carBackwardTypeDelete($uuid: String!) {
    carBackwardTypeDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type CarBackwardTypeDeleteMutationFn = Apollo.MutationFunction<
  CarBackwardTypeDeleteMutation,
  CarBackwardTypeDeleteMutationVariables
>;

/**
 * __useCarBackwardTypeDeleteMutation__
 *
 * To run a mutation, you first call `useCarBackwardTypeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCarBackwardTypeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [carBackwardTypeDeleteMutation, { data, loading, error }] = useCarBackwardTypeDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCarBackwardTypeDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CarBackwardTypeDeleteMutation,
    CarBackwardTypeDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CarBackwardTypeDeleteMutation,
    CarBackwardTypeDeleteMutationVariables
  >(CarBackwardTypeDeleteDocument, options);
}
export type CarBackwardTypeDeleteMutationHookResult = ReturnType<
  typeof useCarBackwardTypeDeleteMutation
>;
export type CarBackwardTypeDeleteMutationResult =
  Apollo.MutationResult<CarBackwardTypeDeleteMutation>;
export type CarBackwardTypeDeleteMutationOptions = Apollo.BaseMutationOptions<
  CarBackwardTypeDeleteMutation,
  CarBackwardTypeDeleteMutationVariables
>;
export const CarBackwardTypeUpdateDocument = gql`
  mutation carBackwardTypeUpdate(
    $uuid: String!
    $input: UpdateCarBackwardTypeInput!
  ) {
    carBackwardTypeUpdate(uuid: $uuid, input: $input) {
      carBackwardType {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        has_rear_camera
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        car_backward {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          extra_price
          installer_extra_price
          installation_difficulty
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          version_lateral {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            installation_difficulty
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            side_glazings {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              info_title
              info_description
              preview_text
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              preview_image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              version_lateral {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                extra_price
                installer_extra_price
                installation_difficulty
                info_title
                info_description
                preview_text
                cars_backwards {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  info_title
                  info_description
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  preview_text
                  pictogram {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  preview_image {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  version_lateral {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    extra_price
                    installer_extra_price
                    installation_difficulty
                    info_title
                    info_description
                    preview_text
                    car_version {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      version_laterals {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        side_glazings {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          info_title
                          info_description
                          preview_text
                        }
                        cars_backwards {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          preview_text
                          car_backward_types {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            has_rear_camera
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            car_backward {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              preview_text
                              car_backward_types {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                info_title
                                info_description
                                has_rear_camera
                              }
                            }
                          }
                        }
                        car_version {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          preview_text
                          model_year {
                            uuid
                            createdAt
                            updatedAt
                            start_year
                            end_year
                            info_title
                            info_description
                            brand_model {
                              uuid
                              createdAt
                              updatedAt
                              model_name
                              info_title
                              info_description
                              car_brand {
                                uuid
                                createdAt
                                updatedAt
                                brand_name
                                info_title
                                info_description
                                pictogram {
                                  uuid
                                  createdAt
                                  updatedAt
                                  file_name
                                  url
                                  mime_type
                                  width
                                  height
                                  size
                                  caption
                                  description
                                  alternative_title
                                }
                                pictogram_dark {
                                  uuid
                                  createdAt
                                  updatedAt
                                  file_name
                                  url
                                  mime_type
                                  width
                                  height
                                  size
                                  caption
                                  description
                                  alternative_title
                                  thumbnail {
                                    width
                                    height
                                    size
                                    url
                                    file_name
                                  }
                                  medium {
                                    width
                                    height
                                    size
                                    url
                                    file_name
                                  }
                                }
                                brand_models {
                                  uuid
                                  createdAt
                                  updatedAt
                                  model_name
                                  info_title
                                  info_description
                                  car_brand {
                                    uuid
                                    createdAt
                                    updatedAt
                                    brand_name
                                    info_title
                                    info_description
                                  }
                                  model_years {
                                    uuid
                                    createdAt
                                    updatedAt
                                    start_year
                                    end_year
                                    info_title
                                    info_description
                                    brand_model {
                                      uuid
                                      createdAt
                                      updatedAt
                                      model_name
                                      info_title
                                      info_description
                                      model_years {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                          pictogram {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          version_laterals {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            extra_price
                                            installer_extra_price
                                            installation_difficulty
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                          model_year {
                                            uuid
                                            createdAt
                                            updatedAt
                                            start_year
                                            end_year
                                            info_title
                                            info_description
                                            car_versions {
                                              uuid
                                              createdAt
                                              updatedAt
                                              title
                                              sub_title
                                              info_title
                                              info_description
                                              preview_text
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type CarBackwardTypeUpdateMutationFn = Apollo.MutationFunction<
  CarBackwardTypeUpdateMutation,
  CarBackwardTypeUpdateMutationVariables
>;

/**
 * __useCarBackwardTypeUpdateMutation__
 *
 * To run a mutation, you first call `useCarBackwardTypeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCarBackwardTypeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [carBackwardTypeUpdateMutation, { data, loading, error }] = useCarBackwardTypeUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCarBackwardTypeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CarBackwardTypeUpdateMutation,
    CarBackwardTypeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CarBackwardTypeUpdateMutation,
    CarBackwardTypeUpdateMutationVariables
  >(CarBackwardTypeUpdateDocument, options);
}
export type CarBackwardTypeUpdateMutationHookResult = ReturnType<
  typeof useCarBackwardTypeUpdateMutation
>;
export type CarBackwardTypeUpdateMutationResult =
  Apollo.MutationResult<CarBackwardTypeUpdateMutation>;
export type CarBackwardTypeUpdateMutationOptions = Apollo.BaseMutationOptions<
  CarBackwardTypeUpdateMutation,
  CarBackwardTypeUpdateMutationVariables
>;
export const CarBackwardUpdateDocument = gql`
  mutation carBackwardUpdate($uuid: String!, $input: UpdateCarBackwardInput!) {
    carBackwardUpdate(uuid: $uuid, input: $input) {
      carBackward {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        extra_price
        installer_extra_price
        installation_difficulty
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        version_lateral {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          installation_difficulty
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          side_glazings {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
              cars_backwards {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                info_title
                info_description
                extra_price
                installer_extra_price
                installation_difficulty
                preview_text
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                preview_image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                version_lateral {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  info_title
                  info_description
                  preview_text
                  car_version {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                      }
                      cars_backwards {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        preview_text
                        car_backward_types {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          has_rear_camera
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          car_backward {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                            }
                            car_backward_types {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              has_rear_camera
                            }
                          }
                        }
                      }
                      car_version {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        preview_text
                        model_year {
                          uuid
                          createdAt
                          updatedAt
                          start_year
                          end_year
                          info_title
                          info_description
                          brand_model {
                            uuid
                            createdAt
                            updatedAt
                            model_name
                            info_title
                            info_description
                            car_brand {
                              uuid
                              createdAt
                              updatedAt
                              brand_name
                              info_title
                              info_description
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              pictogram_dark {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                                thumbnail {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                                medium {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                              }
                              brand_models {
                                uuid
                                createdAt
                                updatedAt
                                model_name
                                info_title
                                info_description
                                car_brand {
                                  uuid
                                  createdAt
                                  updatedAt
                                  brand_name
                                  info_title
                                  info_description
                                }
                                model_years {
                                  uuid
                                  createdAt
                                  updatedAt
                                  start_year
                                  end_year
                                  info_title
                                  info_description
                                  brand_model {
                                    uuid
                                    createdAt
                                    updatedAt
                                    model_name
                                    info_title
                                    info_description
                                    model_years {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_laterals {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                          car_versions {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        car_backward_types {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          has_rear_camera
        }
      }
    }
  }
`;
export type CarBackwardUpdateMutationFn = Apollo.MutationFunction<
  CarBackwardUpdateMutation,
  CarBackwardUpdateMutationVariables
>;

/**
 * __useCarBackwardUpdateMutation__
 *
 * To run a mutation, you first call `useCarBackwardUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCarBackwardUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [carBackwardUpdateMutation, { data, loading, error }] = useCarBackwardUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCarBackwardUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CarBackwardUpdateMutation,
    CarBackwardUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CarBackwardUpdateMutation,
    CarBackwardUpdateMutationVariables
  >(CarBackwardUpdateDocument, options);
}
export type CarBackwardUpdateMutationHookResult = ReturnType<
  typeof useCarBackwardUpdateMutation
>;
export type CarBackwardUpdateMutationResult =
  Apollo.MutationResult<CarBackwardUpdateMutation>;
export type CarBackwardUpdateMutationOptions = Apollo.BaseMutationOptions<
  CarBackwardUpdateMutation,
  CarBackwardUpdateMutationVariables
>;
export const CarBrandCreateDocument = gql`
  mutation carBrandCreate($input: CreateCarBrandInput!) {
    carBrandCreate(input: $input) {
      carBrand {
        uuid
        createdAt
        updatedAt
        brand_name
        info_title
        info_description
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        pictogram_dark {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        brand_models {
          uuid
          createdAt
          updatedAt
          model_name
          info_title
          info_description
          car_brand {
            uuid
            createdAt
            updatedAt
            brand_name
            info_title
            info_description
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            pictogram_dark {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            brand_models {
              uuid
              createdAt
              updatedAt
              model_name
              info_title
              info_description
              model_years {
                uuid
                createdAt
                updatedAt
                start_year
                end_year
                info_title
                info_description
                brand_model {
                  uuid
                  createdAt
                  updatedAt
                  model_name
                  info_title
                  info_description
                  car_brand {
                    uuid
                    createdAt
                    updatedAt
                    brand_name
                    info_title
                    info_description
                  }
                  model_years {
                    uuid
                    createdAt
                    updatedAt
                    start_year
                    end_year
                    info_title
                    info_description
                    car_versions {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      version_laterals {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                          thumbnail {
                            width
                            height
                            size
                            url
                            file_name
                          }
                          medium {
                            width
                            height
                            size
                            url
                            file_name
                          }
                        }
                        side_glazings {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            side_glazings {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              info_title
                              info_description
                              preview_text
                            }
                            cars_backwards {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              preview_text
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              preview_image {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              version_lateral {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                extra_price
                                installer_extra_price
                                installation_difficulty
                                info_title
                                info_description
                                preview_text
                                car_version {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  info_title
                                  info_description
                                  preview_text
                                  pictogram {
                                    uuid
                                    createdAt
                                    updatedAt
                                    file_name
                                    url
                                    mime_type
                                    width
                                    height
                                    size
                                    caption
                                    description
                                    alternative_title
                                  }
                                  version_laterals {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    info_title
                                    info_description
                                    preview_text
                                    cars_backwards {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      extra_price
                                      installer_extra_price
                                      installation_difficulty
                                      preview_text
                                      car_backward_types {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        has_rear_camera
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        car_backward {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          preview_text
                                          pictogram {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          preview_image {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          version_lateral {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            extra_price
                                            installer_extra_price
                                            installation_difficulty
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                          car_backward_types {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            has_rear_camera
                                          }
                                        }
                                      }
                                    }
                                    car_version {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        brand_model {
                                          uuid
                                          createdAt
                                          updatedAt
                                          model_name
                                          info_title
                                          info_description
                                        }
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                          model_year {
                                            uuid
                                            createdAt
                                            updatedAt
                                            start_year
                                            end_year
                                            info_title
                                            info_description
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type CarBrandCreateMutationFn = Apollo.MutationFunction<
  CarBrandCreateMutation,
  CarBrandCreateMutationVariables
>;

/**
 * __useCarBrandCreateMutation__
 *
 * To run a mutation, you first call `useCarBrandCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCarBrandCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [carBrandCreateMutation, { data, loading, error }] = useCarBrandCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCarBrandCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CarBrandCreateMutation,
    CarBrandCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CarBrandCreateMutation,
    CarBrandCreateMutationVariables
  >(CarBrandCreateDocument, options);
}
export type CarBrandCreateMutationHookResult = ReturnType<
  typeof useCarBrandCreateMutation
>;
export type CarBrandCreateMutationResult =
  Apollo.MutationResult<CarBrandCreateMutation>;
export type CarBrandCreateMutationOptions = Apollo.BaseMutationOptions<
  CarBrandCreateMutation,
  CarBrandCreateMutationVariables
>;
export const CarBrandDeleteDocument = gql`
  mutation carBrandDelete($uuid: ID!) {
    carBrandDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type CarBrandDeleteMutationFn = Apollo.MutationFunction<
  CarBrandDeleteMutation,
  CarBrandDeleteMutationVariables
>;

/**
 * __useCarBrandDeleteMutation__
 *
 * To run a mutation, you first call `useCarBrandDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCarBrandDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [carBrandDeleteMutation, { data, loading, error }] = useCarBrandDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCarBrandDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CarBrandDeleteMutation,
    CarBrandDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CarBrandDeleteMutation,
    CarBrandDeleteMutationVariables
  >(CarBrandDeleteDocument, options);
}
export type CarBrandDeleteMutationHookResult = ReturnType<
  typeof useCarBrandDeleteMutation
>;
export type CarBrandDeleteMutationResult =
  Apollo.MutationResult<CarBrandDeleteMutation>;
export type CarBrandDeleteMutationOptions = Apollo.BaseMutationOptions<
  CarBrandDeleteMutation,
  CarBrandDeleteMutationVariables
>;
export const CarBrandUpdateDocument = gql`
  mutation carBrandUpdate($uuid: ID!, $input: UpdateCarBrandInput!) {
    carBrandUpdate(uuid: $uuid, input: $input) {
      carBrand {
        uuid
        createdAt
        updatedAt
        brand_name
        info_title
        info_description
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        pictogram_dark {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        brand_models {
          uuid
          createdAt
          updatedAt
          model_name
          info_title
          info_description
          car_brand {
            uuid
            createdAt
            updatedAt
            brand_name
            info_title
            info_description
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            pictogram_dark {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            brand_models {
              uuid
              createdAt
              updatedAt
              model_name
              info_title
              info_description
              model_years {
                uuid
                createdAt
                updatedAt
                start_year
                end_year
                info_title
                info_description
                brand_model {
                  uuid
                  createdAt
                  updatedAt
                  model_name
                  info_title
                  info_description
                  car_brand {
                    uuid
                    createdAt
                    updatedAt
                    brand_name
                    info_title
                    info_description
                  }
                  model_years {
                    uuid
                    createdAt
                    updatedAt
                    start_year
                    end_year
                    info_title
                    info_description
                    car_versions {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      version_laterals {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                          thumbnail {
                            width
                            height
                            size
                            url
                            file_name
                          }
                          medium {
                            width
                            height
                            size
                            url
                            file_name
                          }
                        }
                        side_glazings {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            side_glazings {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              info_title
                              info_description
                              preview_text
                            }
                            cars_backwards {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              preview_text
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              preview_image {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              version_lateral {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                extra_price
                                installer_extra_price
                                installation_difficulty
                                info_title
                                info_description
                                preview_text
                                car_version {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  info_title
                                  info_description
                                  preview_text
                                  pictogram {
                                    uuid
                                    createdAt
                                    updatedAt
                                    file_name
                                    url
                                    mime_type
                                    width
                                    height
                                    size
                                    caption
                                    description
                                    alternative_title
                                  }
                                  version_laterals {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    info_title
                                    info_description
                                    preview_text
                                    cars_backwards {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      extra_price
                                      installer_extra_price
                                      installation_difficulty
                                      preview_text
                                      car_backward_types {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        has_rear_camera
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        car_backward {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          preview_text
                                          pictogram {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          preview_image {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          version_lateral {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            extra_price
                                            installer_extra_price
                                            installation_difficulty
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                          car_backward_types {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            has_rear_camera
                                          }
                                        }
                                      }
                                    }
                                    car_version {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        brand_model {
                                          uuid
                                          createdAt
                                          updatedAt
                                          model_name
                                          info_title
                                          info_description
                                        }
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                          model_year {
                                            uuid
                                            createdAt
                                            updatedAt
                                            start_year
                                            end_year
                                            info_title
                                            info_description
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type CarBrandUpdateMutationFn = Apollo.MutationFunction<
  CarBrandUpdateMutation,
  CarBrandUpdateMutationVariables
>;

/**
 * __useCarBrandUpdateMutation__
 *
 * To run a mutation, you first call `useCarBrandUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCarBrandUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [carBrandUpdateMutation, { data, loading, error }] = useCarBrandUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCarBrandUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CarBrandUpdateMutation,
    CarBrandUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CarBrandUpdateMutation,
    CarBrandUpdateMutationVariables
  >(CarBrandUpdateDocument, options);
}
export type CarBrandUpdateMutationHookResult = ReturnType<
  typeof useCarBrandUpdateMutation
>;
export type CarBrandUpdateMutationResult =
  Apollo.MutationResult<CarBrandUpdateMutation>;
export type CarBrandUpdateMutationOptions = Apollo.BaseMutationOptions<
  CarBrandUpdateMutation,
  CarBrandUpdateMutationVariables
>;
export const CarVersionCreateDocument = gql`
  mutation carVersionCreate($input: CreateCarVersionInput!) {
    carVersionCreate(input: $input) {
      carVersion {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        version_laterals {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          installation_difficulty
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          side_glazings {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              preview_image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              side_glazings {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                extra_price
                installer_extra_price
                info_title
                info_description
                preview_text
              }
              cars_backwards {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                info_title
                info_description
                extra_price
                installer_extra_price
                installation_difficulty
                preview_text
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                preview_image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                version_lateral {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  info_title
                  info_description
                  preview_text
                  car_version {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      cars_backwards {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        preview_text
                        car_backward_types {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          has_rear_camera
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          car_backward {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                            }
                            car_backward_types {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              has_rear_camera
                            }
                          }
                        }
                      }
                      car_version {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        preview_text
                        model_year {
                          uuid
                          createdAt
                          updatedAt
                          start_year
                          end_year
                          info_title
                          info_description
                          brand_model {
                            uuid
                            createdAt
                            updatedAt
                            model_name
                            info_title
                            info_description
                            car_brand {
                              uuid
                              createdAt
                              updatedAt
                              brand_name
                              info_title
                              info_description
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              pictogram_dark {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                                thumbnail {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                                medium {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                              }
                              brand_models {
                                uuid
                                createdAt
                                updatedAt
                                model_name
                                info_title
                                info_description
                                car_brand {
                                  uuid
                                  createdAt
                                  updatedAt
                                  brand_name
                                  info_title
                                  info_description
                                }
                                model_years {
                                  uuid
                                  createdAt
                                  updatedAt
                                  start_year
                                  end_year
                                  info_title
                                  info_description
                                  brand_model {
                                    uuid
                                    createdAt
                                    updatedAt
                                    model_name
                                    info_title
                                    info_description
                                    model_years {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_laterals {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                          car_versions {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        model_year {
          uuid
          createdAt
          updatedAt
          start_year
          end_year
          info_title
          info_description
        }
      }
    }
  }
`;
export type CarVersionCreateMutationFn = Apollo.MutationFunction<
  CarVersionCreateMutation,
  CarVersionCreateMutationVariables
>;

/**
 * __useCarVersionCreateMutation__
 *
 * To run a mutation, you first call `useCarVersionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCarVersionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [carVersionCreateMutation, { data, loading, error }] = useCarVersionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCarVersionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CarVersionCreateMutation,
    CarVersionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CarVersionCreateMutation,
    CarVersionCreateMutationVariables
  >(CarVersionCreateDocument, options);
}
export type CarVersionCreateMutationHookResult = ReturnType<
  typeof useCarVersionCreateMutation
>;
export type CarVersionCreateMutationResult =
  Apollo.MutationResult<CarVersionCreateMutation>;
export type CarVersionCreateMutationOptions = Apollo.BaseMutationOptions<
  CarVersionCreateMutation,
  CarVersionCreateMutationVariables
>;
export const CarVersionRemoveDocument = gql`
  mutation carVersionRemove($uuid: String!) {
    carVersionRemove(uuid: $uuid) {
      uuid
    }
  }
`;
export type CarVersionRemoveMutationFn = Apollo.MutationFunction<
  CarVersionRemoveMutation,
  CarVersionRemoveMutationVariables
>;

/**
 * __useCarVersionRemoveMutation__
 *
 * To run a mutation, you first call `useCarVersionRemoveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCarVersionRemoveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [carVersionRemoveMutation, { data, loading, error }] = useCarVersionRemoveMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCarVersionRemoveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CarVersionRemoveMutation,
    CarVersionRemoveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CarVersionRemoveMutation,
    CarVersionRemoveMutationVariables
  >(CarVersionRemoveDocument, options);
}
export type CarVersionRemoveMutationHookResult = ReturnType<
  typeof useCarVersionRemoveMutation
>;
export type CarVersionRemoveMutationResult =
  Apollo.MutationResult<CarVersionRemoveMutation>;
export type CarVersionRemoveMutationOptions = Apollo.BaseMutationOptions<
  CarVersionRemoveMutation,
  CarVersionRemoveMutationVariables
>;
export const CarVersionUpdateDocument = gql`
  mutation carVersionUpdate($uuid: String!, $input: UpdateCarVersionInput!) {
    carVersionUpdate(uuid: $uuid, input: $input) {
      carVersion {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        version_laterals {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          installation_difficulty
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          side_glazings {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              preview_image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              side_glazings {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                extra_price
                installer_extra_price
                info_title
                info_description
                preview_text
              }
              cars_backwards {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                info_title
                info_description
                extra_price
                installer_extra_price
                installation_difficulty
                preview_text
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                preview_image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                version_lateral {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  info_title
                  info_description
                  preview_text
                  car_version {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      cars_backwards {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        preview_text
                        car_backward_types {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          has_rear_camera
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          car_backward {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                            }
                            car_backward_types {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              has_rear_camera
                            }
                          }
                        }
                      }
                      car_version {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        preview_text
                        model_year {
                          uuid
                          createdAt
                          updatedAt
                          start_year
                          end_year
                          info_title
                          info_description
                          brand_model {
                            uuid
                            createdAt
                            updatedAt
                            model_name
                            info_title
                            info_description
                            car_brand {
                              uuid
                              createdAt
                              updatedAt
                              brand_name
                              info_title
                              info_description
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              pictogram_dark {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                                thumbnail {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                                medium {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                              }
                              brand_models {
                                uuid
                                createdAt
                                updatedAt
                                model_name
                                info_title
                                info_description
                                car_brand {
                                  uuid
                                  createdAt
                                  updatedAt
                                  brand_name
                                  info_title
                                  info_description
                                }
                                model_years {
                                  uuid
                                  createdAt
                                  updatedAt
                                  start_year
                                  end_year
                                  info_title
                                  info_description
                                  brand_model {
                                    uuid
                                    createdAt
                                    updatedAt
                                    model_name
                                    info_title
                                    info_description
                                    model_years {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_laterals {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                          car_versions {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        model_year {
          uuid
          createdAt
          updatedAt
          start_year
          end_year
          info_title
          info_description
        }
      }
    }
  }
`;
export type CarVersionUpdateMutationFn = Apollo.MutationFunction<
  CarVersionUpdateMutation,
  CarVersionUpdateMutationVariables
>;

/**
 * __useCarVersionUpdateMutation__
 *
 * To run a mutation, you first call `useCarVersionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCarVersionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [carVersionUpdateMutation, { data, loading, error }] = useCarVersionUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCarVersionUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CarVersionUpdateMutation,
    CarVersionUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CarVersionUpdateMutation,
    CarVersionUpdateMutationVariables
  >(CarVersionUpdateDocument, options);
}
export type CarVersionUpdateMutationHookResult = ReturnType<
  typeof useCarVersionUpdateMutation
>;
export type CarVersionUpdateMutationResult =
  Apollo.MutationResult<CarVersionUpdateMutation>;
export type CarVersionUpdateMutationOptions = Apollo.BaseMutationOptions<
  CarVersionUpdateMutation,
  CarVersionUpdateMutationVariables
>;
export const Extra_StickerCreateDocument = gql`
  mutation extra_stickerCreate($input: CreateExtraStickersInput!) {
    extra_stickerCreate(input: $input) {
      extra_stickers {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        design_choice_possible
        extra_price
        extra_price_sections {
          section_uuid
          extra_price
        }
        installer_extra_price
        overview
        video_url
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        depends_on_sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
      }
      product_uuid
    }
  }
`;
export type Extra_StickerCreateMutationFn = Apollo.MutationFunction<
  Extra_StickerCreateMutation,
  Extra_StickerCreateMutationVariables
>;

/**
 * __useExtra_StickerCreateMutation__
 *
 * To run a mutation, you first call `useExtra_StickerCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtra_StickerCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extraStickerCreateMutation, { data, loading, error }] = useExtra_StickerCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtra_StickerCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Extra_StickerCreateMutation,
    Extra_StickerCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Extra_StickerCreateMutation,
    Extra_StickerCreateMutationVariables
  >(Extra_StickerCreateDocument, options);
}
export type Extra_StickerCreateMutationHookResult = ReturnType<
  typeof useExtra_StickerCreateMutation
>;
export type Extra_StickerCreateMutationResult =
  Apollo.MutationResult<Extra_StickerCreateMutation>;
export type Extra_StickerCreateMutationOptions = Apollo.BaseMutationOptions<
  Extra_StickerCreateMutation,
  Extra_StickerCreateMutationVariables
>;
export const Extra_StickerDeleteDocument = gql`
  mutation extra_stickerDelete($uuid: ID!) {
    extra_stickerDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type Extra_StickerDeleteMutationFn = Apollo.MutationFunction<
  Extra_StickerDeleteMutation,
  Extra_StickerDeleteMutationVariables
>;

/**
 * __useExtra_StickerDeleteMutation__
 *
 * To run a mutation, you first call `useExtra_StickerDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtra_StickerDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extraStickerDeleteMutation, { data, loading, error }] = useExtra_StickerDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useExtra_StickerDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Extra_StickerDeleteMutation,
    Extra_StickerDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Extra_StickerDeleteMutation,
    Extra_StickerDeleteMutationVariables
  >(Extra_StickerDeleteDocument, options);
}
export type Extra_StickerDeleteMutationHookResult = ReturnType<
  typeof useExtra_StickerDeleteMutation
>;
export type Extra_StickerDeleteMutationResult =
  Apollo.MutationResult<Extra_StickerDeleteMutation>;
export type Extra_StickerDeleteMutationOptions = Apollo.BaseMutationOptions<
  Extra_StickerDeleteMutation,
  Extra_StickerDeleteMutationVariables
>;
export const Extra_StickerUpdateDocument = gql`
  mutation extra_stickerUpdate($uuid: ID!, $input: ExtraStickerUpdateInput!) {
    extra_stickerUpdate(uuid: $uuid, input: $input) {
      extra_sticker {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        design_choice_possible
        extra_price
        extra_price_sections {
          section_uuid
          extra_price
        }
        installer_extra_price
        overview
        video_url
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        depends_on_sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
      }
    }
  }
`;
export type Extra_StickerUpdateMutationFn = Apollo.MutationFunction<
  Extra_StickerUpdateMutation,
  Extra_StickerUpdateMutationVariables
>;

/**
 * __useExtra_StickerUpdateMutation__
 *
 * To run a mutation, you first call `useExtra_StickerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtra_StickerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extraStickerUpdateMutation, { data, loading, error }] = useExtra_StickerUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtra_StickerUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Extra_StickerUpdateMutation,
    Extra_StickerUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Extra_StickerUpdateMutation,
    Extra_StickerUpdateMutationVariables
  >(Extra_StickerUpdateDocument, options);
}
export type Extra_StickerUpdateMutationHookResult = ReturnType<
  typeof useExtra_StickerUpdateMutation
>;
export type Extra_StickerUpdateMutationResult =
  Apollo.MutationResult<Extra_StickerUpdateMutation>;
export type Extra_StickerUpdateMutationOptions = Apollo.BaseMutationOptions<
  Extra_StickerUpdateMutation,
  Extra_StickerUpdateMutationVariables
>;
export const Extra_StickerUpdateDependenciesDocument = gql`
  mutation extra_stickerUpdateDependencies(
    $uuid: ID!
    $input: ExtraStickerUpdateDependenciesInput!
  ) {
    extra_stickerUpdateDependencies(uuid: $uuid, input: $input) {
      extra_sticker {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        design_choice_possible
        extra_price
        extra_price_sections {
          section_uuid
          extra_price
        }
        installer_extra_price
        overview
        video_url
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        depends_on_sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
      }
    }
  }
`;
export type Extra_StickerUpdateDependenciesMutationFn = Apollo.MutationFunction<
  Extra_StickerUpdateDependenciesMutation,
  Extra_StickerUpdateDependenciesMutationVariables
>;

/**
 * __useExtra_StickerUpdateDependenciesMutation__
 *
 * To run a mutation, you first call `useExtra_StickerUpdateDependenciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtra_StickerUpdateDependenciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extraStickerUpdateDependenciesMutation, { data, loading, error }] = useExtra_StickerUpdateDependenciesMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExtra_StickerUpdateDependenciesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Extra_StickerUpdateDependenciesMutation,
    Extra_StickerUpdateDependenciesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Extra_StickerUpdateDependenciesMutation,
    Extra_StickerUpdateDependenciesMutationVariables
  >(Extra_StickerUpdateDependenciesDocument, options);
}
export type Extra_StickerUpdateDependenciesMutationHookResult = ReturnType<
  typeof useExtra_StickerUpdateDependenciesMutation
>;
export type Extra_StickerUpdateDependenciesMutationResult =
  Apollo.MutationResult<Extra_StickerUpdateDependenciesMutation>;
export type Extra_StickerUpdateDependenciesMutationOptions =
  Apollo.BaseMutationOptions<
    Extra_StickerUpdateDependenciesMutation,
    Extra_StickerUpdateDependenciesMutationVariables
  >;
export const ModelYearCreateDocument = gql`
  mutation modelYearCreate($input: CreateModelYearInput!) {
    modelYearCreate(input: $input) {
      modelYear {
        uuid
        createdAt
        updatedAt
        start_year
        end_year
        info_title
        info_description
        brand_model {
          uuid
          createdAt
          updatedAt
          model_name
          info_title
          info_description
          car_brand {
            uuid
            createdAt
            updatedAt
            brand_name
            info_title
            info_description
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            pictogram_dark {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            brand_models {
              uuid
              createdAt
              updatedAt
              model_name
              info_title
              info_description
              car_brand {
                uuid
                createdAt
                updatedAt
                brand_name
                info_title
                info_description
              }
              model_years {
                uuid
                createdAt
                updatedAt
                start_year
                end_year
                info_title
                info_description
                brand_model {
                  uuid
                  createdAt
                  updatedAt
                  model_name
                  info_title
                  info_description
                  model_years {
                    uuid
                    createdAt
                    updatedAt
                    start_year
                    end_year
                    info_title
                    info_description
                    car_versions {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      version_laterals {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                          thumbnail {
                            width
                            height
                            size
                            url
                            file_name
                          }
                          medium {
                            width
                            height
                            size
                            url
                            file_name
                          }
                        }
                        side_glazings {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            side_glazings {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              info_title
                              info_description
                              preview_text
                            }
                            cars_backwards {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              preview_text
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              preview_image {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              version_lateral {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                extra_price
                                installer_extra_price
                                installation_difficulty
                                info_title
                                info_description
                                preview_text
                                car_version {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  info_title
                                  info_description
                                  preview_text
                                  pictogram {
                                    uuid
                                    createdAt
                                    updatedAt
                                    file_name
                                    url
                                    mime_type
                                    width
                                    height
                                    size
                                    caption
                                    description
                                    alternative_title
                                  }
                                  version_laterals {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    info_title
                                    info_description
                                    preview_text
                                    cars_backwards {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      extra_price
                                      installer_extra_price
                                      installation_difficulty
                                      preview_text
                                      car_backward_types {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        has_rear_camera
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        car_backward {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          preview_text
                                          pictogram {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          preview_image {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          version_lateral {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            extra_price
                                            installer_extra_price
                                            installation_difficulty
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                          car_backward_types {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            has_rear_camera
                                          }
                                        }
                                      }
                                    }
                                    car_version {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        brand_model {
                                          uuid
                                          createdAt
                                          updatedAt
                                          model_name
                                          info_title
                                          info_description
                                        }
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                          model_year {
                                            uuid
                                            createdAt
                                            updatedAt
                                            start_year
                                            end_year
                                            info_title
                                            info_description
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        car_versions {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          preview_text
        }
      }
    }
  }
`;
export type ModelYearCreateMutationFn = Apollo.MutationFunction<
  ModelYearCreateMutation,
  ModelYearCreateMutationVariables
>;

/**
 * __useModelYearCreateMutation__
 *
 * To run a mutation, you first call `useModelYearCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModelYearCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modelYearCreateMutation, { data, loading, error }] = useModelYearCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModelYearCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModelYearCreateMutation,
    ModelYearCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ModelYearCreateMutation,
    ModelYearCreateMutationVariables
  >(ModelYearCreateDocument, options);
}
export type ModelYearCreateMutationHookResult = ReturnType<
  typeof useModelYearCreateMutation
>;
export type ModelYearCreateMutationResult =
  Apollo.MutationResult<ModelYearCreateMutation>;
export type ModelYearCreateMutationOptions = Apollo.BaseMutationOptions<
  ModelYearCreateMutation,
  ModelYearCreateMutationVariables
>;
export const ModelYearDeleteDocument = gql`
  mutation modelYearDelete($uuid: String!) {
    modelYearDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type ModelYearDeleteMutationFn = Apollo.MutationFunction<
  ModelYearDeleteMutation,
  ModelYearDeleteMutationVariables
>;

/**
 * __useModelYearDeleteMutation__
 *
 * To run a mutation, you first call `useModelYearDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModelYearDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modelYearDeleteMutation, { data, loading, error }] = useModelYearDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useModelYearDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModelYearDeleteMutation,
    ModelYearDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ModelYearDeleteMutation,
    ModelYearDeleteMutationVariables
  >(ModelYearDeleteDocument, options);
}
export type ModelYearDeleteMutationHookResult = ReturnType<
  typeof useModelYearDeleteMutation
>;
export type ModelYearDeleteMutationResult =
  Apollo.MutationResult<ModelYearDeleteMutation>;
export type ModelYearDeleteMutationOptions = Apollo.BaseMutationOptions<
  ModelYearDeleteMutation,
  ModelYearDeleteMutationVariables
>;
export const ModelYearUpdateDocument = gql`
  mutation modelYearUpdate($uuid: String!, $input: UpdateModelYearInput!) {
    modelYearUpdate(uuid: $uuid, input: $input) {
      modelYear {
        uuid
        createdAt
        updatedAt
        start_year
        end_year
        info_title
        info_description
        brand_model {
          uuid
          createdAt
          updatedAt
          model_name
          info_title
          info_description
          car_brand {
            uuid
            createdAt
            updatedAt
            brand_name
            info_title
            info_description
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            pictogram_dark {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            brand_models {
              uuid
              createdAt
              updatedAt
              model_name
              info_title
              info_description
              car_brand {
                uuid
                createdAt
                updatedAt
                brand_name
                info_title
                info_description
              }
              model_years {
                uuid
                createdAt
                updatedAt
                start_year
                end_year
                info_title
                info_description
                brand_model {
                  uuid
                  createdAt
                  updatedAt
                  model_name
                  info_title
                  info_description
                  model_years {
                    uuid
                    createdAt
                    updatedAt
                    start_year
                    end_year
                    info_title
                    info_description
                    car_versions {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      version_laterals {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                          thumbnail {
                            width
                            height
                            size
                            url
                            file_name
                          }
                          medium {
                            width
                            height
                            size
                            url
                            file_name
                          }
                        }
                        side_glazings {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            side_glazings {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              info_title
                              info_description
                              preview_text
                            }
                            cars_backwards {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              preview_text
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              preview_image {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              version_lateral {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                extra_price
                                installer_extra_price
                                installation_difficulty
                                info_title
                                info_description
                                preview_text
                                car_version {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  info_title
                                  info_description
                                  preview_text
                                  pictogram {
                                    uuid
                                    createdAt
                                    updatedAt
                                    file_name
                                    url
                                    mime_type
                                    width
                                    height
                                    size
                                    caption
                                    description
                                    alternative_title
                                  }
                                  version_laterals {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    info_title
                                    info_description
                                    preview_text
                                    cars_backwards {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      extra_price
                                      installer_extra_price
                                      installation_difficulty
                                      preview_text
                                      car_backward_types {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        has_rear_camera
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        car_backward {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          preview_text
                                          pictogram {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          preview_image {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          version_lateral {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            extra_price
                                            installer_extra_price
                                            installation_difficulty
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                          car_backward_types {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            has_rear_camera
                                          }
                                        }
                                      }
                                    }
                                    car_version {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        brand_model {
                                          uuid
                                          createdAt
                                          updatedAt
                                          model_name
                                          info_title
                                          info_description
                                        }
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                          model_year {
                                            uuid
                                            createdAt
                                            updatedAt
                                            start_year
                                            end_year
                                            info_title
                                            info_description
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        car_versions {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          preview_text
        }
      }
    }
  }
`;
export type ModelYearUpdateMutationFn = Apollo.MutationFunction<
  ModelYearUpdateMutation,
  ModelYearUpdateMutationVariables
>;

/**
 * __useModelYearUpdateMutation__
 *
 * To run a mutation, you first call `useModelYearUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModelYearUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modelYearUpdateMutation, { data, loading, error }] = useModelYearUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useModelYearUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModelYearUpdateMutation,
    ModelYearUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ModelYearUpdateMutation,
    ModelYearUpdateMutationVariables
  >(ModelYearUpdateDocument, options);
}
export type ModelYearUpdateMutationHookResult = ReturnType<
  typeof useModelYearUpdateMutation
>;
export type ModelYearUpdateMutationResult =
  Apollo.MutationResult<ModelYearUpdateMutation>;
export type ModelYearUpdateMutationOptions = Apollo.BaseMutationOptions<
  ModelYearUpdateMutation,
  ModelYearUpdateMutationVariables
>;
export const ProductCreateDocument = gql`
  mutation productCreate($input: CreateProductInput!) {
    productCreate(input: $input) {
      product {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        type
        description
        slug
        meta_title
        meta_description
        verification
        graphic_service
        professional_installer
        enabled
        index
        start_price
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        supports {
          uuid
          createdAt
          updatedAt
          title
          can_have_background
          extra_price
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
        sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
        sections {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          can_have_background
          extra_price
          canvas_width
          canvas_height
          canvas_radius
          extra_price_size {
            size_uuid
            extra_price
          }
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
          }
        }
        extra_stickers {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          design_choice_possible
          extra_price
          extra_price_sections {
            section_uuid
            extra_price
          }
          installer_extra_price
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
          }
        }
        templates {
          uuid
          createdAt
          updatedAt
          title
          file {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
        product_images {
          uuid
          createdAt
          updatedAt
          support_uuid
          size_uuid
          section_uuid
          extra_sticker_uuid
          file {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
        }
      }
    }
  }
`;
export type ProductCreateMutationFn = Apollo.MutationFunction<
  ProductCreateMutation,
  ProductCreateMutationVariables
>;

/**
 * __useProductCreateMutation__
 *
 * To run a mutation, you first call `useProductCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productCreateMutation, { data, loading, error }] = useProductCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProductCreateMutation,
    ProductCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProductCreateMutation,
    ProductCreateMutationVariables
  >(ProductCreateDocument, options);
}
export type ProductCreateMutationHookResult = ReturnType<
  typeof useProductCreateMutation
>;
export type ProductCreateMutationResult =
  Apollo.MutationResult<ProductCreateMutation>;
export type ProductCreateMutationOptions = Apollo.BaseMutationOptions<
  ProductCreateMutation,
  ProductCreateMutationVariables
>;
export const ProductDeleteDocument = gql`
  mutation productDelete($uuid: ID!) {
    productDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type ProductDeleteMutationFn = Apollo.MutationFunction<
  ProductDeleteMutation,
  ProductDeleteMutationVariables
>;

/**
 * __useProductDeleteMutation__
 *
 * To run a mutation, you first call `useProductDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productDeleteMutation, { data, loading, error }] = useProductDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useProductDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProductDeleteMutation,
    ProductDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProductDeleteMutation,
    ProductDeleteMutationVariables
  >(ProductDeleteDocument, options);
}
export type ProductDeleteMutationHookResult = ReturnType<
  typeof useProductDeleteMutation
>;
export type ProductDeleteMutationResult =
  Apollo.MutationResult<ProductDeleteMutation>;
export type ProductDeleteMutationOptions = Apollo.BaseMutationOptions<
  ProductDeleteMutation,
  ProductDeleteMutationVariables
>;
export const ProductImageCreateDocument = gql`
  mutation productImageCreate($input: CreateProductImageInput!) {
    productImageCreate(input: $input) {
      product_image {
        uuid
        createdAt
        updatedAt
        support_uuid
        size_uuid
        section_uuid
        extra_sticker_uuid
        file {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;
export type ProductImageCreateMutationFn = Apollo.MutationFunction<
  ProductImageCreateMutation,
  ProductImageCreateMutationVariables
>;

/**
 * __useProductImageCreateMutation__
 *
 * To run a mutation, you first call `useProductImageCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductImageCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productImageCreateMutation, { data, loading, error }] = useProductImageCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductImageCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProductImageCreateMutation,
    ProductImageCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProductImageCreateMutation,
    ProductImageCreateMutationVariables
  >(ProductImageCreateDocument, options);
}
export type ProductImageCreateMutationHookResult = ReturnType<
  typeof useProductImageCreateMutation
>;
export type ProductImageCreateMutationResult =
  Apollo.MutationResult<ProductImageCreateMutation>;
export type ProductImageCreateMutationOptions = Apollo.BaseMutationOptions<
  ProductImageCreateMutation,
  ProductImageCreateMutationVariables
>;
export const ProductImageDeleteDocument = gql`
  mutation productImageDelete($uuid: ID!) {
    productImageDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type ProductImageDeleteMutationFn = Apollo.MutationFunction<
  ProductImageDeleteMutation,
  ProductImageDeleteMutationVariables
>;

/**
 * __useProductImageDeleteMutation__
 *
 * To run a mutation, you first call `useProductImageDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductImageDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productImageDeleteMutation, { data, loading, error }] = useProductImageDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useProductImageDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProductImageDeleteMutation,
    ProductImageDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProductImageDeleteMutation,
    ProductImageDeleteMutationVariables
  >(ProductImageDeleteDocument, options);
}
export type ProductImageDeleteMutationHookResult = ReturnType<
  typeof useProductImageDeleteMutation
>;
export type ProductImageDeleteMutationResult =
  Apollo.MutationResult<ProductImageDeleteMutation>;
export type ProductImageDeleteMutationOptions = Apollo.BaseMutationOptions<
  ProductImageDeleteMutation,
  ProductImageDeleteMutationVariables
>;
export const ProductUpdateDocument = gql`
  mutation productUpdate($uuid: ID!, $input: ProductUpdateInput!) {
    productUpdate(uuid: $uuid, input: $input) {
      product {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        type
        description
        slug
        meta_title
        meta_description
        verification
        graphic_service
        professional_installer
        enabled
        index
        start_price
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        supports {
          uuid
          createdAt
          updatedAt
          title
          can_have_background
          extra_price
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
        sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
        sections {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          can_have_background
          extra_price
          canvas_width
          canvas_height
          canvas_radius
          extra_price_size {
            size_uuid
            extra_price
          }
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
          }
        }
        extra_stickers {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          design_choice_possible
          extra_price
          extra_price_sections {
            section_uuid
            extra_price
          }
          installer_extra_price
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
          }
        }
        templates {
          uuid
          createdAt
          updatedAt
          title
          file {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
        product_images {
          uuid
          createdAt
          updatedAt
          support_uuid
          size_uuid
          section_uuid
          extra_sticker_uuid
          file {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
        }
      }
    }
  }
`;
export type ProductUpdateMutationFn = Apollo.MutationFunction<
  ProductUpdateMutation,
  ProductUpdateMutationVariables
>;

/**
 * __useProductUpdateMutation__
 *
 * To run a mutation, you first call `useProductUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productUpdateMutation, { data, loading, error }] = useProductUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ProductUpdateMutation,
    ProductUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ProductUpdateMutation,
    ProductUpdateMutationVariables
  >(ProductUpdateDocument, options);
}
export type ProductUpdateMutationHookResult = ReturnType<
  typeof useProductUpdateMutation
>;
export type ProductUpdateMutationResult =
  Apollo.MutationResult<ProductUpdateMutation>;
export type ProductUpdateMutationOptions = Apollo.BaseMutationOptions<
  ProductUpdateMutation,
  ProductUpdateMutationVariables
>;
export const SectionCreateDocument = gql`
  mutation sectionCreate($input: CreateSectionsInput!) {
    sectionCreate(input: $input) {
      sections {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        can_have_background
        extra_price
        canvas_width
        canvas_height
        canvas_radius
        extra_price_size {
          size_uuid
          extra_price
        }
        overview
        video_url
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        depends_on_sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
      }
      product_uuid
    }
  }
`;
export type SectionCreateMutationFn = Apollo.MutationFunction<
  SectionCreateMutation,
  SectionCreateMutationVariables
>;

/**
 * __useSectionCreateMutation__
 *
 * To run a mutation, you first call `useSectionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectionCreateMutation, { data, loading, error }] = useSectionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSectionCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SectionCreateMutation,
    SectionCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SectionCreateMutation,
    SectionCreateMutationVariables
  >(SectionCreateDocument, options);
}
export type SectionCreateMutationHookResult = ReturnType<
  typeof useSectionCreateMutation
>;
export type SectionCreateMutationResult =
  Apollo.MutationResult<SectionCreateMutation>;
export type SectionCreateMutationOptions = Apollo.BaseMutationOptions<
  SectionCreateMutation,
  SectionCreateMutationVariables
>;
export const SectionDeleteDocument = gql`
  mutation sectionDelete($uuid: ID!) {
    sectionDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type SectionDeleteMutationFn = Apollo.MutationFunction<
  SectionDeleteMutation,
  SectionDeleteMutationVariables
>;

/**
 * __useSectionDeleteMutation__
 *
 * To run a mutation, you first call `useSectionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectionDeleteMutation, { data, loading, error }] = useSectionDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSectionDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SectionDeleteMutation,
    SectionDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SectionDeleteMutation,
    SectionDeleteMutationVariables
  >(SectionDeleteDocument, options);
}
export type SectionDeleteMutationHookResult = ReturnType<
  typeof useSectionDeleteMutation
>;
export type SectionDeleteMutationResult =
  Apollo.MutationResult<SectionDeleteMutation>;
export type SectionDeleteMutationOptions = Apollo.BaseMutationOptions<
  SectionDeleteMutation,
  SectionDeleteMutationVariables
>;
export const SectionUpdateDocument = gql`
  mutation sectionUpdate($uuid: ID!, $input: SectionUpdateInput!) {
    sectionUpdate(uuid: $uuid, input: $input) {
      section {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        can_have_background
        extra_price
        canvas_width
        canvas_height
        canvas_radius
        extra_price_size {
          size_uuid
          extra_price
        }
        overview
        video_url
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        depends_on_sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
      }
    }
  }
`;
export type SectionUpdateMutationFn = Apollo.MutationFunction<
  SectionUpdateMutation,
  SectionUpdateMutationVariables
>;

/**
 * __useSectionUpdateMutation__
 *
 * To run a mutation, you first call `useSectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectionUpdateMutation, { data, loading, error }] = useSectionUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSectionUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SectionUpdateMutation,
    SectionUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SectionUpdateMutation,
    SectionUpdateMutationVariables
  >(SectionUpdateDocument, options);
}
export type SectionUpdateMutationHookResult = ReturnType<
  typeof useSectionUpdateMutation
>;
export type SectionUpdateMutationResult =
  Apollo.MutationResult<SectionUpdateMutation>;
export type SectionUpdateMutationOptions = Apollo.BaseMutationOptions<
  SectionUpdateMutation,
  SectionUpdateMutationVariables
>;
export const SectionUpdateDependenciesDocument = gql`
  mutation sectionUpdateDependencies(
    $uuid: ID!
    $input: SectionUpdateDependenciesInput!
  ) {
    sectionUpdateDependencies(uuid: $uuid, input: $input) {
      section {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        can_have_background
        extra_price
        canvas_width
        canvas_height
        canvas_radius
        extra_price_size {
          size_uuid
          extra_price
        }
        overview
        video_url
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        depends_on_sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
      }
    }
  }
`;
export type SectionUpdateDependenciesMutationFn = Apollo.MutationFunction<
  SectionUpdateDependenciesMutation,
  SectionUpdateDependenciesMutationVariables
>;

/**
 * __useSectionUpdateDependenciesMutation__
 *
 * To run a mutation, you first call `useSectionUpdateDependenciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSectionUpdateDependenciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sectionUpdateDependenciesMutation, { data, loading, error }] = useSectionUpdateDependenciesMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSectionUpdateDependenciesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SectionUpdateDependenciesMutation,
    SectionUpdateDependenciesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SectionUpdateDependenciesMutation,
    SectionUpdateDependenciesMutationVariables
  >(SectionUpdateDependenciesDocument, options);
}
export type SectionUpdateDependenciesMutationHookResult = ReturnType<
  typeof useSectionUpdateDependenciesMutation
>;
export type SectionUpdateDependenciesMutationResult =
  Apollo.MutationResult<SectionUpdateDependenciesMutation>;
export type SectionUpdateDependenciesMutationOptions =
  Apollo.BaseMutationOptions<
    SectionUpdateDependenciesMutation,
    SectionUpdateDependenciesMutationVariables
  >;
export const SideGlazingCreateDocument = gql`
  mutation sideGlazingCreate($input: CreateSideGlazingInput!) {
    sideGlazingCreate(input: $input) {
      sideGlazing {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        extra_price
        installer_extra_price
        info_title
        info_description
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        version_lateral {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          installation_difficulty
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          side_glazings {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
              cars_backwards {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                info_title
                info_description
                extra_price
                installer_extra_price
                installation_difficulty
                preview_text
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                preview_image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                version_lateral {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  info_title
                  info_description
                  preview_text
                  car_version {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                      }
                      cars_backwards {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        preview_text
                        car_backward_types {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          has_rear_camera
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          car_backward {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                            }
                            car_backward_types {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              has_rear_camera
                            }
                          }
                        }
                      }
                      car_version {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        preview_text
                        model_year {
                          uuid
                          createdAt
                          updatedAt
                          start_year
                          end_year
                          info_title
                          info_description
                          brand_model {
                            uuid
                            createdAt
                            updatedAt
                            model_name
                            info_title
                            info_description
                            car_brand {
                              uuid
                              createdAt
                              updatedAt
                              brand_name
                              info_title
                              info_description
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              pictogram_dark {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                                thumbnail {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                                medium {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                              }
                              brand_models {
                                uuid
                                createdAt
                                updatedAt
                                model_name
                                info_title
                                info_description
                                car_brand {
                                  uuid
                                  createdAt
                                  updatedAt
                                  brand_name
                                  info_title
                                  info_description
                                }
                                model_years {
                                  uuid
                                  createdAt
                                  updatedAt
                                  start_year
                                  end_year
                                  info_title
                                  info_description
                                  brand_model {
                                    uuid
                                    createdAt
                                    updatedAt
                                    model_name
                                    info_title
                                    info_description
                                    model_years {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_laterals {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                          car_versions {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type SideGlazingCreateMutationFn = Apollo.MutationFunction<
  SideGlazingCreateMutation,
  SideGlazingCreateMutationVariables
>;

/**
 * __useSideGlazingCreateMutation__
 *
 * To run a mutation, you first call `useSideGlazingCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSideGlazingCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sideGlazingCreateMutation, { data, loading, error }] = useSideGlazingCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSideGlazingCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SideGlazingCreateMutation,
    SideGlazingCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SideGlazingCreateMutation,
    SideGlazingCreateMutationVariables
  >(SideGlazingCreateDocument, options);
}
export type SideGlazingCreateMutationHookResult = ReturnType<
  typeof useSideGlazingCreateMutation
>;
export type SideGlazingCreateMutationResult =
  Apollo.MutationResult<SideGlazingCreateMutation>;
export type SideGlazingCreateMutationOptions = Apollo.BaseMutationOptions<
  SideGlazingCreateMutation,
  SideGlazingCreateMutationVariables
>;
export const SideGlazingDeleteDocument = gql`
  mutation sideGlazingDelete($uuid: String!) {
    sideGlazingDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type SideGlazingDeleteMutationFn = Apollo.MutationFunction<
  SideGlazingDeleteMutation,
  SideGlazingDeleteMutationVariables
>;

/**
 * __useSideGlazingDeleteMutation__
 *
 * To run a mutation, you first call `useSideGlazingDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSideGlazingDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sideGlazingDeleteMutation, { data, loading, error }] = useSideGlazingDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSideGlazingDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SideGlazingDeleteMutation,
    SideGlazingDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SideGlazingDeleteMutation,
    SideGlazingDeleteMutationVariables
  >(SideGlazingDeleteDocument, options);
}
export type SideGlazingDeleteMutationHookResult = ReturnType<
  typeof useSideGlazingDeleteMutation
>;
export type SideGlazingDeleteMutationResult =
  Apollo.MutationResult<SideGlazingDeleteMutation>;
export type SideGlazingDeleteMutationOptions = Apollo.BaseMutationOptions<
  SideGlazingDeleteMutation,
  SideGlazingDeleteMutationVariables
>;
export const SideGlazingUpdateDocument = gql`
  mutation sideGlazingUpdate($uuid: String!, $input: UpdateSideGlazingInput!) {
    sideGlazingUpdate(uuid: $uuid, input: $input) {
      sideGlazing {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        extra_price
        installer_extra_price
        info_title
        info_description
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        version_lateral {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          installation_difficulty
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          side_glazings {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
              cars_backwards {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                info_title
                info_description
                extra_price
                installer_extra_price
                installation_difficulty
                preview_text
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                preview_image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                version_lateral {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  info_title
                  info_description
                  preview_text
                  car_version {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                      }
                      cars_backwards {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        preview_text
                        car_backward_types {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          has_rear_camera
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          car_backward {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                            }
                            car_backward_types {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              has_rear_camera
                            }
                          }
                        }
                      }
                      car_version {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        preview_text
                        model_year {
                          uuid
                          createdAt
                          updatedAt
                          start_year
                          end_year
                          info_title
                          info_description
                          brand_model {
                            uuid
                            createdAt
                            updatedAt
                            model_name
                            info_title
                            info_description
                            car_brand {
                              uuid
                              createdAt
                              updatedAt
                              brand_name
                              info_title
                              info_description
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              pictogram_dark {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                                thumbnail {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                                medium {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                              }
                              brand_models {
                                uuid
                                createdAt
                                updatedAt
                                model_name
                                info_title
                                info_description
                                car_brand {
                                  uuid
                                  createdAt
                                  updatedAt
                                  brand_name
                                  info_title
                                  info_description
                                }
                                model_years {
                                  uuid
                                  createdAt
                                  updatedAt
                                  start_year
                                  end_year
                                  info_title
                                  info_description
                                  brand_model {
                                    uuid
                                    createdAt
                                    updatedAt
                                    model_name
                                    info_title
                                    info_description
                                    model_years {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_laterals {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                          car_versions {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export type SideGlazingUpdateMutationFn = Apollo.MutationFunction<
  SideGlazingUpdateMutation,
  SideGlazingUpdateMutationVariables
>;

/**
 * __useSideGlazingUpdateMutation__
 *
 * To run a mutation, you first call `useSideGlazingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSideGlazingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sideGlazingUpdateMutation, { data, loading, error }] = useSideGlazingUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSideGlazingUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SideGlazingUpdateMutation,
    SideGlazingUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SideGlazingUpdateMutation,
    SideGlazingUpdateMutationVariables
  >(SideGlazingUpdateDocument, options);
}
export type SideGlazingUpdateMutationHookResult = ReturnType<
  typeof useSideGlazingUpdateMutation
>;
export type SideGlazingUpdateMutationResult =
  Apollo.MutationResult<SideGlazingUpdateMutation>;
export type SideGlazingUpdateMutationOptions = Apollo.BaseMutationOptions<
  SideGlazingUpdateMutation,
  SideGlazingUpdateMutationVariables
>;
export const SizeCreateDocument = gql`
  mutation sizeCreate($input: CreateSizesInput!) {
    sizeCreate(input: $input) {
      sizes {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        need_vehicle_info
        price
        index
        installer_price
        canvas_width
        canvas_height
        canvas_radius
        overview
        video_url
        design_choice_possible
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        depends_on_supports {
          uuid
          createdAt
          updatedAt
          title
          can_have_background
          extra_price
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
        }
      }
      product_uuid
    }
  }
`;
export type SizeCreateMutationFn = Apollo.MutationFunction<
  SizeCreateMutation,
  SizeCreateMutationVariables
>;

/**
 * __useSizeCreateMutation__
 *
 * To run a mutation, you first call `useSizeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSizeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sizeCreateMutation, { data, loading, error }] = useSizeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSizeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SizeCreateMutation,
    SizeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SizeCreateMutation, SizeCreateMutationVariables>(
    SizeCreateDocument,
    options,
  );
}
export type SizeCreateMutationHookResult = ReturnType<
  typeof useSizeCreateMutation
>;
export type SizeCreateMutationResult =
  Apollo.MutationResult<SizeCreateMutation>;
export type SizeCreateMutationOptions = Apollo.BaseMutationOptions<
  SizeCreateMutation,
  SizeCreateMutationVariables
>;
export const SizeDeleteDocument = gql`
  mutation sizeDelete($uuid: ID!) {
    sizeDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type SizeDeleteMutationFn = Apollo.MutationFunction<
  SizeDeleteMutation,
  SizeDeleteMutationVariables
>;

/**
 * __useSizeDeleteMutation__
 *
 * To run a mutation, you first call `useSizeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSizeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sizeDeleteMutation, { data, loading, error }] = useSizeDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSizeDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SizeDeleteMutation,
    SizeDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SizeDeleteMutation, SizeDeleteMutationVariables>(
    SizeDeleteDocument,
    options,
  );
}
export type SizeDeleteMutationHookResult = ReturnType<
  typeof useSizeDeleteMutation
>;
export type SizeDeleteMutationResult =
  Apollo.MutationResult<SizeDeleteMutation>;
export type SizeDeleteMutationOptions = Apollo.BaseMutationOptions<
  SizeDeleteMutation,
  SizeDeleteMutationVariables
>;
export const SizeUpdateDocument = gql`
  mutation sizeUpdate($uuid: ID!, $input: SizeUpdateInput!) {
    sizeUpdate(uuid: $uuid, input: $input) {
      size {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        need_vehicle_info
        price
        index
        installer_price
        canvas_width
        canvas_height
        canvas_radius
        overview
        video_url
        design_choice_possible
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        depends_on_supports {
          uuid
          createdAt
          updatedAt
          title
          can_have_background
          extra_price
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
        }
      }
    }
  }
`;
export type SizeUpdateMutationFn = Apollo.MutationFunction<
  SizeUpdateMutation,
  SizeUpdateMutationVariables
>;

/**
 * __useSizeUpdateMutation__
 *
 * To run a mutation, you first call `useSizeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSizeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sizeUpdateMutation, { data, loading, error }] = useSizeUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSizeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SizeUpdateMutation,
    SizeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SizeUpdateMutation, SizeUpdateMutationVariables>(
    SizeUpdateDocument,
    options,
  );
}
export type SizeUpdateMutationHookResult = ReturnType<
  typeof useSizeUpdateMutation
>;
export type SizeUpdateMutationResult =
  Apollo.MutationResult<SizeUpdateMutation>;
export type SizeUpdateMutationOptions = Apollo.BaseMutationOptions<
  SizeUpdateMutation,
  SizeUpdateMutationVariables
>;
export const SizeUpdateDependenciesDocument = gql`
  mutation sizeUpdateDependencies(
    $uuid: ID!
    $input: SizeUpdateDependenciesInput!
  ) {
    sizeUpdateDependencies(uuid: $uuid, input: $input) {
      size {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        need_vehicle_info
        price
        index
        installer_price
        canvas_width
        canvas_height
        canvas_radius
        overview
        video_url
        design_choice_possible
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        depends_on_supports {
          uuid
          createdAt
          updatedAt
          title
          can_have_background
          extra_price
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
        }
      }
    }
  }
`;
export type SizeUpdateDependenciesMutationFn = Apollo.MutationFunction<
  SizeUpdateDependenciesMutation,
  SizeUpdateDependenciesMutationVariables
>;

/**
 * __useSizeUpdateDependenciesMutation__
 *
 * To run a mutation, you first call `useSizeUpdateDependenciesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSizeUpdateDependenciesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sizeUpdateDependenciesMutation, { data, loading, error }] = useSizeUpdateDependenciesMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSizeUpdateDependenciesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SizeUpdateDependenciesMutation,
    SizeUpdateDependenciesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SizeUpdateDependenciesMutation,
    SizeUpdateDependenciesMutationVariables
  >(SizeUpdateDependenciesDocument, options);
}
export type SizeUpdateDependenciesMutationHookResult = ReturnType<
  typeof useSizeUpdateDependenciesMutation
>;
export type SizeUpdateDependenciesMutationResult =
  Apollo.MutationResult<SizeUpdateDependenciesMutation>;
export type SizeUpdateDependenciesMutationOptions = Apollo.BaseMutationOptions<
  SizeUpdateDependenciesMutation,
  SizeUpdateDependenciesMutationVariables
>;
export const SquidstudioCategoryCreateDocument = gql`
  mutation squidstudioCategoryCreate($input: CreateSquidstudioCategoryInput!) {
    squidstudioCategoryCreate(input: $input) {
      squidstudio_category {
        uuid
        createdAt
        updatedAt
        title
        type
        icon {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        files {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;
export type SquidstudioCategoryCreateMutationFn = Apollo.MutationFunction<
  SquidstudioCategoryCreateMutation,
  SquidstudioCategoryCreateMutationVariables
>;

/**
 * __useSquidstudioCategoryCreateMutation__
 *
 * To run a mutation, you first call `useSquidstudioCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squidstudioCategoryCreateMutation, { data, loading, error }] = useSquidstudioCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSquidstudioCategoryCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SquidstudioCategoryCreateMutation,
    SquidstudioCategoryCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SquidstudioCategoryCreateMutation,
    SquidstudioCategoryCreateMutationVariables
  >(SquidstudioCategoryCreateDocument, options);
}
export type SquidstudioCategoryCreateMutationHookResult = ReturnType<
  typeof useSquidstudioCategoryCreateMutation
>;
export type SquidstudioCategoryCreateMutationResult =
  Apollo.MutationResult<SquidstudioCategoryCreateMutation>;
export type SquidstudioCategoryCreateMutationOptions =
  Apollo.BaseMutationOptions<
    SquidstudioCategoryCreateMutation,
    SquidstudioCategoryCreateMutationVariables
  >;
export const SquidstudioCategoryDeleteDocument = gql`
  mutation squidstudioCategoryDelete($uuid: String!) {
    squidstudioCategoryDelete(uuid: $uuid) {
      message
    }
  }
`;
export type SquidstudioCategoryDeleteMutationFn = Apollo.MutationFunction<
  SquidstudioCategoryDeleteMutation,
  SquidstudioCategoryDeleteMutationVariables
>;

/**
 * __useSquidstudioCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useSquidstudioCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squidstudioCategoryDeleteMutation, { data, loading, error }] = useSquidstudioCategoryDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSquidstudioCategoryDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SquidstudioCategoryDeleteMutation,
    SquidstudioCategoryDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SquidstudioCategoryDeleteMutation,
    SquidstudioCategoryDeleteMutationVariables
  >(SquidstudioCategoryDeleteDocument, options);
}
export type SquidstudioCategoryDeleteMutationHookResult = ReturnType<
  typeof useSquidstudioCategoryDeleteMutation
>;
export type SquidstudioCategoryDeleteMutationResult =
  Apollo.MutationResult<SquidstudioCategoryDeleteMutation>;
export type SquidstudioCategoryDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    SquidstudioCategoryDeleteMutation,
    SquidstudioCategoryDeleteMutationVariables
  >;
export const SquidstudioCategoryUpdateDocument = gql`
  mutation squidstudioCategoryUpdate(
    $uuid: ID!
    $input: UpdateSquidstudioCategoryInput!
  ) {
    squidstudioCategoryUpdate(uuid: $uuid, input: $input) {
      squidstudio_category {
        uuid
        createdAt
        updatedAt
        title
        type
        icon {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        files {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;
export type SquidstudioCategoryUpdateMutationFn = Apollo.MutationFunction<
  SquidstudioCategoryUpdateMutation,
  SquidstudioCategoryUpdateMutationVariables
>;

/**
 * __useSquidstudioCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useSquidstudioCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squidstudioCategoryUpdateMutation, { data, loading, error }] = useSquidstudioCategoryUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSquidstudioCategoryUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SquidstudioCategoryUpdateMutation,
    SquidstudioCategoryUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SquidstudioCategoryUpdateMutation,
    SquidstudioCategoryUpdateMutationVariables
  >(SquidstudioCategoryUpdateDocument, options);
}
export type SquidstudioCategoryUpdateMutationHookResult = ReturnType<
  typeof useSquidstudioCategoryUpdateMutation
>;
export type SquidstudioCategoryUpdateMutationResult =
  Apollo.MutationResult<SquidstudioCategoryUpdateMutation>;
export type SquidstudioCategoryUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    SquidstudioCategoryUpdateMutation,
    SquidstudioCategoryUpdateMutationVariables
  >;
export const SupportCreateDocument = gql`
  mutation supportCreate($input: CreateSupportsInput!) {
    supportCreate(input: $input) {
      supports {
        uuid
        createdAt
        updatedAt
        title
        can_have_background
        extra_price
        overview
        video_url
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
      product_uuid
    }
  }
`;
export type SupportCreateMutationFn = Apollo.MutationFunction<
  SupportCreateMutation,
  SupportCreateMutationVariables
>;

/**
 * __useSupportCreateMutation__
 *
 * To run a mutation, you first call `useSupportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supportCreateMutation, { data, loading, error }] = useSupportCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupportCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SupportCreateMutation,
    SupportCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SupportCreateMutation,
    SupportCreateMutationVariables
  >(SupportCreateDocument, options);
}
export type SupportCreateMutationHookResult = ReturnType<
  typeof useSupportCreateMutation
>;
export type SupportCreateMutationResult =
  Apollo.MutationResult<SupportCreateMutation>;
export type SupportCreateMutationOptions = Apollo.BaseMutationOptions<
  SupportCreateMutation,
  SupportCreateMutationVariables
>;
export const SupportDeleteDocument = gql`
  mutation supportDelete($uuid: ID!) {
    supportDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type SupportDeleteMutationFn = Apollo.MutationFunction<
  SupportDeleteMutation,
  SupportDeleteMutationVariables
>;

/**
 * __useSupportDeleteMutation__
 *
 * To run a mutation, you first call `useSupportDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupportDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supportDeleteMutation, { data, loading, error }] = useSupportDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSupportDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SupportDeleteMutation,
    SupportDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SupportDeleteMutation,
    SupportDeleteMutationVariables
  >(SupportDeleteDocument, options);
}
export type SupportDeleteMutationHookResult = ReturnType<
  typeof useSupportDeleteMutation
>;
export type SupportDeleteMutationResult =
  Apollo.MutationResult<SupportDeleteMutation>;
export type SupportDeleteMutationOptions = Apollo.BaseMutationOptions<
  SupportDeleteMutation,
  SupportDeleteMutationVariables
>;
export const SupportUpdateDocument = gql`
  mutation supportUpdate($uuid: ID!, $input: SupportUpdateInput!) {
    supportUpdate(uuid: $uuid, input: $input) {
      support {
        uuid
        createdAt
        updatedAt
        title
        can_have_background
        extra_price
        overview
        video_url
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;
export type SupportUpdateMutationFn = Apollo.MutationFunction<
  SupportUpdateMutation,
  SupportUpdateMutationVariables
>;

/**
 * __useSupportUpdateMutation__
 *
 * To run a mutation, you first call `useSupportUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSupportUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [supportUpdateMutation, { data, loading, error }] = useSupportUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSupportUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SupportUpdateMutation,
    SupportUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SupportUpdateMutation,
    SupportUpdateMutationVariables
  >(SupportUpdateDocument, options);
}
export type SupportUpdateMutationHookResult = ReturnType<
  typeof useSupportUpdateMutation
>;
export type SupportUpdateMutationResult =
  Apollo.MutationResult<SupportUpdateMutation>;
export type SupportUpdateMutationOptions = Apollo.BaseMutationOptions<
  SupportUpdateMutation,
  SupportUpdateMutationVariables
>;
export const TemplateCreateDocument = gql`
  mutation templateCreate($input: CreateTemplatesInput!) {
    templateCreate(input: $input) {
      templates {
        uuid
        createdAt
        updatedAt
        title
        file {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
      product_uuid
    }
  }
`;
export type TemplateCreateMutationFn = Apollo.MutationFunction<
  TemplateCreateMutation,
  TemplateCreateMutationVariables
>;

/**
 * __useTemplateCreateMutation__
 *
 * To run a mutation, you first call `useTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [templateCreateMutation, { data, loading, error }] = useTemplateCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTemplateCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TemplateCreateMutation,
    TemplateCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TemplateCreateMutation,
    TemplateCreateMutationVariables
  >(TemplateCreateDocument, options);
}
export type TemplateCreateMutationHookResult = ReturnType<
  typeof useTemplateCreateMutation
>;
export type TemplateCreateMutationResult =
  Apollo.MutationResult<TemplateCreateMutation>;
export type TemplateCreateMutationOptions = Apollo.BaseMutationOptions<
  TemplateCreateMutation,
  TemplateCreateMutationVariables
>;
export const TemplateDeleteDocument = gql`
  mutation templateDelete($uuid: ID!) {
    templateDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type TemplateDeleteMutationFn = Apollo.MutationFunction<
  TemplateDeleteMutation,
  TemplateDeleteMutationVariables
>;

/**
 * __useTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [templateDeleteMutation, { data, loading, error }] = useTemplateDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useTemplateDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TemplateDeleteMutation,
    TemplateDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TemplateDeleteMutation,
    TemplateDeleteMutationVariables
  >(TemplateDeleteDocument, options);
}
export type TemplateDeleteMutationHookResult = ReturnType<
  typeof useTemplateDeleteMutation
>;
export type TemplateDeleteMutationResult =
  Apollo.MutationResult<TemplateDeleteMutation>;
export type TemplateDeleteMutationOptions = Apollo.BaseMutationOptions<
  TemplateDeleteMutation,
  TemplateDeleteMutationVariables
>;
export const UpdateTemplateDocument = gql`
  mutation updateTemplate($uuid: ID!, $input: TemplateUpdateInput!) {
    updateTemplate(uuid: $uuid, input: $input) {
      template {
        uuid
        createdAt
        updatedAt
        title
        file {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<
  UpdateTemplateMutation,
  UpdateTemplateMutationVariables
>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTemplateMutation,
    UpdateTemplateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTemplateMutation,
    UpdateTemplateMutationVariables
  >(UpdateTemplateDocument, options);
}
export type UpdateTemplateMutationHookResult = ReturnType<
  typeof useUpdateTemplateMutation
>;
export type UpdateTemplateMutationResult =
  Apollo.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<
  UpdateTemplateMutation,
  UpdateTemplateMutationVariables
>;
export const VersionLateralCreateDocument = gql`
  mutation versionLateralCreate($input: CreateVersionLateralInput!) {
    versionLateralCreate(input: $input) {
      versionLateral {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        extra_price
        installer_extra_price
        installation_difficulty
        info_title
        info_description
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        side_glazings {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          version_lateral {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            installation_difficulty
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            side_glazings {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              info_title
              info_description
              preview_text
            }
            cars_backwards {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              info_title
              info_description
              extra_price
              installer_extra_price
              installation_difficulty
              preview_text
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              preview_image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              version_lateral {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                extra_price
                installer_extra_price
                installation_difficulty
                info_title
                info_description
                preview_text
                car_version {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  info_title
                  info_description
                  preview_text
                  pictogram {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  version_laterals {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    extra_price
                    installer_extra_price
                    installation_difficulty
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    preview_image {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    side_glazings {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      info_title
                      info_description
                      preview_text
                    }
                    cars_backwards {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      preview_text
                      car_backward_types {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        has_rear_camera
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        car_backward {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                          }
                          car_backward_types {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            has_rear_camera
                          }
                        }
                      }
                    }
                    car_version {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      model_year {
                        uuid
                        createdAt
                        updatedAt
                        start_year
                        end_year
                        info_title
                        info_description
                        brand_model {
                          uuid
                          createdAt
                          updatedAt
                          model_name
                          info_title
                          info_description
                          car_brand {
                            uuid
                            createdAt
                            updatedAt
                            brand_name
                            info_title
                            info_description
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            pictogram_dark {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                              thumbnail {
                                width
                                height
                                size
                                url
                                file_name
                              }
                              medium {
                                width
                                height
                                size
                                url
                                file_name
                              }
                            }
                            brand_models {
                              uuid
                              createdAt
                              updatedAt
                              model_name
                              info_title
                              info_description
                              car_brand {
                                uuid
                                createdAt
                                updatedAt
                                brand_name
                                info_title
                                info_description
                              }
                              model_years {
                                uuid
                                createdAt
                                updatedAt
                                start_year
                                end_year
                                info_title
                                info_description
                                brand_model {
                                  uuid
                                  createdAt
                                  updatedAt
                                  model_name
                                  info_title
                                  info_description
                                  model_years {
                                    uuid
                                    createdAt
                                    updatedAt
                                    start_year
                                    end_year
                                    info_title
                                    info_description
                                    car_versions {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      pictogram {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      version_laterals {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        extra_price
                                        installer_extra_price
                                        installation_difficulty
                                        info_title
                                        info_description
                                        preview_text
                                      }
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        cars_backwards {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          extra_price
          installer_extra_price
          installation_difficulty
          preview_text
        }
        car_version {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          preview_text
        }
      }
    }
  }
`;
export type VersionLateralCreateMutationFn = Apollo.MutationFunction<
  VersionLateralCreateMutation,
  VersionLateralCreateMutationVariables
>;

/**
 * __useVersionLateralCreateMutation__
 *
 * To run a mutation, you first call `useVersionLateralCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVersionLateralCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [versionLateralCreateMutation, { data, loading, error }] = useVersionLateralCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVersionLateralCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VersionLateralCreateMutation,
    VersionLateralCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VersionLateralCreateMutation,
    VersionLateralCreateMutationVariables
  >(VersionLateralCreateDocument, options);
}
export type VersionLateralCreateMutationHookResult = ReturnType<
  typeof useVersionLateralCreateMutation
>;
export type VersionLateralCreateMutationResult =
  Apollo.MutationResult<VersionLateralCreateMutation>;
export type VersionLateralCreateMutationOptions = Apollo.BaseMutationOptions<
  VersionLateralCreateMutation,
  VersionLateralCreateMutationVariables
>;
export const VersionLateralDeleteDocument = gql`
  mutation versionLateralDelete($uuid: String!) {
    versionLateralDelete(uuid: $uuid) {
      uuid
    }
  }
`;
export type VersionLateralDeleteMutationFn = Apollo.MutationFunction<
  VersionLateralDeleteMutation,
  VersionLateralDeleteMutationVariables
>;

/**
 * __useVersionLateralDeleteMutation__
 *
 * To run a mutation, you first call `useVersionLateralDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVersionLateralDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [versionLateralDeleteMutation, { data, loading, error }] = useVersionLateralDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useVersionLateralDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VersionLateralDeleteMutation,
    VersionLateralDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VersionLateralDeleteMutation,
    VersionLateralDeleteMutationVariables
  >(VersionLateralDeleteDocument, options);
}
export type VersionLateralDeleteMutationHookResult = ReturnType<
  typeof useVersionLateralDeleteMutation
>;
export type VersionLateralDeleteMutationResult =
  Apollo.MutationResult<VersionLateralDeleteMutation>;
export type VersionLateralDeleteMutationOptions = Apollo.BaseMutationOptions<
  VersionLateralDeleteMutation,
  VersionLateralDeleteMutationVariables
>;
export const VersionLateralUpdateDocument = gql`
  mutation versionLateralUpdate(
    $uuid: String!
    $input: UpdateVersionLateralInput!
  ) {
    versionLateralUpdate(uuid: $uuid, input: $input) {
      versionLateral {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        extra_price
        installer_extra_price
        installation_difficulty
        info_title
        info_description
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        side_glazings {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          version_lateral {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            installation_difficulty
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            side_glazings {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              info_title
              info_description
              preview_text
            }
            cars_backwards {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              info_title
              info_description
              extra_price
              installer_extra_price
              installation_difficulty
              preview_text
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              preview_image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              version_lateral {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                extra_price
                installer_extra_price
                installation_difficulty
                info_title
                info_description
                preview_text
                car_version {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  info_title
                  info_description
                  preview_text
                  pictogram {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  version_laterals {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    extra_price
                    installer_extra_price
                    installation_difficulty
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    preview_image {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    side_glazings {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      info_title
                      info_description
                      preview_text
                    }
                    cars_backwards {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      preview_text
                      car_backward_types {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        has_rear_camera
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        car_backward {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                          }
                          car_backward_types {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            has_rear_camera
                          }
                        }
                      }
                    }
                    car_version {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      model_year {
                        uuid
                        createdAt
                        updatedAt
                        start_year
                        end_year
                        info_title
                        info_description
                        brand_model {
                          uuid
                          createdAt
                          updatedAt
                          model_name
                          info_title
                          info_description
                          car_brand {
                            uuid
                            createdAt
                            updatedAt
                            brand_name
                            info_title
                            info_description
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            pictogram_dark {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                              thumbnail {
                                width
                                height
                                size
                                url
                                file_name
                              }
                              medium {
                                width
                                height
                                size
                                url
                                file_name
                              }
                            }
                            brand_models {
                              uuid
                              createdAt
                              updatedAt
                              model_name
                              info_title
                              info_description
                              car_brand {
                                uuid
                                createdAt
                                updatedAt
                                brand_name
                                info_title
                                info_description
                              }
                              model_years {
                                uuid
                                createdAt
                                updatedAt
                                start_year
                                end_year
                                info_title
                                info_description
                                brand_model {
                                  uuid
                                  createdAt
                                  updatedAt
                                  model_name
                                  info_title
                                  info_description
                                  model_years {
                                    uuid
                                    createdAt
                                    updatedAt
                                    start_year
                                    end_year
                                    info_title
                                    info_description
                                    car_versions {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      pictogram {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      version_laterals {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        extra_price
                                        installer_extra_price
                                        installation_difficulty
                                        info_title
                                        info_description
                                        preview_text
                                      }
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        cars_backwards {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          extra_price
          installer_extra_price
          installation_difficulty
          preview_text
        }
        car_version {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          preview_text
        }
      }
    }
  }
`;
export type VersionLateralUpdateMutationFn = Apollo.MutationFunction<
  VersionLateralUpdateMutation,
  VersionLateralUpdateMutationVariables
>;

/**
 * __useVersionLateralUpdateMutation__
 *
 * To run a mutation, you first call `useVersionLateralUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVersionLateralUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [versionLateralUpdateMutation, { data, loading, error }] = useVersionLateralUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVersionLateralUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VersionLateralUpdateMutation,
    VersionLateralUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VersionLateralUpdateMutation,
    VersionLateralUpdateMutationVariables
  >(VersionLateralUpdateDocument, options);
}
export type VersionLateralUpdateMutationHookResult = ReturnType<
  typeof useVersionLateralUpdateMutation
>;
export type VersionLateralUpdateMutationResult =
  Apollo.MutationResult<VersionLateralUpdateMutation>;
export type VersionLateralUpdateMutationOptions = Apollo.BaseMutationOptions<
  VersionLateralUpdateMutation,
  VersionLateralUpdateMutationVariables
>;
export const ConfirmResetPasswordDocument = gql`
  mutation confirmResetPassword($input: ConfirmResetPasswordInput!) {
    confirmResetPassword(input: $input) {
      message
    }
  }
`;
export type ConfirmResetPasswordMutationFn = Apollo.MutationFunction<
  ConfirmResetPasswordMutation,
  ConfirmResetPasswordMutationVariables
>;

/**
 * __useConfirmResetPasswordMutation__
 *
 * To run a mutation, you first call `useConfirmResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmResetPasswordMutation, { data, loading, error }] = useConfirmResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmResetPasswordMutation,
    ConfirmResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmResetPasswordMutation,
    ConfirmResetPasswordMutationVariables
  >(ConfirmResetPasswordDocument, options);
}
export type ConfirmResetPasswordMutationHookResult = ReturnType<
  typeof useConfirmResetPasswordMutation
>;
export type ConfirmResetPasswordMutationResult =
  Apollo.MutationResult<ConfirmResetPasswordMutation>;
export type ConfirmResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ConfirmResetPasswordMutation,
  ConfirmResetPasswordMutationVariables
>;
export const UpdateMyPasswordDocument = gql`
  mutation updateMyPassword($input: UpdateMyPasswordDto!) {
    updateMyPassword(input: $input) {
      message
    }
  }
`;
export type UpdateMyPasswordMutationFn = Apollo.MutationFunction<
  UpdateMyPasswordMutation,
  UpdateMyPasswordMutationVariables
>;

/**
 * __useUpdateMyPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateMyPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyPasswordMutation, { data, loading, error }] = useUpdateMyPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyPasswordMutation,
    UpdateMyPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMyPasswordMutation,
    UpdateMyPasswordMutationVariables
  >(UpdateMyPasswordDocument, options);
}
export type UpdateMyPasswordMutationHookResult = ReturnType<
  typeof useUpdateMyPasswordMutation
>;
export type UpdateMyPasswordMutationResult =
  Apollo.MutationResult<UpdateMyPasswordMutation>;
export type UpdateMyPasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyPasswordMutation,
  UpdateMyPasswordMutationVariables
>;
export const UpdateProfileDocument = gql`
  mutation updateProfile($input: UpdateProfileDto!) {
    updateProfile(input: $input) {
      user {
        uuid
        createdAt
        updatedAt
        email
        firstName
        lastName
        company_name
        address
        postal_code
        city
        country
        phonenumber
        vehicles {
          brand
          model
          year
          color
        }
        activated
        enabled
        role
      }
    }
  }
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<
  typeof useUpdateProfileMutation
>;
export type UpdateProfileMutationResult =
  Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const EstimateDeleteDocument = gql`
  mutation estimateDelete($uuid: String!) {
    estimateDelete(uuid: $uuid) {
      message
    }
  }
`;
export type EstimateDeleteMutationFn = Apollo.MutationFunction<
  EstimateDeleteMutation,
  EstimateDeleteMutationVariables
>;

/**
 * __useEstimateDeleteMutation__
 *
 * To run a mutation, you first call `useEstimateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstimateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estimateDeleteMutation, { data, loading, error }] = useEstimateDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useEstimateDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EstimateDeleteMutation,
    EstimateDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EstimateDeleteMutation,
    EstimateDeleteMutationVariables
  >(EstimateDeleteDocument, options);
}
export type EstimateDeleteMutationHookResult = ReturnType<
  typeof useEstimateDeleteMutation
>;
export type EstimateDeleteMutationResult =
  Apollo.MutationResult<EstimateDeleteMutation>;
export type EstimateDeleteMutationOptions = Apollo.BaseMutationOptions<
  EstimateDeleteMutation,
  EstimateDeleteMutationVariables
>;
export const EstimateUpdateDocument = gql`
  mutation estimateUpdate($uuid: ID!, $input: UpdateEstimateInput!) {
    estimateUpdate(uuid: $uuid, input: $input) {
      estimate {
        uuid
        createdAt
        updatedAt
        name
        company_name
        phonenumber
        mail
        subject
        status
        vehicle
        description_of_project
        your_mark
        your_model
        year_of_your_vehicle
        marquetry
        file {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;
export type EstimateUpdateMutationFn = Apollo.MutationFunction<
  EstimateUpdateMutation,
  EstimateUpdateMutationVariables
>;

/**
 * __useEstimateUpdateMutation__
 *
 * To run a mutation, you first call `useEstimateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEstimateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [estimateUpdateMutation, { data, loading, error }] = useEstimateUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEstimateUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EstimateUpdateMutation,
    EstimateUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EstimateUpdateMutation,
    EstimateUpdateMutationVariables
  >(EstimateUpdateDocument, options);
}
export type EstimateUpdateMutationHookResult = ReturnType<
  typeof useEstimateUpdateMutation
>;
export type EstimateUpdateMutationResult =
  Apollo.MutationResult<EstimateUpdateMutation>;
export type EstimateUpdateMutationOptions = Apollo.BaseMutationOptions<
  EstimateUpdateMutation,
  EstimateUpdateMutationVariables
>;
export const CreateReviewDocument = gql`
  mutation createReview($input: CreateReviewInput!) {
    createReview(input: $input) {
      review {
        uuid
        createdAt
        updatedAt
        text
        note
        display_on_home
        display_on_product
        products {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          type
          description
          slug
          meta_title
          meta_description
          verification
          graphic_service
          professional_installer
          enabled
          index
          start_price
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          sections {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            extra_price_sections {
              section_uuid
              extra_price
            }
            installer_extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          templates {
            uuid
            createdAt
            updatedAt
            title
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          product_images {
            uuid
            createdAt
            updatedAt
            support_uuid
            size_uuid
            section_uuid
            extra_sticker_uuid
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
        user {
          uuid
          createdAt
          updatedAt
          email
          firstName
          lastName
          company_name
          address
          postal_code
          city
          country
          phonenumber
          vehicles {
            brand
            model
            year
            color
          }
          activated
          enabled
          role
        }
        order {
          uuid
          createdAt
          updatedAt
          order_code
          already_add_review
          sub_total
          shipping_cost
          total_without_vat
          start_date_of_delivery
          end_date_of_delivery
          vat
          total_all_vat
          discount_amount
          payment_method
          ordering_status
          delivery_mode
          invoice {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          discount_code {
            uuid
            createdAt
            updatedAt
            code
            value
            use_before
            max_use_times
            used_times
            saved_money
            total_buy_amount
            is_percentage
          }
          stripe_payment_data {
            status
            id
            payment_link
            balance_transaction
            payment_method
            receipt_url
            source {
              id
              brand
              country
              last4
              exp_month
              exp_year
            }
          }
          has_quote
          user {
            uuid
            createdAt
            updatedAt
            email
            firstName
            lastName
            company_name
            address
            postal_code
            city
            country
            phonenumber
            activated
            enabled
            role
          }
          delivery_address {
            uuid
            createdAt
            updatedAt
            firstname
            lastname
            company_name
            address
            postal_code
            city
            country
            phonenumber
            email
          }
          billing_address {
            uuid
            createdAt
            updatedAt
            firstname
            lastname
            company_name
            address
            postal_code
            city
            country
            phonenumber
            email
          }
          order_products {
            uuid
            createdAt
            updatedAt
            verification
            graphic_service
            professional_installer
            synthetic_design
            custom_design
            design_is_finished
            quantity
            total
            squid_studio_data {
              width
              height
              radius
              can_have_background
              data
            }
            vehicle_information {
              brand
              model
              year
              color
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image_pdf {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            image_svg {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            product {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              type
              description
              slug
              meta_title
              meta_description
              verification
              graphic_service
              professional_installer
              enabled
              index
              start_price
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
              sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
              sections {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                can_have_background
                extra_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                installation_difficulty
              }
              extra_stickers {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                design_choice_possible
                extra_price
                installer_extra_price
                overview
                video_url
                installation_difficulty
              }
              templates {
                uuid
                createdAt
                updatedAt
                title
              }
              product_images {
                uuid
                createdAt
                updatedAt
                support_uuid
                size_uuid
                section_uuid
                extra_sticker_uuid
              }
            }
            size {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
            }
            support {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
            section {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              extra_price_size {
                size_uuid
                extra_price
              }
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              installer_extra_price
              overview
              video_url
              installation_difficulty
            }
          }
        }
      }
    }
  }
`;
export type CreateReviewMutationFn = Apollo.MutationFunction<
  CreateReviewMutation,
  CreateReviewMutationVariables
>;

/**
 * __useCreateReviewMutation__
 *
 * To run a mutation, you first call `useCreateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReviewMutation, { data, loading, error }] = useCreateReviewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReviewMutation,
    CreateReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateReviewMutation,
    CreateReviewMutationVariables
  >(CreateReviewDocument, options);
}
export type CreateReviewMutationHookResult = ReturnType<
  typeof useCreateReviewMutation
>;
export type CreateReviewMutationResult =
  Apollo.MutationResult<CreateReviewMutation>;
export type CreateReviewMutationOptions = Apollo.BaseMutationOptions<
  CreateReviewMutation,
  CreateReviewMutationVariables
>;
export const UpdateReviewDocument = gql`
  mutation updateReview($uuid: String!, $input: UpdateReviewInput!) {
    updateReview(uuid: $uuid, input: $input) {
      review {
        uuid
        createdAt
        updatedAt
        text
        note
        display_on_home
        display_on_product
        products {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          type
          description
          slug
          meta_title
          meta_description
          verification
          graphic_service
          professional_installer
          enabled
          index
          start_price
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          sections {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            extra_price_sections {
              section_uuid
              extra_price
            }
            installer_extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          templates {
            uuid
            createdAt
            updatedAt
            title
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          product_images {
            uuid
            createdAt
            updatedAt
            support_uuid
            size_uuid
            section_uuid
            extra_sticker_uuid
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
        user {
          uuid
          createdAt
          updatedAt
          email
          firstName
          lastName
          company_name
          address
          postal_code
          city
          country
          phonenumber
          vehicles {
            brand
            model
            year
            color
          }
          activated
          enabled
          role
        }
        order {
          uuid
          createdAt
          updatedAt
          order_code
          already_add_review
          sub_total
          shipping_cost
          total_without_vat
          start_date_of_delivery
          end_date_of_delivery
          vat
          total_all_vat
          discount_amount
          payment_method
          ordering_status
          delivery_mode
          invoice {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          discount_code {
            uuid
            createdAt
            updatedAt
            code
            value
            use_before
            max_use_times
            used_times
            saved_money
            total_buy_amount
            is_percentage
          }
          stripe_payment_data {
            status
            id
            payment_link
            balance_transaction
            payment_method
            receipt_url
            source {
              id
              brand
              country
              last4
              exp_month
              exp_year
            }
          }
          has_quote
          user {
            uuid
            createdAt
            updatedAt
            email
            firstName
            lastName
            company_name
            address
            postal_code
            city
            country
            phonenumber
            activated
            enabled
            role
          }
          delivery_address {
            uuid
            createdAt
            updatedAt
            firstname
            lastname
            company_name
            address
            postal_code
            city
            country
            phonenumber
            email
          }
          billing_address {
            uuid
            createdAt
            updatedAt
            firstname
            lastname
            company_name
            address
            postal_code
            city
            country
            phonenumber
            email
          }
          order_products {
            uuid
            createdAt
            updatedAt
            verification
            graphic_service
            professional_installer
            synthetic_design
            custom_design
            design_is_finished
            quantity
            total
            squid_studio_data {
              width
              height
              radius
              can_have_background
              data
            }
            vehicle_information {
              brand
              model
              year
              color
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image_pdf {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            image_svg {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            product {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              type
              description
              slug
              meta_title
              meta_description
              verification
              graphic_service
              professional_installer
              enabled
              index
              start_price
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
              sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
              sections {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                can_have_background
                extra_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                installation_difficulty
              }
              extra_stickers {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                design_choice_possible
                extra_price
                installer_extra_price
                overview
                video_url
                installation_difficulty
              }
              templates {
                uuid
                createdAt
                updatedAt
                title
              }
              product_images {
                uuid
                createdAt
                updatedAt
                support_uuid
                size_uuid
                section_uuid
                extra_sticker_uuid
              }
            }
            size {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
            }
            support {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
            section {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              extra_price_size {
                size_uuid
                extra_price
              }
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              installer_extra_price
              overview
              video_url
              installation_difficulty
            }
          }
        }
      }
    }
  }
`;
export type UpdateReviewMutationFn = Apollo.MutationFunction<
  UpdateReviewMutation,
  UpdateReviewMutationVariables
>;

/**
 * __useUpdateReviewMutation__
 *
 * To run a mutation, you first call `useUpdateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReviewMutation, { data, loading, error }] = useUpdateReviewMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReviewMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReviewMutation,
    UpdateReviewMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateReviewMutation,
    UpdateReviewMutationVariables
  >(UpdateReviewDocument, options);
}
export type UpdateReviewMutationHookResult = ReturnType<
  typeof useUpdateReviewMutation
>;
export type UpdateReviewMutationResult =
  Apollo.MutationResult<UpdateReviewMutation>;
export type UpdateReviewMutationOptions = Apollo.BaseMutationOptions<
  UpdateReviewMutation,
  UpdateReviewMutationVariables
>;
export const SquidstudioShapeCategoryCreateDocument = gql`
  mutation squidstudioShapeCategoryCreate(
    $input: CreateSquidstudioShapeCategoryInput!
  ) {
    squidstudioShapeCategoryCreate(input: $input) {
      squidstudio_shape_category {
        uuid
        createdAt
        updatedAt
        title
        icon {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        shapes {
          uuid
          createdAt
          updatedAt
          clipPath
          width
          height
          desc
          background
          img {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
      }
    }
  }
`;
export type SquidstudioShapeCategoryCreateMutationFn = Apollo.MutationFunction<
  SquidstudioShapeCategoryCreateMutation,
  SquidstudioShapeCategoryCreateMutationVariables
>;

/**
 * __useSquidstudioShapeCategoryCreateMutation__
 *
 * To run a mutation, you first call `useSquidstudioShapeCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioShapeCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squidstudioShapeCategoryCreateMutation, { data, loading, error }] = useSquidstudioShapeCategoryCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSquidstudioShapeCategoryCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SquidstudioShapeCategoryCreateMutation,
    SquidstudioShapeCategoryCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SquidstudioShapeCategoryCreateMutation,
    SquidstudioShapeCategoryCreateMutationVariables
  >(SquidstudioShapeCategoryCreateDocument, options);
}
export type SquidstudioShapeCategoryCreateMutationHookResult = ReturnType<
  typeof useSquidstudioShapeCategoryCreateMutation
>;
export type SquidstudioShapeCategoryCreateMutationResult =
  Apollo.MutationResult<SquidstudioShapeCategoryCreateMutation>;
export type SquidstudioShapeCategoryCreateMutationOptions =
  Apollo.BaseMutationOptions<
    SquidstudioShapeCategoryCreateMutation,
    SquidstudioShapeCategoryCreateMutationVariables
  >;
export const SquidstudioShapeCategoryDeleteDocument = gql`
  mutation squidstudioShapeCategoryDelete($uuid: String!) {
    squidstudioShapeCategoryDelete(uuid: $uuid) {
      message
    }
  }
`;
export type SquidstudioShapeCategoryDeleteMutationFn = Apollo.MutationFunction<
  SquidstudioShapeCategoryDeleteMutation,
  SquidstudioShapeCategoryDeleteMutationVariables
>;

/**
 * __useSquidstudioShapeCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useSquidstudioShapeCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioShapeCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squidstudioShapeCategoryDeleteMutation, { data, loading, error }] = useSquidstudioShapeCategoryDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSquidstudioShapeCategoryDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SquidstudioShapeCategoryDeleteMutation,
    SquidstudioShapeCategoryDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SquidstudioShapeCategoryDeleteMutation,
    SquidstudioShapeCategoryDeleteMutationVariables
  >(SquidstudioShapeCategoryDeleteDocument, options);
}
export type SquidstudioShapeCategoryDeleteMutationHookResult = ReturnType<
  typeof useSquidstudioShapeCategoryDeleteMutation
>;
export type SquidstudioShapeCategoryDeleteMutationResult =
  Apollo.MutationResult<SquidstudioShapeCategoryDeleteMutation>;
export type SquidstudioShapeCategoryDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    SquidstudioShapeCategoryDeleteMutation,
    SquidstudioShapeCategoryDeleteMutationVariables
  >;
export const SquidstudioShapeCategoryUpdateDocument = gql`
  mutation squidstudioShapeCategoryUpdate(
    $uuid: ID!
    $input: UpdateSquidstudioShapeCategoryInput!
  ) {
    squidstudioShapeCategoryUpdate(uuid: $uuid, input: $input) {
      squidstudio_shape_category {
        uuid
        createdAt
        updatedAt
        title
        icon {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        shapes {
          uuid
          createdAt
          updatedAt
          clipPath
          width
          height
          desc
          background
          img {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
      }
    }
  }
`;
export type SquidstudioShapeCategoryUpdateMutationFn = Apollo.MutationFunction<
  SquidstudioShapeCategoryUpdateMutation,
  SquidstudioShapeCategoryUpdateMutationVariables
>;

/**
 * __useSquidstudioShapeCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useSquidstudioShapeCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioShapeCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squidstudioShapeCategoryUpdateMutation, { data, loading, error }] = useSquidstudioShapeCategoryUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSquidstudioShapeCategoryUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SquidstudioShapeCategoryUpdateMutation,
    SquidstudioShapeCategoryUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SquidstudioShapeCategoryUpdateMutation,
    SquidstudioShapeCategoryUpdateMutationVariables
  >(SquidstudioShapeCategoryUpdateDocument, options);
}
export type SquidstudioShapeCategoryUpdateMutationHookResult = ReturnType<
  typeof useSquidstudioShapeCategoryUpdateMutation
>;
export type SquidstudioShapeCategoryUpdateMutationResult =
  Apollo.MutationResult<SquidstudioShapeCategoryUpdateMutation>;
export type SquidstudioShapeCategoryUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    SquidstudioShapeCategoryUpdateMutation,
    SquidstudioShapeCategoryUpdateMutationVariables
  >;
export const SquidstudioShapeCreateDocument = gql`
  mutation squidstudioShapeCreate($input: CreateSquidstudioShapeInput!) {
    squidstudioShapeCreate(input: $input) {
      squidstudio_shape {
        uuid
        createdAt
        updatedAt
        clipPath
        width
        height
        desc
        background
        img {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;
export type SquidstudioShapeCreateMutationFn = Apollo.MutationFunction<
  SquidstudioShapeCreateMutation,
  SquidstudioShapeCreateMutationVariables
>;

/**
 * __useSquidstudioShapeCreateMutation__
 *
 * To run a mutation, you first call `useSquidstudioShapeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioShapeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squidstudioShapeCreateMutation, { data, loading, error }] = useSquidstudioShapeCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSquidstudioShapeCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SquidstudioShapeCreateMutation,
    SquidstudioShapeCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SquidstudioShapeCreateMutation,
    SquidstudioShapeCreateMutationVariables
  >(SquidstudioShapeCreateDocument, options);
}
export type SquidstudioShapeCreateMutationHookResult = ReturnType<
  typeof useSquidstudioShapeCreateMutation
>;
export type SquidstudioShapeCreateMutationResult =
  Apollo.MutationResult<SquidstudioShapeCreateMutation>;
export type SquidstudioShapeCreateMutationOptions = Apollo.BaseMutationOptions<
  SquidstudioShapeCreateMutation,
  SquidstudioShapeCreateMutationVariables
>;
export const SquidstudioShapeDeleteDocument = gql`
  mutation squidstudioShapeDelete($uuid: String!) {
    squidstudioShapeDelete(uuid: $uuid) {
      message
    }
  }
`;
export type SquidstudioShapeDeleteMutationFn = Apollo.MutationFunction<
  SquidstudioShapeDeleteMutation,
  SquidstudioShapeDeleteMutationVariables
>;

/**
 * __useSquidstudioShapeDeleteMutation__
 *
 * To run a mutation, you first call `useSquidstudioShapeDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioShapeDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squidstudioShapeDeleteMutation, { data, loading, error }] = useSquidstudioShapeDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSquidstudioShapeDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SquidstudioShapeDeleteMutation,
    SquidstudioShapeDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SquidstudioShapeDeleteMutation,
    SquidstudioShapeDeleteMutationVariables
  >(SquidstudioShapeDeleteDocument, options);
}
export type SquidstudioShapeDeleteMutationHookResult = ReturnType<
  typeof useSquidstudioShapeDeleteMutation
>;
export type SquidstudioShapeDeleteMutationResult =
  Apollo.MutationResult<SquidstudioShapeDeleteMutation>;
export type SquidstudioShapeDeleteMutationOptions = Apollo.BaseMutationOptions<
  SquidstudioShapeDeleteMutation,
  SquidstudioShapeDeleteMutationVariables
>;
export const SquidstudioShapeUpdateDocument = gql`
  mutation squidstudioShapeUpdate(
    $uuid: ID!
    $input: UpdateSquidstudioShapeInput!
  ) {
    squidstudioShapeUpdate(uuid: $uuid, input: $input) {
      squidstudio_shape {
        uuid
        createdAt
        updatedAt
        clipPath
        width
        height
        desc
        background
        img {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;
export type SquidstudioShapeUpdateMutationFn = Apollo.MutationFunction<
  SquidstudioShapeUpdateMutation,
  SquidstudioShapeUpdateMutationVariables
>;

/**
 * __useSquidstudioShapeUpdateMutation__
 *
 * To run a mutation, you first call `useSquidstudioShapeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioShapeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squidstudioShapeUpdateMutation, { data, loading, error }] = useSquidstudioShapeUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSquidstudioShapeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SquidstudioShapeUpdateMutation,
    SquidstudioShapeUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SquidstudioShapeUpdateMutation,
    SquidstudioShapeUpdateMutationVariables
  >(SquidstudioShapeUpdateDocument, options);
}
export type SquidstudioShapeUpdateMutationHookResult = ReturnType<
  typeof useSquidstudioShapeUpdateMutation
>;
export type SquidstudioShapeUpdateMutationResult =
  Apollo.MutationResult<SquidstudioShapeUpdateMutation>;
export type SquidstudioShapeUpdateMutationOptions = Apollo.BaseMutationOptions<
  SquidstudioShapeUpdateMutation,
  SquidstudioShapeUpdateMutationVariables
>;
export const SquidstudioTemplateCreateDocument = gql`
  mutation squidstudioTemplateCreate($input: CreateSquidstudioTemplateInput!) {
    squidstudioTemplateCreate(input: $input) {
      squidstudio_template {
        uuid
        createdAt
        updatedAt
        data
        desc
        sizes {
          width
          height
        }
        img {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;
export type SquidstudioTemplateCreateMutationFn = Apollo.MutationFunction<
  SquidstudioTemplateCreateMutation,
  SquidstudioTemplateCreateMutationVariables
>;

/**
 * __useSquidstudioTemplateCreateMutation__
 *
 * To run a mutation, you first call `useSquidstudioTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squidstudioTemplateCreateMutation, { data, loading, error }] = useSquidstudioTemplateCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSquidstudioTemplateCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SquidstudioTemplateCreateMutation,
    SquidstudioTemplateCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SquidstudioTemplateCreateMutation,
    SquidstudioTemplateCreateMutationVariables
  >(SquidstudioTemplateCreateDocument, options);
}
export type SquidstudioTemplateCreateMutationHookResult = ReturnType<
  typeof useSquidstudioTemplateCreateMutation
>;
export type SquidstudioTemplateCreateMutationResult =
  Apollo.MutationResult<SquidstudioTemplateCreateMutation>;
export type SquidstudioTemplateCreateMutationOptions =
  Apollo.BaseMutationOptions<
    SquidstudioTemplateCreateMutation,
    SquidstudioTemplateCreateMutationVariables
  >;
export const SquidstudioTemplateDeleteDocument = gql`
  mutation squidstudioTemplateDelete($uuid: String!) {
    squidstudioTemplateDelete(uuid: $uuid) {
      message
    }
  }
`;
export type SquidstudioTemplateDeleteMutationFn = Apollo.MutationFunction<
  SquidstudioTemplateDeleteMutation,
  SquidstudioTemplateDeleteMutationVariables
>;

/**
 * __useSquidstudioTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useSquidstudioTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squidstudioTemplateDeleteMutation, { data, loading, error }] = useSquidstudioTemplateDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSquidstudioTemplateDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SquidstudioTemplateDeleteMutation,
    SquidstudioTemplateDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SquidstudioTemplateDeleteMutation,
    SquidstudioTemplateDeleteMutationVariables
  >(SquidstudioTemplateDeleteDocument, options);
}
export type SquidstudioTemplateDeleteMutationHookResult = ReturnType<
  typeof useSquidstudioTemplateDeleteMutation
>;
export type SquidstudioTemplateDeleteMutationResult =
  Apollo.MutationResult<SquidstudioTemplateDeleteMutation>;
export type SquidstudioTemplateDeleteMutationOptions =
  Apollo.BaseMutationOptions<
    SquidstudioTemplateDeleteMutation,
    SquidstudioTemplateDeleteMutationVariables
  >;
export const SquidstudioTemplateUpdateDocument = gql`
  mutation squidstudioTemplateUpdate(
    $uuid: ID!
    $input: UpdateSquidstudioTemplateInput!
  ) {
    squidstudioTemplateUpdate(uuid: $uuid, input: $input) {
      squidstudio_template {
        uuid
        createdAt
        updatedAt
        data
        desc
        sizes {
          width
          height
        }
        img {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;
export type SquidstudioTemplateUpdateMutationFn = Apollo.MutationFunction<
  SquidstudioTemplateUpdateMutation,
  SquidstudioTemplateUpdateMutationVariables
>;

/**
 * __useSquidstudioTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useSquidstudioTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [squidstudioTemplateUpdateMutation, { data, loading, error }] = useSquidstudioTemplateUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSquidstudioTemplateUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SquidstudioTemplateUpdateMutation,
    SquidstudioTemplateUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SquidstudioTemplateUpdateMutation,
    SquidstudioTemplateUpdateMutationVariables
  >(SquidstudioTemplateUpdateDocument, options);
}
export type SquidstudioTemplateUpdateMutationHookResult = ReturnType<
  typeof useSquidstudioTemplateUpdateMutation
>;
export type SquidstudioTemplateUpdateMutationResult =
  Apollo.MutationResult<SquidstudioTemplateUpdateMutation>;
export type SquidstudioTemplateUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    SquidstudioTemplateUpdateMutation,
    SquidstudioTemplateUpdateMutationVariables
  >;
export const UserCreateDocument = gql`
  mutation userCreate($input: CreateUserInput!) {
    userCreate(input: $input) {
      user {
        uuid
        createdAt
        updatedAt
        email
        firstName
        lastName
        activated
        enabled
        role
      }
    }
  }
`;
export type UserCreateMutationFn = Apollo.MutationFunction<
  UserCreateMutation,
  UserCreateMutationVariables
>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserCreateMutation,
    UserCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserCreateMutation, UserCreateMutationVariables>(
    UserCreateDocument,
    options,
  );
}
export type UserCreateMutationHookResult = ReturnType<
  typeof useUserCreateMutation
>;
export type UserCreateMutationResult =
  Apollo.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<
  UserCreateMutation,
  UserCreateMutationVariables
>;
export const UserDeleteDocument = gql`
  mutation userDelete($uuid: String!) {
    userDelete(uuid: $uuid) {
      message
    }
  }
`;
export type UserDeleteMutationFn = Apollo.MutationFunction<
  UserDeleteMutation,
  UserDeleteMutationVariables
>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUserDeleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserDeleteMutation,
    UserDeleteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(
    UserDeleteDocument,
    options,
  );
}
export type UserDeleteMutationHookResult = ReturnType<
  typeof useUserDeleteMutation
>;
export type UserDeleteMutationResult =
  Apollo.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<
  UserDeleteMutation,
  UserDeleteMutationVariables
>;
export const UserUpdateDocument = gql`
  mutation userUpdate($uuid: ID!, $input: UserDtoUpdate!) {
    userUpdate(uuid: $uuid, input: $input) {
      user {
        uuid
        createdAt
        updatedAt
        email
        firstName
        lastName
        activated
        enabled
        role
      }
    }
  }
`;
export type UserUpdateMutationFn = Apollo.MutationFunction<
  UserUpdateMutation,
  UserUpdateMutationVariables
>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserUpdateMutation,
    UserUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(
    UserUpdateDocument,
    options,
  );
}
export type UserUpdateMutationHookResult = ReturnType<
  typeof useUserUpdateMutation
>;
export type UserUpdateMutationResult =
  Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<
  UserUpdateMutation,
  UserUpdateMutationVariables
>;
export const ArticleCategoryGetDocument = gql`
  query articleCategoryGet($uuid: String!) {
    articleCategoryGet(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      category
      articles {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        duration
        text
        enabled
        slug
        meta_title
        meta_description
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        article_category {
          uuid
          createdAt
          updatedAt
          category
          articles {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            duration
            text
            enabled
            slug
            meta_title
            meta_description
          }
        }
      }
    }
  }
`;

/**
 * __useArticleCategoryGetQuery__
 *
 * To run a query within a React component, call `useArticleCategoryGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleCategoryGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleCategoryGetQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useArticleCategoryGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArticleCategoryGetQuery,
    ArticleCategoryGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ArticleCategoryGetQuery,
    ArticleCategoryGetQueryVariables
  >(ArticleCategoryGetDocument, options);
}
export function useArticleCategoryGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticleCategoryGetQuery,
    ArticleCategoryGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ArticleCategoryGetQuery,
    ArticleCategoryGetQueryVariables
  >(ArticleCategoryGetDocument, options);
}
export type ArticleCategoryGetQueryHookResult = ReturnType<
  typeof useArticleCategoryGetQuery
>;
export type ArticleCategoryGetLazyQueryHookResult = ReturnType<
  typeof useArticleCategoryGetLazyQuery
>;
export type ArticleCategoryGetQueryResult = Apollo.QueryResult<
  ArticleCategoryGetQuery,
  ArticleCategoryGetQueryVariables
>;
export const ArticleGetDocument = gql`
  query articleGet($uuid: String!) {
    articleGet(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      title
      subtitle
      duration
      text
      enabled
      slug
      meta_title
      meta_description
      image {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      article_category {
        uuid
        createdAt
        updatedAt
        category
        articles {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          duration
          text
          enabled
          slug
          meta_title
          meta_description
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          article_category {
            uuid
            createdAt
            updatedAt
            category
          }
        }
      }
    }
  }
`;

/**
 * __useArticleGetQuery__
 *
 * To run a query within a React component, call `useArticleGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleGetQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useArticleGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArticleGetQuery,
    ArticleGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticleGetQuery, ArticleGetQueryVariables>(
    ArticleGetDocument,
    options,
  );
}
export function useArticleGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticleGetQuery,
    ArticleGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ArticleGetQuery, ArticleGetQueryVariables>(
    ArticleGetDocument,
    options,
  );
}
export type ArticleGetQueryHookResult = ReturnType<typeof useArticleGetQuery>;
export type ArticleGetLazyQueryHookResult = ReturnType<
  typeof useArticleGetLazyQuery
>;
export type ArticleGetQueryResult = Apollo.QueryResult<
  ArticleGetQuery,
  ArticleGetQueryVariables
>;
export const ArticleGetBySlugDocument = gql`
  query articleGetBySlug($slug: String!) {
    articleGetBySlug(slug: $slug) {
      uuid
      createdAt
      updatedAt
      title
      subtitle
      duration
      text
      enabled
      slug
      meta_title
      meta_description
      image {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      article_category {
        uuid
        createdAt
        updatedAt
        category
        articles {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          duration
          text
          enabled
          slug
          meta_title
          meta_description
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          article_category {
            uuid
            createdAt
            updatedAt
            category
          }
        }
      }
    }
  }
`;

/**
 * __useArticleGetBySlugQuery__
 *
 * To run a query within a React component, call `useArticleGetBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleGetBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleGetBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useArticleGetBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArticleGetBySlugQuery,
    ArticleGetBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ArticleGetBySlugQuery, ArticleGetBySlugQueryVariables>(
    ArticleGetBySlugDocument,
    options,
  );
}
export function useArticleGetBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticleGetBySlugQuery,
    ArticleGetBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ArticleGetBySlugQuery,
    ArticleGetBySlugQueryVariables
  >(ArticleGetBySlugDocument, options);
}
export type ArticleGetBySlugQueryHookResult = ReturnType<
  typeof useArticleGetBySlugQuery
>;
export type ArticleGetBySlugLazyQueryHookResult = ReturnType<
  typeof useArticleGetBySlugLazyQuery
>;
export type ArticleGetBySlugQueryResult = Apollo.QueryResult<
  ArticleGetBySlugQuery,
  ArticleGetBySlugQueryVariables
>;
export const ArticlesCategoriesListDocument = gql`
  query articlesCategoriesList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: ArticleCategoryPaginationSortBy
  ) {
    articlesCategoriesList(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        category
        articles {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          duration
          text
          enabled
          slug
          meta_title
          meta_description
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          article_category {
            uuid
            createdAt
            updatedAt
            category
            articles {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              duration
              text
              enabled
              slug
              meta_title
              meta_description
            }
          }
        }
      }
    }
  }
`;

/**
 * __useArticlesCategoriesListQuery__
 *
 * To run a query within a React component, call `useArticlesCategoriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesCategoriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesCategoriesListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useArticlesCategoriesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArticlesCategoriesListQuery,
    ArticlesCategoriesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ArticlesCategoriesListQuery,
    ArticlesCategoriesListQueryVariables
  >(ArticlesCategoriesListDocument, options);
}
export function useArticlesCategoriesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticlesCategoriesListQuery,
    ArticlesCategoriesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ArticlesCategoriesListQuery,
    ArticlesCategoriesListQueryVariables
  >(ArticlesCategoriesListDocument, options);
}
export type ArticlesCategoriesListQueryHookResult = ReturnType<
  typeof useArticlesCategoriesListQuery
>;
export type ArticlesCategoriesListLazyQueryHookResult = ReturnType<
  typeof useArticlesCategoriesListLazyQuery
>;
export type ArticlesCategoriesListQueryResult = Apollo.QueryResult<
  ArticlesCategoriesListQuery,
  ArticlesCategoriesListQueryVariables
>;
export const ArticlesListAdminDocument = gql`
  query articlesListAdmin(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: ArticlePaginationSortBy
  ) {
    articlesListAdmin(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        duration
        text
        enabled
        slug
        meta_title
        meta_description
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        article_category {
          uuid
          createdAt
          updatedAt
          category
          articles {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            duration
            text
            enabled
            slug
            meta_title
            meta_description
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            article_category {
              uuid
              createdAt
              updatedAt
              category
            }
          }
        }
      }
    }
  }
`;

/**
 * __useArticlesListAdminQuery__
 *
 * To run a query within a React component, call `useArticlesListAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesListAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesListAdminQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useArticlesListAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    ArticlesListAdminQuery,
    ArticlesListAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ArticlesListAdminQuery,
    ArticlesListAdminQueryVariables
  >(ArticlesListAdminDocument, options);
}
export function useArticlesListAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ArticlesListAdminQuery,
    ArticlesListAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ArticlesListAdminQuery,
    ArticlesListAdminQueryVariables
  >(ArticlesListAdminDocument, options);
}
export type ArticlesListAdminQueryHookResult = ReturnType<
  typeof useArticlesListAdminQuery
>;
export type ArticlesListAdminLazyQueryHookResult = ReturnType<
  typeof useArticlesListAdminLazyQuery
>;
export type ArticlesListAdminQueryResult = Apollo.QueryResult<
  ArticlesListAdminQuery,
  ArticlesListAdminQueryVariables
>;
export const ConfigsGetDocument = gql`
  query configsGet {
    configsGet {
      uuid
      createdAt
      updatedAt
      shipping_standard_cost {
        start_amount
        end_amount
        cost
      }
      country {
        country_type
        country_name
        country_code
        vat
        delivery_cost
        shipping_days_number
      }
      shipping_standard_days_number
      shipping_express_cost_percentage
      shipping_express_days_number
      verification_cost
      symetrique_design_cost
      graphic_service_cost
      installer_price
      vat
      facebook_social_link
      instagram_social_link
      youtube_social_link
    }
  }
`;

/**
 * __useConfigsGetQuery__
 *
 * To run a query within a React component, call `useConfigsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigsGetQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigsGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ConfigsGetQuery,
    ConfigsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConfigsGetQuery, ConfigsGetQueryVariables>(
    ConfigsGetDocument,
    options,
  );
}
export function useConfigsGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConfigsGetQuery,
    ConfigsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ConfigsGetQuery, ConfigsGetQueryVariables>(
    ConfigsGetDocument,
    options,
  );
}
export type ConfigsGetQueryHookResult = ReturnType<typeof useConfigsGetQuery>;
export type ConfigsGetLazyQueryHookResult = ReturnType<
  typeof useConfigsGetLazyQuery
>;
export type ConfigsGetQueryResult = Apollo.QueryResult<
  ConfigsGetQuery,
  ConfigsGetQueryVariables
>;
export const DiscountCodesListDocument = gql`
  query discountCodesList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: DiscountCodesPaginationSortBy
  ) {
    discountCodesList(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        code
        value
        use_before
        max_use_times
        used_times
        saved_money
        total_buy_amount
        is_percentage
      }
    }
  }
`;

/**
 * __useDiscountCodesListQuery__
 *
 * To run a query within a React component, call `useDiscountCodesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountCodesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountCodesListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useDiscountCodesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    DiscountCodesListQuery,
    DiscountCodesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DiscountCodesListQuery,
    DiscountCodesListQueryVariables
  >(DiscountCodesListDocument, options);
}
export function useDiscountCodesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DiscountCodesListQuery,
    DiscountCodesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DiscountCodesListQuery,
    DiscountCodesListQueryVariables
  >(DiscountCodesListDocument, options);
}
export type DiscountCodesListQueryHookResult = ReturnType<
  typeof useDiscountCodesListQuery
>;
export type DiscountCodesListLazyQueryHookResult = ReturnType<
  typeof useDiscountCodesListLazyQuery
>;
export type DiscountCodesListQueryResult = Apollo.QueryResult<
  DiscountCodesListQuery,
  DiscountCodesListQueryVariables
>;
export const FaqsListDocument = gql`
  query faqsList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: FaqsPaginationSortBy
  ) {
    faqsList(skip: $skip, take: $take, keyword: $keyword, sortBy: $sortBy) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        response
        category
        author {
          uuid
          createdAt
          updatedAt
          email
          firstName
          lastName
          activated
          enabled
          role
        }
      }
    }
  }
`;

/**
 * __useFaqsListQuery__
 *
 * To run a query within a React component, call `useFaqsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFaqsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFaqsListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useFaqsListQuery(
  baseOptions: Apollo.QueryHookOptions<FaqsListQuery, FaqsListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FaqsListQuery, FaqsListQueryVariables>(
    FaqsListDocument,
    options,
  );
}
export function useFaqsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FaqsListQuery,
    FaqsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FaqsListQuery, FaqsListQueryVariables>(
    FaqsListDocument,
    options,
  );
}
export type FaqsListQueryHookResult = ReturnType<typeof useFaqsListQuery>;
export type FaqsListLazyQueryHookResult = ReturnType<
  typeof useFaqsListLazyQuery
>;
export type FaqsListQueryResult = Apollo.QueryResult<
  FaqsListQuery,
  FaqsListQueryVariables
>;
export const FontGetDocument = gql`
  query fontGet($uuid: String!) {
    fontGet(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      title
      response
    }
  }
`;

/**
 * __useFontGetQuery__
 *
 * To run a query within a React component, call `useFontGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useFontGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFontGetQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useFontGetQuery(
  baseOptions: Apollo.QueryHookOptions<FontGetQuery, FontGetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FontGetQuery, FontGetQueryVariables>(
    FontGetDocument,
    options,
  );
}
export function useFontGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FontGetQuery,
    FontGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FontGetQuery, FontGetQueryVariables>(
    FontGetDocument,
    options,
  );
}
export type FontGetQueryHookResult = ReturnType<typeof useFontGetQuery>;
export type FontGetLazyQueryHookResult = ReturnType<typeof useFontGetLazyQuery>;
export type FontGetQueryResult = Apollo.QueryResult<
  FontGetQuery,
  FontGetQueryVariables
>;
export const FontListDocument = gql`
  query fontList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: FontPaginationSortBy
  ) {
    fontList(skip: $skip, take: $take, keyword: $keyword, sortBy: $sortBy) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        response
      }
    }
  }
`;

/**
 * __useFontListQuery__
 *
 * To run a query within a React component, call `useFontListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFontListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFontListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useFontListQuery(
  baseOptions: Apollo.QueryHookOptions<FontListQuery, FontListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FontListQuery, FontListQueryVariables>(
    FontListDocument,
    options,
  );
}
export function useFontListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FontListQuery,
    FontListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FontListQuery, FontListQueryVariables>(
    FontListDocument,
    options,
  );
}
export type FontListQueryHookResult = ReturnType<typeof useFontListQuery>;
export type FontListLazyQueryHookResult = ReturnType<
  typeof useFontListLazyQuery
>;
export type FontListQueryResult = Apollo.QueryResult<
  FontListQuery,
  FontListQueryVariables
>;
export const StorageGetDocument = gql`
  query storageGet($uuid: String!) {
    storageGet(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      file_name
      url
      mime_type
      width
      height
      size
      caption
      description
      alternative_title
      thumbnail {
        width
        height
        size
        url
        file_name
      }
      medium {
        width
        height
        size
        url
        file_name
      }
    }
  }
`;

/**
 * __useStorageGetQuery__
 *
 * To run a query within a React component, call `useStorageGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageGetQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useStorageGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    StorageGetQuery,
    StorageGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StorageGetQuery, StorageGetQueryVariables>(
    StorageGetDocument,
    options,
  );
}
export function useStorageGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StorageGetQuery,
    StorageGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StorageGetQuery, StorageGetQueryVariables>(
    StorageGetDocument,
    options,
  );
}
export type StorageGetQueryHookResult = ReturnType<typeof useStorageGetQuery>;
export type StorageGetLazyQueryHookResult = ReturnType<
  typeof useStorageGetLazyQuery
>;
export type StorageGetQueryResult = Apollo.QueryResult<
  StorageGetQuery,
  StorageGetQueryVariables
>;
export const StoragesListDocument = gql`
  query storagesList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: StoragesPaginationSortBy
    $filterByMimeType: [String!]
  ) {
    storagesList(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
      filterByMimeType: $filterByMimeType
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
    }
  }
`;

/**
 * __useStoragesListQuery__
 *
 * To run a query within a React component, call `useStoragesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoragesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoragesListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *      filterByMimeType: // value for 'filterByMimeType'
 *   },
 * });
 */
export function useStoragesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoragesListQuery,
    StoragesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoragesListQuery, StoragesListQueryVariables>(
    StoragesListDocument,
    options,
  );
}
export function useStoragesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoragesListQuery,
    StoragesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoragesListQuery, StoragesListQueryVariables>(
    StoragesListDocument,
    options,
  );
}
export type StoragesListQueryHookResult = ReturnType<
  typeof useStoragesListQuery
>;
export type StoragesListLazyQueryHookResult = ReturnType<
  typeof useStoragesListLazyQuery
>;
export type StoragesListQueryResult = Apollo.QueryResult<
  StoragesListQuery,
  StoragesListQueryVariables
>;
export const EstimatesNotificationsGetDocument = gql`
  query estimatesNotificationsGet {
    estimatesNotificationsGet {
      uuid
      createdAt
      updatedAt
      estimate {
        uuid
        createdAt
        updatedAt
        name
        company_name
        phonenumber
        mail
        subject
        status
        vehicle
        description_of_project
        your_mark
        your_model
        year_of_your_vehicle
        marquetry
        file {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;

/**
 * __useEstimatesNotificationsGetQuery__
 *
 * To run a query within a React component, call `useEstimatesNotificationsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimatesNotificationsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimatesNotificationsGetQuery({
 *   variables: {
 *   },
 * });
 */
export function useEstimatesNotificationsGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    EstimatesNotificationsGetQuery,
    EstimatesNotificationsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EstimatesNotificationsGetQuery,
    EstimatesNotificationsGetQueryVariables
  >(EstimatesNotificationsGetDocument, options);
}
export function useEstimatesNotificationsGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstimatesNotificationsGetQuery,
    EstimatesNotificationsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EstimatesNotificationsGetQuery,
    EstimatesNotificationsGetQueryVariables
  >(EstimatesNotificationsGetDocument, options);
}
export type EstimatesNotificationsGetQueryHookResult = ReturnType<
  typeof useEstimatesNotificationsGetQuery
>;
export type EstimatesNotificationsGetLazyQueryHookResult = ReturnType<
  typeof useEstimatesNotificationsGetLazyQuery
>;
export type EstimatesNotificationsGetQueryResult = Apollo.QueryResult<
  EstimatesNotificationsGetQuery,
  EstimatesNotificationsGetQueryVariables
>;
export const OrdersGetStatsDocument = gql`
  query ordersGetStats {
    ordersGetStats {
      total {
        quantity
        total
      }
      paid {
        quantity
        total
      }
      waiting {
        quantity
        total
      }
      delivered {
        quantity
        total
      }
      draft {
        quantity
        total
      }
      shipped {
        quantity
        total
      }
      waiting_for_visuel {
        quantity
        total
      }
      waiting_for_bat {
        quantity
        total
      }
      order_in_verification {
        quantity
        total
      }
      order_in_printing {
        quantity
        total
      }
    }
  }
`;

/**
 * __useOrdersGetStatsQuery__
 *
 * To run a query within a React component, call `useOrdersGetStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersGetStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersGetStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersGetStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrdersGetStatsQuery,
    OrdersGetStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrdersGetStatsQuery, OrdersGetStatsQueryVariables>(
    OrdersGetStatsDocument,
    options,
  );
}
export function useOrdersGetStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersGetStatsQuery,
    OrdersGetStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrdersGetStatsQuery, OrdersGetStatsQueryVariables>(
    OrdersGetStatsDocument,
    options,
  );
}
export type OrdersGetStatsQueryHookResult = ReturnType<
  typeof useOrdersGetStatsQuery
>;
export type OrdersGetStatsLazyQueryHookResult = ReturnType<
  typeof useOrdersGetStatsLazyQuery
>;
export type OrdersGetStatsQueryResult = Apollo.QueryResult<
  OrdersGetStatsQuery,
  OrdersGetStatsQueryVariables
>;
export const OrdersNotificationsGetDocument = gql`
  query ordersNotificationsGet {
    ordersNotificationsGet {
      uuid
      createdAt
      updatedAt
      order {
        uuid
        createdAt
        updatedAt
        order_code
        already_add_review
        sub_total
        shipping_cost
        total_without_vat
        start_date_of_delivery
        end_date_of_delivery
        vat
        total_all_vat
        discount_amount
        payment_method
        ordering_status
        delivery_mode
        invoice {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        discount_code {
          uuid
          createdAt
          updatedAt
          code
          value
          use_before
          max_use_times
          used_times
          saved_money
          total_buy_amount
          is_percentage
        }
        stripe_payment_data {
          status
          id
          payment_link
          balance_transaction
          payment_method
          receipt_url
          source {
            id
            brand
            country
            last4
            exp_month
            exp_year
          }
        }
        has_quote
        user {
          uuid
          createdAt
          updatedAt
          email
          firstName
          lastName
          company_name
          address
          postal_code
          city
          country
          phonenumber
          vehicles {
            brand
            model
            year
            color
          }
          activated
          enabled
          role
        }
        delivery_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        billing_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        order_products {
          uuid
          createdAt
          updatedAt
          verification
          graphic_service
          professional_installer
          synthetic_design
          custom_design
          design_is_finished
          quantity
          total
          squid_studio_data {
            width
            height
            radius
            can_have_background
            data
          }
          vehicle_information {
            brand
            model
            year
            color
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_pdf {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_svg {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          product {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            type
            description
            slug
            meta_title
            meta_description
            verification
            graphic_service
            professional_installer
            enabled
            index
            start_price
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
              }
            }
            sections {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              extra_price_size {
                size_uuid
                extra_price
              }
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                depends_on_supports {
                  uuid
                  createdAt
                  updatedAt
                  title
                  can_have_background
                  extra_price
                  overview
                  video_url
                  installation_difficulty
                }
              }
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              extra_price_sections {
                section_uuid
                extra_price
              }
              installer_extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
            }
            templates {
              uuid
              createdAt
              updatedAt
              title
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
                thumbnail {
                  width
                  height
                  size
                  url
                  file_name
                }
                medium {
                  width
                  height
                  size
                  url
                  file_name
                }
              }
            }
            product_images {
              uuid
              createdAt
              updatedAt
              support_uuid
              size_uuid
              section_uuid
              extra_sticker_uuid
              file {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          size {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
          }
          support {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
          section {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            installer_extra_price
            overview
            video_url
            installation_difficulty
          }
        }
      }
    }
  }
`;

/**
 * __useOrdersNotificationsGetQuery__
 *
 * To run a query within a React component, call `useOrdersNotificationsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersNotificationsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersNotificationsGetQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersNotificationsGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OrdersNotificationsGetQuery,
    OrdersNotificationsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrdersNotificationsGetQuery,
    OrdersNotificationsGetQueryVariables
  >(OrdersNotificationsGetDocument, options);
}
export function useOrdersNotificationsGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrdersNotificationsGetQuery,
    OrdersNotificationsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrdersNotificationsGetQuery,
    OrdersNotificationsGetQueryVariables
  >(OrdersNotificationsGetDocument, options);
}
export type OrdersNotificationsGetQueryHookResult = ReturnType<
  typeof useOrdersNotificationsGetQuery
>;
export type OrdersNotificationsGetLazyQueryHookResult = ReturnType<
  typeof useOrdersNotificationsGetLazyQuery
>;
export type OrdersNotificationsGetQueryResult = Apollo.QueryResult<
  OrdersNotificationsGetQuery,
  OrdersNotificationsGetQueryVariables
>;
export const GetStatsByDayDocument = gql`
  query getStatsByDay {
    getStatsByDay {
      stats {
        quantity
        quantityPercent
      }
    }
  }
`;

/**
 * __useGetStatsByDayQuery__
 *
 * To run a query within a React component, call `useGetStatsByDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatsByDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatsByDayQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatsByDayQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStatsByDayQuery,
    GetStatsByDayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatsByDayQuery, GetStatsByDayQueryVariables>(
    GetStatsByDayDocument,
    options,
  );
}
export function useGetStatsByDayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStatsByDayQuery,
    GetStatsByDayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatsByDayQuery, GetStatsByDayQueryVariables>(
    GetStatsByDayDocument,
    options,
  );
}
export type GetStatsByDayQueryHookResult = ReturnType<
  typeof useGetStatsByDayQuery
>;
export type GetStatsByDayLazyQueryHookResult = ReturnType<
  typeof useGetStatsByDayLazyQuery
>;
export type GetStatsByDayQueryResult = Apollo.QueryResult<
  GetStatsByDayQuery,
  GetStatsByDayQueryVariables
>;
export const OrderGetDocument = gql`
  query orderGet($uuid: String!) {
    orderGet(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      order_code
      already_add_review
      sub_total
      shipping_cost
      total_without_vat
      start_date_of_delivery
      end_date_of_delivery
      vat
      total_all_vat
      discount_amount
      payment_method
      ordering_status
      delivery_mode
      invoice {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      discount_code {
        uuid
        createdAt
        updatedAt
        code
        value
        use_before
        max_use_times
        used_times
        saved_money
        total_buy_amount
        is_percentage
      }
      stripe_payment_data {
        status
        id
        payment_link
        balance_transaction
        payment_method
        receipt_url
        source {
          id
          brand
          country
          last4
          exp_month
          exp_year
        }
      }
      has_quote
      user {
        uuid
        createdAt
        updatedAt
        email
        firstName
        lastName
        company_name
        address
        postal_code
        city
        country
        phonenumber
        vehicles {
          brand
          model
          year
          color
        }
        activated
        enabled
        role
      }
      delivery_address {
        uuid
        createdAt
        updatedAt
        firstname
        lastname
        company_name
        tva_number
        address
        postal_code
        city
        country
        phonenumber
        email
      }
      billing_address {
        uuid
        createdAt
        updatedAt
        firstname
        lastname
        company_name
        tva_number
        address
        postal_code
        city
        country
        phonenumber
        email
      }
      order_products {
        uuid
        createdAt
        updatedAt
        verification
        graphic_service
        professional_installer
        synthetic_design
        custom_design
        design_is_finished
        quantity
        total
        squid_studio_data {
          width
          height
          radius
          can_have_background
          data
        }
        vehicle_information {
          brand
          model
          year
          color
        }
        note
        is_rear_camera_selected
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        image_pdf {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        image_svg {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        product {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          type
          description
          slug
          meta_title
          meta_description
          verification
          graphic_service
          professional_installer
          enabled
          index
          start_price
          is_perfect_squid
          canvas_width
          canvas_height
          canvas_radius
          video_url
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          sections {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            extra_price_sections {
              section_uuid
              extra_price
            }
            installer_extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          templates {
            uuid
            createdAt
            updatedAt
            title
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          product_images {
            uuid
            createdAt
            updatedAt
            support_uuid
            size_uuid
            section_uuid
            extra_sticker_uuid
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
        size {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
          }
        }
        support {
          uuid
          createdAt
          updatedAt
          title
          can_have_background
          extra_price
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
        }
        section {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          can_have_background
          extra_price
          canvas_width
          canvas_height
          canvas_radius
          extra_price_size {
            size_uuid
            extra_price
          }
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
          }
        }
        extra_stickers {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          design_choice_possible
          extra_price
          installer_extra_price
          overview
          video_url
          installation_difficulty
        }
        car_brand {
          uuid
          createdAt
          updatedAt
          brand_name
          info_title
          info_description
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          pictogram_dark {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          brand_models {
            uuid
            createdAt
            updatedAt
            model_name
            info_title
            info_description
            car_brand {
              uuid
              createdAt
              updatedAt
              brand_name
              info_title
              info_description
            }
            model_years {
              uuid
              createdAt
              updatedAt
              start_year
              end_year
              info_title
              info_description
              brand_model {
                uuid
                createdAt
                updatedAt
                model_name
                info_title
                info_description
                car_brand {
                  uuid
                  createdAt
                  updatedAt
                  brand_name
                  info_title
                  info_description
                }
                model_years {
                  uuid
                  createdAt
                  updatedAt
                  start_year
                  end_year
                  info_title
                  info_description
                  car_versions {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                        thumbnail {
                          width
                          height
                          size
                          url
                          file_name
                        }
                        medium {
                          width
                          height
                          size
                          url
                          file_name
                        }
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        version_lateral {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          side_glazings {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            info_title
                            info_description
                            preview_text
                          }
                          cars_backwards {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                              car_version {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                info_title
                                info_description
                                preview_text
                                pictogram {
                                  uuid
                                  createdAt
                                  updatedAt
                                  file_name
                                  url
                                  mime_type
                                  width
                                  height
                                  size
                                  caption
                                  description
                                  alternative_title
                                }
                                version_laterals {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  extra_price
                                  installer_extra_price
                                  installation_difficulty
                                  info_title
                                  info_description
                                  preview_text
                                  cars_backwards {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    preview_text
                                    car_backward_types {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      has_rear_camera
                                      pictogram {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      preview_image {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      car_backward {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        extra_price
                                        installer_extra_price
                                        installation_difficulty
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_lateral {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        car_backward_types {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          has_rear_camera
                                        }
                                      }
                                    }
                                  }
                                  car_version {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    preview_text
                                    model_year {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      brand_model {
                                        uuid
                                        createdAt
                                        updatedAt
                                        model_name
                                        info_title
                                        info_description
                                      }
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        brand_model {
          uuid
          createdAt
          updatedAt
          model_name
          info_title
          info_description
        }
        model_year {
          uuid
          createdAt
          updatedAt
          start_year
          end_year
          info_title
          info_description
        }
        car_version {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          preview_text
        }
        version_lateral {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          installation_difficulty
          info_title
          info_description
          preview_text
        }
        side_glazing {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          version_lateral {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            installation_difficulty
            info_title
            info_description
            preview_text
          }
        }
        car_backward {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          extra_price
          installer_extra_price
          installation_difficulty
          preview_text
        }
        car_backward_type {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          has_rear_camera
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          car_backward {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            info_title
            info_description
            extra_price
            installer_extra_price
            installation_difficulty
            preview_text
          }
        }
      }
    }
  }
`;

/**
 * __useOrderGetQuery__
 *
 * To run a query within a React component, call `useOrderGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderGetQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useOrderGetQuery(
  baseOptions: Apollo.QueryHookOptions<OrderGetQuery, OrderGetQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderGetQuery, OrderGetQueryVariables>(
    OrderGetDocument,
    options,
  );
}
export function useOrderGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderGetQuery,
    OrderGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderGetQuery, OrderGetQueryVariables>(
    OrderGetDocument,
    options,
  );
}
export type OrderGetQueryHookResult = ReturnType<typeof useOrderGetQuery>;
export type OrderGetLazyQueryHookResult = ReturnType<
  typeof useOrderGetLazyQuery
>;
export type OrderGetQueryResult = Apollo.QueryResult<
  OrderGetQuery,
  OrderGetQueryVariables
>;
export const PagesListDocument = gql`
  query pagesList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: PagePaginationSortBy
  ) {
    pagesList(skip: $skip, take: $take, keyword: $keyword, sortBy: $sortBy) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        name
        slug
        meta_title
        meta_description
      }
    }
  }
`;

/**
 * __usePagesListQuery__
 *
 * To run a query within a React component, call `usePagesListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagesListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function usePagesListQuery(
  baseOptions: Apollo.QueryHookOptions<PagesListQuery, PagesListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PagesListQuery, PagesListQueryVariables>(
    PagesListDocument,
    options,
  );
}
export function usePagesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PagesListQuery,
    PagesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PagesListQuery, PagesListQueryVariables>(
    PagesListDocument,
    options,
  );
}
export type PagesListQueryHookResult = ReturnType<typeof usePagesListQuery>;
export type PagesListLazyQueryHookResult = ReturnType<
  typeof usePagesListLazyQuery
>;
export type PagesListQueryResult = Apollo.QueryResult<
  PagesListQuery,
  PagesListQueryVariables
>;
export const PagesListWithoutAuthDocument = gql`
  query pagesListWithoutAuth(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: PagePaginationSortBy
    $slug: String
  ) {
    pagesListWithoutAuth(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
      slug: $slug
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        name
        slug
        meta_title
        meta_description
        faqPageLists {
          uuid
          createdAt
          updatedAt
          title
          faqPages {
            uuid
            createdAt
            updatedAt
            question
            response
          }
        }
        questionPageLists {
          uuid
          createdAt
          updatedAt
          title
          questionsPages {
            uuid
            createdAt
            updatedAt
            question
            response
          }
        }
      }
    }
  }
`;

/**
 * __usePagesListWithoutAuthQuery__
 *
 * To run a query within a React component, call `usePagesListWithoutAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagesListWithoutAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagesListWithoutAuthQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function usePagesListWithoutAuthQuery(
  baseOptions: Apollo.QueryHookOptions<
    PagesListWithoutAuthQuery,
    PagesListWithoutAuthQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PagesListWithoutAuthQuery,
    PagesListWithoutAuthQueryVariables
  >(PagesListWithoutAuthDocument, options);
}
export function usePagesListWithoutAuthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PagesListWithoutAuthQuery,
    PagesListWithoutAuthQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PagesListWithoutAuthQuery,
    PagesListWithoutAuthQueryVariables
  >(PagesListWithoutAuthDocument, options);
}
export type PagesListWithoutAuthQueryHookResult = ReturnType<
  typeof usePagesListWithoutAuthQuery
>;
export type PagesListWithoutAuthLazyQueryHookResult = ReturnType<
  typeof usePagesListWithoutAuthLazyQuery
>;
export type PagesListWithoutAuthQueryResult = Apollo.QueryResult<
  PagesListWithoutAuthQuery,
  PagesListWithoutAuthQueryVariables
>;
export const BrandModelDetailDocument = gql`
  query brandModelDetail($uuid: String!) {
    brandModelDetail(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      model_name
      info_title
      info_description
      car_brand {
        uuid
        createdAt
        updatedAt
        brand_name
        info_title
        info_description
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        pictogram_dark {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        brand_models {
          uuid
          createdAt
          updatedAt
          model_name
          info_title
          info_description
          car_brand {
            uuid
            createdAt
            updatedAt
            brand_name
            info_title
            info_description
          }
          model_years {
            uuid
            createdAt
            updatedAt
            start_year
            end_year
            info_title
            info_description
            brand_model {
              uuid
              createdAt
              updatedAt
              model_name
              info_title
              info_description
              car_brand {
                uuid
                createdAt
                updatedAt
                brand_name
                info_title
                info_description
              }
              model_years {
                uuid
                createdAt
                updatedAt
                start_year
                end_year
                info_title
                info_description
                car_versions {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  info_title
                  info_description
                  preview_text
                  pictogram {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  version_laterals {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    extra_price
                    installer_extra_price
                    installation_difficulty
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    preview_image {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                      thumbnail {
                        width
                        height
                        size
                        url
                        file_name
                      }
                      medium {
                        width
                        height
                        size
                        url
                        file_name
                      }
                    }
                    side_glazings {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      version_lateral {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        side_glazings {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          info_title
                          info_description
                          preview_text
                        }
                        cars_backwards {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                            car_version {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              preview_text
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              version_laterals {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                extra_price
                                installer_extra_price
                                installation_difficulty
                                info_title
                                info_description
                                preview_text
                                cars_backwards {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  info_title
                                  info_description
                                  extra_price
                                  installer_extra_price
                                  installation_difficulty
                                  preview_text
                                  car_backward_types {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    has_rear_camera
                                    pictogram {
                                      uuid
                                      createdAt
                                      updatedAt
                                      file_name
                                      url
                                      mime_type
                                      width
                                      height
                                      size
                                      caption
                                      description
                                      alternative_title
                                    }
                                    preview_image {
                                      uuid
                                      createdAt
                                      updatedAt
                                      file_name
                                      url
                                      mime_type
                                      width
                                      height
                                      size
                                      caption
                                      description
                                      alternative_title
                                    }
                                    car_backward {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      extra_price
                                      installer_extra_price
                                      installation_difficulty
                                      preview_text
                                      pictogram {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      preview_image {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      version_lateral {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        extra_price
                                        installer_extra_price
                                        installation_difficulty
                                        info_title
                                        info_description
                                        preview_text
                                      }
                                      car_backward_types {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        has_rear_camera
                                      }
                                    }
                                  }
                                }
                                car_version {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  info_title
                                  info_description
                                  preview_text
                                  model_year {
                                    uuid
                                    createdAt
                                    updatedAt
                                    start_year
                                    end_year
                                    info_title
                                    info_description
                                    brand_model {
                                      uuid
                                      createdAt
                                      updatedAt
                                      model_name
                                      info_title
                                      info_description
                                    }
                                    car_versions {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      model_years {
        uuid
        createdAt
        updatedAt
        start_year
        end_year
        info_title
        info_description
      }
    }
  }
`;

/**
 * __useBrandModelDetailQuery__
 *
 * To run a query within a React component, call `useBrandModelDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandModelDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandModelDetailQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useBrandModelDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    BrandModelDetailQuery,
    BrandModelDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandModelDetailQuery, BrandModelDetailQueryVariables>(
    BrandModelDetailDocument,
    options,
  );
}
export function useBrandModelDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BrandModelDetailQuery,
    BrandModelDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BrandModelDetailQuery,
    BrandModelDetailQueryVariables
  >(BrandModelDetailDocument, options);
}
export type BrandModelDetailQueryHookResult = ReturnType<
  typeof useBrandModelDetailQuery
>;
export type BrandModelDetailLazyQueryHookResult = ReturnType<
  typeof useBrandModelDetailLazyQuery
>;
export type BrandModelDetailQueryResult = Apollo.QueryResult<
  BrandModelDetailQuery,
  BrandModelDetailQueryVariables
>;
export const BrandModelListDocument = gql`
  query brandModelList($car_brand_uuid: String) {
    brandModelList(car_brand_uuid: $car_brand_uuid) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        model_name
        info_title
        info_description
        car_brand {
          uuid
          createdAt
          updatedAt
          brand_name
          info_title
          info_description
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          pictogram_dark {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          brand_models {
            uuid
            createdAt
            updatedAt
            model_name
            info_title
            info_description
            car_brand {
              uuid
              createdAt
              updatedAt
              brand_name
              info_title
              info_description
            }
            model_years {
              uuid
              createdAt
              updatedAt
              start_year
              end_year
              info_title
              info_description
              brand_model {
                uuid
                createdAt
                updatedAt
                model_name
                info_title
                info_description
                car_brand {
                  uuid
                  createdAt
                  updatedAt
                  brand_name
                  info_title
                  info_description
                }
                model_years {
                  uuid
                  createdAt
                  updatedAt
                  start_year
                  end_year
                  info_title
                  info_description
                  car_versions {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                        thumbnail {
                          width
                          height
                          size
                          url
                          file_name
                        }
                        medium {
                          width
                          height
                          size
                          url
                          file_name
                        }
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        version_lateral {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          side_glazings {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            info_title
                            info_description
                            preview_text
                          }
                          cars_backwards {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                              car_version {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                info_title
                                info_description
                                preview_text
                                pictogram {
                                  uuid
                                  createdAt
                                  updatedAt
                                  file_name
                                  url
                                  mime_type
                                  width
                                  height
                                  size
                                  caption
                                  description
                                  alternative_title
                                }
                                version_laterals {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  extra_price
                                  installer_extra_price
                                  installation_difficulty
                                  info_title
                                  info_description
                                  preview_text
                                  cars_backwards {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    preview_text
                                    car_backward_types {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      has_rear_camera
                                      pictogram {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      preview_image {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      car_backward {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        extra_price
                                        installer_extra_price
                                        installation_difficulty
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_lateral {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        car_backward_types {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          has_rear_camera
                                        }
                                      }
                                    }
                                  }
                                  car_version {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    preview_text
                                    model_year {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      brand_model {
                                        uuid
                                        createdAt
                                        updatedAt
                                        model_name
                                        info_title
                                        info_description
                                      }
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        model_years {
          uuid
          createdAt
          updatedAt
          start_year
          end_year
          info_title
          info_description
        }
      }
    }
  }
`;

/**
 * __useBrandModelListQuery__
 *
 * To run a query within a React component, call `useBrandModelListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandModelListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandModelListQuery({
 *   variables: {
 *      car_brand_uuid: // value for 'car_brand_uuid'
 *   },
 * });
 */
export function useBrandModelListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BrandModelListQuery,
    BrandModelListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BrandModelListQuery, BrandModelListQueryVariables>(
    BrandModelListDocument,
    options,
  );
}
export function useBrandModelListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BrandModelListQuery,
    BrandModelListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BrandModelListQuery, BrandModelListQueryVariables>(
    BrandModelListDocument,
    options,
  );
}
export type BrandModelListQueryHookResult = ReturnType<
  typeof useBrandModelListQuery
>;
export type BrandModelListLazyQueryHookResult = ReturnType<
  typeof useBrandModelListLazyQuery
>;
export type BrandModelListQueryResult = Apollo.QueryResult<
  BrandModelListQuery,
  BrandModelListQueryVariables
>;
export const CarBackwardDetailDocument = gql`
  query carBackwardDetail($uuid: String!) {
    carBackwardDetail(uuid: $uuid) {
      carBackward {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        extra_price
        installer_extra_price
        installation_difficulty
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        version_lateral {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          installation_difficulty
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          side_glazings {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
              cars_backwards {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                info_title
                info_description
                extra_price
                installer_extra_price
                installation_difficulty
                preview_text
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                preview_image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                version_lateral {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  info_title
                  info_description
                  preview_text
                  car_version {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                      }
                      cars_backwards {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        preview_text
                        car_backward_types {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          has_rear_camera
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          car_backward {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                            }
                            car_backward_types {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              has_rear_camera
                            }
                          }
                        }
                      }
                      car_version {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        preview_text
                        model_year {
                          uuid
                          createdAt
                          updatedAt
                          start_year
                          end_year
                          info_title
                          info_description
                          brand_model {
                            uuid
                            createdAt
                            updatedAt
                            model_name
                            info_title
                            info_description
                            car_brand {
                              uuid
                              createdAt
                              updatedAt
                              brand_name
                              info_title
                              info_description
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              pictogram_dark {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                                thumbnail {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                                medium {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                              }
                              brand_models {
                                uuid
                                createdAt
                                updatedAt
                                model_name
                                info_title
                                info_description
                                car_brand {
                                  uuid
                                  createdAt
                                  updatedAt
                                  brand_name
                                  info_title
                                  info_description
                                }
                                model_years {
                                  uuid
                                  createdAt
                                  updatedAt
                                  start_year
                                  end_year
                                  info_title
                                  info_description
                                  brand_model {
                                    uuid
                                    createdAt
                                    updatedAt
                                    model_name
                                    info_title
                                    info_description
                                    model_years {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_laterals {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                          car_versions {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        car_backward_types {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          has_rear_camera
        }
      }
    }
  }
`;

/**
 * __useCarBackwardDetailQuery__
 *
 * To run a query within a React component, call `useCarBackwardDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarBackwardDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarBackwardDetailQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCarBackwardDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    CarBackwardDetailQuery,
    CarBackwardDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CarBackwardDetailQuery,
    CarBackwardDetailQueryVariables
  >(CarBackwardDetailDocument, options);
}
export function useCarBackwardDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CarBackwardDetailQuery,
    CarBackwardDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CarBackwardDetailQuery,
    CarBackwardDetailQueryVariables
  >(CarBackwardDetailDocument, options);
}
export type CarBackwardDetailQueryHookResult = ReturnType<
  typeof useCarBackwardDetailQuery
>;
export type CarBackwardDetailLazyQueryHookResult = ReturnType<
  typeof useCarBackwardDetailLazyQuery
>;
export type CarBackwardDetailQueryResult = Apollo.QueryResult<
  CarBackwardDetailQuery,
  CarBackwardDetailQueryVariables
>;
export const CarBackwardListDocument = gql`
  query carBackwardList($version_lateral_uuid: String) {
    carBackwardList(version_lateral_uuid: $version_lateral_uuid) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        extra_price
        installer_extra_price
        installation_difficulty
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        version_lateral {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          installation_difficulty
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          side_glazings {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
              cars_backwards {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                info_title
                info_description
                extra_price
                installer_extra_price
                installation_difficulty
                preview_text
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                preview_image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                version_lateral {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  info_title
                  info_description
                  preview_text
                  car_version {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                      }
                      cars_backwards {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        preview_text
                        car_backward_types {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          has_rear_camera
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          car_backward {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                            }
                            car_backward_types {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              has_rear_camera
                            }
                          }
                        }
                      }
                      car_version {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        preview_text
                        model_year {
                          uuid
                          createdAt
                          updatedAt
                          start_year
                          end_year
                          info_title
                          info_description
                          brand_model {
                            uuid
                            createdAt
                            updatedAt
                            model_name
                            info_title
                            info_description
                            car_brand {
                              uuid
                              createdAt
                              updatedAt
                              brand_name
                              info_title
                              info_description
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              pictogram_dark {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                                thumbnail {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                                medium {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                              }
                              brand_models {
                                uuid
                                createdAt
                                updatedAt
                                model_name
                                info_title
                                info_description
                                car_brand {
                                  uuid
                                  createdAt
                                  updatedAt
                                  brand_name
                                  info_title
                                  info_description
                                }
                                model_years {
                                  uuid
                                  createdAt
                                  updatedAt
                                  start_year
                                  end_year
                                  info_title
                                  info_description
                                  brand_model {
                                    uuid
                                    createdAt
                                    updatedAt
                                    model_name
                                    info_title
                                    info_description
                                    model_years {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_laterals {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                          car_versions {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        car_backward_types {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          has_rear_camera
        }
      }
    }
  }
`;

/**
 * __useCarBackwardListQuery__
 *
 * To run a query within a React component, call `useCarBackwardListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarBackwardListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarBackwardListQuery({
 *   variables: {
 *      version_lateral_uuid: // value for 'version_lateral_uuid'
 *   },
 * });
 */
export function useCarBackwardListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CarBackwardListQuery,
    CarBackwardListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CarBackwardListQuery, CarBackwardListQueryVariables>(
    CarBackwardListDocument,
    options,
  );
}
export function useCarBackwardListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CarBackwardListQuery,
    CarBackwardListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CarBackwardListQuery,
    CarBackwardListQueryVariables
  >(CarBackwardListDocument, options);
}
export type CarBackwardListQueryHookResult = ReturnType<
  typeof useCarBackwardListQuery
>;
export type CarBackwardListLazyQueryHookResult = ReturnType<
  typeof useCarBackwardListLazyQuery
>;
export type CarBackwardListQueryResult = Apollo.QueryResult<
  CarBackwardListQuery,
  CarBackwardListQueryVariables
>;
export const CarBackwardTypeDetailDocument = gql`
  query carBackwardTypeDetail($uuid: String!) {
    carBackwardTypeDetail(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      title
      sub_title
      info_title
      info_description
      has_rear_camera
      pictogram {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      preview_image {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      car_backward {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        extra_price
        installer_extra_price
        installation_difficulty
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        version_lateral {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          installation_difficulty
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          side_glazings {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
              cars_backwards {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                info_title
                info_description
                extra_price
                installer_extra_price
                installation_difficulty
                preview_text
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                preview_image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                version_lateral {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  info_title
                  info_description
                  preview_text
                  car_version {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                      }
                      cars_backwards {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        preview_text
                        car_backward_types {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          has_rear_camera
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          car_backward {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            car_backward_types {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              has_rear_camera
                            }
                          }
                        }
                      }
                      car_version {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        preview_text
                        model_year {
                          uuid
                          createdAt
                          updatedAt
                          start_year
                          end_year
                          info_title
                          info_description
                          brand_model {
                            uuid
                            createdAt
                            updatedAt
                            model_name
                            info_title
                            info_description
                            car_brand {
                              uuid
                              createdAt
                              updatedAt
                              brand_name
                              info_title
                              info_description
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              pictogram_dark {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                                thumbnail {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                                medium {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                              }
                              brand_models {
                                uuid
                                createdAt
                                updatedAt
                                model_name
                                info_title
                                info_description
                                car_brand {
                                  uuid
                                  createdAt
                                  updatedAt
                                  brand_name
                                  info_title
                                  info_description
                                }
                                model_years {
                                  uuid
                                  createdAt
                                  updatedAt
                                  start_year
                                  end_year
                                  info_title
                                  info_description
                                  brand_model {
                                    uuid
                                    createdAt
                                    updatedAt
                                    model_name
                                    info_title
                                    info_description
                                    model_years {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_laterals {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                          car_versions {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCarBackwardTypeDetailQuery__
 *
 * To run a query within a React component, call `useCarBackwardTypeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarBackwardTypeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarBackwardTypeDetailQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCarBackwardTypeDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    CarBackwardTypeDetailQuery,
    CarBackwardTypeDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CarBackwardTypeDetailQuery,
    CarBackwardTypeDetailQueryVariables
  >(CarBackwardTypeDetailDocument, options);
}
export function useCarBackwardTypeDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CarBackwardTypeDetailQuery,
    CarBackwardTypeDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CarBackwardTypeDetailQuery,
    CarBackwardTypeDetailQueryVariables
  >(CarBackwardTypeDetailDocument, options);
}
export type CarBackwardTypeDetailQueryHookResult = ReturnType<
  typeof useCarBackwardTypeDetailQuery
>;
export type CarBackwardTypeDetailLazyQueryHookResult = ReturnType<
  typeof useCarBackwardTypeDetailLazyQuery
>;
export type CarBackwardTypeDetailQueryResult = Apollo.QueryResult<
  CarBackwardTypeDetailQuery,
  CarBackwardTypeDetailQueryVariables
>;
export const CarBackwardTypeListDocument = gql`
  query carBackwardTypeList($car_backward_uuid: String) {
    carBackwardTypeList(car_backward_uuid: $car_backward_uuid) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        has_rear_camera
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        car_backward {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          extra_price
          installer_extra_price
          installation_difficulty
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          version_lateral {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            installation_difficulty
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            side_glazings {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              info_title
              info_description
              preview_text
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              preview_image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              version_lateral {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                extra_price
                installer_extra_price
                installation_difficulty
                info_title
                info_description
                preview_text
                cars_backwards {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  info_title
                  info_description
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  preview_text
                  pictogram {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  preview_image {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  version_lateral {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    extra_price
                    installer_extra_price
                    installation_difficulty
                    info_title
                    info_description
                    preview_text
                    car_version {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      version_laterals {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        side_glazings {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          info_title
                          info_description
                          preview_text
                        }
                        cars_backwards {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          preview_text
                          car_backward_types {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            has_rear_camera
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            car_backward {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              preview_text
                              car_backward_types {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                info_title
                                info_description
                                has_rear_camera
                              }
                            }
                          }
                        }
                        car_version {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          preview_text
                          model_year {
                            uuid
                            createdAt
                            updatedAt
                            start_year
                            end_year
                            info_title
                            info_description
                            brand_model {
                              uuid
                              createdAt
                              updatedAt
                              model_name
                              info_title
                              info_description
                              car_brand {
                                uuid
                                createdAt
                                updatedAt
                                brand_name
                                info_title
                                info_description
                                pictogram {
                                  uuid
                                  createdAt
                                  updatedAt
                                  file_name
                                  url
                                  mime_type
                                  width
                                  height
                                  size
                                  caption
                                  description
                                  alternative_title
                                }
                                pictogram_dark {
                                  uuid
                                  createdAt
                                  updatedAt
                                  file_name
                                  url
                                  mime_type
                                  width
                                  height
                                  size
                                  caption
                                  description
                                  alternative_title
                                  thumbnail {
                                    width
                                    height
                                    size
                                    url
                                    file_name
                                  }
                                  medium {
                                    width
                                    height
                                    size
                                    url
                                    file_name
                                  }
                                }
                                brand_models {
                                  uuid
                                  createdAt
                                  updatedAt
                                  model_name
                                  info_title
                                  info_description
                                  car_brand {
                                    uuid
                                    createdAt
                                    updatedAt
                                    brand_name
                                    info_title
                                    info_description
                                  }
                                  model_years {
                                    uuid
                                    createdAt
                                    updatedAt
                                    start_year
                                    end_year
                                    info_title
                                    info_description
                                    brand_model {
                                      uuid
                                      createdAt
                                      updatedAt
                                      model_name
                                      info_title
                                      info_description
                                      model_years {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                          pictogram {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          version_laterals {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            extra_price
                                            installer_extra_price
                                            installation_difficulty
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                          model_year {
                                            uuid
                                            createdAt
                                            updatedAt
                                            start_year
                                            end_year
                                            info_title
                                            info_description
                                            car_versions {
                                              uuid
                                              createdAt
                                              updatedAt
                                              title
                                              sub_title
                                              info_title
                                              info_description
                                              preview_text
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCarBackwardTypeListQuery__
 *
 * To run a query within a React component, call `useCarBackwardTypeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarBackwardTypeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarBackwardTypeListQuery({
 *   variables: {
 *      car_backward_uuid: // value for 'car_backward_uuid'
 *   },
 * });
 */
export function useCarBackwardTypeListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CarBackwardTypeListQuery,
    CarBackwardTypeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CarBackwardTypeListQuery,
    CarBackwardTypeListQueryVariables
  >(CarBackwardTypeListDocument, options);
}
export function useCarBackwardTypeListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CarBackwardTypeListQuery,
    CarBackwardTypeListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CarBackwardTypeListQuery,
    CarBackwardTypeListQueryVariables
  >(CarBackwardTypeListDocument, options);
}
export type CarBackwardTypeListQueryHookResult = ReturnType<
  typeof useCarBackwardTypeListQuery
>;
export type CarBackwardTypeListLazyQueryHookResult = ReturnType<
  typeof useCarBackwardTypeListLazyQuery
>;
export type CarBackwardTypeListQueryResult = Apollo.QueryResult<
  CarBackwardTypeListQuery,
  CarBackwardTypeListQueryVariables
>;
export const CarBrandDetailDocument = gql`
  query carBrandDetail($uuid: String!) {
    carBrandDetail(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      brand_name
      info_title
      info_description
      pictogram {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      pictogram_dark {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      brand_models {
        uuid
        createdAt
        updatedAt
        model_name
        info_title
        info_description
        car_brand {
          uuid
          createdAt
          updatedAt
          brand_name
          info_title
          info_description
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          pictogram_dark {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          brand_models {
            uuid
            createdAt
            updatedAt
            model_name
            info_title
            info_description
            model_years {
              uuid
              createdAt
              updatedAt
              start_year
              end_year
              info_title
              info_description
              brand_model {
                uuid
                createdAt
                updatedAt
                model_name
                info_title
                info_description
                car_brand {
                  uuid
                  createdAt
                  updatedAt
                  brand_name
                  info_title
                  info_description
                }
                model_years {
                  uuid
                  createdAt
                  updatedAt
                  start_year
                  end_year
                  info_title
                  info_description
                  car_versions {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                        thumbnail {
                          width
                          height
                          size
                          url
                          file_name
                        }
                        medium {
                          width
                          height
                          size
                          url
                          file_name
                        }
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        version_lateral {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          side_glazings {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            info_title
                            info_description
                            preview_text
                          }
                          cars_backwards {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                              car_version {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                info_title
                                info_description
                                preview_text
                                pictogram {
                                  uuid
                                  createdAt
                                  updatedAt
                                  file_name
                                  url
                                  mime_type
                                  width
                                  height
                                  size
                                  caption
                                  description
                                  alternative_title
                                }
                                version_laterals {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  extra_price
                                  installer_extra_price
                                  installation_difficulty
                                  info_title
                                  info_description
                                  preview_text
                                  cars_backwards {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    preview_text
                                    car_backward_types {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      has_rear_camera
                                      pictogram {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      preview_image {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      car_backward {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        extra_price
                                        installer_extra_price
                                        installation_difficulty
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_lateral {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        car_backward_types {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          has_rear_camera
                                        }
                                      }
                                    }
                                  }
                                  car_version {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    preview_text
                                    model_year {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      brand_model {
                                        uuid
                                        createdAt
                                        updatedAt
                                        model_name
                                        info_title
                                        info_description
                                      }
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCarBrandDetailQuery__
 *
 * To run a query within a React component, call `useCarBrandDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarBrandDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarBrandDetailQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCarBrandDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    CarBrandDetailQuery,
    CarBrandDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CarBrandDetailQuery, CarBrandDetailQueryVariables>(
    CarBrandDetailDocument,
    options,
  );
}
export function useCarBrandDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CarBrandDetailQuery,
    CarBrandDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CarBrandDetailQuery, CarBrandDetailQueryVariables>(
    CarBrandDetailDocument,
    options,
  );
}
export type CarBrandDetailQueryHookResult = ReturnType<
  typeof useCarBrandDetailQuery
>;
export type CarBrandDetailLazyQueryHookResult = ReturnType<
  typeof useCarBrandDetailLazyQuery
>;
export type CarBrandDetailQueryResult = Apollo.QueryResult<
  CarBrandDetailQuery,
  CarBrandDetailQueryVariables
>;
export const CarBrandListDocument = gql`
  query carBrandList {
    carBrandList {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        brand_name
        info_title
        info_description
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        pictogram_dark {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        brand_models {
          uuid
          createdAt
          updatedAt
          model_name
          info_title
          info_description
          car_brand {
            uuid
            createdAt
            updatedAt
            brand_name
            info_title
            info_description
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            pictogram_dark {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            brand_models {
              uuid
              createdAt
              updatedAt
              model_name
              info_title
              info_description
              model_years {
                uuid
                createdAt
                updatedAt
                start_year
                end_year
                info_title
                info_description
                brand_model {
                  uuid
                  createdAt
                  updatedAt
                  model_name
                  info_title
                  info_description
                  car_brand {
                    uuid
                    createdAt
                    updatedAt
                    brand_name
                    info_title
                    info_description
                  }
                  model_years {
                    uuid
                    createdAt
                    updatedAt
                    start_year
                    end_year
                    info_title
                    info_description
                    car_versions {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      version_laterals {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                          thumbnail {
                            width
                            height
                            size
                            url
                            file_name
                          }
                          medium {
                            width
                            height
                            size
                            url
                            file_name
                          }
                        }
                        side_glazings {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            side_glazings {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              info_title
                              info_description
                              preview_text
                            }
                            cars_backwards {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              preview_text
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              preview_image {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              version_lateral {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                extra_price
                                installer_extra_price
                                installation_difficulty
                                info_title
                                info_description
                                preview_text
                                car_version {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  info_title
                                  info_description
                                  preview_text
                                  pictogram {
                                    uuid
                                    createdAt
                                    updatedAt
                                    file_name
                                    url
                                    mime_type
                                    width
                                    height
                                    size
                                    caption
                                    description
                                    alternative_title
                                  }
                                  version_laterals {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    info_title
                                    info_description
                                    preview_text
                                    cars_backwards {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      extra_price
                                      installer_extra_price
                                      installation_difficulty
                                      preview_text
                                      car_backward_types {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        has_rear_camera
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        car_backward {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          preview_text
                                          pictogram {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          preview_image {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          version_lateral {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            extra_price
                                            installer_extra_price
                                            installation_difficulty
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                          car_backward_types {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            has_rear_camera
                                          }
                                        }
                                      }
                                    }
                                    car_version {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        brand_model {
                                          uuid
                                          createdAt
                                          updatedAt
                                          model_name
                                          info_title
                                          info_description
                                        }
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                          model_year {
                                            uuid
                                            createdAt
                                            updatedAt
                                            start_year
                                            end_year
                                            info_title
                                            info_description
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useCarBrandListQuery__
 *
 * To run a query within a React component, call `useCarBrandListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarBrandListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarBrandListQuery({
 *   variables: {
 *   },
 * });
 */
export function useCarBrandListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CarBrandListQuery,
    CarBrandListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CarBrandListQuery, CarBrandListQueryVariables>(
    CarBrandListDocument,
    options,
  );
}
export function useCarBrandListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CarBrandListQuery,
    CarBrandListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CarBrandListQuery, CarBrandListQueryVariables>(
    CarBrandListDocument,
    options,
  );
}
export type CarBrandListQueryHookResult = ReturnType<
  typeof useCarBrandListQuery
>;
export type CarBrandListLazyQueryHookResult = ReturnType<
  typeof useCarBrandListLazyQuery
>;
export type CarBrandListQueryResult = Apollo.QueryResult<
  CarBrandListQuery,
  CarBrandListQueryVariables
>;
export const CarVersionDetailDocument = gql`
  query carVersionDetail($uuid: String!) {
    carVersionDetail(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      title
      sub_title
      info_title
      info_description
      preview_text
      pictogram {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      version_laterals {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        extra_price
        installer_extra_price
        installation_difficulty
        info_title
        info_description
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        side_glazings {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          version_lateral {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            installation_difficulty
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            side_glazings {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              info_title
              info_description
              preview_text
            }
            cars_backwards {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              info_title
              info_description
              extra_price
              installer_extra_price
              installation_difficulty
              preview_text
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              preview_image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              version_lateral {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                extra_price
                installer_extra_price
                installation_difficulty
                info_title
                info_description
                preview_text
                car_version {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  info_title
                  info_description
                  preview_text
                  pictogram {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  version_laterals {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    extra_price
                    installer_extra_price
                    installation_difficulty
                    info_title
                    info_description
                    preview_text
                    cars_backwards {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      preview_text
                      car_backward_types {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        has_rear_camera
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        car_backward {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                          }
                          car_backward_types {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            has_rear_camera
                          }
                        }
                      }
                    }
                    car_version {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      model_year {
                        uuid
                        createdAt
                        updatedAt
                        start_year
                        end_year
                        info_title
                        info_description
                        brand_model {
                          uuid
                          createdAt
                          updatedAt
                          model_name
                          info_title
                          info_description
                          car_brand {
                            uuid
                            createdAt
                            updatedAt
                            brand_name
                            info_title
                            info_description
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            pictogram_dark {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                              thumbnail {
                                width
                                height
                                size
                                url
                                file_name
                              }
                              medium {
                                width
                                height
                                size
                                url
                                file_name
                              }
                            }
                            brand_models {
                              uuid
                              createdAt
                              updatedAt
                              model_name
                              info_title
                              info_description
                              car_brand {
                                uuid
                                createdAt
                                updatedAt
                                brand_name
                                info_title
                                info_description
                              }
                              model_years {
                                uuid
                                createdAt
                                updatedAt
                                start_year
                                end_year
                                info_title
                                info_description
                                brand_model {
                                  uuid
                                  createdAt
                                  updatedAt
                                  model_name
                                  info_title
                                  info_description
                                  model_years {
                                    uuid
                                    createdAt
                                    updatedAt
                                    start_year
                                    end_year
                                    info_title
                                    info_description
                                    car_versions {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      pictogram {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      version_laterals {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        extra_price
                                        installer_extra_price
                                        installation_difficulty
                                        info_title
                                        info_description
                                        preview_text
                                      }
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      model_year {
        uuid
        createdAt
        updatedAt
        start_year
        end_year
        info_title
        info_description
      }
    }
  }
`;

/**
 * __useCarVersionDetailQuery__
 *
 * To run a query within a React component, call `useCarVersionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarVersionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarVersionDetailQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useCarVersionDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    CarVersionDetailQuery,
    CarVersionDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CarVersionDetailQuery, CarVersionDetailQueryVariables>(
    CarVersionDetailDocument,
    options,
  );
}
export function useCarVersionDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CarVersionDetailQuery,
    CarVersionDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CarVersionDetailQuery,
    CarVersionDetailQueryVariables
  >(CarVersionDetailDocument, options);
}
export type CarVersionDetailQueryHookResult = ReturnType<
  typeof useCarVersionDetailQuery
>;
export type CarVersionDetailLazyQueryHookResult = ReturnType<
  typeof useCarVersionDetailLazyQuery
>;
export type CarVersionDetailQueryResult = Apollo.QueryResult<
  CarVersionDetailQuery,
  CarVersionDetailQueryVariables
>;
export const CarVersionListDocument = gql`
  query carVersionList($model_year_uuid: String) {
    carVersionList(model_year_uuid: $model_year_uuid) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        version_laterals {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          installation_difficulty
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          side_glazings {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              preview_image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              side_glazings {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                extra_price
                installer_extra_price
                info_title
                info_description
                preview_text
              }
              cars_backwards {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                info_title
                info_description
                extra_price
                installer_extra_price
                installation_difficulty
                preview_text
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                preview_image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                version_lateral {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  info_title
                  info_description
                  preview_text
                  car_version {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      cars_backwards {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        preview_text
                        car_backward_types {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          has_rear_camera
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          car_backward {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                            }
                            car_backward_types {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              has_rear_camera
                            }
                          }
                        }
                      }
                      car_version {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        preview_text
                        model_year {
                          uuid
                          createdAt
                          updatedAt
                          start_year
                          end_year
                          info_title
                          info_description
                          brand_model {
                            uuid
                            createdAt
                            updatedAt
                            model_name
                            info_title
                            info_description
                            car_brand {
                              uuid
                              createdAt
                              updatedAt
                              brand_name
                              info_title
                              info_description
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              pictogram_dark {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                                thumbnail {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                                medium {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                              }
                              brand_models {
                                uuid
                                createdAt
                                updatedAt
                                model_name
                                info_title
                                info_description
                                car_brand {
                                  uuid
                                  createdAt
                                  updatedAt
                                  brand_name
                                  info_title
                                  info_description
                                }
                                model_years {
                                  uuid
                                  createdAt
                                  updatedAt
                                  start_year
                                  end_year
                                  info_title
                                  info_description
                                  brand_model {
                                    uuid
                                    createdAt
                                    updatedAt
                                    model_name
                                    info_title
                                    info_description
                                    model_years {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_laterals {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                          car_versions {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        model_year {
          uuid
          createdAt
          updatedAt
          start_year
          end_year
          info_title
          info_description
        }
      }
    }
  }
`;

/**
 * __useCarVersionListQuery__
 *
 * To run a query within a React component, call `useCarVersionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarVersionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarVersionListQuery({
 *   variables: {
 *      model_year_uuid: // value for 'model_year_uuid'
 *   },
 * });
 */
export function useCarVersionListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CarVersionListQuery,
    CarVersionListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CarVersionListQuery, CarVersionListQueryVariables>(
    CarVersionListDocument,
    options,
  );
}
export function useCarVersionListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CarVersionListQuery,
    CarVersionListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CarVersionListQuery, CarVersionListQueryVariables>(
    CarVersionListDocument,
    options,
  );
}
export type CarVersionListQueryHookResult = ReturnType<
  typeof useCarVersionListQuery
>;
export type CarVersionListLazyQueryHookResult = ReturnType<
  typeof useCarVersionListLazyQuery
>;
export type CarVersionListQueryResult = Apollo.QueryResult<
  CarVersionListQuery,
  CarVersionListQueryVariables
>;
export const GetProductsMonthStatsDocument = gql`
  query getProductsMonthStats {
    getProductsMonthStats {
      stats {
        quantity {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          type
          description
          slug
          meta_title
          meta_description
          verification
          graphic_service
          professional_installer
          enabled
          index
          start_price
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          sections {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            extra_price_sections {
              section_uuid
              extra_price
            }
            installer_extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          templates {
            uuid
            createdAt
            updatedAt
            title
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          product_images {
            uuid
            createdAt
            updatedAt
            support_uuid
            size_uuid
            section_uuid
            extra_sticker_uuid
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
        totalTTC
        totalHT
        quantityPercent
        totalPercent
      }
    }
  }
`;

/**
 * __useGetProductsMonthStatsQuery__
 *
 * To run a query within a React component, call `useGetProductsMonthStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsMonthStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsMonthStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsMonthStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductsMonthStatsQuery,
    GetProductsMonthStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductsMonthStatsQuery,
    GetProductsMonthStatsQueryVariables
  >(GetProductsMonthStatsDocument, options);
}
export function useGetProductsMonthStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsMonthStatsQuery,
    GetProductsMonthStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsMonthStatsQuery,
    GetProductsMonthStatsQueryVariables
  >(GetProductsMonthStatsDocument, options);
}
export type GetProductsMonthStatsQueryHookResult = ReturnType<
  typeof useGetProductsMonthStatsQuery
>;
export type GetProductsMonthStatsLazyQueryHookResult = ReturnType<
  typeof useGetProductsMonthStatsLazyQuery
>;
export type GetProductsMonthStatsQueryResult = Apollo.QueryResult<
  GetProductsMonthStatsQuery,
  GetProductsMonthStatsQueryVariables
>;
export const GetProductsYearsStatsDocument = gql`
  query getProductsYearsStats($year: Float!) {
    getProductsYearsStats(year: $year) {
      stats {
        quantityTab
        quantityPercent
      }
    }
  }
`;

/**
 * __useGetProductsYearsStatsQuery__
 *
 * To run a query within a React component, call `useGetProductsYearsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsYearsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsYearsStatsQuery({
 *   variables: {
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetProductsYearsStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductsYearsStatsQuery,
    GetProductsYearsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProductsYearsStatsQuery,
    GetProductsYearsStatsQueryVariables
  >(GetProductsYearsStatsDocument, options);
}
export function useGetProductsYearsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductsYearsStatsQuery,
    GetProductsYearsStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProductsYearsStatsQuery,
    GetProductsYearsStatsQueryVariables
  >(GetProductsYearsStatsDocument, options);
}
export type GetProductsYearsStatsQueryHookResult = ReturnType<
  typeof useGetProductsYearsStatsQuery
>;
export type GetProductsYearsStatsLazyQueryHookResult = ReturnType<
  typeof useGetProductsYearsStatsLazyQuery
>;
export type GetProductsYearsStatsQueryResult = Apollo.QueryResult<
  GetProductsYearsStatsQuery,
  GetProductsYearsStatsQueryVariables
>;
export const ListAllVersionLateralDocument = gql`
  query listAllVersionLateral($car_version_uuid: String) {
    listAllVersionLateral(car_version_uuid: $car_version_uuid) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        extra_price
        installer_extra_price
        installation_difficulty
        info_title
        info_description
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        side_glazings {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          version_lateral {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            installation_difficulty
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            side_glazings {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              info_title
              info_description
              preview_text
            }
            cars_backwards {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              info_title
              info_description
              extra_price
              installer_extra_price
              installation_difficulty
              preview_text
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              preview_image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              version_lateral {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                extra_price
                installer_extra_price
                installation_difficulty
                info_title
                info_description
                preview_text
                car_version {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  info_title
                  info_description
                  preview_text
                  pictogram {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  version_laterals {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    extra_price
                    installer_extra_price
                    installation_difficulty
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    preview_image {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    side_glazings {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      info_title
                      info_description
                      preview_text
                    }
                    cars_backwards {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      preview_text
                      car_backward_types {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        has_rear_camera
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        car_backward {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                          }
                          car_backward_types {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            has_rear_camera
                          }
                        }
                      }
                    }
                    car_version {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      model_year {
                        uuid
                        createdAt
                        updatedAt
                        start_year
                        end_year
                        info_title
                        info_description
                        brand_model {
                          uuid
                          createdAt
                          updatedAt
                          model_name
                          info_title
                          info_description
                          car_brand {
                            uuid
                            createdAt
                            updatedAt
                            brand_name
                            info_title
                            info_description
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            pictogram_dark {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                              thumbnail {
                                width
                                height
                                size
                                url
                                file_name
                              }
                              medium {
                                width
                                height
                                size
                                url
                                file_name
                              }
                            }
                            brand_models {
                              uuid
                              createdAt
                              updatedAt
                              model_name
                              info_title
                              info_description
                              car_brand {
                                uuid
                                createdAt
                                updatedAt
                                brand_name
                                info_title
                                info_description
                              }
                              model_years {
                                uuid
                                createdAt
                                updatedAt
                                start_year
                                end_year
                                info_title
                                info_description
                                brand_model {
                                  uuid
                                  createdAt
                                  updatedAt
                                  model_name
                                  info_title
                                  info_description
                                  model_years {
                                    uuid
                                    createdAt
                                    updatedAt
                                    start_year
                                    end_year
                                    info_title
                                    info_description
                                    car_versions {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      pictogram {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      version_laterals {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        extra_price
                                        installer_extra_price
                                        installation_difficulty
                                        info_title
                                        info_description
                                        preview_text
                                      }
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        cars_backwards {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          extra_price
          installer_extra_price
          installation_difficulty
          preview_text
        }
        car_version {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          preview_text
        }
      }
    }
  }
`;

/**
 * __useListAllVersionLateralQuery__
 *
 * To run a query within a React component, call `useListAllVersionLateralQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllVersionLateralQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllVersionLateralQuery({
 *   variables: {
 *      car_version_uuid: // value for 'car_version_uuid'
 *   },
 * });
 */
export function useListAllVersionLateralQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAllVersionLateralQuery,
    ListAllVersionLateralQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListAllVersionLateralQuery,
    ListAllVersionLateralQueryVariables
  >(ListAllVersionLateralDocument, options);
}
export function useListAllVersionLateralLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAllVersionLateralQuery,
    ListAllVersionLateralQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListAllVersionLateralQuery,
    ListAllVersionLateralQueryVariables
  >(ListAllVersionLateralDocument, options);
}
export type ListAllVersionLateralQueryHookResult = ReturnType<
  typeof useListAllVersionLateralQuery
>;
export type ListAllVersionLateralLazyQueryHookResult = ReturnType<
  typeof useListAllVersionLateralLazyQuery
>;
export type ListAllVersionLateralQueryResult = Apollo.QueryResult<
  ListAllVersionLateralQuery,
  ListAllVersionLateralQueryVariables
>;
export const ListSideGlazingsDocument = gql`
  query listSideGlazings($version_lateral_uuid: String) {
    listSideGlazings(version_lateral_uuid: $version_lateral_uuid) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        extra_price
        installer_extra_price
        info_title
        info_description
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        version_lateral {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          installation_difficulty
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          side_glazings {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
              cars_backwards {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                info_title
                info_description
                extra_price
                installer_extra_price
                installation_difficulty
                preview_text
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                preview_image {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                version_lateral {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  info_title
                  info_description
                  preview_text
                  car_version {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                      }
                      cars_backwards {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        preview_text
                        car_backward_types {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          has_rear_camera
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          car_backward {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                            }
                            car_backward_types {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              has_rear_camera
                            }
                          }
                        }
                      }
                      car_version {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        preview_text
                        model_year {
                          uuid
                          createdAt
                          updatedAt
                          start_year
                          end_year
                          info_title
                          info_description
                          brand_model {
                            uuid
                            createdAt
                            updatedAt
                            model_name
                            info_title
                            info_description
                            car_brand {
                              uuid
                              createdAt
                              updatedAt
                              brand_name
                              info_title
                              info_description
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              pictogram_dark {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                                thumbnail {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                                medium {
                                  width
                                  height
                                  size
                                  url
                                  file_name
                                }
                              }
                              brand_models {
                                uuid
                                createdAt
                                updatedAt
                                model_name
                                info_title
                                info_description
                                car_brand {
                                  uuid
                                  createdAt
                                  updatedAt
                                  brand_name
                                  info_title
                                  info_description
                                }
                                model_years {
                                  uuid
                                  createdAt
                                  updatedAt
                                  start_year
                                  end_year
                                  info_title
                                  info_description
                                  brand_model {
                                    uuid
                                    createdAt
                                    updatedAt
                                    model_name
                                    info_title
                                    info_description
                                    model_years {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_laterals {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                          car_versions {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useListSideGlazingsQuery__
 *
 * To run a query within a React component, call `useListSideGlazingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSideGlazingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSideGlazingsQuery({
 *   variables: {
 *      version_lateral_uuid: // value for 'version_lateral_uuid'
 *   },
 * });
 */
export function useListSideGlazingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListSideGlazingsQuery,
    ListSideGlazingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListSideGlazingsQuery, ListSideGlazingsQueryVariables>(
    ListSideGlazingsDocument,
    options,
  );
}
export function useListSideGlazingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListSideGlazingsQuery,
    ListSideGlazingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListSideGlazingsQuery,
    ListSideGlazingsQueryVariables
  >(ListSideGlazingsDocument, options);
}
export type ListSideGlazingsQueryHookResult = ReturnType<
  typeof useListSideGlazingsQuery
>;
export type ListSideGlazingsLazyQueryHookResult = ReturnType<
  typeof useListSideGlazingsLazyQuery
>;
export type ListSideGlazingsQueryResult = Apollo.QueryResult<
  ListSideGlazingsQuery,
  ListSideGlazingsQueryVariables
>;
export const ModelYearDetailDocument = gql`
  query modelYearDetail($uuid: String!) {
    modelYearDetail(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      start_year
      end_year
      info_title
      info_description
      brand_model {
        uuid
        createdAt
        updatedAt
        model_name
        info_title
        info_description
        car_brand {
          uuid
          createdAt
          updatedAt
          brand_name
          info_title
          info_description
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          pictogram_dark {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          brand_models {
            uuid
            createdAt
            updatedAt
            model_name
            info_title
            info_description
            car_brand {
              uuid
              createdAt
              updatedAt
              brand_name
              info_title
              info_description
            }
            model_years {
              uuid
              createdAt
              updatedAt
              start_year
              end_year
              info_title
              info_description
              brand_model {
                uuid
                createdAt
                updatedAt
                model_name
                info_title
                info_description
                model_years {
                  uuid
                  createdAt
                  updatedAt
                  start_year
                  end_year
                  info_title
                  info_description
                  car_versions {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    version_laterals {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                        thumbnail {
                          width
                          height
                          size
                          url
                          file_name
                        }
                        medium {
                          width
                          height
                          size
                          url
                          file_name
                        }
                      }
                      side_glazings {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        version_lateral {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          side_glazings {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            info_title
                            info_description
                            preview_text
                          }
                          cars_backwards {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            version_lateral {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              info_title
                              info_description
                              preview_text
                              car_version {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                info_title
                                info_description
                                preview_text
                                pictogram {
                                  uuid
                                  createdAt
                                  updatedAt
                                  file_name
                                  url
                                  mime_type
                                  width
                                  height
                                  size
                                  caption
                                  description
                                  alternative_title
                                }
                                version_laterals {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  extra_price
                                  installer_extra_price
                                  installation_difficulty
                                  info_title
                                  info_description
                                  preview_text
                                  cars_backwards {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    preview_text
                                    car_backward_types {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      has_rear_camera
                                      pictogram {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      preview_image {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      car_backward {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        extra_price
                                        installer_extra_price
                                        installation_difficulty
                                        preview_text
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        version_lateral {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                        car_backward_types {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          has_rear_camera
                                        }
                                      }
                                    }
                                  }
                                  car_version {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    preview_text
                                    model_year {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      brand_model {
                                        uuid
                                        createdAt
                                        updatedAt
                                        model_name
                                        info_title
                                        info_description
                                      }
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                        model_year {
                                          uuid
                                          createdAt
                                          updatedAt
                                          start_year
                                          end_year
                                          info_title
                                          info_description
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      car_versions {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        preview_text
      }
    }
  }
`;

/**
 * __useModelYearDetailQuery__
 *
 * To run a query within a React component, call `useModelYearDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelYearDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelYearDetailQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useModelYearDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    ModelYearDetailQuery,
    ModelYearDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ModelYearDetailQuery, ModelYearDetailQueryVariables>(
    ModelYearDetailDocument,
    options,
  );
}
export function useModelYearDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModelYearDetailQuery,
    ModelYearDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ModelYearDetailQuery,
    ModelYearDetailQueryVariables
  >(ModelYearDetailDocument, options);
}
export type ModelYearDetailQueryHookResult = ReturnType<
  typeof useModelYearDetailQuery
>;
export type ModelYearDetailLazyQueryHookResult = ReturnType<
  typeof useModelYearDetailLazyQuery
>;
export type ModelYearDetailQueryResult = Apollo.QueryResult<
  ModelYearDetailQuery,
  ModelYearDetailQueryVariables
>;
export const ModelYearsListDocument = gql`
  query modelYearsList($brand_model_uuid: String) {
    modelYearsList(brand_model_uuid: $brand_model_uuid) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        start_year
        end_year
        info_title
        info_description
        brand_model {
          uuid
          createdAt
          updatedAt
          model_name
          info_title
          info_description
          car_brand {
            uuid
            createdAt
            updatedAt
            brand_name
            info_title
            info_description
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            pictogram_dark {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            brand_models {
              uuid
              createdAt
              updatedAt
              model_name
              info_title
              info_description
              car_brand {
                uuid
                createdAt
                updatedAt
                brand_name
                info_title
                info_description
              }
              model_years {
                uuid
                createdAt
                updatedAt
                start_year
                end_year
                info_title
                info_description
                brand_model {
                  uuid
                  createdAt
                  updatedAt
                  model_name
                  info_title
                  info_description
                  model_years {
                    uuid
                    createdAt
                    updatedAt
                    start_year
                    end_year
                    info_title
                    info_description
                    car_versions {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      version_laterals {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        info_title
                        info_description
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                          thumbnail {
                            width
                            height
                            size
                            url
                            file_name
                          }
                          medium {
                            width
                            height
                            size
                            url
                            file_name
                          }
                        }
                        side_glazings {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          info_title
                          info_description
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            preview_image {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            side_glazings {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              extra_price
                              installer_extra_price
                              info_title
                              info_description
                              preview_text
                            }
                            cars_backwards {
                              uuid
                              createdAt
                              updatedAt
                              title
                              sub_title
                              info_title
                              info_description
                              extra_price
                              installer_extra_price
                              installation_difficulty
                              preview_text
                              pictogram {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              preview_image {
                                uuid
                                createdAt
                                updatedAt
                                file_name
                                url
                                mime_type
                                width
                                height
                                size
                                caption
                                description
                                alternative_title
                              }
                              version_lateral {
                                uuid
                                createdAt
                                updatedAt
                                title
                                sub_title
                                extra_price
                                installer_extra_price
                                installation_difficulty
                                info_title
                                info_description
                                preview_text
                                car_version {
                                  uuid
                                  createdAt
                                  updatedAt
                                  title
                                  sub_title
                                  info_title
                                  info_description
                                  preview_text
                                  pictogram {
                                    uuid
                                    createdAt
                                    updatedAt
                                    file_name
                                    url
                                    mime_type
                                    width
                                    height
                                    size
                                    caption
                                    description
                                    alternative_title
                                  }
                                  version_laterals {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    extra_price
                                    installer_extra_price
                                    installation_difficulty
                                    info_title
                                    info_description
                                    preview_text
                                    cars_backwards {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      extra_price
                                      installer_extra_price
                                      installation_difficulty
                                      preview_text
                                      car_backward_types {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        has_rear_camera
                                        pictogram {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        preview_image {
                                          uuid
                                          createdAt
                                          updatedAt
                                          file_name
                                          url
                                          mime_type
                                          width
                                          height
                                          size
                                          caption
                                          description
                                          alternative_title
                                        }
                                        car_backward {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          extra_price
                                          installer_extra_price
                                          installation_difficulty
                                          preview_text
                                          pictogram {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          preview_image {
                                            uuid
                                            createdAt
                                            updatedAt
                                            file_name
                                            url
                                            mime_type
                                            width
                                            height
                                            size
                                            caption
                                            description
                                            alternative_title
                                          }
                                          version_lateral {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            extra_price
                                            installer_extra_price
                                            installation_difficulty
                                            info_title
                                            info_description
                                            preview_text
                                          }
                                          car_backward_types {
                                            uuid
                                            createdAt
                                            updatedAt
                                            title
                                            sub_title
                                            info_title
                                            info_description
                                            has_rear_camera
                                          }
                                        }
                                      }
                                    }
                                    car_version {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        brand_model {
                                          uuid
                                          createdAt
                                          updatedAt
                                          model_name
                                          info_title
                                          info_description
                                        }
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                          model_year {
                                            uuid
                                            createdAt
                                            updatedAt
                                            start_year
                                            end_year
                                            info_title
                                            info_description
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        car_versions {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          info_title
          info_description
          preview_text
        }
      }
    }
  }
`;

/**
 * __useModelYearsListQuery__
 *
 * To run a query within a React component, call `useModelYearsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelYearsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelYearsListQuery({
 *   variables: {
 *      brand_model_uuid: // value for 'brand_model_uuid'
 *   },
 * });
 */
export function useModelYearsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ModelYearsListQuery,
    ModelYearsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ModelYearsListQuery, ModelYearsListQueryVariables>(
    ModelYearsListDocument,
    options,
  );
}
export function useModelYearsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModelYearsListQuery,
    ModelYearsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ModelYearsListQuery, ModelYearsListQueryVariables>(
    ModelYearsListDocument,
    options,
  );
}
export type ModelYearsListQueryHookResult = ReturnType<
  typeof useModelYearsListQuery
>;
export type ModelYearsListLazyQueryHookResult = ReturnType<
  typeof useModelYearsListLazyQuery
>;
export type ModelYearsListQueryResult = Apollo.QueryResult<
  ModelYearsListQuery,
  ModelYearsListQueryVariables
>;
export const ProductGetDocument = gql`
  query productGet($uuid: String!) {
    productGet(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      title
      subtitle
      type
      description
      slug
      meta_title
      meta_description
      verification
      graphic_service
      professional_installer
      enabled
      index
      start_price
      is_perfect_squid
      canvas_width
      canvas_height
      canvas_radius
      video_url
      image {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      supports {
        uuid
        createdAt
        updatedAt
        title
        can_have_background
        extra_price
        overview
        video_url
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
      sizes {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        need_vehicle_info
        price
        index
        installer_price
        canvas_width
        canvas_height
        canvas_radius
        overview
        video_url
        design_choice_possible
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        depends_on_supports {
          uuid
          createdAt
          updatedAt
          title
          can_have_background
          extra_price
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
        }
      }
      sections {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        can_have_background
        extra_price
        canvas_width
        canvas_height
        canvas_radius
        extra_price_size {
          size_uuid
          extra_price
        }
        overview
        video_url
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        depends_on_sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
          }
        }
      }
      extra_stickers {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        design_choice_possible
        extra_price
        extra_price_sections {
          section_uuid
          extra_price
        }
        installer_extra_price
        overview
        video_url
        installation_difficulty
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        depends_on_sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          installation_difficulty
        }
      }
      templates {
        uuid
        createdAt
        updatedAt
        title
        file {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
      product_images {
        uuid
        createdAt
        updatedAt
        support_uuid
        size_uuid
        section_uuid
        extra_sticker_uuid
        file {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
      }
    }
  }
`;

/**
 * __useProductGetQuery__
 *
 * To run a query within a React component, call `useProductGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductGetQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useProductGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductGetQuery,
    ProductGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductGetQuery, ProductGetQueryVariables>(
    ProductGetDocument,
    options,
  );
}
export function useProductGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductGetQuery,
    ProductGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductGetQuery, ProductGetQueryVariables>(
    ProductGetDocument,
    options,
  );
}
export type ProductGetQueryHookResult = ReturnType<typeof useProductGetQuery>;
export type ProductGetLazyQueryHookResult = ReturnType<
  typeof useProductGetLazyQuery
>;
export type ProductGetQueryResult = Apollo.QueryResult<
  ProductGetQuery,
  ProductGetQueryVariables
>;
export const ProductGetBySlugDocument = gql`
  query productGetBySlug($slug: String!) {
    productGetBySlug(slug: $slug) {
      uuid
      createdAt
      updatedAt
      title
      subtitle
      type
      description
      slug
      meta_title
      meta_description
      verification
      graphic_service
      professional_installer
      enabled
      index
      start_price
      image {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      supports {
        uuid
        createdAt
        updatedAt
        title
        can_have_background
        extra_price
        installation_difficulty
        overview
        video_url
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
      sizes {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        need_vehicle_info
        price
        index
        installation_difficulty
        installer_price
        canvas_width
        canvas_height
        canvas_radius
        overview
        video_url
        design_choice_possible
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        depends_on_supports {
          uuid
          createdAt
          updatedAt
          title
          can_have_background
          extra_price
          overview
          video_url
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
        }
      }
      sections {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        can_have_background
        extra_price
        canvas_width
        canvas_height
        canvas_radius
        extra_price_size {
          size_uuid
          extra_price
        }
        installation_difficulty
        overview
        video_url
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        depends_on_sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
          }
        }
      }
      extra_stickers {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        help_title
        help
        design_choice_possible
        extra_price
        extra_price_sections {
          section_uuid
          extra_price
        }
        installer_extra_price
        installation_difficulty
        overview
        video_url
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        depends_on_sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
        }
      }
      templates {
        uuid
        createdAt
        updatedAt
        title
        file {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
      product_images {
        uuid
        createdAt
        updatedAt
        support_uuid
        size_uuid
        section_uuid
        extra_sticker_uuid
        file {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
      }
    }
  }
`;

/**
 * __useProductGetBySlugQuery__
 *
 * To run a query within a React component, call `useProductGetBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductGetBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductGetBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useProductGetBySlugQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductGetBySlugQuery,
    ProductGetBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductGetBySlugQuery, ProductGetBySlugQueryVariables>(
    ProductGetBySlugDocument,
    options,
  );
}
export function useProductGetBySlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductGetBySlugQuery,
    ProductGetBySlugQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductGetBySlugQuery,
    ProductGetBySlugQueryVariables
  >(ProductGetBySlugDocument, options);
}
export type ProductGetBySlugQueryHookResult = ReturnType<
  typeof useProductGetBySlugQuery
>;
export type ProductGetBySlugLazyQueryHookResult = ReturnType<
  typeof useProductGetBySlugLazyQuery
>;
export type ProductGetBySlugQueryResult = Apollo.QueryResult<
  ProductGetBySlugQuery,
  ProductGetBySlugQueryVariables
>;
export const ProductsListDocument = gql`
  query productsList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: ProductsPaginationSortBy
  ) {
    productsList(skip: $skip, take: $take, keyword: $keyword, sortBy: $sortBy) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        type
        description
        slug
        meta_title
        meta_description
        verification
        graphic_service
        professional_installer
        enabled
        index
        start_price
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        supports {
          uuid
          createdAt
          updatedAt
          title
          can_have_background
          extra_price
          installation_difficulty
          overview
          video_url
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
        sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installation_difficulty
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
        sections {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          can_have_background
          extra_price
          canvas_width
          canvas_height
          canvas_radius
          extra_price_size {
            size_uuid
            extra_price
          }
          installation_difficulty
          overview
          video_url
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
            }
          }
        }
        extra_stickers {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          design_choice_possible
          installation_difficulty
          extra_price
          extra_price_sections {
            section_uuid
            extra_price
          }
          installer_extra_price
          overview
          video_url
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
          }
        }
        templates {
          uuid
          createdAt
          updatedAt
          title
          file {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
        product_images {
          uuid
          createdAt
          updatedAt
          support_uuid
          size_uuid
          section_uuid
          extra_sticker_uuid
          file {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
        }
      }
    }
  }
`;

/**
 * __useProductsListQuery__
 *
 * To run a query within a React component, call `useProductsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useProductsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductsListQuery,
    ProductsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductsListQuery, ProductsListQueryVariables>(
    ProductsListDocument,
    options,
  );
}
export function useProductsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsListQuery,
    ProductsListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductsListQuery, ProductsListQueryVariables>(
    ProductsListDocument,
    options,
  );
}
export type ProductsListQueryHookResult = ReturnType<
  typeof useProductsListQuery
>;
export type ProductsListLazyQueryHookResult = ReturnType<
  typeof useProductsListLazyQuery
>;
export type ProductsListQueryResult = Apollo.QueryResult<
  ProductsListQuery,
  ProductsListQueryVariables
>;
export const ProductsListAdminDocument = gql`
  query productsListAdmin(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: ProductsPaginationSortBy
  ) {
    productsListAdmin(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        type
        description
        slug
        meta_title
        meta_description
        verification
        graphic_service
        professional_installer
        enabled
        index
        start_price
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        supports {
          uuid
          createdAt
          updatedAt
          title
          can_have_background
          installation_difficulty
          extra_price
          overview
          video_url
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
        sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          installation_difficulty
          overview
          video_url
          design_choice_possible
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
        sections {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          can_have_background
          extra_price
          canvas_width
          canvas_height
          canvas_radius
          extra_price_size {
            size_uuid
            extra_price
          }
          installation_difficulty
          overview
          video_url
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
            }
          }
        }
        extra_stickers {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          design_choice_possible
          extra_price
          extra_price_sections {
            section_uuid
            extra_price
          }
          installer_extra_price
          installation_difficulty
          overview
          video_url
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
          }
        }
        templates {
          uuid
          createdAt
          updatedAt
          title
          file {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
        product_images {
          uuid
          createdAt
          updatedAt
          support_uuid
          size_uuid
          section_uuid
          extra_sticker_uuid
          file {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
        }
      }
    }
  }
`;

/**
 * __useProductsListAdminQuery__
 *
 * To run a query within a React component, call `useProductsListAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsListAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsListAdminQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useProductsListAdminQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductsListAdminQuery,
    ProductsListAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductsListAdminQuery,
    ProductsListAdminQueryVariables
  >(ProductsListAdminDocument, options);
}
export function useProductsListAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsListAdminQuery,
    ProductsListAdminQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductsListAdminQuery,
    ProductsListAdminQueryVariables
  >(ProductsListAdminDocument, options);
}
export type ProductsListAdminQueryHookResult = ReturnType<
  typeof useProductsListAdminQuery
>;
export type ProductsListAdminLazyQueryHookResult = ReturnType<
  typeof useProductsListAdminLazyQuery
>;
export type ProductsListAdminQueryResult = Apollo.QueryResult<
  ProductsListAdminQuery,
  ProductsListAdminQueryVariables
>;
export const SideGlazingDetailDocument = gql`
  query sideGlazingDetail($uuid: String!) {
    sideGlazingDetail(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      title
      sub_title
      extra_price
      installer_extra_price
      info_title
      info_description
      preview_text
      pictogram {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      preview_image {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      version_lateral {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        extra_price
        installer_extra_price
        installation_difficulty
        info_title
        info_description
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        side_glazings {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          version_lateral {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            installation_difficulty
            info_title
            info_description
            preview_text
            cars_backwards {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              info_title
              info_description
              extra_price
              installer_extra_price
              installation_difficulty
              preview_text
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              preview_image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              version_lateral {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                extra_price
                installer_extra_price
                installation_difficulty
                info_title
                info_description
                preview_text
                car_version {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  info_title
                  info_description
                  preview_text
                  pictogram {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  version_laterals {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    extra_price
                    installer_extra_price
                    installation_difficulty
                    info_title
                    info_description
                    preview_text
                    pictogram {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    preview_image {
                      uuid
                      createdAt
                      updatedAt
                      file_name
                      url
                      mime_type
                      width
                      height
                      size
                      caption
                      description
                      alternative_title
                    }
                    side_glazings {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      extra_price
                      installer_extra_price
                      info_title
                      info_description
                      preview_text
                    }
                    cars_backwards {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      extra_price
                      installer_extra_price
                      installation_difficulty
                      preview_text
                      car_backward_types {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        has_rear_camera
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        car_backward {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          preview_text
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          preview_image {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          version_lateral {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            extra_price
                            installer_extra_price
                            installation_difficulty
                            info_title
                            info_description
                            preview_text
                          }
                          car_backward_types {
                            uuid
                            createdAt
                            updatedAt
                            title
                            sub_title
                            info_title
                            info_description
                            has_rear_camera
                          }
                        }
                      }
                    }
                    car_version {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      preview_text
                      model_year {
                        uuid
                        createdAt
                        updatedAt
                        start_year
                        end_year
                        info_title
                        info_description
                        brand_model {
                          uuid
                          createdAt
                          updatedAt
                          model_name
                          info_title
                          info_description
                          car_brand {
                            uuid
                            createdAt
                            updatedAt
                            brand_name
                            info_title
                            info_description
                            pictogram {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                            }
                            pictogram_dark {
                              uuid
                              createdAt
                              updatedAt
                              file_name
                              url
                              mime_type
                              width
                              height
                              size
                              caption
                              description
                              alternative_title
                              thumbnail {
                                width
                                height
                                size
                                url
                                file_name
                              }
                              medium {
                                width
                                height
                                size
                                url
                                file_name
                              }
                            }
                            brand_models {
                              uuid
                              createdAt
                              updatedAt
                              model_name
                              info_title
                              info_description
                              car_brand {
                                uuid
                                createdAt
                                updatedAt
                                brand_name
                                info_title
                                info_description
                              }
                              model_years {
                                uuid
                                createdAt
                                updatedAt
                                start_year
                                end_year
                                info_title
                                info_description
                                brand_model {
                                  uuid
                                  createdAt
                                  updatedAt
                                  model_name
                                  info_title
                                  info_description
                                  model_years {
                                    uuid
                                    createdAt
                                    updatedAt
                                    start_year
                                    end_year
                                    info_title
                                    info_description
                                    car_versions {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      info_title
                                      info_description
                                      preview_text
                                      pictogram {
                                        uuid
                                        createdAt
                                        updatedAt
                                        file_name
                                        url
                                        mime_type
                                        width
                                        height
                                        size
                                        caption
                                        description
                                        alternative_title
                                      }
                                      version_laterals {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        extra_price
                                        installer_extra_price
                                        installation_difficulty
                                        info_title
                                        info_description
                                        preview_text
                                      }
                                      model_year {
                                        uuid
                                        createdAt
                                        updatedAt
                                        start_year
                                        end_year
                                        info_title
                                        info_description
                                        car_versions {
                                          uuid
                                          createdAt
                                          updatedAt
                                          title
                                          sub_title
                                          info_title
                                          info_description
                                          preview_text
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSideGlazingDetailQuery__
 *
 * To run a query within a React component, call `useSideGlazingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSideGlazingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSideGlazingDetailQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSideGlazingDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    SideGlazingDetailQuery,
    SideGlazingDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SideGlazingDetailQuery,
    SideGlazingDetailQueryVariables
  >(SideGlazingDetailDocument, options);
}
export function useSideGlazingDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SideGlazingDetailQuery,
    SideGlazingDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SideGlazingDetailQuery,
    SideGlazingDetailQueryVariables
  >(SideGlazingDetailDocument, options);
}
export type SideGlazingDetailQueryHookResult = ReturnType<
  typeof useSideGlazingDetailQuery
>;
export type SideGlazingDetailLazyQueryHookResult = ReturnType<
  typeof useSideGlazingDetailLazyQuery
>;
export type SideGlazingDetailQueryResult = Apollo.QueryResult<
  SideGlazingDetailQuery,
  SideGlazingDetailQueryVariables
>;
export const SquidstudioCategoriesListDocument = gql`
  query squidstudioCategoriesList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: SquidstudioCategoryPaginationSortBy
  ) {
    squidstudioCategoriesList(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        type
        icon {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        files {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;

/**
 * __useSquidstudioCategoriesListQuery__
 *
 * To run a query within a React component, call `useSquidstudioCategoriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioCategoriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSquidstudioCategoriesListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useSquidstudioCategoriesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    SquidstudioCategoriesListQuery,
    SquidstudioCategoriesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SquidstudioCategoriesListQuery,
    SquidstudioCategoriesListQueryVariables
  >(SquidstudioCategoriesListDocument, options);
}
export function useSquidstudioCategoriesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SquidstudioCategoriesListQuery,
    SquidstudioCategoriesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SquidstudioCategoriesListQuery,
    SquidstudioCategoriesListQueryVariables
  >(SquidstudioCategoriesListDocument, options);
}
export type SquidstudioCategoriesListQueryHookResult = ReturnType<
  typeof useSquidstudioCategoriesListQuery
>;
export type SquidstudioCategoriesListLazyQueryHookResult = ReturnType<
  typeof useSquidstudioCategoriesListLazyQuery
>;
export type SquidstudioCategoriesListQueryResult = Apollo.QueryResult<
  SquidstudioCategoriesListQuery,
  SquidstudioCategoriesListQueryVariables
>;
export const SquidstudioCategoriesListUnAuthentifiedDocument = gql`
  query squidstudioCategoriesListUnAuthentified(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: SquidstudioCategoryPaginationSortBy
  ) {
    squidstudioCategoriesListUnAuthentified(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        type
        icon {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        files {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;

/**
 * __useSquidstudioCategoriesListUnAuthentifiedQuery__
 *
 * To run a query within a React component, call `useSquidstudioCategoriesListUnAuthentifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioCategoriesListUnAuthentifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSquidstudioCategoriesListUnAuthentifiedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useSquidstudioCategoriesListUnAuthentifiedQuery(
  baseOptions: Apollo.QueryHookOptions<
    SquidstudioCategoriesListUnAuthentifiedQuery,
    SquidstudioCategoriesListUnAuthentifiedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SquidstudioCategoriesListUnAuthentifiedQuery,
    SquidstudioCategoriesListUnAuthentifiedQueryVariables
  >(SquidstudioCategoriesListUnAuthentifiedDocument, options);
}
export function useSquidstudioCategoriesListUnAuthentifiedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SquidstudioCategoriesListUnAuthentifiedQuery,
    SquidstudioCategoriesListUnAuthentifiedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SquidstudioCategoriesListUnAuthentifiedQuery,
    SquidstudioCategoriesListUnAuthentifiedQueryVariables
  >(SquidstudioCategoriesListUnAuthentifiedDocument, options);
}
export type SquidstudioCategoriesListUnAuthentifiedQueryHookResult = ReturnType<
  typeof useSquidstudioCategoriesListUnAuthentifiedQuery
>;
export type SquidstudioCategoriesListUnAuthentifiedLazyQueryHookResult =
  ReturnType<typeof useSquidstudioCategoriesListUnAuthentifiedLazyQuery>;
export type SquidstudioCategoriesListUnAuthentifiedQueryResult =
  Apollo.QueryResult<
    SquidstudioCategoriesListUnAuthentifiedQuery,
    SquidstudioCategoriesListUnAuthentifiedQueryVariables
  >;
export const SquidstudioCategoryGetDocument = gql`
  query squidstudioCategoryGet($uuid: String!) {
    squidstudioCategoryGet(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      title
      type
      icon {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      files {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
    }
  }
`;

/**
 * __useSquidstudioCategoryGetQuery__
 *
 * To run a query within a React component, call `useSquidstudioCategoryGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioCategoryGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSquidstudioCategoryGetQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSquidstudioCategoryGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    SquidstudioCategoryGetQuery,
    SquidstudioCategoryGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SquidstudioCategoryGetQuery,
    SquidstudioCategoryGetQueryVariables
  >(SquidstudioCategoryGetDocument, options);
}
export function useSquidstudioCategoryGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SquidstudioCategoryGetQuery,
    SquidstudioCategoryGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SquidstudioCategoryGetQuery,
    SquidstudioCategoryGetQueryVariables
  >(SquidstudioCategoryGetDocument, options);
}
export type SquidstudioCategoryGetQueryHookResult = ReturnType<
  typeof useSquidstudioCategoryGetQuery
>;
export type SquidstudioCategoryGetLazyQueryHookResult = ReturnType<
  typeof useSquidstudioCategoryGetLazyQuery
>;
export type SquidstudioCategoryGetQueryResult = Apollo.QueryResult<
  SquidstudioCategoryGetQuery,
  SquidstudioCategoryGetQueryVariables
>;
export const SquidstudioCategoryGetUnAuthentifiedDocument = gql`
  query squidstudioCategoryGetUnAuthentified($uuid: String!) {
    squidstudioCategoryGetUnAuthentified(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      title
      type
      icon {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      files {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
    }
  }
`;

/**
 * __useSquidstudioCategoryGetUnAuthentifiedQuery__
 *
 * To run a query within a React component, call `useSquidstudioCategoryGetUnAuthentifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioCategoryGetUnAuthentifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSquidstudioCategoryGetUnAuthentifiedQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSquidstudioCategoryGetUnAuthentifiedQuery(
  baseOptions: Apollo.QueryHookOptions<
    SquidstudioCategoryGetUnAuthentifiedQuery,
    SquidstudioCategoryGetUnAuthentifiedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SquidstudioCategoryGetUnAuthentifiedQuery,
    SquidstudioCategoryGetUnAuthentifiedQueryVariables
  >(SquidstudioCategoryGetUnAuthentifiedDocument, options);
}
export function useSquidstudioCategoryGetUnAuthentifiedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SquidstudioCategoryGetUnAuthentifiedQuery,
    SquidstudioCategoryGetUnAuthentifiedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SquidstudioCategoryGetUnAuthentifiedQuery,
    SquidstudioCategoryGetUnAuthentifiedQueryVariables
  >(SquidstudioCategoryGetUnAuthentifiedDocument, options);
}
export type SquidstudioCategoryGetUnAuthentifiedQueryHookResult = ReturnType<
  typeof useSquidstudioCategoryGetUnAuthentifiedQuery
>;
export type SquidstudioCategoryGetUnAuthentifiedLazyQueryHookResult =
  ReturnType<typeof useSquidstudioCategoryGetUnAuthentifiedLazyQuery>;
export type SquidstudioCategoryGetUnAuthentifiedQueryResult =
  Apollo.QueryResult<
    SquidstudioCategoryGetUnAuthentifiedQuery,
    SquidstudioCategoryGetUnAuthentifiedQueryVariables
  >;
export const VersionLateralDetailDocument = gql`
  query versionLateralDetail($uuid: String!) {
    versionLateralDetail(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      title
      sub_title
      extra_price
      installer_extra_price
      installation_difficulty
      info_title
      info_description
      preview_text
      pictogram {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      preview_image {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      side_glazings {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        extra_price
        installer_extra_price
        info_title
        info_description
        preview_text
        pictogram {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        preview_image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
        }
        version_lateral {
          uuid
          createdAt
          updatedAt
          title
          sub_title
          extra_price
          installer_extra_price
          installation_difficulty
          info_title
          info_description
          preview_text
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          preview_image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          side_glazings {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            extra_price
            installer_extra_price
            info_title
            info_description
            preview_text
          }
          cars_backwards {
            uuid
            createdAt
            updatedAt
            title
            sub_title
            info_title
            info_description
            extra_price
            installer_extra_price
            installation_difficulty
            preview_text
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            preview_image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            version_lateral {
              uuid
              createdAt
              updatedAt
              title
              sub_title
              extra_price
              installer_extra_price
              installation_difficulty
              info_title
              info_description
              preview_text
              car_version {
                uuid
                createdAt
                updatedAt
                title
                sub_title
                info_title
                info_description
                preview_text
                pictogram {
                  uuid
                  createdAt
                  updatedAt
                  file_name
                  url
                  mime_type
                  width
                  height
                  size
                  caption
                  description
                  alternative_title
                }
                version_laterals {
                  uuid
                  createdAt
                  updatedAt
                  title
                  sub_title
                  extra_price
                  installer_extra_price
                  installation_difficulty
                  info_title
                  info_description
                  preview_text
                  pictogram {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  preview_image {
                    uuid
                    createdAt
                    updatedAt
                    file_name
                    url
                    mime_type
                    width
                    height
                    size
                    caption
                    description
                    alternative_title
                  }
                  side_glazings {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    extra_price
                    installer_extra_price
                    info_title
                    info_description
                    preview_text
                  }
                  cars_backwards {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    extra_price
                    installer_extra_price
                    installation_difficulty
                    preview_text
                    car_backward_types {
                      uuid
                      createdAt
                      updatedAt
                      title
                      sub_title
                      info_title
                      info_description
                      has_rear_camera
                      pictogram {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      preview_image {
                        uuid
                        createdAt
                        updatedAt
                        file_name
                        url
                        mime_type
                        width
                        height
                        size
                        caption
                        description
                        alternative_title
                      }
                      car_backward {
                        uuid
                        createdAt
                        updatedAt
                        title
                        sub_title
                        info_title
                        info_description
                        extra_price
                        installer_extra_price
                        installation_difficulty
                        preview_text
                        pictogram {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        preview_image {
                          uuid
                          createdAt
                          updatedAt
                          file_name
                          url
                          mime_type
                          width
                          height
                          size
                          caption
                          description
                          alternative_title
                        }
                        version_lateral {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          extra_price
                          installer_extra_price
                          installation_difficulty
                          info_title
                          info_description
                          preview_text
                        }
                        car_backward_types {
                          uuid
                          createdAt
                          updatedAt
                          title
                          sub_title
                          info_title
                          info_description
                          has_rear_camera
                        }
                      }
                    }
                  }
                  car_version {
                    uuid
                    createdAt
                    updatedAt
                    title
                    sub_title
                    info_title
                    info_description
                    preview_text
                    model_year {
                      uuid
                      createdAt
                      updatedAt
                      start_year
                      end_year
                      info_title
                      info_description
                      brand_model {
                        uuid
                        createdAt
                        updatedAt
                        model_name
                        info_title
                        info_description
                        car_brand {
                          uuid
                          createdAt
                          updatedAt
                          brand_name
                          info_title
                          info_description
                          pictogram {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                          }
                          pictogram_dark {
                            uuid
                            createdAt
                            updatedAt
                            file_name
                            url
                            mime_type
                            width
                            height
                            size
                            caption
                            description
                            alternative_title
                            thumbnail {
                              width
                              height
                              size
                              url
                              file_name
                            }
                            medium {
                              width
                              height
                              size
                              url
                              file_name
                            }
                          }
                          brand_models {
                            uuid
                            createdAt
                            updatedAt
                            model_name
                            info_title
                            info_description
                            car_brand {
                              uuid
                              createdAt
                              updatedAt
                              brand_name
                              info_title
                              info_description
                            }
                            model_years {
                              uuid
                              createdAt
                              updatedAt
                              start_year
                              end_year
                              info_title
                              info_description
                              brand_model {
                                uuid
                                createdAt
                                updatedAt
                                model_name
                                info_title
                                info_description
                                model_years {
                                  uuid
                                  createdAt
                                  updatedAt
                                  start_year
                                  end_year
                                  info_title
                                  info_description
                                  car_versions {
                                    uuid
                                    createdAt
                                    updatedAt
                                    title
                                    sub_title
                                    info_title
                                    info_description
                                    preview_text
                                    pictogram {
                                      uuid
                                      createdAt
                                      updatedAt
                                      file_name
                                      url
                                      mime_type
                                      width
                                      height
                                      size
                                      caption
                                      description
                                      alternative_title
                                    }
                                    version_laterals {
                                      uuid
                                      createdAt
                                      updatedAt
                                      title
                                      sub_title
                                      extra_price
                                      installer_extra_price
                                      installation_difficulty
                                      info_title
                                      info_description
                                      preview_text
                                    }
                                    model_year {
                                      uuid
                                      createdAt
                                      updatedAt
                                      start_year
                                      end_year
                                      info_title
                                      info_description
                                      car_versions {
                                        uuid
                                        createdAt
                                        updatedAt
                                        title
                                        sub_title
                                        info_title
                                        info_description
                                        preview_text
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      cars_backwards {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        extra_price
        installer_extra_price
        installation_difficulty
        preview_text
      }
      car_version {
        uuid
        createdAt
        updatedAt
        title
        sub_title
        info_title
        info_description
        preview_text
      }
    }
  }
`;

/**
 * __useVersionLateralDetailQuery__
 *
 * To run a query within a React component, call `useVersionLateralDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useVersionLateralDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVersionLateralDetailQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useVersionLateralDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    VersionLateralDetailQuery,
    VersionLateralDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VersionLateralDetailQuery,
    VersionLateralDetailQueryVariables
  >(VersionLateralDetailDocument, options);
}
export function useVersionLateralDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VersionLateralDetailQuery,
    VersionLateralDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VersionLateralDetailQuery,
    VersionLateralDetailQueryVariables
  >(VersionLateralDetailDocument, options);
}
export type VersionLateralDetailQueryHookResult = ReturnType<
  typeof useVersionLateralDetailQuery
>;
export type VersionLateralDetailLazyQueryHookResult = ReturnType<
  typeof useVersionLateralDetailLazyQuery
>;
export type VersionLateralDetailQueryResult = Apollo.QueryResult<
  VersionLateralDetailQuery,
  VersionLateralDetailQueryVariables
>;
export const GetProfileDocument = gql`
  query getProfile {
    getProfile {
      user {
        uuid
        createdAt
        updatedAt
        email
        firstName
        lastName
        company_name
        address
        postal_code
        city
        country
        phonenumber
        vehicles {
          brand
          model
          year
          color
        }
        activated
        enabled
        role
      }
    }
  }
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options,
  );
}
export function useGetProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProfileQuery,
    GetProfileQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(
    GetProfileDocument,
    options,
  );
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<
  typeof useGetProfileLazyQuery
>;
export type GetProfileQueryResult = Apollo.QueryResult<
  GetProfileQuery,
  GetProfileQueryVariables
>;
export const EstimateGetDocument = gql`
  query estimateGet($uuid: String!) {
    estimateGet(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      name
      company_name
      phonenumber
      mail
      subject
      vehicle
      description_of_project
      your_mark
      your_model
      year_of_your_vehicle
      marquetry
      status
      file {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
    }
  }
`;

/**
 * __useEstimateGetQuery__
 *
 * To run a query within a React component, call `useEstimateGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimateGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimateGetQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useEstimateGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    EstimateGetQuery,
    EstimateGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstimateGetQuery, EstimateGetQueryVariables>(
    EstimateGetDocument,
    options,
  );
}
export function useEstimateGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstimateGetQuery,
    EstimateGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstimateGetQuery, EstimateGetQueryVariables>(
    EstimateGetDocument,
    options,
  );
}
export type EstimateGetQueryHookResult = ReturnType<typeof useEstimateGetQuery>;
export type EstimateGetLazyQueryHookResult = ReturnType<
  typeof useEstimateGetLazyQuery
>;
export type EstimateGetQueryResult = Apollo.QueryResult<
  EstimateGetQuery,
  EstimateGetQueryVariables
>;
export const EstimateListDocument = gql`
  query estimateList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: EstimatePaginationSortBy
    $status: String
  ) {
    estimateList(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
      status: $status
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        name
        company_name
        phonenumber
        mail
        subject
        status
        vehicle
        description_of_project
        your_mark
        your_model
        year_of_your_vehicle
        marquetry
        file {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;

/**
 * __useEstimateListQuery__
 *
 * To run a query within a React component, call `useEstimateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimateListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useEstimateListQuery(
  baseOptions: Apollo.QueryHookOptions<
    EstimateListQuery,
    EstimateListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EstimateListQuery, EstimateListQueryVariables>(
    EstimateListDocument,
    options,
  );
}
export function useEstimateListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EstimateListQuery,
    EstimateListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EstimateListQuery, EstimateListQueryVariables>(
    EstimateListDocument,
    options,
  );
}
export type EstimateListQueryHookResult = ReturnType<
  typeof useEstimateListQuery
>;
export type EstimateListLazyQueryHookResult = ReturnType<
  typeof useEstimateListLazyQuery
>;
export type EstimateListQueryResult = Apollo.QueryResult<
  EstimateListQuery,
  EstimateListQueryVariables
>;
export const ReviewDocument = gql`
  query review($uuid: String!) {
    review(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      text
      note
      display_on_home
      display_on_product
      products {
        uuid
        createdAt
        updatedAt
        title
        subtitle
        type
        description
        slug
        meta_title
        meta_description
        verification
        graphic_service
        professional_installer
        enabled
        index
        start_price
        image {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        supports {
          uuid
          createdAt
          updatedAt
          title
          can_have_background
          extra_price
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
        sizes {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          need_vehicle_info
          price
          index
          installer_price
          canvas_width
          canvas_height
          canvas_radius
          overview
          video_url
          design_choice_possible
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
        sections {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          can_have_background
          extra_price
          canvas_width
          canvas_height
          canvas_radius
          extra_price_size {
            size_uuid
            extra_price
          }
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
          }
        }
        extra_stickers {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          help_title
          help
          design_choice_possible
          extra_price
          extra_price_sections {
            section_uuid
            extra_price
          }
          installer_extra_price
          overview
          video_url
          installation_difficulty
          pictogram {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          depends_on_sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
          }
        }
        templates {
          uuid
          createdAt
          updatedAt
          title
          file {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
        product_images {
          uuid
          createdAt
          updatedAt
          support_uuid
          size_uuid
          section_uuid
          extra_sticker_uuid
          file {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
        }
      }
      user {
        uuid
        createdAt
        updatedAt
        email
        firstName
        lastName
        company_name
        address
        postal_code
        city
        country
        phonenumber
        vehicles {
          brand
          model
          year
          color
        }
        activated
        enabled
        role
      }
      order {
        uuid
        createdAt
        updatedAt
        order_code
        already_add_review
        sub_total
        shipping_cost
        total_without_vat
        start_date_of_delivery
        end_date_of_delivery
        vat
        total_all_vat
        discount_amount
        payment_method
        ordering_status
        delivery_mode
        invoice {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        discount_code {
          uuid
          createdAt
          updatedAt
          code
          value
          use_before
          max_use_times
          used_times
          saved_money
          total_buy_amount
          is_percentage
        }
        stripe_payment_data {
          status
          id
          payment_link
          balance_transaction
          payment_method
          receipt_url
          source {
            id
            brand
            country
            last4
            exp_month
            exp_year
          }
        }
        has_quote
        user {
          uuid
          createdAt
          updatedAt
          email
          firstName
          lastName
          company_name
          address
          postal_code
          city
          country
          phonenumber
          activated
          enabled
          role
        }
        delivery_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        billing_address {
          uuid
          createdAt
          updatedAt
          firstname
          lastname
          company_name
          address
          postal_code
          city
          country
          phonenumber
          email
        }
        order_products {
          uuid
          createdAt
          updatedAt
          verification
          graphic_service
          professional_installer
          synthetic_design
          custom_design
          design_is_finished
          quantity
          total
          squid_studio_data {
            width
            height
            radius
            can_have_background
            data
          }
          vehicle_information {
            brand
            model
            year
            color
          }
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
          }
          image_pdf {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          image_svg {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          product {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            type
            description
            slug
            meta_title
            meta_description
            verification
            graphic_service
            professional_installer
            enabled
            index
            start_price
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
            sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
            sections {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              installation_difficulty
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              installer_extra_price
              overview
              video_url
              installation_difficulty
            }
            templates {
              uuid
              createdAt
              updatedAt
              title
            }
            product_images {
              uuid
              createdAt
              updatedAt
              support_uuid
              size_uuid
              section_uuid
              extra_sticker_uuid
            }
          }
          size {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
            }
          }
          support {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
          section {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            installer_extra_price
            overview
            video_url
            installation_difficulty
          }
        }
      }
    }
  }
`;

/**
 * __useReviewQuery__
 *
 * To run a query within a React component, call `useReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useReviewQuery(
  baseOptions: Apollo.QueryHookOptions<ReviewQuery, ReviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewQuery, ReviewQueryVariables>(
    ReviewDocument,
    options,
  );
}
export function useReviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReviewQuery, ReviewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewQuery, ReviewQueryVariables>(
    ReviewDocument,
    options,
  );
}
export type ReviewQueryHookResult = ReturnType<typeof useReviewQuery>;
export type ReviewLazyQueryHookResult = ReturnType<typeof useReviewLazyQuery>;
export type ReviewQueryResult = Apollo.QueryResult<
  ReviewQuery,
  ReviewQueryVariables
>;
export const ReviewListDocument = gql`
  query reviewList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: ReviewPaginationSortBy
    $note: Float
    $display_on_home: Boolean
    $display_on_product: Boolean
  ) {
    reviewList(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
      note: $note
      display_on_home: $display_on_home
      display_on_product: $display_on_product
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        text
        note
        display_on_home
        display_on_product
        products {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          type
          description
          slug
          meta_title
          meta_description
          verification
          graphic_service
          professional_installer
          enabled
          index
          start_price
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          sections {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            extra_price_sections {
              section_uuid
              extra_price
            }
            installer_extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          templates {
            uuid
            createdAt
            updatedAt
            title
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          product_images {
            uuid
            createdAt
            updatedAt
            support_uuid
            size_uuid
            section_uuid
            extra_sticker_uuid
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
        user {
          uuid
          createdAt
          updatedAt
          email
          firstName
          lastName
          company_name
          address
          postal_code
          city
          country
          phonenumber
          vehicles {
            brand
            model
            year
            color
          }
          activated
          enabled
          role
        }
        order {
          uuid
          createdAt
          updatedAt
          order_code
          already_add_review
          sub_total
          shipping_cost
          total_without_vat
          start_date_of_delivery
          end_date_of_delivery
          vat
          total_all_vat
          discount_amount
          payment_method
          ordering_status
          delivery_mode
          invoice {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          discount_code {
            uuid
            createdAt
            updatedAt
            code
            value
            use_before
            max_use_times
            used_times
            saved_money
            total_buy_amount
            is_percentage
          }
          stripe_payment_data {
            status
            id
            payment_link
            balance_transaction
            payment_method
            receipt_url
            source {
              id
              brand
              country
              last4
              exp_month
              exp_year
            }
          }
          has_quote
          user {
            uuid
            createdAt
            updatedAt
            email
            firstName
            lastName
            company_name
            address
            postal_code
            city
            country
            phonenumber
            activated
            enabled
            role
          }
          delivery_address {
            uuid
            createdAt
            updatedAt
            firstname
            lastname
            company_name
            address
            postal_code
            city
            country
            phonenumber
            email
          }
          billing_address {
            uuid
            createdAt
            updatedAt
            firstname
            lastname
            company_name
            address
            postal_code
            city
            country
            phonenumber
            email
          }
          order_products {
            uuid
            createdAt
            updatedAt
            verification
            graphic_service
            professional_installer
            synthetic_design
            custom_design
            design_is_finished
            quantity
            total
            squid_studio_data {
              width
              height
              radius
              can_have_background
              data
            }
            vehicle_information {
              brand
              model
              year
              color
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image_pdf {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            image_svg {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            product {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              type
              description
              slug
              meta_title
              meta_description
              verification
              graphic_service
              professional_installer
              enabled
              index
              start_price
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
              sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
              sections {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                can_have_background
                extra_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                installation_difficulty
              }
              extra_stickers {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                design_choice_possible
                extra_price
                installer_extra_price
                overview
                video_url
                installation_difficulty
              }
              templates {
                uuid
                createdAt
                updatedAt
                title
              }
              product_images {
                uuid
                createdAt
                updatedAt
                support_uuid
                size_uuid
                section_uuid
                extra_sticker_uuid
              }
            }
            size {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
            }
            support {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
            section {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              extra_price_size {
                size_uuid
                extra_price
              }
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              installer_extra_price
              overview
              video_url
              installation_difficulty
            }
          }
        }
      }
    }
  }
`;

/**
 * __useReviewListQuery__
 *
 * To run a query within a React component, call `useReviewListQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *      note: // value for 'note'
 *      display_on_home: // value for 'display_on_home'
 *      display_on_product: // value for 'display_on_product'
 *   },
 * });
 */
export function useReviewListQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReviewListQuery,
    ReviewListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReviewListQuery, ReviewListQueryVariables>(
    ReviewListDocument,
    options,
  );
}
export function useReviewListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReviewListQuery,
    ReviewListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReviewListQuery, ReviewListQueryVariables>(
    ReviewListDocument,
    options,
  );
}
export type ReviewListQueryHookResult = ReturnType<typeof useReviewListQuery>;
export type ReviewListLazyQueryHookResult = ReturnType<
  typeof useReviewListLazyQuery
>;
export type ReviewListQueryResult = Apollo.QueryResult<
  ReviewListQuery,
  ReviewListQueryVariables
>;
export const ReviewListByOrderDocument = gql`
  query reviewListByOrder(
    $orderUuid: String!
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: ReviewPaginationSortBy
    $note: Float
    $display_on_home: Boolean
    $display_on_product: Boolean
  ) {
    reviewListByOrder(
      orderUuid: $orderUuid
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
      note: $note
      display_on_home: $display_on_home
      display_on_product: $display_on_product
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        text
        note
        display_on_home
        display_on_product
        products {
          uuid
          createdAt
          updatedAt
          title
          subtitle
          type
          description
          slug
          meta_title
          meta_description
          verification
          graphic_service
          professional_installer
          enabled
          index
          start_price
          image {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          supports {
            uuid
            createdAt
            updatedAt
            title
            can_have_background
            extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          sizes {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            need_vehicle_info
            price
            index
            installer_price
            canvas_width
            canvas_height
            canvas_radius
            overview
            video_url
            design_choice_possible
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_supports {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
          }
          sections {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            can_have_background
            extra_price
            canvas_width
            canvas_height
            canvas_radius
            extra_price_size {
              size_uuid
              extra_price
            }
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
            }
          }
          extra_stickers {
            uuid
            createdAt
            updatedAt
            title
            subtitle
            help_title
            help
            design_choice_possible
            extra_price
            extra_price_sections {
              section_uuid
              extra_price
            }
            installer_extra_price
            overview
            video_url
            installation_difficulty
            pictogram {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            depends_on_sizes {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
            }
          }
          templates {
            uuid
            createdAt
            updatedAt
            title
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
          }
          product_images {
            uuid
            createdAt
            updatedAt
            support_uuid
            size_uuid
            section_uuid
            extra_sticker_uuid
            file {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
          }
        }
        user {
          uuid
          createdAt
          updatedAt
          email
          firstName
          lastName
          company_name
          address
          postal_code
          city
          country
          phonenumber
          vehicles {
            brand
            model
            year
            color
          }
          activated
          enabled
          role
        }
        order {
          uuid
          createdAt
          updatedAt
          order_code
          already_add_review
          sub_total
          shipping_cost
          total_without_vat
          start_date_of_delivery
          end_date_of_delivery
          vat
          total_all_vat
          discount_amount
          payment_method
          ordering_status
          delivery_mode
          invoice {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
          discount_code {
            uuid
            createdAt
            updatedAt
            code
            value
            use_before
            max_use_times
            used_times
            saved_money
            total_buy_amount
            is_percentage
          }
          stripe_payment_data {
            status
            id
            payment_link
            balance_transaction
            payment_method
            receipt_url
            source {
              id
              brand
              country
              last4
              exp_month
              exp_year
            }
          }
          has_quote
          user {
            uuid
            createdAt
            updatedAt
            email
            firstName
            lastName
            company_name
            address
            postal_code
            city
            country
            phonenumber
            activated
            enabled
            role
          }
          delivery_address {
            uuid
            createdAt
            updatedAt
            firstname
            lastname
            company_name
            address
            postal_code
            city
            country
            phonenumber
            email
          }
          billing_address {
            uuid
            createdAt
            updatedAt
            firstname
            lastname
            company_name
            address
            postal_code
            city
            country
            phonenumber
            email
          }
          order_products {
            uuid
            createdAt
            updatedAt
            verification
            graphic_service
            professional_installer
            synthetic_design
            custom_design
            design_is_finished
            quantity
            total
            squid_studio_data {
              width
              height
              radius
              can_have_background
              data
            }
            vehicle_information {
              brand
              model
              year
              color
            }
            image {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
            }
            image_pdf {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            image_svg {
              uuid
              createdAt
              updatedAt
              file_name
              url
              mime_type
              width
              height
              size
              caption
              description
              alternative_title
              thumbnail {
                width
                height
                size
                url
                file_name
              }
              medium {
                width
                height
                size
                url
                file_name
              }
            }
            product {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              type
              description
              slug
              meta_title
              meta_description
              verification
              graphic_service
              professional_installer
              enabled
              index
              start_price
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
              sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
              sections {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                can_have_background
                extra_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                installation_difficulty
              }
              extra_stickers {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                design_choice_possible
                extra_price
                installer_extra_price
                overview
                video_url
                installation_difficulty
              }
              templates {
                uuid
                createdAt
                updatedAt
                title
              }
              product_images {
                uuid
                createdAt
                updatedAt
                support_uuid
                size_uuid
                section_uuid
                extra_sticker_uuid
              }
            }
            size {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              need_vehicle_info
              price
              index
              installer_price
              canvas_width
              canvas_height
              canvas_radius
              overview
              video_url
              design_choice_possible
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              image {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_supports {
                uuid
                createdAt
                updatedAt
                title
                can_have_background
                extra_price
                overview
                video_url
                installation_difficulty
              }
            }
            support {
              uuid
              createdAt
              updatedAt
              title
              can_have_background
              extra_price
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
            }
            section {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              can_have_background
              extra_price
              canvas_width
              canvas_height
              canvas_radius
              extra_price_size {
                size_uuid
                extra_price
              }
              overview
              video_url
              installation_difficulty
              pictogram {
                uuid
                createdAt
                updatedAt
                file_name
                url
                mime_type
                width
                height
                size
                caption
                description
                alternative_title
              }
              depends_on_sizes {
                uuid
                createdAt
                updatedAt
                title
                subtitle
                help_title
                help
                need_vehicle_info
                price
                index
                installer_price
                canvas_width
                canvas_height
                canvas_radius
                overview
                video_url
                design_choice_possible
                installation_difficulty
              }
            }
            extra_stickers {
              uuid
              createdAt
              updatedAt
              title
              subtitle
              help_title
              help
              design_choice_possible
              extra_price
              installer_extra_price
              overview
              video_url
              installation_difficulty
            }
          }
        }
      }
    }
  }
`;

/**
 * __useReviewListByOrderQuery__
 *
 * To run a query within a React component, call `useReviewListByOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewListByOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewListByOrderQuery({
 *   variables: {
 *      orderUuid: // value for 'orderUuid'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *      note: // value for 'note'
 *      display_on_home: // value for 'display_on_home'
 *      display_on_product: // value for 'display_on_product'
 *   },
 * });
 */
export function useReviewListByOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReviewListByOrderQuery,
    ReviewListByOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ReviewListByOrderQuery,
    ReviewListByOrderQueryVariables
  >(ReviewListByOrderDocument, options);
}
export function useReviewListByOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReviewListByOrderQuery,
    ReviewListByOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReviewListByOrderQuery,
    ReviewListByOrderQueryVariables
  >(ReviewListByOrderDocument, options);
}
export type ReviewListByOrderQueryHookResult = ReturnType<
  typeof useReviewListByOrderQuery
>;
export type ReviewListByOrderLazyQueryHookResult = ReturnType<
  typeof useReviewListByOrderLazyQuery
>;
export type ReviewListByOrderQueryResult = Apollo.QueryResult<
  ReviewListByOrderQuery,
  ReviewListByOrderQueryVariables
>;
export const FindAllSquidstudioShapeDocument = gql`
  query findAllSquidstudioShape($uuid: String!) {
    findAllSquidstudioShape(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      clipPath
      width
      height
      desc
      background
      img {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
    }
  }
`;

/**
 * __useFindAllSquidstudioShapeQuery__
 *
 * To run a query within a React component, call `useFindAllSquidstudioShapeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllSquidstudioShapeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllSquidstudioShapeQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useFindAllSquidstudioShapeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAllSquidstudioShapeQuery,
    FindAllSquidstudioShapeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAllSquidstudioShapeQuery,
    FindAllSquidstudioShapeQueryVariables
  >(FindAllSquidstudioShapeDocument, options);
}
export function useFindAllSquidstudioShapeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAllSquidstudioShapeQuery,
    FindAllSquidstudioShapeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAllSquidstudioShapeQuery,
    FindAllSquidstudioShapeQueryVariables
  >(FindAllSquidstudioShapeDocument, options);
}
export type FindAllSquidstudioShapeQueryHookResult = ReturnType<
  typeof useFindAllSquidstudioShapeQuery
>;
export type FindAllSquidstudioShapeLazyQueryHookResult = ReturnType<
  typeof useFindAllSquidstudioShapeLazyQuery
>;
export type FindAllSquidstudioShapeQueryResult = Apollo.QueryResult<
  FindAllSquidstudioShapeQuery,
  FindAllSquidstudioShapeQueryVariables
>;
export const SquidstudioShapeCategoriesListDocument = gql`
  query squidstudioShapeCategoriesList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: SquidstudioShapeCategoryPaginationSortBy
  ) {
    squidstudioShapeCategoriesList(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        icon {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        shapes {
          uuid
          createdAt
          updatedAt
          clipPath
          width
          height
          desc
          background
          img {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSquidstudioShapeCategoriesListQuery__
 *
 * To run a query within a React component, call `useSquidstudioShapeCategoriesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioShapeCategoriesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSquidstudioShapeCategoriesListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useSquidstudioShapeCategoriesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    SquidstudioShapeCategoriesListQuery,
    SquidstudioShapeCategoriesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SquidstudioShapeCategoriesListQuery,
    SquidstudioShapeCategoriesListQueryVariables
  >(SquidstudioShapeCategoriesListDocument, options);
}
export function useSquidstudioShapeCategoriesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SquidstudioShapeCategoriesListQuery,
    SquidstudioShapeCategoriesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SquidstudioShapeCategoriesListQuery,
    SquidstudioShapeCategoriesListQueryVariables
  >(SquidstudioShapeCategoriesListDocument, options);
}
export type SquidstudioShapeCategoriesListQueryHookResult = ReturnType<
  typeof useSquidstudioShapeCategoriesListQuery
>;
export type SquidstudioShapeCategoriesListLazyQueryHookResult = ReturnType<
  typeof useSquidstudioShapeCategoriesListLazyQuery
>;
export type SquidstudioShapeCategoriesListQueryResult = Apollo.QueryResult<
  SquidstudioShapeCategoriesListQuery,
  SquidstudioShapeCategoriesListQueryVariables
>;
export const SquidstudioShapeCategoriesListUnAuthentifiedDocument = gql`
  query squidstudioShapeCategoriesListUnAuthentified(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: SquidstudioShapeCategoryPaginationSortBy
  ) {
    squidstudioShapeCategoriesListUnAuthentified(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        title
        icon {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
        shapes {
          uuid
          createdAt
          updatedAt
          clipPath
          width
          height
          desc
          background
          img {
            uuid
            createdAt
            updatedAt
            file_name
            url
            mime_type
            width
            height
            size
            caption
            description
            alternative_title
            thumbnail {
              width
              height
              size
              url
              file_name
            }
            medium {
              width
              height
              size
              url
              file_name
            }
          }
        }
      }
    }
  }
`;

/**
 * __useSquidstudioShapeCategoriesListUnAuthentifiedQuery__
 *
 * To run a query within a React component, call `useSquidstudioShapeCategoriesListUnAuthentifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioShapeCategoriesListUnAuthentifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSquidstudioShapeCategoriesListUnAuthentifiedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useSquidstudioShapeCategoriesListUnAuthentifiedQuery(
  baseOptions: Apollo.QueryHookOptions<
    SquidstudioShapeCategoriesListUnAuthentifiedQuery,
    SquidstudioShapeCategoriesListUnAuthentifiedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SquidstudioShapeCategoriesListUnAuthentifiedQuery,
    SquidstudioShapeCategoriesListUnAuthentifiedQueryVariables
  >(SquidstudioShapeCategoriesListUnAuthentifiedDocument, options);
}
export function useSquidstudioShapeCategoriesListUnAuthentifiedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SquidstudioShapeCategoriesListUnAuthentifiedQuery,
    SquidstudioShapeCategoriesListUnAuthentifiedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SquidstudioShapeCategoriesListUnAuthentifiedQuery,
    SquidstudioShapeCategoriesListUnAuthentifiedQueryVariables
  >(SquidstudioShapeCategoriesListUnAuthentifiedDocument, options);
}
export type SquidstudioShapeCategoriesListUnAuthentifiedQueryHookResult =
  ReturnType<typeof useSquidstudioShapeCategoriesListUnAuthentifiedQuery>;
export type SquidstudioShapeCategoriesListUnAuthentifiedLazyQueryHookResult =
  ReturnType<typeof useSquidstudioShapeCategoriesListUnAuthentifiedLazyQuery>;
export type SquidstudioShapeCategoriesListUnAuthentifiedQueryResult =
  Apollo.QueryResult<
    SquidstudioShapeCategoriesListUnAuthentifiedQuery,
    SquidstudioShapeCategoriesListUnAuthentifiedQueryVariables
  >;
export const SquidstudioShapeCategoryGetDocument = gql`
  query squidstudioShapeCategoryGet($uuid: String!) {
    squidstudioShapeCategoryGet(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      title
      icon {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      shapes {
        uuid
        createdAt
        updatedAt
        clipPath
        width
        height
        desc
        background
        img {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;

/**
 * __useSquidstudioShapeCategoryGetQuery__
 *
 * To run a query within a React component, call `useSquidstudioShapeCategoryGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioShapeCategoryGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSquidstudioShapeCategoryGetQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSquidstudioShapeCategoryGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    SquidstudioShapeCategoryGetQuery,
    SquidstudioShapeCategoryGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SquidstudioShapeCategoryGetQuery,
    SquidstudioShapeCategoryGetQueryVariables
  >(SquidstudioShapeCategoryGetDocument, options);
}
export function useSquidstudioShapeCategoryGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SquidstudioShapeCategoryGetQuery,
    SquidstudioShapeCategoryGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SquidstudioShapeCategoryGetQuery,
    SquidstudioShapeCategoryGetQueryVariables
  >(SquidstudioShapeCategoryGetDocument, options);
}
export type SquidstudioShapeCategoryGetQueryHookResult = ReturnType<
  typeof useSquidstudioShapeCategoryGetQuery
>;
export type SquidstudioShapeCategoryGetLazyQueryHookResult = ReturnType<
  typeof useSquidstudioShapeCategoryGetLazyQuery
>;
export type SquidstudioShapeCategoryGetQueryResult = Apollo.QueryResult<
  SquidstudioShapeCategoryGetQuery,
  SquidstudioShapeCategoryGetQueryVariables
>;
export const SquidstudioShapeCategoryGetUnAuthentifiedDocument = gql`
  query squidstudioShapeCategoryGetUnAuthentified($uuid: String!) {
    squidstudioShapeCategoryGetUnAuthentified(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      title
      icon {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
      shapes {
        uuid
        createdAt
        updatedAt
        clipPath
        width
        height
        desc
        background
        img {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;

/**
 * __useSquidstudioShapeCategoryGetUnAuthentifiedQuery__
 *
 * To run a query within a React component, call `useSquidstudioShapeCategoryGetUnAuthentifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioShapeCategoryGetUnAuthentifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSquidstudioShapeCategoryGetUnAuthentifiedQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSquidstudioShapeCategoryGetUnAuthentifiedQuery(
  baseOptions: Apollo.QueryHookOptions<
    SquidstudioShapeCategoryGetUnAuthentifiedQuery,
    SquidstudioShapeCategoryGetUnAuthentifiedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SquidstudioShapeCategoryGetUnAuthentifiedQuery,
    SquidstudioShapeCategoryGetUnAuthentifiedQueryVariables
  >(SquidstudioShapeCategoryGetUnAuthentifiedDocument, options);
}
export function useSquidstudioShapeCategoryGetUnAuthentifiedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SquidstudioShapeCategoryGetUnAuthentifiedQuery,
    SquidstudioShapeCategoryGetUnAuthentifiedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SquidstudioShapeCategoryGetUnAuthentifiedQuery,
    SquidstudioShapeCategoryGetUnAuthentifiedQueryVariables
  >(SquidstudioShapeCategoryGetUnAuthentifiedDocument, options);
}
export type SquidstudioShapeCategoryGetUnAuthentifiedQueryHookResult =
  ReturnType<typeof useSquidstudioShapeCategoryGetUnAuthentifiedQuery>;
export type SquidstudioShapeCategoryGetUnAuthentifiedLazyQueryHookResult =
  ReturnType<typeof useSquidstudioShapeCategoryGetUnAuthentifiedLazyQuery>;
export type SquidstudioShapeCategoryGetUnAuthentifiedQueryResult =
  Apollo.QueryResult<
    SquidstudioShapeCategoryGetUnAuthentifiedQuery,
    SquidstudioShapeCategoryGetUnAuthentifiedQueryVariables
  >;
export const SquidstudioShapeGetDocument = gql`
  query squidstudioShapeGet($uuid: String!) {
    squidstudioShapeGet(uuid: $uuid) {
      uuid
      createdAt
      updatedAt
      clipPath
      width
      height
      desc
      background
      img {
        uuid
        createdAt
        updatedAt
        file_name
        url
        mime_type
        width
        height
        size
        caption
        description
        alternative_title
        thumbnail {
          width
          height
          size
          url
          file_name
        }
        medium {
          width
          height
          size
          url
          file_name
        }
      }
    }
  }
`;

/**
 * __useSquidstudioShapeGetQuery__
 *
 * To run a query within a React component, call `useSquidstudioShapeGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioShapeGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSquidstudioShapeGetQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSquidstudioShapeGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    SquidstudioShapeGetQuery,
    SquidstudioShapeGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SquidstudioShapeGetQuery,
    SquidstudioShapeGetQueryVariables
  >(SquidstudioShapeGetDocument, options);
}
export function useSquidstudioShapeGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SquidstudioShapeGetQuery,
    SquidstudioShapeGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SquidstudioShapeGetQuery,
    SquidstudioShapeGetQueryVariables
  >(SquidstudioShapeGetDocument, options);
}
export type SquidstudioShapeGetQueryHookResult = ReturnType<
  typeof useSquidstudioShapeGetQuery
>;
export type SquidstudioShapeGetLazyQueryHookResult = ReturnType<
  typeof useSquidstudioShapeGetLazyQuery
>;
export type SquidstudioShapeGetQueryResult = Apollo.QueryResult<
  SquidstudioShapeGetQuery,
  SquidstudioShapeGetQueryVariables
>;
export const SquidstudioTemplatesListDocument = gql`
  query squidstudioTemplatesList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: SquidstudioTemplatePaginationSortBy
  ) {
    squidstudioTemplatesList(
      skip: $skip
      take: $take
      keyword: $keyword
      sortBy: $sortBy
    ) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        data
        desc
        sizes {
          width
          height
        }
        img {
          uuid
          createdAt
          updatedAt
          file_name
          url
          mime_type
          width
          height
          size
          caption
          description
          alternative_title
          thumbnail {
            width
            height
            size
            url
            file_name
          }
          medium {
            width
            height
            size
            url
            file_name
          }
        }
      }
    }
  }
`;

/**
 * __useSquidstudioTemplatesListQuery__
 *
 * To run a query within a React component, call `useSquidstudioTemplatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSquidstudioTemplatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSquidstudioTemplatesListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useSquidstudioTemplatesListQuery(
  baseOptions: Apollo.QueryHookOptions<
    SquidstudioTemplatesListQuery,
    SquidstudioTemplatesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SquidstudioTemplatesListQuery,
    SquidstudioTemplatesListQueryVariables
  >(SquidstudioTemplatesListDocument, options);
}
export function useSquidstudioTemplatesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SquidstudioTemplatesListQuery,
    SquidstudioTemplatesListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SquidstudioTemplatesListQuery,
    SquidstudioTemplatesListQueryVariables
  >(SquidstudioTemplatesListDocument, options);
}
export type SquidstudioTemplatesListQueryHookResult = ReturnType<
  typeof useSquidstudioTemplatesListQuery
>;
export type SquidstudioTemplatesListLazyQueryHookResult = ReturnType<
  typeof useSquidstudioTemplatesListLazyQuery
>;
export type SquidstudioTemplatesListQueryResult = Apollo.QueryResult<
  SquidstudioTemplatesListQuery,
  SquidstudioTemplatesListQueryVariables
>;
export const UsersListDocument = gql`
  query usersList(
    $skip: Int!
    $take: Int!
    $keyword: String
    $sortBy: UsersPaginationSortBy
  ) {
    usersList(skip: $skip, take: $take, keyword: $keyword, sortBy: $sortBy) {
      totalCount
      nodes {
        uuid
        createdAt
        updatedAt
        email
        firstName
        lastName
        activated
        enabled
        role
      }
    }
  }
`;

/**
 * __useUsersListQuery__
 *
 * To run a query within a React component, call `useUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      keyword: // value for 'keyword'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useUsersListQuery(
  baseOptions: Apollo.QueryHookOptions<UsersListQuery, UsersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersListQuery, UsersListQueryVariables>(
    UsersListDocument,
    options,
  );
}
export function useUsersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UsersListQuery,
    UsersListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersListQuery, UsersListQueryVariables>(
    UsersListDocument,
    options,
  );
}
export type UsersListQueryHookResult = ReturnType<typeof useUsersListQuery>;
export type UsersListLazyQueryHookResult = ReturnType<
  typeof useUsersListLazyQuery
>;
export type UsersListQueryResult = Apollo.QueryResult<
  UsersListQuery,
  UsersListQueryVariables
>;
